import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
const { VITE_BASE_URL } = import.meta.env

const getToken = (state) => state.authSetup.token

export const clientApi = createApi({
	reducerPath: 'clientApi',
	tagTypes: ['clientApi'],
	baseQuery: fetchBaseQuery({
		baseUrl: `${VITE_BASE_URL}/v1/user`,
		prepareHeaders: (headers, { getState }) => {
			const token = getToken(getState())
			if (token) {
				headers.set('Authorization', `Bearer ${token}`)
			}
			return headers
		},
	}),
	endpoints: (builder) => ({
		getClientData: builder.query({
			query: (url) => `/${url}`,
		}),
		getParentsData: builder.query({
			query: () => '/parent',
		}),
		getClientChild: builder.query({
			query: () => '/child',
		}),
		getScheduleData: builder.query({
			query: (month) => `/schedules?month=${month}`,
		}),
		getInvoiceData: builder.query({
			query: ({ keyword }) => `/invoices?keyword=${keyword}`,
		}),
		getInvoiceDetail: builder.query({
			query: (id) => `/invoices/${id}`,
		}),
		updateClientData: builder.mutation({
			query: ({ id, ...payload }) => ({
				url: `/profile`,
				method: 'PUT',
				body: payload,
			}),
		}),
		updateClientParent: builder.mutation({
			query: ({ id, ...payload }) => ({
				url: `/parent/${id}`,
				method: 'PUT',
				body: payload,
			}),
		}),
		uploadPaymentEvidence: builder.mutation({
			query: ({ invoiceId, ...payload }) => ({
				url: `/invoices/${invoiceId}/payment-bill`,
				method: 'PUT',
				body: payload,
			}),
		}),
		exportInvoiceUser: builder.mutation({
			query: (id) => `/invoices/${id}/export`,
		}),
	}),
	refetchOnMountOrArgChange: true,
	refetchOnFocus: true,
})

export const {
	useGetClientDataQuery,
	useGetParentsDataQuery,
	useGetScheduleDataQuery,
	useGetInvoiceDataQuery,
	useGetInvoiceDetailQuery,
	useExportInvoiceUserMutation,
	useGetClientChildQuery,
	useUploadPaymentEvidenceMutation,
	useUpdateClientDataMutation,
	useUpdateClientParentMutation
} = clientApi
