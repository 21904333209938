import React, { useState } from 'react'
import { BiEdit, BiSolidTrashAlt } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'

import classNames from 'classnames'
import { FaCheck, FaEye } from 'react-icons/fa6'
import { useSelector } from 'react-redux'
import {
	Button,
	Col,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	NavItem,
	NavLink,
	Row,
	TabContent,
	TabPane,
	Toast,
	ToastBody,
	ToastHeader,
} from 'reactstrap'
import { layoutSetupSelector } from '../../../app/layoutSlice'
import ActiveBadge from '../../../components/templates/ActiveBadge'
import { formatDateToIndonesian } from '../../../helpers/GlobalHelper'
import { useGetMasterDataQuery } from '../../general/api/masterApi'
import {
	useDeleteChildMutation,
	useGetListChildQuery,
	usePostGetChildByIdMutation,
} from '../api/patientApi'

const initSuccessDelete = {
	status: false,
	message: '',
}

const ActionButtonPatient = ({ id, serviceName, dataSource, parentData }) => {
	const navigate = useNavigate()
	const [showModal, setShowModal] = useState(false)
	const [showDetailPatient, setShowDetailPatient] = useState(false)
	const [successDelete, setSuccessDelete] = useState(initSuccessDelete)
	const [childDetail, setChildDetail] = useState(null)
	const [activeTab, setActiveTab] = useState(1)

	const { tablePage, tableKeyword } = useSelector(layoutSetupSelector)
	const [deleteChild, { isLoading }] = useDeleteChildMutation()
	const [postGetChildById, { isLoading: loadingChild }] =
		usePostGetChildByIdMutation()
	const { refetch } = useGetListChildQuery({ page: tablePage, keyword: tableKeyword })
	const {
		data: { data: religionData = [] } = {},
		isLoading: loadinDataReligion,
	} = useGetMasterDataQuery({ endpoint: 'religion' })
	const {
		data: { data: educationData = [] } = {},
		isLoading: loadingDataEducation,
	} = useGetMasterDataQuery({ endpoint: 'educations' })

	const toggleModal = () => {
		setShowModal(!showModal)
	}

	const deleteHandler = async (id) => {
		try {
			const result = await deleteChild(id)

			setShowModal(!showModal)
			setSuccessDelete({
				status: true,
				message: result.data.message,
			})

			setTimeout(() => {
				refetch()
			}, 800)
		} catch (error) {
			console.error(error)

		}
	}

	const toggleDetail = async () => {
		setShowDetailPatient(!showDetailPatient)

		try {
			// ? only fetch when modal is show
			if (!showDetailPatient) {
				const result = await postGetChildById(id).unwrap()
				if (result.status === 'ok') {
					setChildDetail({ ...result.data })
				}
			} else {
				setTimeout(() => {
					setChildDetail(null)
				}, 800)
			}
		} catch (error) {
			console.error(error)

		}
	}

	if (loadinDataReligion || loadingDataEducation) {
		return <div>Loading....</div>
	}

	return (
		<>
			<div className="d-flex gap-2">
				<button
					className="btn text-primary p-0 btn-light p-1"
					onClick={toggleDetail}
				>
					<FaEye size={16} />
				</button>
				<button
					className="btn text-primary p-0 btn-light p-1"
					onClick={() => navigate(`/data-patient/edit-patient/${id}`)}
				>
					<BiEdit size={16} />
				</button>
				<button
					className="btn text-primary p-0 btn-light p-1"
					onClick={toggleModal}
				>
					<BiSolidTrashAlt size={16} />
				</button>
			</div>
			{successDelete.status && (
				<Toast className="position-absolute" style={{ left: '35%', top: '1%' }}>
					<ToastHeader
						icon={<FaCheck className="text-success" />}
						className="text-success"
					>
						Success Delete
					</ToastHeader>
					<ToastBody>{successDelete.message}</ToastBody>
				</Toast>
			)}
			<div>
				<Modal isOpen={showModal} toggle={toggleModal} centered>
					<ModalHeader toggle={toggleModal}>
						Hapus <span className="fw-bold">{serviceName}</span> ?
					</ModalHeader>
					<ModalBody>
						<p className="mb-0">
							Yakin ingin menghapus
							<span className="fw-bold"> {serviceName}?</span> Anda tidak bisa
							mengembalikan data yang sudah dihapus
						</p>
					</ModalBody>
					<ModalFooter>
						<Button
							color="danger"
							onClick={() => deleteHandler(id)}
							disabled={isLoading}
						>
							Hapus
						</Button>{' '}
						<Button color="light" onClick={toggleModal} disabled={isLoading}>
							Cancel
						</Button>
					</ModalFooter>
				</Modal>
			</div>
			<div>
				<Modal
					isOpen={showDetailPatient}
					toggle={toggleDetail}
					centered
					size="lg"
				>
					{!loadingChild && (
						<>
							<ModalHeader toggle={toggleDetail}>
								Detail {childDetail?.full_name}
							</ModalHeader>
							<ModalBody>
								<Row>
									<Col lg="6">
										<div className="d-flex justify-content-between gap-3">
											<Label>Child Number :</Label>
											<p className="mb-0 text-start">
												{childDetail?.child_number}
											</p>
										</div>
										<div className="d-flex justify-content-between gap-3">
											<Label>Nama :</Label>
											<p className="mb-0 text-start">
												{childDetail?.full_name}
											</p>
										</div>
										<div className="d-flex justify-content-between gap-3">
											<Label>Jenis Kelamin :</Label>
											<p className="mb-0 text-start">
												{childDetail?.gender === 'male'
													? 'Laki-laki'
													: 'Perempuan'}
											</p>
										</div>
										<div className="d-flex justify-content-between gap-3">
											<Label>Tempat tanggal lahir :</Label>
											<p className="mb-0 text-start">
												{childDetail?.address_of_birth},{' '}
												{formatDateToIndonesian(childDetail?.date_of_birth)}
											</p>
										</div>
										<div className="d-flex justify-content-between gap-3">
											<Label>Agama :</Label>
											<p className="mb-0 text-start">
												{religionData?.find(
													(rel) => rel.id === childDetail?.religion_id
												)?.name ?? '-'}
											</p>
										</div>
									</Col>
									<Col lg="6">
										<div className="d-flex justify-content-between gap-3">
											<Label>Suku Bangsa :</Label>
											<p className="mb-0 text-start">{childDetail?.clan}</p>
										</div>
										<div className="d-flex justify-content-between gap-3">
											<Label>Sekolah / Kelas :</Label>
											<p className="mb-0 text-start">
												{childDetail?.school_or_class}
											</p>
										</div>
										<div className="d-flex justify-content-between gap-3">
											<Label>Cabang :</Label>
											<p className="mb-0 text-start">
												{childDetail?.branch.branch_name}
											</p>
										</div>
										<div className="d-flex justify-content-between gap-3">
											<Label>Status :</Label>
											<div className="mb-0 text-start">
												<ActiveBadge status={childDetail?.status} />
											</div>
										</div>
									</Col>
									{parentData && (
										<Col lg="12">
											<div className="wizard clearfix">
												<div className="steps clearfix">
													<ul className="gap-4 my-3">
														<NavItem
															className={classNames({
																current: activeTab === 1,
															})}
														>
															<NavLink
																className={classNames({
																	active: activeTab === 1,
																})}
																onClick={() => {
																	setActiveTab(1)
																}}
															>
																<span className="number">1.</span> Data Ayah
															</NavLink>
														</NavItem>
														<NavItem
															className={classNames({
																current: activeTab === 2,
															})}
														>
															<NavLink
																className={classNames({
																	active: activeTab === 2,
																})}
																onClick={() => {
																	setActiveTab(2)
																}}
															>
																<span className="number">2.</span> Data Ibu
															</NavLink>
														</NavItem>
													</ul>
												</div>
											</div>
											<div className="wizard clearfix">
												<div className="content clearfix">
													<TabContent
														activeTab={activeTab}
														className="body pt-0"
													>
														<TabPane tabId={1}>
															<Row>
																<Col lg="6">
																	<div className="d-flex justify-content-between gap-3">
																		<Label>Nama Lengkap :</Label>
																		<p className="mb-0 text-start">
																			{parentData?.father?.full_name ?? '-'}
																		</p>
																	</div>
																	<div className="d-flex justify-content-between gap-3">
																		<Label>Alamat Email :</Label>
																		<p className="mb-0 text-start">
																			{parentData?.father?.email
																				? parentData?.father?.email
																				: '-'}
																		</p>
																	</div>
																	<div className="d-flex justify-content-between gap-3">
																		<Label>No Telp :</Label>
																		<p className="mb-0 text-start">
																			{parentData?.father?.phone_number
																				? parentData?.father?.phone_number
																				: '-'}
																		</p>
																	</div>
																	<div className="d-flex justify-content-between gap-3">
																		<Label>Tempat tanggal lahir :</Label>
																		<p className="mb-0 text-start">
																			{parentData?.father?.address_of_birth},{' '}
																			{formatDateToIndonesian(
																				parentData?.father?.date_of_birth
																			)}
																		</p>
																	</div>
																	<div className="d-flex justify-content-between gap-3">
																		<Label>Alamat :</Label>
																		<p className="mb-0 text-start">
																			{parentData?.father?.address ?? '-'}
																		</p>
																	</div>
																</Col>
																<Col lg="6">
																	<div className="d-flex justify-content-between gap-3">
																		<Label>Suku Bangsa :</Label>
																		<p className="mb-0 text-start">
																			{parentData?.father?.clan ?? '-'}
																		</p>
																	</div>
																	<div className="d-flex justify-content-between gap-3">
																		<Label>Agama :</Label>
																		<p className="mb-0 text-start">
																			{religionData?.find(
																				(rel) =>
																					rel.id ===
																					parentData?.father?.religion_id
																			)?.name ?? '-'}
																		</p>
																	</div>
																	<div className="d-flex justify-content-between gap-3">
																		<Label>Pekerjaan :</Label>
																		<p className="mb-0 text-start">
																			{parentData?.father?.current_job ?? '-'}
																		</p>
																	</div>
																	<div className="d-flex justify-content-between gap-3">
																		<Label>Pendidikan :</Label>
																		<p className="mb-0 text-start">
																			{educationData?.find(
																				(rel) =>
																					rel.id ===
																					parentData?.father?.education_id
																			)?.name ?? '-'}
																		</p>
																	</div>
																</Col>
															</Row>
														</TabPane>
														<TabPane tabId={2}>
															<Row>
																<Col lg="6">
																	<div className="d-flex justify-content-between gap-3">
																		<Label>Nama Lengkap :</Label>
																		<p className="mb-0 text-start">
																			{parentData?.mother?.full_name ?? ''}
																		</p>
																	</div>
																	<div className="d-flex justify-content-between gap-3">
																		<Label>Alamat Email :</Label>
																		<p className="mb-0 text-start">
																			{parentData?.mother?.email
																				? parentData?.mother?.email
																				: '-'}
																		</p>
																	</div>
																	<div className="d-flex justify-content-between gap-3">
																		<Label>No Telp :</Label>
																		<p className="mb-0 text-start">
																			{parentData?.mother?.phone_number
																				? parentData?.mother?.phone_number
																				: '-'}
																		</p>
																	</div>
																	<div className="d-flex justify-content-between gap-3">
																		<Label>Tempat tanggal lahir :</Label>
																		<p className="mb-0 text-start">
																			{parentData?.mother?.address_of_birth},{' '}
																			{formatDateToIndonesian(
																				parentData?.mother?.date_of_birth
																			)}
																		</p>
																	</div>
																	<div className="d-flex justify-content-between gap-3">
																		<Label>Alamat :</Label>
																		<p className="mb-0 text-start">
																			{parentData?.mother?.address ?? '-'}
																		</p>
																	</div>
																</Col>
																<Col lg="6">
																	<div className="d-flex justify-content-between gap-3">
																		<Label>Suku Bangsa :</Label>
																		<p className="mb-0 text-start">
																			{parentData?.mother?.clan ?? '-'}
																		</p>
																	</div>
																	<div className="d-flex justify-content-between gap-3">
																		<Label>Agama :</Label>
																		<p className="mb-0 text-start">
																			{religionData?.find(
																				(rel) =>
																					rel.id ===
																					parentData?.mother?.religion_id
																			)?.name ?? '-'}
																		</p>
																	</div>
																	<div className="d-flex justify-content-between gap-3">
																		<Label>Pekerjaan :</Label>
																		<p className="mb-0 text-start">
																			{parentData?.mother?.current_job ?? '-'}
																		</p>
																	</div>
																	<div className="d-flex justify-content-between gap-3">
																		<Label>Pendidikan :</Label>
																		<p className="mb-0 text-start">
																			{educationData?.find(
																				(rel) =>
																					rel.id ===
																					parentData?.mother?.education_id
																			)?.name ?? '-'}
																		</p>
																	</div>
																</Col>
															</Row>
														</TabPane>
													</TabContent>
												</div>
											</div>
										</Col>
									)}
								</Row>
							</ModalBody>
							<ModalFooter>
								<Button color="secondary" onClick={toggleDetail}>
									Tutup
								</Button>
							</ModalFooter>
						</>
					)}
				</Modal>
			</div>
		</>
	)
}

export default ActionButtonPatient
