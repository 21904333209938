import React, { useCallback, useEffect } from 'react'
import { CiSearch } from 'react-icons/ci'
import { Button, Card, CardBody, Col, Container, Input, Row } from 'reactstrap'
import HelmetTitle from '../../../components/templates/HelmetTitle'
import TableService from '../components/TableService'
import PaginationButton from '../../../components/templates/PaginationButton/PaginationButton'
import { useNavigate } from 'react-router-dom'
import { useGetListServiceQuery } from '../api/listServiceApi'
import { useState } from 'react'
import ActionButtonService from './ActionButtonService'
import { useDispatch, useSelector } from 'react-redux'
import { layoutSetupSelector, setPageTitle, setTableKeyword } from '../../../app/layoutSlice'
import { debounce } from 'lodash'

const headersListService = [
	{
		id: 1,
		title: 'No',
		width: '10%',
	},
	{
		id: 2,
		title: 'Nama Layanan',
		width: '20%',
	},
	{
		id: 3,
		title: 'Deskripsi Layanan',
		width: '60%',
		className: 'text-center',
	},
	{
		id: 4,
		title: 'Aksi',
		width: '10%',
	},
]

const ListService = (props) => {
	const { type } = props
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [page, setPage] = useState(1)

	const { tableKeyword } = useSelector(layoutSetupSelector)

	const { data: fetchServices, error } = useGetListServiceQuery(
		{
			endpoint: 'service',
			page,
			keyword: tableKeyword
		},
		{ refetchOnMountOrArgChange: true }
	)

	const { data: dataServices } = fetchServices ?? []

	const handlerSearch = (event) => {
		dispatch(setTableKeyword(event.target.value))
	}

	const debouncedHandleSearch = useCallback(debounce(handlerSearch, 500), []);

	useEffect(() => {
		dispatch(setPageTitle('Layanan'))
	}, [])

	if (error) {
		return (
			<div className="text-center">
				Something went wrong, {error.data.message ?? 'unknown'}
			</div>
		)
	}

	return (
		<>
			<HelmetTitle title="Jenis Layanan" />
			<Container fluid>
				<Row className="justify-content-center">
					<Col xs={12}>
						<Card>
							<CardBody>
								<div className="d-flex justify-content-between align-items-center">
									<h3 className="mb-0">Data Layanan</h3>
									<div className="d-flex gap-2">
										<div className="position-relative">
											<Input
												onChange={debouncedHandleSearch}
												placeholder="Pencarian..."
												className="pe-5"
											/>
											<CiSearch
												size={24}
												className="position-absolute"
												style={{ top: '20%', right: '5%' }}
											/>
										</div>
										<Button
											color="success"
											className="px-4"
											onClick={() => navigate('/service-type/add-service')}
										>
											Tambah
										</Button>
									</div>
								</div>
								<hr className="border border-light border-2 opacity-50"></hr>
								<TableService
									dataHeader={headersListService}
									dataRow={dataServices?.data}
									actionButton={ActionButtonService}
									dataSource={'service'}
								/>
								<PaginationButton
									metaPages={dataServices}
									setPage={setPage}
									currentPage={page}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	)
}

export default ListService
