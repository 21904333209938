import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { parseDate } from '../../../helpers/GlobalHelper'
const { VITE_BASE_URL } = import.meta.env

const getToken = (state) => state.authSetup.token

export const scheduleApi = createApi({
	reducerPath: 'scheduleApi',
	tagTypes: ['scheduleApi'],
	baseQuery: fetchBaseQuery({
		baseUrl: `${VITE_BASE_URL}/v1/admin/`,
		prepareHeaders: (headers, { getState }) => {
			const token = getToken(getState())
			if (token) {
				headers.set('Authorization', `Bearer ${token}`)
			}
			return headers
		},
	}),
	endpoints: (builder) => ({
		createSchedule: builder.mutation({
			query: (payload) => ({
				url: `/schedules`,
				method: 'POST',
				body: payload,
			}),
			invalidatesTags: ['CreateSchedule'],
		}),
		getAllSchedules: builder.query({
			query: () => '/schedules',
		}),
		getAllSchedulesByChild: builder.query({
			query: ({child_id, month}) => `/schedules?child_id=${child_id}&month=${month}`,
		}),
		getScheduleByDayAndTime: builder.query({
			query: (time) => `/schedules?time=${time}&date=${parseDate(new Date())}`,
			invalidatesTags: ['ScheduleByDayAndTimes'],
		}),
		deleteSchedule: builder.mutation({
			query: (id) => ({ url: `/schedules/${id}`, method: 'DELETE' }),
			invalidatesTags: ['DeleteSchedule'],
		}),
		updateScheduleStatus: builder.mutation({
			query: ({ schedule_id, schedule_date_id, ...payload }) => ({
				url: `/schedules/${schedule_id}/schedule-date/${schedule_date_id}`,
				method: 'PUT',
				body: payload,
			}),
			invalidatesTags: ['UpdateScheduleStatus'],
		}),
	}),
	refetchOnMountOrArgChange:  true,
	refetchOnFocus: true,
})

export const {
	useCreateScheduleMutation,
	useGetAllSchedulesQuery,
	useGetAllSchedulesByChildQuery,
	useDeleteScheduleMutation,
	useUpdateScheduleStatusMutation,
	useGetScheduleByDayAndTimeQuery
} = scheduleApi
