import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Alert, Button, Form, Input, Label } from "reactstrap";
import * as yup from "yup";
import { setConditionResetPassword, setProfileResetPassword } from "../../../app/authSlice";
import { usePostResetPasswordMutation } from "../api/ResetPasswordAPI";
const FormResetPasswordAdmin = () => {
    const [errorMessage, setErrorMessage] = useState('');
    const [postResetPassword, { isLoading }] = usePostResetPasswordMutation();
    const dispatch = useDispatch()

    const schema = yup.object({
        email: yup
            .string()
            .email("Email harus berformat email!")
            .required("Email wajib di isi!"),
    });

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            email: "",
        },
    });

    const handleOnSubmit = async (param) => {
        param.type = "admin";
        try {
            const result = await postResetPassword(param).unwrap();
            dispatch(
                setProfileResetPassword(
                    {
                        name_reset_password: result.data.name,
                        email_reset_password: param.email
                    }
                )
            )

            dispatch(
                setConditionResetPassword(
                    { condition_reset_password: 'form_change' }
                )
            )
        } catch (error) {
            console.error(error.data)
            setErrorMessage("Email tidak ditemukan")
        }
    }


    return (
        <div className="my-3">
            <div>
                <h5 className="text-success">Lupa Kata Sandi</h5>
                <p className="text-muted">Reset Password Admin</p>
            </div>
            <div className="mt-2">
                <Form onSubmit={handleSubmit(handleOnSubmit)} className="form-horizontal">
                    {
                        errorMessage
                        &&
                        <Alert color="danger">{errorMessage}</Alert>
                    }

                    <div className="mb-3">
                        <Label className="form-label">
                            Email
                            {` `}
                            <span className="text-danger">*</span>
                        </Label>
                        <Controller
                            name="email"
                            control={control}
                            render={({ field: { onChange, onBlur, value } }) => {
                                return (
                                    <Input
                                        name="email"
                                        className="form-control" placeholder="Enter email"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        type="email"

                                        invalid={false}
                                    />
                                )
                            }}
                        />

                    </div>
                    <div className="mt-0">
                        <span className="mt-0 text-danger text-start">
                            {errors.email?.message}
                        </span>
                    </div>
                    <div className="text-end">
                        <Button
                            type="submit"
                            color="success"
                            disabled={isLoading}
                        >
                            {isLoading ? 'Loading...' : 'Reset'}
                        </Button>
                    </div>
                </Form>
                <div className="mt-5 text-center">
                    <p>
                        Ingat ?{" "}
                        <Link
                            to="/login-admin"
                            className="fw-medium text-success"
                        >
                            {" "}
                            Silahkan Masuk
                        </Link>{" "}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default FormResetPasswordAdmin;
