import React from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, FormGroup, Input, Label, Row } from 'reactstrap'
import { setForm6Data, surveyStateSelector } from '../../../../app/surveySlice'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useGetSurveyFormValueQuery } from '../../api/surveyApi'

const FormStep6 = () => {
	const { childId } = useParams()
	const dispatch = useDispatch()
	const [forms6Data, setForms6Data] = useState({})
	const { child_id } = useSelector(surveyStateSelector)
	const { data: fetchForm6Value } = useGetSurveyFormValueQuery(
		{
			type: 6,
			child_id: childId,
		},
		{ skip: !childId }
	)
	const { data: form6Value } = fetchForm6Value?.data ?? {}

	useEffect(() => {
		if (form6Value) {
			setForms6Data({ ...forms6Data, ...form6Value })
		}
	}, [form6Value])

	useEffect(() => {
		const initForms6Data = {
			type: 6,
			child_id: childId ? childId : null,
			anak_minum_asi: 0,
			anak_minum_asi_sampai_bulan: '',
			pernah_jatuh: 0,
			dari_ketinggian_berapa: '',
			anak_pernah_sakit_parah: 0,
			pernah_panas_tinggi: 0,
			pernah_panas_tinggi_usia: '',
			pernah_panas_tinggi_durasi: '',
			panas_disertai_kejang: 0,
			panas_disertai_kejang_usia: '',
			panas_disertai_kejang_durasi: '',
			kejang_tanpa_panas: 0,
			kejang_tanpa_panas_usia: '',
			kejang_tanpa_panas_durasi: '',
			sakit_sampai_berat_badan_rendah_usia: '',
			sakit_sampai_berat_badan_rendah: 0,
			sakit_karena_virus: 0,
			sakit_karena_virus_usia: '',
			sakit_karena_virus_type: '',
			tengkurap: 0,
			tengkurap_usia: '',
			berguling: 0,
			berguling_usia: '',
			duduk: 0,
			duduk_usia: '',
			merayap: 0,
			merayap_usia: '',
			merangkap: 0,
			merangkap_usia: '',
			jongkok: 0,
			jongkok_usia: '',
			transisi_ke_berdiri: 0,
			transisi_ke_berdiri_usia: '',
			berdiri_tanpa_pegangan: 0,
			berdiri_tanpa_pegangan_usia: '',
			berjalan_tanpa_pegangan: 0,
			berjalan_tanpa_pegangan_usia: '',
			berlari: 0,
			berlari_usia: '',
			melompat: 0,
			melompat_usia: '',
		}

		setForms6Data(initForms6Data)
	}, [])

	useEffect(() => {
		const timer = setTimeout(() => {
			dispatch(setForm6Data(forms6Data))
		}, 1000)

		return () => clearTimeout(timer)
	}, [forms6Data])

	const inputHandle = (value, inputName) => {
		setForms6Data({ ...forms6Data, [inputName]: value, child_id })
	}

	return (
		<>
			<Row>
				<Col lg="12">
					<h5 className="fw-bold">Riwayat Setelah Lahir</h5>
					<p>Centang yang dialami anak</p>
				</Col>
				<Col lg="12">
					<Row className="align-items-center">
						<Col lg="3">
							<FormGroup check>
								<Input
									type="checkbox"
									name="anak_minum_asi"
									id="asi"
									value="1"
									checked={forms6Data?.anak_minum_asi === 1}
									onChange={(e) =>
										inputHandle(e.target.checked ? 1 : 0, 'anak_minum_asi')
									}
								/>
								<Label for="asi">Anak minum ASI</Label>
							</FormGroup>
						</Col>
						<Col lg="5">
							<FormGroup>
								<Input
									type="text"
									className="form-control bg-light"
									name="anak_minum_asi_sampai_bulan"
									placeholder="Sampai bulan"
									defaultValue={forms6Data?.anak_minum_asi_sampai_bulan ?? ''}
									onChange={(e) =>
										inputHandle(+e.target.value, 'anak_minum_asi_sampai_bulan')
									}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row className="align-items-center">
						<Col lg="3">
							<FormGroup check>
								<Input
									type="checkbox"
									name="pernah_jatuh"
									id="has_fall"
									value="1"
									checked={forms6Data?.pernah_jatuh === 1}
									onChange={(e) =>
										inputHandle(e.target.checked ? 1 : 0, 'pernah_jatuh')
									}
								/>
								<Label for="has_fall">Pernah jatuh</Label>
							</FormGroup>
						</Col>
						<Col lg="5">
							<FormGroup>
								<Input
									type="text"
									className="form-control bg-light"
									name="dari_ketinggian_berapa"
									placeholder="Dari ketinggian berapa"
									defaultValue={forms6Data?.dari_ketinggian_berapa ?? ''}
									onChange={(e) =>
										inputHandle(e.target.value, 'dari_ketinggian_berapa')
									}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row className="align-items-center my-3">
						<Col lg="3">
							<FormGroup check>
								<Input
									type="checkbox"
									name="anak_pernah_sakit_parah"
									id="serious_sick"
									value="1"
									checked={forms6Data?.anak_pernah_sakit_parah === 1}
									onChange={(e) =>
										inputHandle(
											e.target.checked ? 1 : 0,
											'anak_pernah_sakit_parah'
										)
									}
								/>
								<Label for="serious_sick">Anak pernah sakit parah</Label>
							</FormGroup>
						</Col>
					</Row>
					<Row className="align-items-center">
						<Col lg="3">
							<FormGroup check>
								<Input
									type="checkbox"
									name="pernah_panas_tinggi"
									id="high_fever"
									value="1"
									checked={forms6Data?.pernah_panas_tinggi === 1}
									onChange={(e) =>
										inputHandle(e.target.checked ? 1 : 0, 'pernah_panas_tinggi')
									}
								/>
								<Label for="high_fever">Pernah panas tinggi</Label>
							</FormGroup>
						</Col>
						<Col lg="5">
							<FormGroup>
								<Input
									type="text"
									className="form-control bg-light"
									name="pernah_panas_tinggi_usia"
									placeholder="Usia"
									defaultValue={forms6Data?.pernah_panas_tinggi_usia ?? ''}
									onChange={(e) =>
										inputHandle(e.target.value, 'pernah_panas_tinggi_usia')
									}
								/>
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<Input
									type="text"
									className="form-control bg-light"
									name="pernah_panas_tinggi_durasi"
									placeholder="Durasi"
									defaultValue={forms6Data?.pernah_panas_tinggi_durasi ?? ''}
									onChange={(e) =>
										inputHandle(e.target.value, 'pernah_panas_tinggi_durasi')
									}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row className="align-items-center">
						<Col lg="3">
							<FormGroup check>
								<Input
									type="checkbox"
									name="panas_disertai_kejang"
									id="fever_stiff"
									value="1"
									checked={forms6Data?.panas_disertai_kejang === 1}
									onChange={(e) =>
										inputHandle(
											e.target.checked ? 1 : 0,
											'panas_disertai_kejang'
										)
									}
								/>
								<Label for="fever_stiff">Panas disertai kejang</Label>
							</FormGroup>
						</Col>
						<Col lg="5">
							<FormGroup>
								<Input
									type="text"
									className="form-control bg-light"
									name="panas_disertai_kejang_usia"
									placeholder="Usia"
									defaultValue={forms6Data?.panas_disertai_kejang_usia ?? ''}
									onChange={(e) =>
										inputHandle(e.target.value, 'panas_disertai_kejang_usia')
									}
								/>
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<Input
									type="text"
									className="form-control bg-light"
									name="panas_disertai_kejang_durasi"
									placeholder="Durasi"
									defaultValue={forms6Data?.panas_disertai_kejang_durasi ?? ''}
									onChange={(e) =>
										inputHandle(e.target.value, 'panas_disertai_kejang_durasi')
									}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row className="align-items-center">
						<Col lg="3">
							<FormGroup check>
								<Input
									type="checkbox"
									name="kejang_tanpa_panas"
									id="fever_no_stiff"
									value="1"
									checked={forms6Data?.kejang_tanpa_panas === 1}
									onChange={(e) =>
										inputHandle(e.target.checked ? 1 : 0, 'kejang_tanpa_panas')
									}
								/>
								<Label for="fever_no_stiff">Pernah kejang tanpa panas</Label>
							</FormGroup>
						</Col>
						<Col lg="5">
							<FormGroup>
								<Input
									type="text"
									className="form-control bg-light"
									name="kejang_tanpa_panas_usia"
									placeholder="Usia"
									defaultValue={forms6Data?.kejang_tanpa_panas_usia ?? ''}
									onChange={(e) =>
										inputHandle(e.target.value, 'kejang_tanpa_panas_usia')
									}
								/>
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<Input
									type="text"
									className="form-control bg-light"
									name="kejang_tanpa_panas_durasi"
									placeholder="Durasi"
									defaultValue={forms6Data?.kejang_tanpa_panas_durasi ?? ''}
									onChange={(e) =>
										inputHandle(e.target.value, 'kejang_tanpa_panas_durasi')
									}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row className="align-items-center">
						<Col lg="3">
							<FormGroup check>
								<Input
									type="checkbox"
									name="sakit_sampai_berat_badan_rendah"
									id="sick_lose_weight"
									value="1"
									checked={forms6Data?.sakit_sampai_berat_badan_rendah === 1}
									onChange={(e) =>
										inputHandle(
											e.target.checked ? 1 : 0,
											'sakit_sampai_berat_badan_rendah'
										)
									}
								/>
								<Label for="sick_lose_weight">
									Pernah sakit sampai berat badan rendah
								</Label>
							</FormGroup>
						</Col>
						<Col lg="5">
							<FormGroup>
								<Input
									type="text"
									className="form-control bg-light"
									name="sakit_sampai_berat_badan_rendah_usia"
									placeholder="Usia"
									defaultValue={
										forms6Data?.sakit_sampai_berat_badan_rendah_usia ?? ''
									}
									onChange={(e) =>
										inputHandle(
											e.target.value,
											'sakit_sampai_berat_badan_rendah_usia'
										)
									}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row className="align-items-center">
						<Col lg="3">
							<FormGroup check>
								<Input
									type="checkbox"
									name="sakit_karena_virus"
									id="sick_by_virus"
									value="1"
									checked={forms6Data?.sakit_karena_virus === 1}
									onChange={(e) =>
										inputHandle(e.target.checked ? 1 : 0, 'sakit_karena_virus')
									}
								/>
								<Label for="sick_by_virus">Sakit karena virus</Label>
							</FormGroup>
						</Col>
						<Col lg="5">
							<FormGroup>
								<Input
									type="text"
									className="form-control bg-light"
									name="sakit_karena_virus_usia"
									placeholder="Usia"
									defaultValue={forms6Data?.sakit_karena_virus_usia ?? ''}
									onChange={(e) =>
										inputHandle(e.target.value, 'sakit_karena_virus_usia')
									}
								/>
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<Input
									type="text"
									className="form-control bg-light"
									name="sakit_karena_virus_type"
									placeholder="Jenis Virus"
									defaultValue={forms6Data?.sakit_karena_virus_type ?? ''}
									onChange={(e) =>
										inputHandle(e.target.value, 'sakit_karena_virus_type')
									}
								/>
							</FormGroup>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row>
				<hr className="border border-light border-2 opacity-50" />
				<Col lg="12">
					<h5 className="fw-bold">Perkembangan Fisik Anak</h5>
					<p>Centang yang dialami anak</p>
				</Col>
				<Col lg="6">
					<Row>
						<Col col="12">
							<Row className="align-items-center">
								<Col lg="4">
									<FormGroup check>
										<Input
											type="checkbox"
											name="prone"
											id="prone"
											value="1"
											checked={forms6Data?.tengkurap === 1}
											onChange={(e) =>
												inputHandle(e.target.checked ? 1 : 0, 'tengkurap')
											}
										/>
										<Label for="prone">Tengkurap</Label>
									</FormGroup>
								</Col>
								<Col lg="8">
									<FormGroup>
										<Input
											type="text"
											className="form-control bg-light"
											name="tengkurap_usia"
											placeholder="Usia (dalam bulan)"
											defaultValue={forms6Data?.tengkurap_usia ?? ''}
											onChange={(e) =>
												inputHandle(e.target.value, 'tengkurap_usia')
											}
										/>
									</FormGroup>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col col="12">
							<Row className="align-items-center">
								<Col lg="4">
									<FormGroup check>
										<Input
											type="checkbox"
											name="berguling"
											id="rolled"
											value="1"
											checked={forms6Data?.berguling === 1}
											onChange={(e) =>
												inputHandle(e.target.checked ? 1 : 0, 'berguling')
											}
										/>
										<Label for="rolled">Berguling</Label>
									</FormGroup>
								</Col>
								<Col lg="8">
									<FormGroup>
										<Input
											type="text"
											className="form-control bg-light"
											name="rolled_age"
											placeholder="Usia (dalam bulan)"
											defaultValue={forms6Data?.berguling_usia ?? ''}
											onChange={(e) =>
												inputHandle(e.target.value, 'berguling_usia')
											}
										/>
									</FormGroup>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col col="12">
							<Row className="align-items-center">
								<Col lg="4">
									<FormGroup check>
										<Input
											type="checkbox"
											name="sit"
											id="sit"
											value="1"
											checked={forms6Data?.duduk === 1}
											onChange={(e) =>
												inputHandle(e.target.checked ? 1 : 0, 'duduk')
											}
										/>
										<Label for="sit">Duduk</Label>
									</FormGroup>
								</Col>
								<Col lg="8">
									<FormGroup>
										<Input
											type="text"
											className="form-control bg-light"
											name="sit_age"
											placeholder="Usia (dalam bulan)"
											defaultValue={forms6Data?.duduk_usia ?? ''}
											onChange={(e) =>
												inputHandle(e.target.value, 'duduk_usia')
											}
										/>
									</FormGroup>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col col="12">
							<Row className="align-items-center">
								<Col lg="4">
									<FormGroup check>
										<Input
											type="checkbox"
											name="crawl"
											id="crawl"
											value="1"
											checked={forms6Data?.merayap === 1}
											onChange={(e) =>
												inputHandle(e.target.checked ? 1 : 0, 'merayap')
											}
										/>
										<Label for="crawl">Merayap</Label>
									</FormGroup>
								</Col>
								<Col lg="8">
									<FormGroup>
										<Input
											type="text"
											className="form-control bg-light"
											name="crawl_age"
											placeholder="Usia (dalam bulan)"
											defaultValue={forms6Data?.merayap_usia ?? ''}
											onChange={(e) =>
												inputHandle(e.target.value, 'merayap_usia')
											}
										/>
									</FormGroup>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col col="12">
							<Row className="align-items-center">
								<Col lg="4">
									<FormGroup check>
										<Input
											type="checkbox"
											name="creep"
											id="creep"
											value="1"
											checked={forms6Data?.merangkap === 1}
											onChange={(e) =>
												inputHandle(e.target.checked ? 1 : 0, 'merangkap')
											}
										/>
										<Label for="creep">Merangkak</Label>
									</FormGroup>
								</Col>
								<Col lg="8">
									<FormGroup>
										<Input
											type="text"
											className="form-control bg-light"
											name="creep_age"
											placeholder="Usia (dalam bulan)"
											defaultValue={forms6Data?.merangkap_usia ?? ''}
											onChange={(e) =>
												inputHandle(e.target.value, 'merangkap_usia')
											}
										/>
									</FormGroup>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col col="12">
							<Row className="align-items-center">
								<Col lg="4">
									<FormGroup check>
										<Input
											type="checkbox"
											name="squat"
											id="squat"
											value="1"
											checked={forms6Data?.jongkok === 1}
											onChange={(e) =>
												inputHandle(e.target.checked ? 1 : 0, 'jongkok')
											}
										/>
										<Label for="squat">Jongkok</Label>
									</FormGroup>
								</Col>
								<Col lg="8">
									<FormGroup>
										<Input
											type="text"
											className="form-control bg-light"
											name="squat_age"
											placeholder="Usia (dalam bulan)"
											defaultValue={forms6Data?.jongkok_usia ?? ''}
											onChange={(e) =>
												inputHandle(e.target.value, 'jongkok_usia')
											}
										/>
									</FormGroup>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
				<Col lg="6">
					<Row>
						<Col col="12">
							<Row className="align-items-center">
								<Col lg="4">
									<FormGroup check>
										<Input
											type="checkbox"
											name="stand_transition"
											id="stand_transition"
											value="1"
											checked={forms6Data?.transisi_ke_berdiri === 1}
											onChange={(e) =>
												inputHandle(
													e.target.checked ? 1 : 0,
													'transisi_ke_berdiri'
												)
											}
										/>
										<Label for="stand_transition">Transisi ke berdiri</Label>
									</FormGroup>
								</Col>
								<Col lg="8">
									<FormGroup>
										<Input
											type="text"
											className="form-control bg-light"
											name="stand_transition_age"
											placeholder="Usia (dalam bulan)"
											value={forms6Data?.transisi_ke_berdiri_usia ?? ''}
											onChange={(e) =>
												inputHandle(e.target.value, 'transisi_ke_berdiri_usia')
											}
										/>
									</FormGroup>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col col="12">
							<Row className="align-items-center">
								<Col lg="4">
									<FormGroup check>
										<Input
											type="checkbox"
											name="stand_no_hang"
											id="stand_no_hang"
											value="1"
											checked={forms6Data?.berdiri_tanpa_pegangan === 1}
											onChange={(e) =>
												inputHandle(
													e.target.checked ? 1 : 0,
													'berdiri_tanpa_pegangan'
												)
											}
										/>
										<Label for="stand_no_hang">Berdiri tanpa pegangan</Label>
									</FormGroup>
								</Col>
								<Col lg="8">
									<FormGroup>
										<Input
											type="text"
											className="form-control bg-light"
											name="stand_no_hang"
											placeholder="Usia (dalam bulan)"
											defaultValue={forms6Data?.berdiri_tanpa_pegangan_usia}
											onChange={(e) =>
												inputHandle(
													e.target.value,
													'berdiri_tanpa_pegangan_usia'
												)
											}
										/>
									</FormGroup>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col col="12">
							<Row className="align-items-center">
								<Col lg="4">
									<FormGroup check>
										<Input
											type="checkbox"
											name="walk_no_hang"
											id="walk_no_hang"
											value="1"
											checked={forms6Data?.berjalan_tanpa_pegangan === 1}
											onChange={(e) =>
												inputHandle(
													e.target.checked ? 1 : 0,
													'berjalan_tanpa_pegangan'
												)
											}
										/>
										<Label for="walk_no_hang">Berjalan tanpa pegangan</Label>
									</FormGroup>
								</Col>
								<Col lg="8">
									<FormGroup>
										<Input
											type="text"
											className="form-control bg-light"
											placeholder="Usia (dalam bulan)"
											defaultValue={
												forms6Data?.berjalan_tanpa_pegangan_usia ?? ''
											}
											onChange={(e) =>
												inputHandle(
													e.target.value,
													'berjalan_tanpa_pegangan_usia'
												)
											}
										/>
									</FormGroup>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col col="12">
							<Row className="align-items-center">
								<Col lg="4">
									<FormGroup check>
										<Input
											type="checkbox"
											name="run"
											id="run"
											value="1"
											checked={forms6Data?.berlari === 1}
											onChange={(e) =>
												inputHandle(e.target.checked ? 1 : 0, 'berlari')
											}
										/>
										<Label for="run">Berlari</Label>
									</FormGroup>
								</Col>
								<Col lg="8">
									<FormGroup>
										<Input
											type="text"
											className="form-control bg-light"
											name="run"
											placeholder="Usia (dalam bulan)"
											defaultValue={forms6Data?.berlari_usia}
											onChange={(e) =>
												inputHandle(e.target.value, 'berlari_usia')
											}
										/>
									</FormGroup>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col col="12">
							<Row className="align-items-center">
								<Col lg="4">
									<FormGroup check>
										<Input
											type="checkbox"
											name="jump"
											id="jump"
											value="1"
											checked={forms6Data?.melompat === 1}
											onChange={(e) =>
												inputHandle(e.target.checked ? 1 : 0, 'melompat')
											}
										/>
										<Label for="jump">Melompat</Label>
									</FormGroup>
								</Col>
								<Col lg="8">
									<FormGroup>
										<Input
											type="text"
											className="form-control bg-light"
											name="jump"
											placeholder="Usia (dalam bulan)"
											defaultValue={forms6Data?.melompat_usia ?? ''}
											onChange={(e) =>
												inputHandle(e.target.value, 'melompat_usia')
											}
										/>
									</FormGroup>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	)
}

export default FormStep6
