import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
const { VITE_BASE_URL } = import.meta.env

const getToken = (state) => state.authSetup.token

export const surveyApi = createApi({
	reducerPath: 'surveyApi',
	tagTypes: ['surveyApi'],
	baseQuery: fetchBaseQuery({
		baseUrl: `${VITE_BASE_URL}/v1/admin`,
		prepareHeaders: (headers, { getState }) => {
			const token = getToken(getState())
			if (token) {
				headers.set('Authorization', `Bearer ${token}`)
			}
			return headers
		},
	}),
	endpoints: (builder) => ({
		postFormTwo: builder.mutation({
			query: (payload) => ({
				url: `/form/inform_consent`,
				method: 'POST',
				body: payload,
			}),
		}),
		postForm: builder.mutation({
			query: (payload) => ({
				url: '/form',
				method: 'POST',
				body: payload,
			}),
		}),
		formUpload: builder.mutation({
			query: (payload) => ({
				url: '/form/upload',
				method: 'POST',
				body: payload,
			}),
		}),
		getSurveyFormValue: builder.query({
			query: ({ child_id, type }) => `/form?child_id=${child_id}&type=${type}`,
		}),
		getFormTwo: builder.query({
			query: (user_id) => `/form/inform_consent?user_id=${user_id}`,
		}),
	}),
})

export const {
	usePostFormTwoMutation,
	usePostFormMutation,
	useFormUploadMutation,
	useGetSurveyFormValueQuery,
	useGetFormTwoQuery
} = surveyApi
