import { useCallback, useEffect, useState } from 'react'
import { CiSearch } from 'react-icons/ci'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Card, CardBody, Col, Container, Input, Row } from 'reactstrap'
import { layoutSetupSelector, setPageTitle, setTableKeyword } from '../../app/layoutSlice'
import HelmetTitle from '../../components/templates/HelmetTitle'
import PaginationButton from '../../components/templates/PaginationButton/PaginationButton'
import TableClient from '../../components/templates/TableClient'
import { useGetAllUsersQuery } from '../general/api/userApi'
import ActionButtonCustomer from './components/ActionButtonCustomer'
import { debounce } from 'lodash'

const headerDataCustomer = [
	{
		id: 1,
		title: 'No',
	},
	{
		id: 2,
		title: 'ID Customer',
	},
	{
		id: 3,
		title: 'Nama Customer',
	},
	{
		id: 4,
		title: 'Email',
	},
	{
		id: 5,
		title: 'No Telp',
	},
	{
		id: 6,
		title: 'Aksi',
	},
]

const ListCustomer = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [page, setPage] = useState(1)

	const { tableKeyword } = useSelector(layoutSetupSelector)

	const { data: fetchCustomer, error } = useGetAllUsersQuery(
		{ page, keyword: tableKeyword },
		{
			refetchOnMountOrArgChange: true,
		})

	const { data: dataParents } = fetchCustomer ?? []

	const handlerSearch = (event) => {
		dispatch(setTableKeyword(event.target.value))
	}

	const debouncedHandleSearch = useCallback(debounce(handlerSearch, 500), []);

	useEffect(() => {
		dispatch(setPageTitle('Customer'))
	}, [])

	if (error) {
		return (
			<div className="text-center">
				Something went wrong, {error.data.message ?? 'unknown'}
			</div>
		)
	}

	return (
		<>
			<HelmetTitle title="Data Customer" />
			<Container fluid>
				<Row className="justify-content-center">
					<Col xs={12}>
						<Card>
							<CardBody>
								<div className="d-flex justify-content-between align-items-center">
									<h3 className="mb-0">Data Customer</h3>
									<div className="d-flex gap-2">
										<div className="position-relative">
											<Input
												onChange={debouncedHandleSearch}
												placeholder="Pencarian..."
												className="pe-5"
											/>
											<CiSearch
												size={24}
												className="position-absolute"
												style={{ top: '20%', right: '5%' }}
											/>
										</div>
										<Button
											color="success"
											className="px-4"
											onClick={() => navigate('/data-customer/add-customer')}
										>
											Tambah
										</Button>
									</div>
								</div>
								<hr className="border border-light border-2 opacity-50"></hr>
								<TableClient
									dataHeader={headerDataCustomer}
									dataRow={dataParents?.data}
									dataSource={'customer'}
									actionButton={ActionButtonCustomer}
								/>
								<PaginationButton
									metaPages={dataParents}
									currentPage={page}
									setPage={setPage}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	)
}

export default ListCustomer
