import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
const { VITE_BASE_URL } = import.meta.env

const getToken = (state) => state.authSetup.token

export const adminLoginApi = createApi({
	reducerPath: 'adminLoginApi',
	tagTypes: ['AdminLogin'],
	baseQuery: fetchBaseQuery({
		baseUrl: `${VITE_BASE_URL}/v1/admin/auth/`,
		prepareHeaders: (headers, { getState }) => {
			const token = getToken(getState())
			if (token) {
				headers.set('Authorization', `Bearer ${token}`)
			}
			return headers
		},
		// prepareHeaders: (headers, { getState }) => {
		// 	const token = getState().authSetup?.token
		// 	if (token) {
		// 		headers.set('Authorization', `Bearer ${token}`)
		// 	}
		// 	return headers
		// },
	}),
	endpoints: (builder) => ({
		postAdminLogin: builder.mutation({
			query: (body) => ({
				url: `login`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Auth'],
		}),
		checkLoginAdmin: builder.query({
			query: () => 'check-login',
		}),
	}),
})

export const {
	usePostAdminLoginMutation,
	useCheckLoginAdminQuery
} = adminLoginApi
