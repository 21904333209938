import dayjs from 'dayjs/esm/index.js';
import id from 'dayjs/locale/id';
import customParseFormat from 'dayjs/plugin/customParseFormat.js';
import localizedFormat from 'dayjs/plugin/localizedFormat.js';

export const SetDisplayName = (value, length = 0) => {
	if (length < 1) {
		return value
	}

	if (length > 0) {
		let newName = ''
		const splitVal = value.split(' ')
		for (let index = 0; index <= length; index++) {
			newName += !splitVal[index] ? '' : ` ${splitVal[index]}`
		}
		return newName
	}
}

export const UpperCaseName = (value) => {
	return value.charAt(0).toUpperCase() + value.slice(1)
}

export const formatCurrency = (amount = 0) => {
	// Convert the number to a string and remove any non-digit characters
	const numericString = String(amount).replace(/\D/g, '')

	// Format the number with separators for thousands, millions, etc.
	const formattedAmount = new Intl.NumberFormat('id-ID', {
		style: 'currency',
		currency: 'IDR',
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	}).format(numericString)

	// Return the formatted currency string
	return formattedAmount
}

export const parseDate = (inputDate, separator = '-') => {
	const months = {
		Jan: '01',
		Feb: '02',
		Mar: '03',
		Apr: '04',
		May: '05',
		Jun: '06',
		Jul: '07',
		Aug: '08',
		Sep: '09',
		Oct: '10',
		Nov: '11',
		Dec: '12',
	}

	// Convert the inputDate to a string in case it's not already a string
	const dateString = inputDate.toString()

	const dateRegex = /([A-Za-z]{3})\s([A-Za-z]{3})\s(\d{2})\s(\d{4}).*/
	const [, , monthStr, day, year] = dateString.match(dateRegex)
	const month = months[monthStr]

	if (separator === '/') {
		return `${day}/${month}/${year}`
	}

	return `${year}-${month}-${day}`
}

export const setDisplayDate = (inputDate) => {
	const date = new Date(inputDate)
	return date
}

export const formatTime = (date) => {
	const hours = String(date.getHours()).padStart(2, '0')
	const minutes = String(date.getMinutes()).padStart(2, '0')
	const seconds = String(date.getSeconds()).padStart(2, '0')
	return `${hours}:${minutes}:${seconds}`
}

export const createDateTimeFromDateAndTime = (dateString, timeString) => {
	const date = new Date(dateString)
	const timeParts = timeString.split(':')
	const hours = Number(timeParts[0])
	const minutes = Number(timeParts[1])
	const seconds = Number(timeParts[2])

	date.setHours(hours)
	date.setMinutes(minutes)
	date.setSeconds(seconds)

	return date
}

export const formatDateToIndonesian = (dateString = '2023-01-01') => {
	const months = [
		'Januari',
		'Februari',
		'Maret',
		'April',
		'Mei',
		'Juni',
		'Juli',
		'Agustus',
		'September',
		'Oktober',
		'November',
		'Desember',
	]

	const [year, month, day] = dateString.split('-')
	const monthIndex = parseInt(month) - 1 // Subtracting 1 since JavaScript months are zero-indexed

	const formattedDate = `${day} ${months[monthIndex]} ${year}`

	return formattedDate
}

export const getMonthNameIndonesian = (monthNumber) => {
	const months = [
		'Januari',
		'Februari',
		'Maret',
		'April',
		'Mei',
		'Juni',
		'Juli',
		'Agustus',
		'September',
		'Oktober',
		'November',
		'Desember',
	]

	const monthIndex = parseInt(monthNumber) - 1 // Subtracting 1 since JavaScript months are zero-indexed
	if (monthIndex >= 0 && monthIndex < 12) {
		return months[monthIndex]
	} else {
		return 'Invalid month number'
	}
}

export const checkObject = (obj) => {
	return Object.keys(obj).length === 0 ? false : true
}

export const calculateAge = (birthDate) => {
	const birthDateObj = new Date(birthDate)
	const currentDate = new Date()

	if (isNaN(birthDateObj.getTime())) {
		throw new Error(
			'Invalid date format. Please provide a valid date in the format "yyyy-mm-dd".'
		)
	}

	const age = currentDate.getFullYear() - birthDateObj.getFullYear()
	const monthDiff = currentDate.getMonth() - birthDateObj.getMonth()

	if (
		monthDiff < 0 ||
		(monthDiff === 0 && currentDate.getDate() < birthDateObj.getDate())
	) {
		return age - 1
	}

	return age
}

export const reformatDate = (value, format = "YYYY-MM-DD") => {
	if (!value) {
		return value
	}

	dayjs.extend(customParseFormat)
	dayjs.extend(localizedFormat)
	return dayjs(value).locale(id).format(format);
}

export const adjustNumberFormat = (input) => {
	const parsedValue = parseInt(input, 10);

	// Jika input adalah angka atau panjangnya 0
	if (!isNaN(parsedValue) || input.length === 0) {
		// Jika panjang lebih dari 1, hilangkan angka 0 di awal
		if (input.length > 1) {
			return parsedValue;
		}
		// Jika panjangnya 1 atau kurang, ubah menjadi 0
		return 0;
	}

	// Jika input bukan angka atau panjangnya lebih dari 0
	return 0;
}

export const adjustFloatFormat = (input) => {
	// Mengganti koma dengan titik
	const replacedValue = input.replace(/,/g, '.');

	// Menghapus semua titik setelah koma dan mengonversi ke float
	const parsedValue = parseFloat(replacedValue.replace(/\.+/g, '.'));

	// Jika input adalah angka atau panjangnya 0
	if (!isNaN(parsedValue) || parsedValue === 0) {
		return parsedValue;
	}

	// Jika input bukan angka atau panjangnya lebih dari 0
	return 0;
};