import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, FormGroup, Input, Label, Row } from 'reactstrap'
import { setForm5Data, surveyStateSelector } from '../../../../app/surveySlice'
import { useParams } from 'react-router-dom'
import { useGetSurveyFormValueQuery } from '../../api/surveyApi'

const conPregnantcheckboxItem = [
	{
		id: 1,
		name: 'mual_sering_muntah_sulit_makan',
		label: 'Mual/sering muntah/sulit makan',
		value: 0,
	},
	{
		id: 2,
		name: 'asupan_gizi_memadai',
		label: 'Asupan gizi memadai',
		value: 0,
	},
	{
		id: 4,
		name: 'kehamilan_diinginkan',
		label: 'Kehamilan diinginkan',
		value: 0,
	},
	{
		id: 5,
		name: 'melakukan_perawatan_kehamilan',
		label: 'Melakukan perawatan kehamilan',
		value: 0,
	},
	{
		id: 6,
		name: 'berat_bayi_tiap_semester_normal',
		label: 'Berat bayi tiap semester normal',
		value: 0,
	},
	{
		id: 7,
		name: 'diabetes',
		label: 'Diabetes',
		value: 0,
	},
	{
		id: 8,
		name: 'hipertensi',
		label: 'Hipertensi',
		value: 0,
	},
	{
		id: 9,
		name: 'asma',
		label: 'Asma',
		value: 0,
	},

	{
		id: 10,
		name: 'tbc',
		label: 'TBC',
		value: 0,
	},
	{
		id: 11,
		name: 'merokok',
		label: 'Merokok',
		value: 0,
	},
	{
		id: 12,
		name: 'tinggal_atau_bekerja_disekitar_perokok_berat',
		label: 'Tinggal/bekerja disekitar perokok berat',
		value: 0,
	},
	{
		id: 13,
		name: 'konsumsi_alkohol',
		label: 'Konsumsi alkohol',
		value: 0,
	},
	{
		id: 14,
		name: 'konsumsi_obat_obatan',
		label: 'Konsumsi obat-obatan',
		value: 0,
	},
	{
		id: 15,
		name: 'infeksi_virus',
		label: 'Infeksi virus (toxoplasma, rubella, CMV, herpes, dll)',
		value: 0,
	},
	{
		id: 16,
		name: 'kecelakaan_atau_trauma',
		label: 'Kecelakaan/trauma',
		value: 0,
	},
	{
		id: 17,
		name: 'pendarahan',
		label: 'Pendarahan/flek',
		value: 0,
	},
	{
		id: 18,
		name: 'masalah_pernapasan',
		label: 'Masalah pernafasan',
		value: 0,
	},
]

const immunizationHistory = [
	{
		id: 1,
		name: 'bcg_1',
		label: 'BCG 1 (lahir-2 bln)',
	},
	{
		id: 2,
		name: 'hep_b1',
		label: 'Hep B1 (0-2 bln)',
	},
	{
		id: 3,
		name: 'hep_b2',
		label: 'Hep B2 (1-4 bln)',
	},
	{
		id: 4,
		name: 'hep_b3',
		label: 'Hep B3 (6-18 bln)',
	},
	{
		id: 5,
		name: 'dpt_1',
		label: 'DPT 1 (2-4 bln)',
	},
	{
		id: 6,
		name: 'dpt_2',
		label: 'DPT 2 (3-5 bln)',
	},
	{
		id: 7,
		name: 'dpt_3',
		label: 'DPT 3 (4-6 bln)',
	},
	{
		id: 8,
		name: 'dpt_4',
		label: 'DPT 4 (18-24 bln)',
	},
	{
		id: 9,
		name: 'polio_1',
		label: 'Polio 1 (lahir)',
	},
	{
		id: 10,
		name: 'polio_2',
		label: 'Polio 2 (2-4 bln)',
	},
	{
		id: 11,
		name: 'polio_3',
		label: 'Polio 3 (3-5 bln)',
	},
	{
		id: 12,
		name: 'polio_4',
		label: 'Polio 4 (4-6 bln)',
	},
	{
		id: 13,
		name: 'polio_5',
		label: 'Polio 5 (18-24 bln)',
	},
	{
		id: 14,
		name: 'campak_1',
		label: 'Campak 1 (6-9 bln)',
	},
	{
		id: 15,
		name: 'campak_2',
		label: 'Campak 2 (5-7 thn)',
	},
	{
		id: 16,
		name: 'hib_1',
		label: 'Hib 1 (2 bln)',
	},
	{
		id: 17,
		name: 'hib_2',
		label: 'Hib 2 (4 bln)',
	},
	{
		id: 18,
		name: 'hib_3',
		label: 'Hib 3 (6 bln)',
	},
	{
		id: 19,
		name: 'hib_4',
		label: 'Hib 4 (15 bln)',
	},
	{
		id: 20,
		name: 'mmr_1',
		label: 'MMR 1 (12-18 bln)',
	},
]

const FormStep5 = () => {
	const { childId } = useParams()
	const dispatch = useDispatch()
	const [forms5Data, setForms5Data] = useState({})
	const { child_id } = useSelector(surveyStateSelector)
	const { data: fetchForm5Value } = useGetSurveyFormValueQuery(
		{
			type: 5,
			child_id: childId,
		},
		{ skip: !childId }
	)
	const { data: form5Value } = fetchForm5Value?.data ?? {}

	useEffect(() => {
		const initForms5Data = {
			type: 5,
			child_id: childId ? childId : null,
			cara_melahirkan: '',
			teknik_melahirkan: '',
			prematur: 0,
			usia_kelahiran: '',
			berat_badan_bayi: 0,
			panjang_badan_bayi: 0,
			posisi_lahir: '',
			sungsang: 0,
			kuning: 0,
			detak_jantung_anak: '',
			apgar_score: '',
			lama_melahirkan: '',
			tempat_melahirkan: '',
			dibantu_oleh: '',
			laporan_kelahiran: '',
		}
		for (const item of conPregnantcheckboxItem) {
			initForms5Data[item.name] = 0
		}
		for (const item of immunizationHistory) {
			initForms5Data[item.name] = 0
		}

		setForms5Data(initForms5Data)
	}, [])

	useEffect(() => {
		const timer = setTimeout(() => {
			dispatch(setForm5Data(forms5Data))
		}, 1000)

		return () => clearTimeout(timer)
	}, [forms5Data])

	useEffect(() => {
		if (form5Value) {
			setForms5Data({ ...forms5Data, ...form5Value })
		}
	}, [form5Value])

	const inputHandle = (value, inputName) => {
		setForms5Data({ ...forms5Data, [inputName]: value, child_id })
	}

	return (
		<div>
			<Row>
				<Col lg="12">
					<h5 className="fw-bold">Riwayat Perkembangan Anak</h5>
					<p>Centang yang dialami Anak</p>
				</Col>
			</Row>
			<Row className="mt-3 mb-2">
				<Col lg="6">
					<FormGroup>
						<Label>Usia Ibu saat hamil</Label>
						<Input
							type="text"
							className="form-control bg-light"
							name="mother_pregnant"
							placeholder="Masukkan usia dalam tahun"
							defaultValue={forms5Data?.usia_ibu_saat_hamil ?? ''}
							onChange={(e) =>
								inputHandle(e.target.value, 'usia_ibu_saat_hamil')
							}
						/>
					</FormGroup>
				</Col>
				<Col lg="6">
					<FormGroup>
						<Label>Usia Ayah saat ibu hamil</Label>
						<Input
							type="text"
							className="form-control bg-light"
							name="father_pregnant"
							placeholder="Masukkan usia dalam tahun"
							defaultValue={forms5Data?.usia_ayah_saat_ibu_hamil ?? ''}
							onChange={(e) =>
								inputHandle(e.target.value, 'usia_ayah_saat_ibu_hamil')
							}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col lg="12">
					<h5 className="fw-bold mb-4">Kondisi saat hamil</h5>
				</Col>
				<Col lg="6">
					{conPregnantcheckboxItem?.map((item) => {
						if (item.id <= 9) {
							return (
								<Fragment key={item.id}>
									<FormGroup check>
										<Input
											type="checkbox"
											name={item.name}
											id={item.name}
											value={item.value}
											checked={forms5Data[item.name] === 1}
											onChange={(e) =>
												inputHandle(e.target.checked ? 1 : 0, item.name)
											}
										/>
										<Label for={item.name}>{item.label}</Label>
									</FormGroup>
								</Fragment>
							)
						}
					})}
				</Col>
				<Col lg="6">
					{conPregnantcheckboxItem?.map((item) => {
						if (item.id >= 10) {
							return (
								<Fragment key={item.id}>
									<FormGroup check>
										<Input
											type="checkbox"
											name={item.name}
											id={item.name}
											value={item.value}
											checked={forms5Data[item.name] === 1}
											onChange={(e) =>
												inputHandle(e.target.checked ? 1 : 0, item.name)
											}
										/>
										<Label for={item.name}>{item.label}</Label>
									</FormGroup>
								</Fragment>
							)
						}
					})}
				</Col>
			</Row>
			<hr className="border border-light border-2 opacity-50" />
			<Row>
				<Col lg="12">
					<h5 className="fw-bold mb-4">Riwayat Kelahiran</h5>
				</Col>
				<Col lg="6">
					<Row>
						<Col lg="4">
							<FormGroup>
								<Label className="mb-3">Pilih cara melahirkan</Label>
								<div className="d-flex gap-5">
									<FormGroup check>
										<Input
											type="radio"
											id="caesar"
											name="cara_melahirkan"
											value={`caesar`}
											checked={forms5Data?.cara_melahirkan === 'caesar'}
											onChange={(e) =>
												inputHandle(e.target.value, 'cara_melahirkan')
											}
										/>
										<Label for="caesar">Caesar</Label>
									</FormGroup>
									<FormGroup check>
										<Input
											type="radio"
											id="normal"
											name="cara_melahirkan"
											value={`normal`}
											checked={forms5Data?.cara_melahirkan === 'normal'}
											onChange={(e) =>
												inputHandle(e.target.value, 'cara_melahirkan')
											}
										/>
										<Label for="normal">Normal</Label>
									</FormGroup>
								</div>
							</FormGroup>
						</Col>
						{forms5Data?.cara_melahirkan === 'caesar' && (
							<Col lg="8">
								<FormGroup>
									<Label>Alasan jika caesar</Label>
									<Input
										type="text"
										className="form-control bg-light"
										name="alasan_caesar"
										placeholder="Masukkan alasan jika memilih caesar"
										defaultValue={forms5Data?.alasan_caesar ?? ''}
										onChange={(e) =>
											inputHandle(e.target.value, 'alasan_caesar')
										}
									/>
								</FormGroup>
							</Col>
						)}

						<Col lg="12">
							<FormGroup>
								<Label className="mb-3">Teknik melahirkan</Label>
								<div className="d-flex gap-5">
									<FormGroup check>
										<Input
											type="radio"
											id="forcep"
											name="birth_technique"
											value={`force`}
											checked={forms5Data?.teknik_melahirkan === 'force'}
											onChange={(e) =>
												inputHandle(e.target.value, 'teknik_melahirkan')
											}
										/>
										<Label for="forcep">Force</Label>
									</FormGroup>
									<FormGroup check>
										<Input
											type="radio"
											id="vacuum"
											name="birth_technique"
											value={`vacuum`}
											checked={forms5Data?.teknik_melahirkan === 'vacuum'}
											onChange={(e) =>
												inputHandle(e.target.value, 'teknik_melahirkan')
											}
										/>
										<Label for="vacuum">Vacuum</Label>
									</FormGroup>
									<FormGroup check>
										<Input
											type="radio"
											id="dipacu"
											name="birth_technique"
											value={`dipacu`}
											checked={forms5Data?.teknik_melahirkan === 'dipacu'}
											onChange={(e) =>
												inputHandle(e.target.value, 'teknik_melahirkan')
											}
										/>
										<Label for="dipacu">Dipacu</Label>
									</FormGroup>
									<FormGroup check>
										<Input
											type="radio"
											id="none"
											name="birth_technique"
											value={`none`}
											checked={forms5Data?.teknik_melahirkan === 'none'}
											onChange={(e) =>
												inputHandle(e.target.value, 'teknik_melahirkan')
											}
										/>
										<Label for="none">Tidak Ada</Label>
									</FormGroup>
								</div>
							</FormGroup>
						</Col>
						<Col lg="12 mb-3">
							<Row className="align-items-center">
								<Col lg="4">
									<FormGroup check>
										<Input
											type="checkbox"
											name="premature"
											id="premature"
											value="1"
											checked={forms5Data?.prematur === 1}
											onChange={(e) =>
												inputHandle(e.target.checked ? 1 : 0, 'prematur')
											}
										/>
										<Label for="premature">Prematur</Label>
									</FormGroup>
								</Col>
								{forms5Data?.prematur === 1 && (
									<Col lg="8">
										<FormGroup>
											<Label>Jika premature berapa bulan?</Label>
											<Input
												type="text"
												className="form-control bg-light"
												name="premature_reason"
												placeholder="Masukkan usia kelahiran dalam bulan"
												defaultValue={forms5Data?.jika_prematur_berapa_bulan}
												onChange={(e) =>
													inputHandle(
														+e.target.value,
														'jika_prematur_berapa_bulan'
													)
												}
											/>
										</FormGroup>
									</Col>
								)}
							</Row>
						</Col>
						<Col lg="12">
							<FormGroup>
								<Label>Usia kelahiran</Label>
								<Input
									type="number"
									step="0.1"
									min="0"
									className="form-control bg-light"
									name="birth_month"
									placeholder="Masukkan usia kelahiran dalam bulan"
									defaultValue={forms5Data?.usia_kelahiran}
									onChange={
										(e) => {
											const setValUsiaKelahiran = parseInt(e.target.value) < 0 ? 0 : parseInt(e.target.value)
											inputHandle(+setValUsiaKelahiran, 'usia_kelahiran')
										}
									}
								/>
								<div className='mt-1 text-secondary'>
									Mohon gunakan simbol titik(.) jika terdapat koma atau bukan bilangan bulat contoh: 20.5
								</div>
							</FormGroup>
						</Col>
						<Col lg="12">
							<FormGroup>
								<Label>Berat badan bayi (KG)</Label>
								<Input
									// type="text"
									type="number"
									step="0.1"
									min="0"
									className="form-control bg-light"
									name="baby_weight"
									placeholder="Masukkan berat bayi dalam angka"
									defaultValue={forms5Data?.berat_badan_bayi}
									onChange={
										(e) => {
											const setValBBB = parseInt(e.target.value) < 0 ? 0 : parseInt(e.target.value)
											inputHandle(+setValBBB, 'berat_badan_bayi')
										}
									}
								/>
								<div className='mt-1 text-secondary'>
									Mohon gunakan simbol titik(.) jika terdapat koma atau bukan bilangan bulat contoh: 20.5
								</div>
							</FormGroup>
						</Col>
						<Col lg="12">
							<FormGroup>
								<Label>Panjang badan bayi (CM)</Label>
								<Input
									// type="text"
									type="number"
									step="0.1"
									min="0"
									className="form-control bg-light"
									name="baby_height"
									placeholder="Masukkan panjang bayi dalam angka"
									defaultValue={forms5Data?.panjang_badan_bayi}
									onChange={
										(e) => {
											const setHB = parseInt(e.target.value) < 0 ? 0 : parseInt(e.target.value)
											inputHandle(+setHB, 'panjang_badan_bayi')
										}
									}
								/>
								<div className='mt-1 text-secondary'>
									Mohon gunakan simbol titik(.) jika terdapat koma atau bukan bilangan bulat, contoh: 20.5
								</div>
							</FormGroup>
						</Col>
						<Col lg="12">
							<FormGroup>
								<Label className="mb-3">Posisi anak saat keluar</Label>
								<div className="d-flex align-items-center gap-5">
									<FormGroup check>
										<Input
											type="radio"
											name="birth_position"
											id="head"
											value="kepala_dulu"
											checked={forms5Data?.posisi_lahir === 'kepala_dulu'}
											onChange={(e) =>
												inputHandle(e.target.value, 'posisi_lahir')
											}
										/>
										<Label for="head">Kepala keluar dulu</Label>
									</FormGroup>
									<FormGroup check>
										<Input
											type="radio"
											name="birth_position"
											id="foot"
											value="kaki_dulu"
											checked={forms5Data?.posisi_lahir === 'kaki_dulu'}
											onChange={(e) =>
												inputHandle(e.target.value, 'posisi_lahir')
											}
										/>
										<Label for="foot">Kaki keluar dulu</Label>
									</FormGroup>
								</div>
							</FormGroup>
						</Col>
						<Col lg="12">
							<FormGroup>
								<Label className="mb-3">Apakah sungsang?</Label>
								<div className="d-flex align-items-center gap-5">
									<FormGroup check>
										<Input
											type="radio"
											name="sungsang"
											id="yes_sungsang"
											value="1"
											checked={forms5Data?.sungsang === 1}
											onChange={(e) => inputHandle(+e.target.value, 'sungsang')}
										/>
										<Label for="yes_sungsang">Iya</Label>
									</FormGroup>
									<FormGroup check>
										<Input
											type="radio"
											name="sungsang"
											id="no_sungsang"
											value="0"
											checked={forms5Data?.sungsang === 0}
											onChange={(e) => inputHandle(+e.target.value, 'sungsang')}
										/>
										<Label for="no_sungsang">Tidak</Label>
									</FormGroup>
								</div>
							</FormGroup>
						</Col>
					</Row>
				</Col>
				<Col lg="6">
					<Row>
						<Col lg="12">
							<FormGroup>
								<Label className="mb-3">Apakah mengalami kuning?</Label>
								<div className="d-flex align-items-center gap-5">
									<FormGroup check>
										<Input
											type="radio"
											name="kuning"
											id="yes_kuning"
											value="1"
											checked={forms5Data?.kuning === 0}
											onChange={(e) => inputHandle(+e.target.value, 'kuning')}
										/>
										<Label for="yes_kuning">Iya</Label>
									</FormGroup>
									<FormGroup check>
										<Input
											type="radio"
											name="kuning"
											id="no_kuning"
											value="0"
											checked={forms5Data?.kuning === 0}
											onChange={(e) => inputHandle(+e.target.value, 'kuning')}
										/>
										<Label for="no_kuning">Tidak</Label>
									</FormGroup>
								</div>
							</FormGroup>
						</Col>
						<Col lg="12">
							<FormGroup>
								<Label>Detak Jantung anak</Label>
								<Input
									type="text"
									className="form-control bg-light"
									name="heart_rate"
									placeholder="Masukkan detak jantung anak dalam angka"
									defaultValue={forms5Data?.detak_jantung_anak ?? ''}
									onChange={(e) =>
										inputHandle(e.target.value, 'detak_jantung_anak')
									}
								/>
							</FormGroup>
						</Col>
						<Col lg="12">
							<FormGroup>
								<Label>APGAR Score</Label>
								<Input
									type="text"
									className="form-control bg-light"
									name="apgar_score"
									placeholder="Masukkan APGAR Score dalam angka"
									defaultValue={forms5Data?.apgar_score ?? ''}
									onChange={(e) => inputHandle(e.target.value, 'apgar_score')}
								/>
							</FormGroup>
						</Col>
						<Col lg="12">
							<FormGroup>
								<Label>Lama melahirkan (jam)</Label>
								<Input
									type="text"
									className="form-control bg-light"
									name="lama_melahirkan"
									placeholder="Masukkan lama melahirkan"
									defaultValue={forms5Data?.lama_melahirkan ?? ''}
									onChange={(e) =>
										inputHandle(e.target.value, 'lama_melahirkan')
									}
								/>
							</FormGroup>
						</Col>
						<Col lg="12">
							<FormGroup>
								<Label className="mb-3">Dibantu oleh</Label>
								<div className="d-flex align-items-center gap-5">
									<FormGroup check>
										<Input
											type="radio"
											name="help_by"
											id="doctor"
											value="dokter"
											checked={forms5Data?.dibantu_oleh === 'dokter'}
											onChange={(e) =>
												inputHandle(e.target.value, 'dibantu_oleh')
											}
										/>
										<Label for="doctor">Dokter</Label>
									</FormGroup>
									<FormGroup check>
										<Input
											type="radio"
											name="help_by"
											id="nurse"
											value="bidan"
											checked={forms5Data?.dibantu_oleh === 'bidan'}
											onChange={(e) =>
												inputHandle(e.target.value, 'dibantu_oleh')
											}
										/>
										<Label for="nurse">Bidan</Label>
									</FormGroup>
									<FormGroup check>
										<Input
											type="radio"
											name="help_by"
											id="shaman"
											value="dukun"
											checked={forms5Data?.dibantu_oleh === 'dukun'}
											onChange={(e) =>
												inputHandle(e.target.value, 'dibantu_oleh')
											}
										/>
										<Label for="shaman">Dukun Bayi</Label>
									</FormGroup>
								</div>
							</FormGroup>
						</Col>
						<Col lg="12">
							<FormGroup>
								<Label>Tempat Bersalin</Label>
								<Input
									type="text"
									className="form-control bg-light"
									name="birth_place"
									placeholder="Nama tempat bersalin"
									defaultValue={forms5Data?.tempat_melahirkan ?? ''}
									onChange={(e) =>
										inputHandle(e.target.value, 'tempat_melahirkan')
									}
								/>
							</FormGroup>
						</Col>
						<Col lg="12">
							<FormGroup>
								<Label>Ceritakan hal spesifik saat proses kelahiran</Label>
								<Input
									type="textarea"
									className="form-control"
									name="birth_story"
									placeholder="Masukan keterangan"
									rows={5}
									defaultValue={forms5Data?.laporan_kelahiran ?? ''}
									onChange={(e) =>
										inputHandle(e.target.value, 'laporan_kelahiran')
									}
								/>
							</FormGroup>
						</Col>
					</Row>
				</Col>
			</Row>
			<hr className="border border-light border-2 opacity-50" />
			<Row>
				<Col lg="12">
					<h5 className="fw-bold">Riwayat Imunisasi</h5>
					<p>Centang yang dialami Anak</p>
				</Col>
				<Row>
					<Col lg="6">
						{immunizationHistory?.map((item) => {
							if (item.id <= 10) {
								return (
									<Fragment key={item.id}>
										<FormGroup check>
											<Input
												type="checkbox"
												name={item.name}
												id={`${item.name}_${item.id}`}
												checked={forms5Data[item.name] === 1}
												onChange={(e) =>
													inputHandle(e.target.checked ? 1 : 0, item.name)
												}
											/>
											<Label for={`${item.name}_${item.id}`}>
												{item.label}
											</Label>
										</FormGroup>
									</Fragment>
								)
							}
						})}
					</Col>
					<Col lg="6">
						{immunizationHistory?.map((item) => {
							if (item.id >= 11) {
								return (
									<Fragment key={item.id}>
										<FormGroup check>
											<Input
												type="checkbox"
												name={item.name}
												id={`${item.name}_${item.id}`}
												onChange={(e) =>
													inputHandle(e.target.checked ? 1 : 0, item.name)
												}
											/>
											<Label for={`${item.name}_${item.id}`}>
												{item.label}
											</Label>
										</FormGroup>
									</Fragment>
								)
							}
						})}
					</Col>
				</Row>
			</Row>
		</div>
	)
}

export default FormStep5
