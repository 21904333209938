import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
const { VITE_BASE_URL } = import.meta.env

const getToken = (state) => state.authSetup.token

export const serviceApi = createApi({
	reducerPath: 'serviceApi',
	tagTypes: ['ServiceApi'],
	baseQuery: fetchBaseQuery({
		baseUrl: `${VITE_BASE_URL}/v1/admin/`,
		prepareHeaders: (headers, { getState }) => {
			const token = getToken(getState())
			if (token) {
				headers.set('Authorization', `Bearer ${token}`)
			}
			return headers
		},
	}),
	endpoints: (builder) => ({
		postService: builder.mutation({
			query: (body) => ({
				url: `service`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['AddService'],
		}),
		deleteService: builder.mutation({
			query: (id) => ({
				url: `service/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['GetService'],
		}),
		updateService: builder.mutation({
			query: ({ id, name, description }) => ({
				url: `service/${id}`,
				method: 'PUT',
				body: {
					name,
					description,
				},
			}),
			invalidatesTags: ['UpdateService'],
		}),
	}),
})

export const {
	usePostServiceMutation,
	useDeleteServiceMutation,
	useUpdateServiceMutation,
} = serviceApi
