import { FaBookOpenReader, FaCalendar, FaCalendarDays, FaFileInvoiceDollar, FaHandHoldingDollar, FaHandHoldingMedical, FaHouse, FaNetworkWired, FaStore, FaUser, FaUsersBetweenLines, FaUsersGear } from "react-icons/fa6";

const Menu = [
    {
        item: 'Beranda',
        url: '/',
        icon: <FaHouse size={18} />,
        type: 'item_content',
        role: ['admin_pusat', 'admin_cabang', 'all']
    },
    {
        item: 'Profile',
        url: '/profile',
        icon: <FaUser size={18} />,
        type: 'item_content',
        role: ['client', 'all']
    },
    {
        item: 'Data',
        url: '#',
        icon: '',
        type: 'item_header',
        role: ['admin_pusat', 'admin_cabang', 'all']
    },
    {
        item: 'Form Survey',
        url: '/form-survey',
        icon: <FaBookOpenReader size={18} />,
        type: 'item_content',
        role: ['admin_pusat', 'admin_cabang', 'all']
    },
    {
        item: 'Data Customer',
        url: '/data-customer',
        icon: <FaUsersBetweenLines size={18} />,
        type: 'item_content',
        role: ['admin_pusat', 'admin_cabang', 'all']
    },
    {
        item: 'Data Pasien',
        url: '/data-patient',
        icon: <FaUsersBetweenLines size={18} />,
        type: 'item_content',
        role: ['admin_pusat', 'admin_cabang', 'all']
    },
    {
        item: 'Data Terapis',
        url: '/data-therapist',
        icon: <FaNetworkWired size={18} />,
        type: 'item_content',
        role: ['admin_pusat', 'admin_cabang', 'all']
    },
    {
        item: 'Tagihan Customer',
        url: '/invoice-customer',
        icon: <FaFileInvoiceDollar size={18} />,
        type: 'item_content',
        role: ['admin_pusat', 'admin_cabang', 'all']
    },
    {
        item: 'Jadwal Terapi',
        url: '/schedule',
        icon: <FaCalendar size={18} />,
        type: 'item_content',
        role: ['client', 'all']
    },
    {
        item: 'Invoice',
        url: '/invoice',
        icon: <FaFileInvoiceDollar size={18} />,
        type: 'item_content',
        role: ['client', 'all']
    },
    {
        item: 'Pengaturan',
        url: '#',
        icon: '',
        type: 'item_header',
        role: ['admin_pusat', 'all']
    },
    {
        item: 'Jenis Layanan',
        url: '/service-type',
        icon: <FaHandHoldingMedical size={18} />,
        type: 'item_content',
        role: ['admin_pusat', 'all']
    },
    {
        item: 'Harga Layanan',
        url: '/service-price',
        icon: <FaHandHoldingDollar size={18} />,
        type: 'item_content',
        role: ['admin_pusat', 'all']
    },
    {
        item: 'Lokasi Cabang',
        url: '/branch-location',
        icon: <FaStore size={18} />,
        type: 'item_content',
        role: ['admin_pusat', 'all']
    },
    {
        item: 'Data Admin',
        url: '/data-admin',
        icon: <FaUsersGear size={18} />,
        type: 'item_content',
        role: ['admin_pusat', 'all']
    },
];

export { Menu }