import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Container, Navbar, Row } from 'reactstrap'
import HelmetTitle from '../../components/templates/HelmetTitle'
import FormCustomer from '../customer/components/Form/FormCustomer'
import FormStep2 from './components/FormStep/FormStep2'
import FormStep3 from './components/FormStep/FormStep3'
import FormStep4 from './components/FormStep/FormStep4'
import FormStep5 from './components/FormStep/FormStep5'
import FormStep6 from './components/FormStep/FormStep6'
import FormStep7 from './components/FormStep/FormStep7'
import FormStep8 from './components/FormStep/FormStep8'
import FormStep9 from './components/FormStep/FormStep9'
import FormStep10 from './components/FormStep/FormStep10'
import summary from './summary.module.css'
import { useParams } from 'react-router-dom'
import { useGetChildByIdQuery } from '../patient/api/patientApi'
import { useDispatch } from 'react-redux'
import { useGetUserByIdQuery } from '../general/api/userApi'
import { setUserId } from '../../app/customerSlice'

const ViewSummary = () => {
	const { childId } = useParams()
	const dispatch = useDispatch()
	const [userIdByChild, setUserIdByChild] = useState(null)

	const { data: fetchChildById, isLoading } = useGetChildByIdQuery(childId, {
		skip: !childId,
	})
	const { data: fetchDataUser } = useGetUserByIdQuery(userIdByChild, {
		skip: !userIdByChild,
	})

	const { data: dataByChildId } = fetchChildById ?? {}
	const { data: dataUserById } = fetchDataUser ?? {}

	useEffect(() => {
		if (dataByChildId) {
			dispatch(setUserId(dataByChildId?.user_id))
			setUserIdByChild(dataByChildId?.user_id)
		}
	}, [dataByChildId])

	if (isLoading) {
		return (
			<div className='py-3 text-center'>Loading...</div>
		)
	}

	return (
		<div className="main-content mx-auto">
			<HelmetTitle title="Form Survey" />
			<Navbar
				fixed="top"
				style={{ zIndex: 999 }}
				className={`bg-white border-bottom border-warning px-2 px-md-3 px-lg-5 ${summary['print-disable']}`}
			>
				<img src="/logo-yamet-2.png" alt="logo" height="50" />
			</Navbar>
			<Container fluid style={{ marginTop: '7em' }}>
				<Row>
					<Col xs={12}>
						<Card>
							<CardBody>
								<FormCustomer dataUserById={dataUserById} />
								<div className="mb-5">
									<FormStep2 userId={dataByChildId?.user_id} />
								</div>
								<FormStep3 dataChildById={dataByChildId} />
								<FormStep4 />
								<FormStep5 />
								<FormStep6 />
								<FormStep7 />
								<FormStep8 />
								<FormStep9 />
								<FormStep10 />
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default ViewSummary
