import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
const { VITE_BASE_URL } = import.meta.env

const getToken = (state) => state.authSetup.token

export const listServiceApi = createApi({
	reducerPath: 'listServiceApi',
	tagTypes: ['listService'],
	baseQuery: fetchBaseQuery({
		baseUrl: `${VITE_BASE_URL}/v1/admin`,
		prepareHeaders: (headers, { getState }) => {
			const token = getToken(getState())
			if (token) {
				headers.set('Authorization', `Bearer ${token}`)
			}
			return headers
		},
	}),
	endpoints: (builder) => ({
		getListService: builder.query({
			query: (url) => {
				const { endpoint, page, keyword } = url
				if (page && endpoint !== 'admin') {
					return `/${endpoint}?page=${page}&keyword=${!keyword || keyword === undefined ? '' : keyword}`
				} else if (endpoint === 'admin') {
					return `?page=${page}&keyword=${!keyword || keyword === undefined ? '' : keyword}`
				}
				return `/${endpoint}`
			},
			providesTags: ['GetService'],
		}),
	}),
	refetchOnMountOrArgChange: true,
	refetchOnFocus: true,
})

export const { useGetListServiceQuery } = listServiceApi
