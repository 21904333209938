import { useState } from 'react'
import { FaX } from 'react-icons/fa6'
import { FiUpload } from 'react-icons/fi'
import { Alert, Button, Modal, ModalBody, ModalFooter } from 'reactstrap'
import { useUploadPaymentEvidenceMutation } from '../../features/general/api/clientApi'

const PaymentEvidence = ({ link, invoiceId }) => {
	const [showModal, setShowModal] = useState(false)
	const [successUpload, setSuccessUpload] = useState(false)
	const [filesData, setFilesData] = useState({})
	const [filename, setFilename] = useState(null)

	const [uploadPaymentEvidence, { isLoading }] =
		useUploadPaymentEvidenceMutation()

	const toggleModal = () => {
		setFilesData({})
		setFilename(null)
		setShowModal(!showModal)
	}

	const handleFileChange = (e) => {
		const file = e.target.files[0]
		if (file) {
			setFilename(file.name)
			convertToBase64(file)
		}
	}

	const convertToBase64 = (file) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => {
			setFilesData(reader.result)
		}
		reader.onerror = (error) => {
			console.error('Error converting file to Base64:', error)
		}
	}

	const handleUploadPayment = async (invoiceId) => {
		setSuccessUpload(false)
		try {
			const payload = {
				invoiceId,
				file: filesData,
			}
			const result = await uploadPaymentEvidence(payload).unwrap()
			const { status } = result

			if (status === 'ok') {
				setSuccessUpload(true)

				setTimeout(() => {
					toggleModal()
				}, 1000)
			}
		} catch (error) {
			console.error(error)
		}
	}

	if (!link) {
		return (
			<>
				<button
					className="btn btn-sm text-white fw-bold"
					style={{ backgroundColor: '#FFA800' }}
					onClick={toggleModal}
				>
					Upload
				</button>
				<Modal isOpen={showModal} toggle={toggleModal} centered>
					<ModalBody>
						{successUpload && (
							<Alert>
								<p className="mb-0">Berhasil upload bukti pembayaran</p>
							</Alert>
						)}
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h5 className="mb-0">Upload Bukti Bayar</h5>
							<FaX onClick={toggleModal} />
						</div>
						<div>
							<input
								type="file"
								placeholder="Klik untuk upload"
								id="last_3_m_therapy"
								name="last_3_m_therapy_file"
								hidden
								onChange={(e) => handleFileChange(e)}
							/>
							<label
								htmlFor="last_3_m_therapy"
								className="bg-light w-100 p-2 rounded border border-dark-subtle"
							>
								<div className="d-flex justify-content-between align-items-center ">
									<p className="mb-0 text-secondary">
										{filename ? filename : 'Klik untuk upload'}
									</p>
									<FiUpload />
								</div>
							</label>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" onClick={toggleModal}>
							Batal
						</Button>
						<Button
							color="success"
							onClick={() => handleUploadPayment(invoiceId)}
							disabled={isLoading}
						>
							Simpan
						</Button>
					</ModalFooter>
				</Modal>
			</>
		)
	}

	return (
		<div className="bg-success fw-bold d-inline-flex text-white py-1 px-3 rounded">
			Uploaded
		</div>
	)
}

export default PaymentEvidence
