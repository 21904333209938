import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
const { VITE_BASE_URL } = import.meta.env

const getToken = (state) => state.authSetup.token

export const priceApi = createApi({
	reducerPath: 'priceApi',
	tagTypes: ['priceApi'],
	baseQuery: fetchBaseQuery({
		baseUrl: `${VITE_BASE_URL}/v1/admin/`,
		prepareHeaders: (headers, { getState }) => {
			const token = getToken(getState())
			if (token) {
				headers.set('Authorization', `Bearer ${token}`)
			}
			return headers
		},
	}),
	endpoints: (builder) => ({
		postPrice: builder.mutation({
			query: (body) => ({
				url: `master-price`,
				method: 'POST',
				body,
			}),

			invalidatesTags: ['AddPrice'],
		}),
		deletePrice: builder.mutation({
			query: (id) => ({
				url: `master-price/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['GetPrice'],
		}),
		updatePrice: builder.mutation({
			query: ({ id, title, description, service_price }) => ({
				url: `master-price/${id}`,
				method: 'PUT',
				body: {
					title,
					description,
					service_price
				},
			}),
			invalidatesTags: ['UpdatePrice'],
		}),
		getPriceDetailById: builder.query({
			query: (id) => ({
				url: `master-price/${id}`
			}),
			invalidatesTags: ['GetPriceDetailById']
		})
	}),
})

export const {
	usePostPriceMutation,
	useDeletePriceMutation,
	useUpdatePriceMutation,
	useGetPriceDetailByIdQuery
} = priceApi
