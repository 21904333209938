import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { layoutSetupSelector, setPageTitle, setTableKeyword } from '../../app/layoutSlice'
import { Button, Card, CardBody, Col, Container, Input, Row } from 'reactstrap'
import HelmetTitle from '../../components/templates/HelmetTitle'
import { CiSearch } from 'react-icons/ci'
import TableClient from '../../components/templates/TableClient'
import PaginationButton from '../../components/templates/PaginationButton/PaginationButton'
import { useGetListChildQuery } from './api/patientApi'
import ActionButtonPatient from './components/ActionButtonPatient'
import { debounce } from 'lodash'

const headerDataPatient = [
	{
		id: 1,
		title: 'No',
	},
	{
		id: 2,
		title: 'ID Anak',
	},
	{
		id: 3,
		title: 'Nama Anak',
	},
	{
		id: 4,
		title: 'Nama Customer',
	},
	{
		id: 5,
		title: 'Cabang',
	},
	{
		id: 6,
		title: 'Status',
	},
	{
		id: 7,
		title: 'Aksi',
	},
]

const ListPatient = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [page, setPage] = useState(1)

	const { tableKeyword } = useSelector(layoutSetupSelector)

	const { data: fetchChild } = useGetListChildQuery({ page, keyword: tableKeyword })
	const { data: dataChild } = fetchChild ?? {}

	const handlerSearch = (event) => {
		dispatch(setTableKeyword(event.target.value))
	}

	const debouncedHandleSearch = useCallback(debounce(handlerSearch, 500), []);

	useEffect(() => {
		dispatch(setPageTitle('Data Pasien'))
	}, [])

	return (
		<>
			<HelmetTitle title="Data Pasien" />
			<Container fluid>
				<Row className="justify-content-center">
					<Col xs={12}>
						<Card>
							<CardBody>
								<div className="d-flex justify-content-between align-items-center">
									<h3 className="mb-0">Data Pasien</h3>
									<div className="d-flex gap-2">
										<hr className="border border-light border-2 opacity-50"></hr>
										<div className="position-relative">
											<Input
												onChange={debouncedHandleSearch}
												placeholder="Pencarian..."
												className="pe-5"
											/>
											<CiSearch
												size={24}
												className="position-absolute"
												style={{ top: '20%', right: '5%' }}
											/>
										</div>
										<Button
											color="success"
											className="px-4"
											onClick={() => navigate('/data-patient/add-patient')}
										>
											Tambah
										</Button>
									</div>
								</div>
								<hr className="border border-light border-2 opacity-50"></hr>
								<TableClient
									dataHeader={headerDataPatient}
									dataRow={dataChild?.data}
									dataSource={'child'}
									actionButton={ActionButtonPatient}
								/>
								<PaginationButton
									metaPages={dataChild}
									currentPage={page}
									setPage={setPage}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	)
}

export default ListPatient
