import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Alert, Button, Form, Input, Label } from "reactstrap";
import * as yup from "yup";
import YupPassword from "yup-password";
import { authSetupSelector, setConditionResetPassword } from "../../../app/authSlice";
import { usePostChangePasswordMutation } from "../api/ResetPasswordAPI";
YupPassword(yup)

const FormChangePasswordAdmin = () => {
    const [errorMessage, setErrorMessage] = useState('');
    const dispatch = useDispatch()

    const {
        name_reset_password,
        email_reset_password
    }
        = useSelector(authSetupSelector)
    const [postChangePassword, { isLoading }] = usePostChangePasswordMutation();

    const schema = yup.object({
        password: yup.string()
            .minUppercase(1, "Minimal 1 huruf kapital!")
            .minLowercase(1, "Minimal 1 huruf kecil!")
            .minNumbers(1, "Minimal 1 angka!")
            .minSymbols(1, "Minimal 1 simbol: _-#1@&^$")
            .min(8, "Minimal panjang 8 karakter!")
    });

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            password: "",
        },
    });

    const handleOnSubmit = async (param) => {
        param.email = email_reset_password
        param.type = "admin"

        try {
            await postChangePassword(param).unwrap();
            dispatch(
                setConditionResetPassword(
                    { condition_reset_password: 'success' }
                )
            )
        } catch (error) {
            console.error(error.data)

            setErrorMessage('Terjadi error dari server, mohon hubungi admin')
        }
    }

    return (
        <div className="my-3">
            <div className="text-center">
                <h5 className="text-success">Ubah Kata Sandi</h5>
                <p className="text-muted">Masukan kata sandi baru anda</p>
            </div>
            <div className="mt-4">
                <Form
                    onSubmit={handleSubmit(handleOnSubmit)} className="form-horizontal">
                    <div className="user-thumb text-center mb-4">
                        <h5 className="font-size-12 mt-3">
                            {name_reset_password}
                        </h5>
                    </div>

                    {
                        errorMessage
                        &&
                        <Alert color="danger">{errorMessage}</Alert>
                    }
                    <div className="mb-3">
                        <Label className="form-label">
                            Kata Sandi
                            {` `}
                            <span className="text-danger">*</span>
                        </Label>
                        <Controller
                            name="password"
                            control={control}
                            render={({ field: { onChange, onBlur, value } }) => {
                                return (
                                    <Input
                                        name="password"
                                        className="form-control"
                                        placeholder="Enter password"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        type="password"
                                        invalid={false}
                                    />
                                )
                            }}
                        />
                    </div>
                    <div className="mt-0">
                        <span className="mt-0 text-danger text-start">
                            {errors.password?.message}
                        </span>
                    </div>
                    <div className="text-end">
                        <Button
                            type="submit"
                            color="success"
                            disabled={isLoading}
                        >
                            {isLoading ? 'Loading...' : 'Ubah'}
                        </Button>
                    </div>
                </Form>
                <div className="mt-5 text-center">
                    <p>
                        Ingat ?{" "}
                        <Link
                            to="/login-admin"
                            className="fw-medium text-success"
                        >
                            {" "}
                            Silahkan Masuk
                        </Link>{" "}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default FormChangePasswordAdmin;
