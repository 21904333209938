import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, FormGroup, Input, Label } from 'reactstrap'
import { customerStateSelector } from '../../../../app/customerSlice'
import { setForm2Data } from '../../../../app/surveySlice'
import { useGetFormTwoQuery } from '../../api/surveyApi'

const FormStep2 = ({ userId }) => {
	const dispatch = useDispatch()
	const [dataForm, setDataForm] = useState({
		user_id: null,
		know_yamet_from: '',
		mechanism_of_observation_and_assessment: 0,
		online_assessment_and_consultation_results: 0,
	})
	const { user_id } = useSelector(customerStateSelector)
	const { data: fetchDataForm2 } = useGetFormTwoQuery(userId, { skip: !userId })
	const { data: dataForm2 } = fetchDataForm2 ?? {}

	const inputRef = useRef()

	useEffect(() => {
		const timer = setTimeout(() => {
			dispatch(setForm2Data(dataForm))
		}, 800)

		return () => {
			clearTimeout(timer)
		}
	}, [dataForm])

	useEffect(() => {
		if (dataForm2) {
			const { created_at, updated_at, id, ...rest } = dataForm2
			setDataForm(rest)
		}
	}, [dataForm2])

	const form2Handler = () => {
		setDataForm({
			...dataForm,
			user_id,
			know_yamet_from: inputRef.current.value,
		})
	}

	return (
		<>
			<h5 className="fw-bold mb-4">DATA UTAMA</h5>
			<ul className="mb-3">
				<li>
					Pengisian form kemungkinan akan memakan waktu 20 menit siapkan
					informasi terkait bagian berikut
					<ul style={{ listStyle: 'lower-roman' }}>
						<li>Data diri Ayah, Ibu, dan Anak</li>
						<li>Keluhan orang tua</li>
						<li>
							Riwayat perkembangan Anak (Riwayata kehamilan, kelahiran,
							imunisasi)
						</li>
						<li>Perkembangan fisik Anak</li>
						<li>Perkembangan bahasa Anak</li>
						<li>Riwayat pendidikan formal dan informal</li>
						<li>Riwayat Terapi</li>
					</ul>
				</li>
				<li>Anda dapat menyimpan sebagai draft data yang sudah Anda isi</li>
			</ul>
			<Form>
				<FormGroup>
					<Label className="fw-bold">
						Mengetahui Yamet Child Development Center dari?
					</Label>
					<input
						ref={inputRef}
						className="form-control bg-light"
						placeholder="Isi dari mana Anda mengetahui Yamet Child Development Center"
						onBlur={form2Handler}
						defaultValue={dataForm?.know_yamet_from ?? ''}
					/>
				</FormGroup>
				<FormGroup>
					<Label>
						Apakah sudah dijelaskan mekanisme observasi & assessment oleh admin
					</Label>
					<div className="d-flex gap-4">
						<FormGroup check>
							<Input
								id="yes-knows"
								type="radio"
								name="knows"
								value={1}
								checked={dataForm?.mechanism_of_observation_and_assessment === 1}
								onChange={(e) =>
									setDataForm({
										...dataForm,
										mechanism_of_observation_and_assessment: +e.target.value,
									})
								}
							/>
							<Label for="yes-knows">Iya</Label>
						</FormGroup>
						<FormGroup check>
							<Input
								id="no-knows"
								type="radio"
								name="knows"
								value={0}
								checked={dataForm?.mechanism_of_observation_and_assessment === 0}
								onChange={(e) =>
									setDataForm({
										...dataForm,
										mechanism_of_observation_and_assessment: +e.target.value,
									})
								}
							/>
							<Label for="no-knows">Tidak</Label>
						</FormGroup>
					</div>
				</FormGroup>
				<FormGroup>
					<Label>Apakah bersedia assessment dan konsultasi hasil online?</Label>
					<div className="d-flex gap-4">
						<FormGroup check>
							<Input
								id="yes-consent"
								type="radio"
								name="online_assessment_and_consultation_results"
								value={1}
								checked={dataForm?.online_assessment_and_consultation_results === 1}
								onChange={(e) =>
									setDataForm({
										...dataForm,
										online_assessment_and_consultation_results: +e.target.value,
									})
								}
							/>
							<Label for="yes-consent">Iya</Label>
						</FormGroup>
						<FormGroup check>
							<Input
								id="no-consent"
								type="radio"
								name="online_assessment_and_consultation_results"
								value={0}
								checked={dataForm?.online_assessment_and_consultation_results === 0}
								onChange={(e) =>
									setDataForm({
										...dataForm,
										online_assessment_and_consultation_results: +e.target.value,
									})
								}
							/>
							<Label for="no-consent">Tidak</Label>
						</FormGroup>
					</div>
				</FormGroup>
			</Form>
		</>
	)
}

export default FormStep2
