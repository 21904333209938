import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, FormGroup, Input, Label, Row } from 'reactstrap'
import { setForm8Data, surveyStateSelector } from '../../../../app/surveySlice'
import { useParams } from 'react-router-dom'
import { useGetSurveyFormValueQuery } from '../../api/surveyApi'

const FormStep8 = () => {
	const { childId } = useParams()
	const dispatch = useDispatch()
	const [forms8Data, setForms8Data] = useState({})
	const { child_id } = useSelector(surveyStateSelector)
	const { data: fetchForm8Value } = useGetSurveyFormValueQuery(
		{
			type: 8,
			child_id: childId,
		},
		{ skip: !childId }
	)
	const { data: form8Value } = fetchForm8Value?.data ?? {}

	useEffect(() => {
		const initForms8Data = {
			type: 8,
			child_id: childId ? childId : null,
			perilaku_saat_bertemu_orang_baru: '',
			perilaku_saat_bertemu_teman_seumur: '',
			perilaku_saat_bertemu_anak_yang_lebih_muda: '',
			perilaku_saat_bertemu_orang_yang_lebih_tua: '',
			bermain_dengan_banyak_anak_atau_tidak: '',
			keterangan_lainnya: '',
			teratur: 0,
			sering_terbangun: 0,
			jam_tidur: '',
			jam_bangun: '',
			sakit_pada_daerah_telinga: 0,
			usia_sakit_pada_daerah_telinga: '',
			penjelasan_sakit_pada_daerah_telinga: '',
			pernah_sakit_pada_pencernaan_atau_pembuangan: 0,
			usia_saat_menderita_sakit_pada_pencernaan_dan_pembuangan: '',
			penjelasan_sakit_pada_pencernaan_dan_pembuangan: '',
			pernah_sakit_pada_mata: 0,
			usia_pernah_sakit_pada_mata: '',
			penjelasan_pernah_sakit_pada_mata: '',
			penyakit_yang_pernah_diderita_lainnya: '',
		}
		setForms8Data(initForms8Data)
	}, [])

	useEffect(() => {
		const timer = setTimeout(() => {
			dispatch(setForm8Data(forms8Data))
		}, 1000)

		return () => clearTimeout(timer)
	}, [forms8Data])

	useEffect(() => {
		if (form8Value) {
			setForms8Data({ ...forms8Data, ...form8Value })
		}
	}, [form8Value])

	const inputHandle = (value, inputName) => {
		setForms8Data({ ...forms8Data, [inputName]: value, child_id })
	}

	return (
		<>
			<Row>
				<Col lg="12">
					<h5 className="fw-bold mb-3">Perkembangan Sosial</h5>
				</Col>
			</Row>
			<Row>
				<Col lg="6">
					<FormGroup>
						<Label>Bagaimana perilaku saat bertemu orang baru</Label>
						<Input
							type="textarea"
							name="perilaku_bertemu_orang"
							placeholder="Masukkan keterangan"
							rows={10}
							defaultValue={forms8Data?.perilaku_saat_bertemu_orang_baru ?? ''}
							onChange={(e) =>
								inputHandle(e.target.value, 'perilaku_saat_bertemu_orang_baru')
							}
						/>
					</FormGroup>
					<FormGroup>
						<Label>Perilaku saat bertemu anak yang lebih muda</Label>
						<Input
							type="textarea"
							name="perilaku_dgn_anak_lebih_muda"
							placeholder="Masukkan keterangan"
							rows={10}
							defaultValue={
								forms8Data?.perilaku_saat_bertemu_anak_yang_lebih_muda ?? ''
							}
							onChange={(e) =>
								inputHandle(
									e.target.value,
									'perilaku_saat_bertemu_anak_yang_lebih_muda'
								)
							}
						/>
					</FormGroup>
					<FormGroup>
						<Label>Bermain dengan banyak anak/tidak</Label>
						<Input
							type="textarea"
							name="bermain_dengan_banyak_anak"
							placeholder="Masukkan keterangan"
							rows={10}
							defaultValue={
								forms8Data?.bermain_dengan_banyak_anak_atau_tidak ?? ''
							}
							onChange={(e) =>
								inputHandle(
									e.target.value,
									'bermain_dengan_banyak_anak_atau_tidak'
								)
							}
						/>
					</FormGroup>
				</Col>
				<Col lg="6">
					<FormGroup>
						<Label>Perilaku saat bertemu teman seumur</Label>
						<Input
							type="textarea"
							name="bermain_dengan_teman_seumur"
							placeholder="Masukkan keterangan"
							rows={10}
							defaultValue={
								forms8Data?.perilaku_saat_bertemu_teman_seumur ?? ''
							}
							onChange={(e) =>
								inputHandle(
									e.target.value,
									'perilaku_saat_bertemu_teman_seumur'
								)
							}
						/>
					</FormGroup>
					<FormGroup>
						<Label>Perilaku saat bertemu orang yang lebih tua</Label>
						<Input
							type="textarea"
							name="perilaku_dgn_anak_lebih_tua"
							placeholder="Masukkan keterangan"
							rows={10}
							defaultValue={
								forms8Data?.perilaku_saat_bertemu_orang_yang_lebih_tua ?? ''
							}
							onChange={(e) =>
								inputHandle(
									e.target.value,
									'perilaku_saat_bertemu_orang_yang_lebih_tua'
								)
							}
						/>
					</FormGroup>
					<FormGroup>
						<Label>Keterangan lainnya:</Label>
						<Input
							type="textarea"
							name="keterangan_lainnya_perkembangan_sosial"
							placeholder="Masukkan keterangan"
							rows={10}
							defaultValue={forms8Data?.keterangan_lainnya ?? ''}
							onChange={(e) =>
								inputHandle(e.target.value, 'keterangan_lainnya')
							}
						/>
					</FormGroup>
				</Col>
			</Row>
			<hr className="border border-light border-2 opacity-50" />
			<Row>
				<Col lg="12">
					<h5 className="fw-bold mb-3">Pola Tidur</h5>
				</Col>
				<Col lg="6">
					<FormGroup>
						<Label>Apakah Anak tidur teratur?</Label>
						<div className="d-flex gap-3">
							<FormGroup check>
								<Input
									type="radio"
									name="tidur_teratur"
									value={1}
									id="tidur_teratur_yes"
									checked={forms8Data?.teratur == 1}
									onChange={(e) => inputHandle(+e.target.value, 'teratur')}
								/>
								<Label for="tidur_teratur_yes">Iya</Label>
							</FormGroup>
							<FormGroup check>
								<Input
									type="radio"
									name="tidur_teratur"
									value={0}
									id="tidur_teratur_no"
									checked={forms8Data?.teratur == 0}
									onChange={(e) => inputHandle(+e.target.value, 'teratur')}
								/>
								<Label for="tidur_teratur_no">Tidak</Label>
							</FormGroup>
						</div>
					</FormGroup>
					<FormGroup>
						<Label>Jam Tidur</Label>
						<Input
							type="text"
							className="form-control bg-light"
							name="jam_tidur"
							placeholder="Masukkan jam tidur, contoh: (21:00)"
							defaultValue={forms8Data?.jam_tidur ?? ''}
							onChange={(e) => inputHandle(e.target.value, 'jam_tidur')}
						/>
					</FormGroup>
				</Col>
				<Col lg="6">
					<FormGroup>
						<Label>Apakah Anak sering terbangun?</Label>
						<div className="d-flex gap-3">
							<FormGroup check>
								<Input
									type="radio"
									name="sering_terbangun"
									value={1}
									id="sering_terbangun_yes"
									checked={forms8Data?.sering_terbangun == 1}
									onChange={(e) =>
										inputHandle(+e.target.value, 'sering_terbangun')
									}
								/>
								<Label for="sering_terbangun_yes">Iya</Label>
							</FormGroup>
							<FormGroup>
								<Input
									type="radio"
									name="sering_terbangun"
									value={0}
									id="sering_terbangun_no"
									checked={forms8Data?.sering_terbangun == 0}
									onChange={(e) =>
										inputHandle(+e.target.value, 'sering_terbangun')
									}
								/>
								<Label for="sering_terbangun_no">Tidak</Label>
							</FormGroup>
						</div>
						<FormGroup>
							<Label>Jam Bangun</Label>
							<Input
								type="text"
								className="form-control bg-light"
								name="jam_bangun"
								placeholder="Jam Bangun, contoh: (06:00)"
								defaultValue={forms8Data?.jam_bangun ?? ''}
								onChange={(e) => inputHandle(e.target.value, 'jam_bangun')}
							/>
						</FormGroup>
					</FormGroup>
				</Col>
			</Row>
			<hr className="border border-light border-2 opacity-50" />
			<Row className="mt-3 mb-2">
				<Col lg="12">
					<h5 className="fw-bold">
						Penyakit yang pernah diderita Anak (sejak lahir-hari ini)
					</h5>
					<p>Centang yang dialami Anak</p>
				</Col>
			</Row>
			<Row className="align-items-center">
				<Col lg="3">
					<FormGroup check>
						<Input
							type="checkbox"
							name="sakit_daerah_telinga"
							id="sakit_daerah_telinga"
							value="1"
							checked={forms8Data?.sakit_pada_daerah_telinga === 1}
							onChange={(e) =>
								inputHandle(
									e.target.checked ? 1 : 0,
									'sakit_pada_daerah_telinga'
								)
							}
						/>
						<Label for="sakit_daerah_telinga">Sakit pada daerah telinga</Label>
					</FormGroup>
				</Col>
				<Col lg="3">
					<FormGroup>
						<Input
							type="text"
							className="form-control bg-light"
							name="sakit_daerah_telinga_age"
							placeholder="Usia (dalam bulan)"
							defaultValue={forms8Data?.usia_sakit_pada_daerah_telinga ?? ''}
							onChange={(e) =>
								inputHandle(e.target.value, 'usia_sakit_pada_daerah_telinga')
							}
						/>
					</FormGroup>
				</Col>
				<Col lg="6">
					<FormGroup>
						<Input
							type="text"
							className="form-control bg-light"
							name="sakit_daerah_telinga_reason"
							placeholder="Jelaskan"
							defaultValue={
								forms8Data?.penjelasan_sakit_pada_daerah_telinga ?? ''
							}
							onChange={(e) =>
								inputHandle(
									e.target.value,
									'penjelasan_sakit_pada_daerah_telinga'
								)
							}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row className="align-items-center">
				<Col lg="3">
					<FormGroup check>
						<Input
							type="checkbox"
							name="sakit_pencernaan"
							id="sakit_pencernaan"
							value="1"
							checked={
								forms8Data?.pernah_sakit_pada_pencernaan_atau_pembuangan === 1
							}
							onChange={(e) =>
								inputHandle(
									e.target.checked ? 1 : 0,
									'pernah_sakit_pada_pencernaan_atau_pembuangan'
								)
							}
						/>
						<Label for="sakit_pencernaan">
							Sakit pada pencernaan/pembuangan
						</Label>
					</FormGroup>
				</Col>
				<Col lg="3">
					<FormGroup>
						<Input
							type="text"
							className="form-control bg-light"
							name="sakit_pencernaan_age"
							placeholder="Usia (dalam bulan)"
							defaultValue={
								forms8Data?.usia_saat_menderita_sakit_pada_pencernaan_dan_pembuangan ??
								''
							}
							onChange={(e) =>
								inputHandle(
									+e.target.value,
									'usia_saat_menderita_sakit_pada_pencernaan_dan_pembuangan'
								)
							}
						/>
					</FormGroup>
				</Col>
				<Col lg="6">
					<FormGroup>
						<Input
							type="text"
							className="form-control bg-light"
							name="sakit_pencernaan_reason"
							placeholder="Jelaskan"
							defaultValue={
								forms8Data?.penjelasan_sakit_pada_pencernaan_dan_pembuangan ??
								''
							}
							onChange={(e) =>
								inputHandle(
									e.target.value,
									'penjelasan_sakit_pada_pencernaan_dan_pembuangan'
								)
							}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row className="align-items-center">
				<Col lg="3">
					<FormGroup check>
						<Input
							type="checkbox"
							name="sakit_mata"
							id="sakit_mata"
							value="1"
							checked={forms8Data?.pernah_sakit_pada_mata === 1}
							onChange={(e) =>
								inputHandle(e.target.checked ? 1 : 0, 'pernah_sakit_pada_mata')
							}
						/>
						<Label for="sakit_mata">Sakit pada mata</Label>
					</FormGroup>
				</Col>
				<Col lg="3">
					<FormGroup>
						<Input
							type="text"
							className="form-control bg-light"
							name="sakit_mata_age"
							placeholder="Usia (dalam bulan)"
							defaultValue={forms8Data?.usia_pernah_sakit_pada_mata ?? ''}
							onChange={(e) =>
								inputHandle(e.target.value, 'usia_pernah_sakit_pada_mata')
							}
						/>
					</FormGroup>
				</Col>
				<Col lg="6">
					<FormGroup>
						<Input
							type="text"
							className="form-control bg-light"
							name="sakit_mata_reason"
							placeholder="Jelaskan"
							defaultValue={forms8Data?.penjelasan_pernah_sakit_pada_mata}
							onChange={(e) =>
								inputHandle(e.target.value, 'penjelasan_pernah_sakit_pada_mata')
							}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row className="align-items-center">
				<Col lg="3">
					<FormGroup check>
						<Input
							type="checkbox"
							name="luka_kepala"
							id="luka_kepala"
							value="1"
						/>
						<Label for="luka_kepala">Terluka di bagian kepala</Label>
					</FormGroup>
				</Col>
				<Col lg="3">
					<FormGroup>
						<Input
							type="text"
							className="form-control bg-light"
							name="luka_kepala_age"
							placeholder="Usia (dalam bulan)"
						/>
					</FormGroup>
				</Col>
				<Col lg="6">
					<FormGroup>
						<Input
							type="text"
							className="form-control bg-light"
							name="luka_kepala_reason"
							placeholder="Jelaskan"
						/>
					</FormGroup>
				</Col>
				<Col lg="12" className="mt-3">
					<FormGroup>
						<Label className="fw-bold">
							Penyakit yang pernah diderita lainnya
						</Label>
						<Input
							type="textarea"
							rows={10}
							name="penyakit_yang_pernah_diderita_lainnya"
							onChange={(e) =>
								inputHandle(
									e.target.value,
									'penyakit_yang_pernah_diderita_lainnya'
								)
							}
						/>
					</FormGroup>
				</Col>
			</Row>
		</>
	)
}

export default FormStep8
