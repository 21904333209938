import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, FormGroup, Input, Label, Row } from 'reactstrap'
import { setForm9Data, surveyStateSelector } from '../../../../app/surveySlice'
import { useParams } from 'react-router-dom'
import { useGetSurveyFormValueQuery } from '../../api/surveyApi'

const FormStep9 = () => {
	const { childId } = useParams()
	const dispatch = useDispatch()
	const [forms9Data, setForms9Data] = useState({})
	const { child_id } = useSelector(surveyStateSelector)
	const { data: fetchForm9Value } = useGetSurveyFormValueQuery(
		{
			type: 9,
			child_id: childId,
		},
		{ skip: !childId }
	)
	const { data: form9Value } = fetchForm9Value?.data ?? {}


	useEffect(() => {
		const initForms9Data = {
			type: 9,
			child_id: childId ? childId : '',
			anak_tinggal_dengan: [],
			sebutkan_jika_lainnya: '',
			gambaran_hubungan_dengan_keluarga: {
				ayah_dengan_ibu: '',
				ayah_dengan_anak: '',
				ibu_dengan_anak: '',
				anak_dengan_kakak_adik: '',
				anak_dengan_kakek_nenek: '',
				anak_dengan_saudara_ibu_ayah_jika_tinggal_bersama: '',
				anak_dengan_pengasuh: '',
			},
		}
		setForms9Data(initForms9Data)
	}, [])

	useEffect(() => {
		const timer = setTimeout(() => {
			dispatch(setForm9Data(forms9Data))
		}, 1000)

		return () => clearTimeout(timer)
	}, [forms9Data])

	useEffect(() => {
		if (form9Value) {
			setForms9Data({ ...forms9Data, ...form9Value })
		}
	}, [form9Value])

	const inputHandle = (value, inputName, subInputName = false) => {
		if (subInputName) {
			setForms9Data({
				...forms9Data,
				child_id,
				gambaran_hubungan_dengan_keluarga: {
					...forms9Data.gambaran_hubungan_dengan_keluarga,
					[inputName]: value,
				},
			})
			return
		}

		if (inputName === 'anak_tinggal_dengan') {
			setForms9Data((prevState) => {
				const newValue = value

				// Check if the value already exists in the state array
				const isValueExists = prevState[inputName].includes(newValue)

				// If value exists, filter it out from the array, otherwise, add it
				const updatedArray = isValueExists
					? prevState[inputName].filter((item) => item !== newValue)
					: [...prevState[inputName], newValue]

				return {
					...prevState,
					[inputName]: updatedArray,
				}
			})
			return
		}
		setForms9Data({ ...forms9Data, [inputName]: value, child_id })
	}

	return (
		<Row>
			<Col lg="6">
				<div className="mb-3">
					<h5 className="fw-bold">Hubungan dalam keluarga</h5>
					<FormGroup>
						<Label>Anak tinggal dengan (bisa pilih lebih dari 1)</Label>
						<FormGroup check>
							<Input
								type="checkbox"
								name="kelaurga_inti"
								id="kelaurga_inti"
								value="main_family"
								checked={forms9Data.anak_tinggal_dengan?.includes(
									'main_family'
								)}
								onChange={(e) =>
									inputHandle(
										e.target.checked ? e.target.value : e.target.value,
										'anak_tinggal_dengan'
									)
								}
							/>
							<Label for="kelaurga_inti">Keluarga Inti</Label>
						</FormGroup>
						<FormGroup check>
							<Input
								type="checkbox"
								name="keluarga_kakek_nenek"
								id="keluarga_kakek_nenek"
								value="grandma_or_grandpa"
								checked={forms9Data.anak_tinggal_dengan?.includes(
									'grandma_or_grandpa'
								)}
								onChange={(e) =>
									inputHandle(
										e.target.checked ? e.target.value : e.target.value,
										'anak_tinggal_dengan'
									)
								}
							/>
							<Label for="keluarga_kakek_nenek">Keluarga nenek-kakek</Label>
						</FormGroup>
						<FormGroup check>
							<Input
								type="checkbox"
								name="keluarga_ibu_ayah"
								id="keluarga_ibu_ayah"
								value="sibling"
								checked={forms9Data.anak_tinggal_dengan?.includes(
									'sibling'
								)}
								onChange={(e) =>
									inputHandle(
										e.target.checked ? e.target.value : e.target.value,
										'anak_tinggal_dengan'
									)
								}
							/>
							<Label for="keluarga_ibu_ayah">Keluarga saudara ibu/ayah</Label>
						</FormGroup>
					</FormGroup>
				</div>
				<div className="mb-3">
					<h5 className="fw-bold">Gambaran hubungan dengan anggota keluarga</h5>
					<FormGroup>
						<Label className="fw-bold">Ayah dengan Ibu</Label>
						<Input
							type="textarea"
							name="ayah_dengan_ibu"
							placeholder="Masukkan keterangan"
							rows={10}
							defaultValue={forms9Data?.gambaran_hubungan_dengan_keluarga?.ayah_dengan_ibu ?? ""}
							onChange={(e) =>
								inputHandle(e.target.value, 'ayah_dengan_ibu', true)
							}
						/>
					</FormGroup>
					<FormGroup>
						<Label className="fw-bold">Ibu dengan anak</Label>
						<Input
							type="textarea"
							name="ibu_dengan_anak"
							placeholder="Masukkan keterangan"
							rows={10}
							defaultValue={forms9Data?.gambaran_hubungan_dengan_keluarga?.ibu_dengan_anak ?? ""}
							onChange={(e) =>
								inputHandle(e.target.value, 'ibu_dengan_anak', true)
							}
						/>
					</FormGroup>
					<FormGroup>
						<Label className="fw-bold">Anak dengan kakek-nenek</Label>
						<Input
							type="textarea"
							name="anak_dengan_kakek_nenek"
							placeholder="Masukkan keterangan"
							rows={10}
							defaultValue={forms9Data?.gambaran_hubungan_dengan_keluarga?.anak_dengan_kakek_nenek ?? ""}
							onChange={(e) =>
								inputHandle(e.target.value, 'anak_dengan_kakek_nenek', true)
							}
						/>
					</FormGroup>
					<FormGroup>
						<Label className="fw-bold">Anak dengan pengasuh</Label>
						<Input
							type="textarea"
							name="anak_dengan_pengasuh"
							placeholder="Masukkan keterangan"
							rows={10}
							defaultValue={forms9Data?.gambaran_hubungan_dengan_keluarga?.anak_dengan_pengasuh ?? ""}
							onChange={(e) =>
								inputHandle(e.target.value, 'anak_dengan_pengasuh', true)
							}
						/>
					</FormGroup>
				</div>
			</Col>
			<Col lg="6">
				<FormGroup>
					<Label className="fw-bold">Gambaran hubungan Anak yang lain dengan Anak</Label>
					<Input
						type="textarea"
						name="keterangan_lain_hubungan"
						placeholder="Masukkan keterangan"
						rows={6}
						defaultValue={forms9Data?.sebutkan_jika_lainnya ?? ""}
						onChange={(e) =>
							inputHandle(e.target.value, 'sebutkan_jika_lainnya')
						}
					/>
				</FormGroup>
				<FormGroup>
					<Label className="fw-bold">Ayah dengan anak</Label>
					<Input
						type="textarea"
						name="ayah_dengan_anak"
						placeholder="Masukkan keterangan"
						rows={10}
						defaultValue={forms9Data?.gambaran_hubungan_dengan_keluarga?.ayah_dengan_anak ?? ""}
						onChange={(e) =>
							inputHandle(e.target.value, 'ayah_dengan_anak', true)
						}
					/>
				</FormGroup>
				<FormGroup>
					<Label className="fw-bold">Anak dengan kakak / adik</Label>
					<Input
						type="textarea"
						name="anak_dengan_adik_kakak"
						placeholder="Masukkan keterangan"
						rows={10}
						defaultValue={forms9Data?.gambaran_hubungan_dengan_keluarga?.anak_dengan_kakak_adik ?? ""}
						onChange={(e) =>
							inputHandle(e.target.value, 'anak_dengan_kakak_adik', true)
						}
					/>
				</FormGroup>
				<FormGroup>
					<Label className="fw-bold">
						Anak dengan saudara ibu / ayah (jika tinggal bersama)
					</Label>
					<Input
						type="textarea"
						name="anak_dengan_saudara_ibu_ayah"
						placeholder="Masukkan keterangan"
						rows={10}
						defaultValue={forms9Data?.gambaran_hubungan_dengan_keluarga?.anak_dengan_saudara_ibu_ayah_jika_tinggal_bersama ?? ""}
						onChange={(e) =>
							inputHandle(
								e.target.value,
								'anak_dengan_saudara_ibu_ayah_jika_tinggal_bersama',
								true
							)
						}
					/>
				</FormGroup>
			</Col>
		</Row>
	)
}

export default FormStep9
