import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Alert, Button, Col, Form, Input, Label, Row } from 'reactstrap'
import * as yup from 'yup'
import { setUserId } from '../../../../app/customerSlice'
import { setForm1Data } from '../../../../app/surveySlice'
import PhoneNumberInput from '../../../../components/templates/PhoneNumberInput'
import {
	usePostUserMutation,
	useUpdateUserMutation,
} from '../../../general/api/userApi'
import { useUpdateClientDataMutation } from '../../../general/api/clientApi'

const FormCustomer = ({
	editForm,
	activeTab,
	setActiveTab,
	toggleTab,
	dataUserById,
}) => {
	const { id } = useParams()
	const { pathname } = useLocation()
	const isSurvey =
		pathname === '/form-survey/add' ||
		pathname.includes('draft') ||
		pathname.includes('/form-survey/summary')
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const checkrole = JSON.parse(localStorage.getItem('data'))
	const isClient = checkrole?.role === 'client'
	const [userNumber, setUserNumber] = useState(0)
	const [postUser, { isLoading }] = usePostUserMutation()
	const [updateUser, { isLoading: updateLoading }] = useUpdateUserMutation()
	const [updateClientData, { isLoading: updateClientLoading }] =
		useUpdateClientDataMutation()

	const [postDataStatus, setPostDataStatus] = useState({
		status: false,
		type: '',
		message: '',
		headerMessage: '',
	})

	const schema = yup.object({
		name: yup.string().required('Nama wajib di isi!'),
		email: yup
			.string()
			.email('Alamat email invalid')
			.required('Alamat email wajib di isi!'),
		phone_number: yup
			.string()
			.required('Nomor Telepon wajib di isi!')
			.matches(/^\+62\d{10,}$/, 'Format tidak valid & minimal 10 digit sesudah +62!'),
		password: yup
			.string()
			.required('Password wajid di isi!')
			.min(8, 'Password minimal 8 karakter!'),
		password_confirmation: yup
			.string()
			.oneOf([yup.ref('password'), null], 'Password tidak sesuai!')
			.required('Konfirmasi password terlebih dahulu!'),
	})

	const updateSchema = yup.object({
		name: yup.string().required('Nama wajib di isi!'),
		email: yup
			.string()
			.email('Alamat email invalid')
			.required('Alamat email wajib di isi!'),
		phone_number: yup
			.string()
			.required('Nomor Telepon wajib di isi!')
			.matches(/^\+62\d{10,}$/, 'Format tidak valid & minimal 10 digit sesudah +62!'),
	})

	const {
		control,
		handleSubmit,
		reset,
		watch,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(!editForm ? schema : updateSchema),
		defaultValues: {
			email: '',
			name: '',
			phone_number: '',
			password: '',
			password_confirmation: '',
		},
	})

	useEffect(() => {
		const subscription = watch((value) => dispatch(setForm1Data(value)))
		return () => {
			subscription.unsubscribe()
		}
	}, [watch])

	useEffect(() => {
		if (dataUserById) {
			const {
				name = '',
				phone_number = '',
				email = '',
				user_number = 0,
			} = dataUserById
			setUserNumber(user_number)
			reset({
				email,
				name,
				phone_number,
			})
		}
	}, [reset, dataUserById, userNumber])

	const handleCustomerSubmit = async (param) => {
		setPostDataStatus({
			status: false,
			type: '',
			message: '',
			headerMessage: '',
		})

		try {
			if (isClient) {
				let payload

				if (!param.password) {
					throw new Error('Password harus diisi.')
				} else {
					payload = { ...param }
				}
				const result = await updateClientData(payload).unwrap()
				const { status, message } = result

				if (status === 'ok') {
					setPostDataStatus({
						status: true,
						type: 'success',
						message,
						headerMessage: 'Berhasil',
					})
				}
				return
			}
			if (editForm) {
				const payload = {
					user_id: id,
					name: param?.name,
					phone_number: param?.phone_number,
				}
				const result = await updateUser(payload).unwrap()
				const { status, message } = result

				if (status === 'ok') {
					setPostDataStatus({
						status: true,
						type: 'success',
						message,
						headerMessage: 'Berhasil',
					})
				}
				return
			}
			const result = await postUser(param).unwrap()
			const {
				message,
				data: { id: user_id },
			} = result
			if (user_id) {
				dispatch(setUserId(user_id))
				localStorage.setItem('latest_userId', user_id)
			}
			setPostDataStatus({
				status: true,
				type: 'success',
				message,
				headerMessage: 'Berhasil',
			})
			setTimeout(() => {
				toggleTab(activeTab + 1)
				setActiveTab(activeTab + 1)
			}, 1000)
		} catch (error) {
			let msgErr = '';
			if (error.status === 422) {
				for (const property in error.data.data) {
					msgErr += error.data.data[property][0] + "\n";
				}
			}

			if (error.status !== 422) {
				msgErr = error.data.message
			}

			setPostDataStatus({
				status: true,
				type: 'danger',
				message: <>{msgErr}</>,
				headerMessage: 'Terjadi Kesalahan'
			})
		}
	}

	return (
		<>
			{postDataStatus.status && (
				<Alert color={postDataStatus?.type ?? 'danger'}>
					<h4 className="alert-heading">
						{postDataStatus?.headerMessage}
					</h4>
					<p
						className="mb-0"
						style={{ whiteSpace: 'pre-line' }}
					>
						{postDataStatus.message}
					</p>
				</Alert>
			)}
			<Form onSubmit={handleSubmit(handleCustomerSubmit)}>
				<Row
					className={`${editForm || pathname.includes('draft')
						? 'justify-content-center'
						: ''
						}`}
				>
					<Col lg="6">
						<h5 className="fw-bold mb-3">DATA CUSTOMER</h5>
						{(editForm ||
							pathname.includes('draft') ||
							pathname.includes('/form-survey/summary')) && (
								<Row className="p-1 mb-3">
									<Label className="col-form-label col-md-3">ID Profile</Label>
									<div className="col-md-9">
										<Input
											type="text"
											className="form-control bg-light"
											id="basicpill-firstname-input1"
											placeholder="Generate"
											value={userNumber ?? 0}
											disabled={true}
										/>
									</div>
								</Row>
							)}

						<Row className="p-1 mb-3">
							<Label className="col-form-label col-md-3 text-truncate">
								Nama Customer
							</Label>
							<div className="col-md-9">
								<Controller
									name="name"
									control={control}
									render={({ field: { onChange, onBlur, value } }) => (
										<>
											<Input
												type="text"
												className="form-control bg-light"
												placeholder="Masukkan Nama"
												onChange={onChange}
												onBlur={onBlur}
												value={value}
												invalid={errors.name ? true : false}
											/>
											{errors && (
												<small className="text-danger">
													{errors?.name?.message}
												</small>
											)}
										</>
									)}
								/>
							</div>
						</Row>
						<Row className="p-1 mb-3">
							<Label className="col-form-label col-md-3">No Telp</Label>
							<div className="col-md-9">
								<Controller
									name="phone_number"
									control={control}
									render={({ field: { onChange, onBlur, value } }) => (
										<>
											{/* <Input
												type="text"
												className="form-control bg-light"
												placeholder="Masukkan No Telp"
												onChange={onChange}
												onBlur={onBlur}
												value={value}
												invalid={errors.phone_number ? true : false}
											/> */}

											<PhoneNumberInput
												initialValue={value || '+62'}
												prefixOnZero="+62"
												className="form-control bg-light"
												placeholder="Masukkan No Telp"
												onChange={onChange}
												onBlur={onBlur}
												value={value}
											/>
											{errors && (
												<small className="text-danger">
													{errors?.phone_number?.message}
												</small>
											)}
										</>
									)}
								/>
							</div>
						</Row>
						<Row className="p-1 mb-3">
							<Label className="col-form-label col-md-3">Alamat Email</Label>
							<div className="col-md-9">
								<Controller
									name="email"
									control={control}
									render={({ field: { onChange, onBlur, value } }) => (
										<>
											<Input
												type="text"
												className="form-control bg-light"
												placeholder="Masukkan Email"
												onChange={onChange}
												onBlur={onBlur}
												value={value}
												invalid={errors.email ? true : false}
												disabled={editForm}
											/>
											{errors && (
												<small className="text-danger">
													{errors?.email?.message}
												</small>
											)}
										</>
									)}
								/>
							</div>
						</Row>
						{isClient && (
							<Row className="p-1 mb-3">
								<Label className="col-form-label col-md-3">Password</Label>
								<div className="col-md-9">
									<Controller
										name="password"
										control={control}
										render={({ field: { onChange, onBlur, value } }) => (
											<>
												<Input
													type="password"
													className="form-control bg-light"
													placeholder="Masukkan Password"
													onChange={onChange}
													onBlur={onBlur}
													value={value}
													invalid={errors.password ? true : false}
												/>
												{errors && (
													<small className="text-danger">
														{errors?.password?.message}
													</small>
												)}
											</>
										)}
									/>
								</div>
							</Row>
						)}
					</Col>
					{!editForm &&
						!pathname.includes('draft') &&
						!pathname.includes('/form-survey/summary') && (
							<Col lg="6">
								<h5 className="fw-bold mb-3">PASSWORD</h5>
								<Row className="p-1 mb-3">
									<Label className="col-form-label col-md-3">Password</Label>
									<div className="col-md-9">
										<Controller
											name="password"
											control={control}
											render={({ field: { onChange, onBlur, value } }) => (
												<>
													<Input
														type="password"
														className="form-control bg-light"
														placeholder="Masukkan Password"
														onChange={onChange}
														onBlur={onBlur}
														value={value}
														invalid={errors.password ? true : false}
													/>
													{errors && (
														<small className="text-danger">
															{errors?.password?.message}
														</small>
													)}
												</>
											)}
										/>
									</div>
								</Row>
								<Row className="p-1 mb-3">
									<Label className="col-form-label col-md-3">
										Konfirmasi Password
									</Label>
									<div className="col-md-9">
										<Controller
											name="password_confirmation"
											control={control}
											render={({ field: { onChange, onBlur, value } }) => (
												<>
													<Input
														type="password"
														className="form-control bg-light"
														placeholder="Masukkan Password"
														onChange={onChange}
														onBlur={onBlur}
														value={value}
														invalid={
															errors.password_confirmation ? true : false
														}
													/>
													{errors && (
														<small className="text-danger">
															{errors?.password_confirmation?.message}
														</small>
													)}
												</>
											)}
										/>
									</div>
								</Row>
							</Col>
						)}
					{!isSurvey && (
						<Col xs={12}>
							<div className="d-flex justify-content-end gap-2">
								{!isClient && (
									<Button
										color="primary"
										onClick={() => navigate('/data-customer')}
									>
										Batal
									</Button>
								)}
								<Button
									color="success"
									className="px-4"
									disabled={isLoading || updateLoading || updateClientLoading}
								>
									Simpan
								</Button>
							</div>
						</Col>
					)}
				</Row>
			</Form>
		</>
	)
}

export default FormCustomer
