import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
	Card,
	CardBody,
	Col,
	Container,
	Nav,
	NavItem,
	NavLink,
	Row,
	TabContent,
	TabPane,
} from 'reactstrap'
import { setPageTitle } from '../../app/layoutSlice'
import HelmetTitle from '../../components/templates/HelmetTitle'

import classnames from 'classnames'
import { useState } from 'react'
import { FaUser } from 'react-icons/fa6'
import FormCustomer from '../customer/components/Form/FormCustomer'
import FormPatient from '../patient/components/FormPatient'
import FormParents from '../customer/components/Form/FormParents'
import {
	useGetClientChildQuery,
	useGetClientDataQuery,
	useGetParentsDataQuery,
} from '../general/api/clientApi'
import { setUserId } from '../../app/customerSlice'

const ProfilePage = () => {
	const checkrole = JSON.parse(localStorage.getItem('data'))
	const dispatch = useDispatch()
	const [activeTab, setActiveTab] = useState('1')
	const [activeParentTab, setActiveParentTab] = useState(1)
	const [activeChildTab, setActiveChildTab] = useState(1)
	const { data: fetchProfileData } = useGetClientDataQuery('profile', {
		skip: checkrole?.role !== 'client',
	})
	const { data: fetchParentsData } = useGetParentsDataQuery()
	const { data: fetchClientChild } = useGetClientChildQuery()
	const { data: childsData } = fetchClientChild ?? []
	const { data: profileData } = fetchProfileData ?? {}
	const { data: parentsData } = fetchParentsData ?? {}

	useEffect(() => {
		dispatch(setPageTitle('Profil'))
	}, [])

	useEffect(() => {
		if (profileData) {
			dispatch(setUserId(+profileData.id))
		}
	}, [profileData])

	return (
		<>
			<HelmetTitle title="Profil" />
			<Container fluid>
				<div className="checkout-tabs">
					<Row>
						<Col xl="1" sm="3">
							<Nav className="flex-column" pills>
								<NavItem>
									<NavLink
										className={classnames({ active: activeTab === '1' })}
										onClick={() => {
											setActiveTab('1')
										}}
									>
										<div>
											<FaUser size={32} className="my-3" />
											<p className="mb-0">Profil</p>
										</div>
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										className={classnames({ active: activeTab === '2' })}
										onClick={() => {
											setActiveTab('2')
										}}
									>
										<div>
											<FaUser size={32} className="my-3" />
											<p className="mb-0">Profil Anak</p>
										</div>
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										className={classnames({ active: activeTab === '3' })}
										onClick={() => {
											setActiveTab('3')
										}}
									>
										<div>
											<FaUser size={32} className="my-3" />
											<p className="mb-0">Profil Orang Tua</p>
										</div>
									</NavLink>
								</NavItem>
							</Nav>
						</Col>
						<Col xl="11" sm="9">
							<Card>
								<CardBody>
									<TabContent activeTab={activeTab}>
										<TabPane tabId="1" role="tabpanel">
											<FormCustomer dataUserById={profileData} editForm />
										</TabPane>
										<TabPane tabId="2" role="tabpanel">
											<div className="wizard clearfix">
												<div className="steps clearfix">
													<ul className="gap-4">
														{childsData?.map((child, idx) => (
															<NavItem
																key={idx}
																className={classnames({
																	current: activeChildTab === 1,
																})}
															>
																<NavLink
																	className={classnames({
																		current: activeChildTab === 1,
																	})}
																	onClick={() => setActiveChildTab(idx + 1)}
																>
																	<span className="number">1.</span>{' '}
																	{child.nickname}
																</NavLink>
															</NavItem>
														))}
													</ul>
												</div>
											</div>
											{childsData?.length === 0 ? (
												<div className="text-center">Tidak ada data anak.</div>
											) : (
												<TabContent activeTab={activeChildTab} className="mt-5">
													{childsData?.map((child, idx) => (
														<TabPane tabId={1} role="tabpanel" key={idx}>
															<FormPatient childData={child} editForm />
														</TabPane>
													))}
												</TabContent>
											)}
										</TabPane>
										<TabPane tabId="3" role="tabpanel">
											<div className="wizard clearfix">
												<div className="steps clearfix">
													<ul className="gap-4">
														<NavItem
															className={classnames({
																current: activeParentTab === 1,
															})}
														>
															<NavLink
																className={classnames({
																	active: activeParentTab === 1,
																})}
																onClick={() => {
																	setActiveParentTab(1)
																}}
															>
																<span className="number">1.</span> Data Ayah
															</NavLink>
														</NavItem>
														<NavItem
															className={classnames({
																current: activeParentTab === 2,
															})}
														>
															<NavLink
																className={classnames({
																	active: activeParentTab === 2,
																})}
																onClick={() => {
																	setActiveParentTab(2)
																}}
															>
																<span className="number">2.</span> Data Ibu
															</NavLink>
														</NavItem>
													</ul>
												</div>
											</div>
											<TabContent activeTab={activeParentTab} className="mt-5">
												<TabPane tabId={1} role="tabpanel">
													<FormParents
														type={`father`}
														dataParent={parentsData?.father}
													/>
												</TabPane>
												<TabPane tabId={2} role="tabpanel">
													<FormParents
														type={`mother`}
														dataParent={parentsData?.mother}
													/>
												</TabPane>
											</TabContent>
										</TabPane>
									</TabContent>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
			</Container>
		</>
	)
}

export default ProfilePage
