import { useState } from 'react'
import { BiHome } from 'react-icons/bi'
import {
	FaChevronDown,
	FaChevronUp,
	FaRightFromBracket,
	FaUserLarge,
} from 'react-icons/fa6'
import { useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import {
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from 'reactstrap'
import { layoutSetupSelector } from '../../app/layoutSlice'

const HeaderContent = () => {
	const [isToggle, setIsToggle] = useState(false)
	const currentUser = JSON.parse(localStorage.getItem('data'))
	const { page_title } = useSelector(layoutSetupSelector)
	const navigate = useNavigate()
	const location = useLocation()
	const { pathname } = location
	const disabledBreadcrumbs = ['/', '/dashboard-client']

	const handlerSidebar = () => {
		var body = document.body
		if (window.screen.width <= 998) {
			body.classList.toggle('sidebar-enable')
		} else {
			body.classList.toggle('vertical-collpsed')
			body.classList.toggle('sidebar-enable')
		}
	}

	const handlerLogout = () => {
		window.location.replace('/login-client')
		localStorage.clear()

		// let url = '/login'
		// if (dataSession) {
		//     url = dataSession.role === 'lawyer' ? '/login-lawyer' : '/login'
		// }
		// const getSession = await postRefreshToken({ refresh_token: dataRefreshToken }).unwrap();
		// const token = getSession.token;
		// await dispatch(setToken(token))
		// await postLogout().unwrap()
		// localStorage.clear()
		// window.location.replace(url);
	}

	return (
		<header id="page-topbar" className="bg-warning text-white">
			<div className="navbar-header">
				<div className="d-flex">
					<div className="navbar-brand-box d-lg-none d-md-block">
						<Link to="/" className="logo logo-dark">
							<span className="logo-sm">
								<img src="logo-yamet-2.png" alt="logo" height="22" />
							</span>
						</Link>
					</div>
					<button
						type="button"
						onClick={() => handlerSidebar()}
						className="btn btn-sm px-3 font-size-16 header-item text-white"
						id="vertical-menu-btn"
					>
						<i className="fa fa-fw fa-bars" />
					</button>
				</div>
				<div className="d-flex">
					<Dropdown
						isOpen={isToggle}
						toggle={() => setIsToggle(!isToggle)}
						className="d-inline-block"
					>
						<DropdownToggle
							className="btn header-item fs-5"
							id="page-header-user-dropdown"
							tag="button"
						>
							<span className="d-none d-xl-inline-block me-1">
								{currentUser?.name}
							</span>
							{isToggle ? (
								<FaChevronUp className="d-none d-xl-inline-block me-3" />
							) : (
								<FaChevronDown className="d-none d-xl-inline-block me-3" />
							)}
							<img
								className="rounded header-profile-user"
								src="/logo-yamet.png"
								alt="User Photo"
							/>
						</DropdownToggle>

						<DropdownMenu className="dropdown-menu-end">
							{currentUser?.role === 'client' && (
								<DropdownItem
									tag="button"
									onClick={() => navigate('/profile')}
									className="dropdown-item text-success"
								>
									<FaUserLarge
										size={14}
										className="align-middle me-2 text-success"
									/>
									<span>Profile</span>
								</DropdownItem>
							)}
							<div className="dropdown-divider" />
							<DropdownItem
								tag="button"
								onClick={handlerLogout}
								className="dropdown-item text-danger"
							>
								<FaRightFromBracket
									size={16}
									className="align-middle me-2 text-danger"
								/>
								<span>Logout</span>
							</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				</div>
			</div>
			{!disabledBreadcrumbs.includes(pathname) && (
				<div className="bg-white text-dark fw-bold" style={{ padding: '2em' }}>
					<div className="d-flex gap-5">
						{page_title}
						<div className="d-flex gap-2 align-items-center text-secondary">
							<BiHome />
							<div>●</div>
							{page_title}
						</div>
					</div>
				</div>
			)}
		</header>
	)
}

export default HeaderContent
