import { Link } from "react-router-dom";
import SidebarContent from "./SidebarContent";

const Sidebar = () => {
	const checkrole = JSON.parse(localStorage.getItem('data'))
	const url = checkrole?.role === 'client' ? '/dashboard-client' : '/'
    return (
        <div className="vertical-menu">
            <div className="navbar-brand-box bg-white">
                <Link to={url} className="logo">
                    <span className="logo-sm">
                        <img src="/logo-yamet-2.png" alt="logo" height="14" />
                    </span>
                    <span className="logo-lg">
                        <img src="/logo-yamet-2.png" alt="logo" height="50" />
                    </span>
                </Link>
            </div>
            <div data-simplebar className="h-100">
                <SidebarContent />
            </div>
        </div>
    );
}

export default Sidebar;
