import React, { useCallback } from 'react'
import HelmetTitle from '../../components/templates/HelmetTitle'
import { Card, CardBody, Col, Container, Input, Row } from 'reactstrap'
import TableClient from '../../components/templates/TableClient'
import { CiSearch } from 'react-icons/ci'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { layoutSetupSelector, setPageTitle, setTableKeyword } from '../../app/layoutSlice'
import { useGetInvoiceDataQuery } from '../general/api/clientApi'
import ActionButtonInvoice from '../invoice/components/ActionButtonInvoice'
import { debounce } from 'lodash'
const dataInvoiceHeader = [
	{
		id: 1,
		title: 'No',
	},
	{
		id: 2,
		title: 'Nomor Invoice',
	},
	{
		id: 3,
		title: 'Nama Anak',
	},
	// {
	// 	id: 4,
	// 	title: 'Nama Customer',
	// },
	// {
	// 	id: 5,
	// 	title: 'No Telp',
	// },
	{
		id: 6,
		title: 'Nilai Tagihan',
	},
	// {
	// 	id: 7,
	// 	title: 'Batas Bayar',
	// },
	{
		id: 8,
		title: 'Bukti Bayar',
	},
	{
		id: 9,
		title: 'Status',
	},
	{
		id: 10,
		title: 'Aksi',
	},
]

const InvoiceUser = () => {
	const dispatch = useDispatch()

	const { tableKeyword } = useSelector(layoutSetupSelector)


	const { data: fetchUserInvoice } = useGetInvoiceDataQuery({ keyword: tableKeyword })
	const { data: invoiceData } = fetchUserInvoice ?? []

	const handlerSearch = (event) => {
		dispatch(setTableKeyword(event.target.value))
	}

	const debouncedHandleSearch = useCallback(debounce(handlerSearch, 500), []);

	useEffect(() => {
		dispatch(setPageTitle('Invoice Customer'))
	}, [])

	return (
		<>
			<HelmetTitle title="Invoice" />
			<Container fluid>
				<Row>
					<Col>
						<Card>
							<CardBody>
								<div className="d-flex justify-content-between align-items-center">
									<h3 className="mb-0">Invoice</h3>
									<div className="d-flex gap-2">
										<hr className="border border-light border-2 opacity-50"></hr>
										<div className="position-relative">
											<Input
												placeholder="Pencarian..."
												className="pe-5"
												onChange={debouncedHandleSearch}
											/>
											<CiSearch
												size={24}
												className="position-absolute"
												style={{ top: '20%', right: '5%' }}
											/>
										</div>
									</div>
								</div>
								<hr className="border border-light border-2 opacity-50"></hr>
								<TableClient
									dataHeader={dataInvoiceHeader}
									dataRow={invoiceData}
									dataSource={'user-invoices'}
									actionButton={ActionButtonInvoice}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	)
}

export default InvoiceUser
