import PropTypes from "prop-types";
import { useEffect } from "react";

const NonAuthLayout = (props) => {
    useEffect(() => {
        checkingSession();
    }, []);

    const checkingSession = () => {
        const sessions = localStorage.getItem('data');
        if (sessions) {
            return window.location.replace('/')
        }
    }

    if (props.isMainContent) {
        return <>
            <div className="main-content ms-0 px-3 h-100 bg-white">
                {props.children}
            </div>
        </>
    } else {
        return <>
            {props.children}
        </>
    }
}

NonAuthLayout.propTypes = {
    children: PropTypes.any,
};

export default NonAuthLayout;
