import React, { useCallback, useEffect, useState } from 'react'
import HelmetTitle from '../../../components/templates/HelmetTitle'
import { Button, Card, CardBody, Col, Container, Input, Row } from 'reactstrap'
import { CiSearch } from 'react-icons/ci'
import TableService from '../components/TableService'
import PaginationButton from '../../../components/templates/PaginationButton/PaginationButton'
import { useGetListServiceQuery } from '../api/listServiceApi'
import ActionButtonBranch from './ActionButtonBranch'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { layoutSetupSelector, setPageTitle, setTableKeyword } from '../../../app/layoutSlice'
import { debounce } from 'lodash'

const headerBranchLocation = [
	{
		id: 1,
		title: 'No',
	},
	{
		id: 2,
		title: 'Nama Cabang',
	},
	{
		id: 3,
		title: 'Alamat',
	},
	{
		id: 4,
		title: 'Wilayah',
	},
	{
		id: 5,
		title: 'Kab/Kota',
	},
	{
		id: 6,
		title: 'No. Telepon',
	},
	{
		id: 7,
		title: 'No. WhatsApp',
	},
	{
		id: 8,
		title: 'Status',
		className: 'text-center',
	},
	{
		id: 9,
		title: 'Aksi',
	},
]

const BranchLocation = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [page, setPage] = useState(1)
	const { tableKeyword } = useSelector(layoutSetupSelector)
	const {
		data: fetchBranchs,
		isLoading,
		error,
	} = useGetListServiceQuery(
		{
			endpoint: 'branchs',
			page,
			keyword: tableKeyword
		},
		{ refetchOnMountOrArgChange: true }
	)
	const { data: dataBranchs } = fetchBranchs ?? []

	const handlerSearch = (event) => {
		dispatch(setTableKeyword(event.target.value))
	}

	const debouncedHandleSearch = useCallback(debounce(handlerSearch, 500), []);

	useEffect(() => {
		dispatch(setPageTitle('Cabang'))
	}, [])

	if (error) {
		return (
			<div className="text-center">
				Something went wrong, {error.data.message ?? 'unknown'}
			</div>
		)
	}

	return (
		<>
			<HelmetTitle title="Lokasi Cabang" />

			<Container fluid>
				<Row className="justify-content-center">
					<Col xs={12}>
						<Card>
							<CardBody>
								<div className="d-flex justify-content-between align-items-center">
									<h3 className="mb-0">Lokasi Cabang</h3>
									<div className="d-flex gap-2">
										<div className="position-relative">
											<Input
												onChange={debouncedHandleSearch}
												placeholder="Pencarian..."
												className="pe-5"
											/>
											<CiSearch
												size={24}
												className="position-absolute"
												style={{ top: '20%', right: '5%' }}
											/>
										</div>
										<Button
											color="success"
											className="px-4"
											onClick={() => navigate('/branch-location/add-branch')}
										>
											Tambah
										</Button>
									</div>
								</div>
								<hr className="border border-light border-2 opacity-50"></hr>
								<TableService
									dataHeader={headerBranchLocation}
									dataRow={dataBranchs?.data}
									dataSource={'branchs'}
									actionButton={ActionButtonBranch}
								/>
								<PaginationButton
									metaPages={dataBranchs}
									setPage={setPage}
									currentPage={page}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	)
}

export default BranchLocation
