import React from 'react'
import { BiEdit, BiSolidTrashAlt } from 'react-icons/bi'
import { FaEye } from 'react-icons/fa6'
import { useDispatch } from 'react-redux'
import { setChildId } from '../../../app/surveySlice'

const ActionButtonSurvey = ({ childId }) => {
	const dispatch = useDispatch()

	const handleEditSurvey = () => {
		dispatch(setChildId(childId))
		window.open(`/form-survey/draft/${childId}`)
	}

	const handleSummarySurvey = () => {
		dispatch(setChildId(childId))
		window.open(`/form-survey/summary/${childId}`)
	}

	return (
		<div className="d-flex gap-2">
			<button className="btn text-primary p-0 btn-light p-1" onClick={handleSummarySurvey}>
				<FaEye/>
			</button>
			<button
				className="btn text-primary p-0 btn-light p-1"
				onClick={handleEditSurvey}
				// disabled
			>
				<BiEdit size={16} />
			</button>
			<button
				className="btn text-primary p-0 btn-light p-1"
				// onClick={toggleModal}
				disabled
			>
				<BiSolidTrashAlt size={16} />
			</button>
		</div>
	)
}

export default ActionButtonSurvey
