import React, { useEffect } from 'react'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6'
import { FiChevronsLeft, FiChevronsRight } from 'react-icons/fi'
import { useDispatch } from 'react-redux'
import { setTablePage } from '../../../app/layoutSlice'
import pagination from './pagination.module.scss'

const PaginationButton = ({ metaPages, setPage, currentPage }) => {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(setTablePage(currentPage))
	}, [currentPage])

	const PageNumber = () => {
		const totalPage = metaPages?.last_page
		const pageItems = []

		for (let i = 0; i < totalPage; i++) {
			pageItems.push(
				<div
					key={i}
					className={`${currentPage === i + 1 ? pagination.active : ''}`}
					onClick={() => setPage(i + 1)}
				>
					{i + 1}
				</div>
			)
		}

		return pageItems
	}

	const setPageHandler = (type) => {
		if (type === 'down' && currentPage > 1) {
			setPage(currentPage - 1)
		} else if (type === 'up' && currentPage < metaPages?.last_page) {
			setPage(currentPage + 1)
		}
	}

	return (
		<div className={`d-flex gap-2 ${pagination['pagination-custom']} mt-5`}>
			<div onClick={() => setPage(1)}>
				<FiChevronsLeft size={20} />
			</div>
			<div onClick={() => setPageHandler('down')}>
				<FaChevronLeft />
			</div>
			<PageNumber />
			<div onClick={() => setPageHandler('up')}>
				<FaChevronRight />
			</div>
			<div onClick={() => setPage(metaPages?.last_page)}>
				<FiChevronsRight size={20} />
			</div>
		</div>
	)
}

export default PaginationButton
