import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const { VITE_BASE_URL } = import.meta.env;


const getToken = (state) => state.authSetup.token;

export const resetPasswordApi = createApi({
    reducerPath: "resetPasswordApi",
    tagTypes: ["ResetPassword"],
    baseQuery: fetchBaseQuery({
        baseUrl: `${VITE_BASE_URL}/`,
        prepareHeaders: (headers, { getState }) => {
            const token = getToken(getState());
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        postResetPassword: builder.mutation({
            query: (body) => ({
                url: `get-name`,
                method: 'POST',
                body
            }),
            invalidatesTags: ["Auth"],
        }),
        postChangePassword: builder.mutation({
            query: (body) => ({
                url: `change-password`,
                method: 'POST',
                body
            }),
            invalidatesTags: ["Auth"],
        }),
    })
})

export const {
    usePostResetPasswordMutation,
    usePostChangePasswordMutation
} = resetPasswordApi;

