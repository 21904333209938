import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import CardWelcome from './components/CardWelcome'
import HelmetTitle from '../../components/templates/HelmetTitle'
import Calendar from './components/Calendar'

const Dashboard = (props) => {
	const { type } = props

	return (
		<>
			<HelmetTitle title="Dashboard" />
			<Container fluid>
				<Row className="justify-content-center">
					{type === 'client' ? (
						<Col xs={12}>
							<CardWelcome type={type} />
						</Col>
					) : (
						<Col lg={12}>
							<Card>
								<CardBody>
									<Calendar />
								</CardBody>
							</Card>
						</Col>
					)}
				</Row>
			</Container>
		</>
	)
}

export default Dashboard
