import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Alert, Button, Col, Form, Input, Label, Row } from 'reactstrap'
import * as yup from 'yup'
import { customerStateSelector, setUserId } from '../../../../app/customerSlice'
import { useGetMasterDataQuery } from '../../../general/api/masterApi'
import {
	useCreateParentMutation,
	useUpdateParentMutation,
} from '../../api/parentApi'
import { setForm3Data } from '../../../../app/surveySlice'
import PhoneNumberInput from '../../../../components/templates/PhoneNumberInput'
import { useUpdateClientParentMutation } from '../../../general/api/clientApi'

const initSubmitStatus = {
	status: false,
	type: '',
	message: '',
	headerMessage: '',
}

const FormParents = ({
	type,
	activeTab,
	setActiveTab,
	toggleTab,
	dataParent,
	editForm,
}) => {
	const { user_id } = useSelector(customerStateSelector)
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { pathname } = useLocation()
	const isSurvey =
		pathname === '/form-survey/add' ||
		pathname.includes('draft') ||
		pathname.includes('/form-survey/summary')
	const checkrole = JSON.parse(localStorage.getItem('data'))
	const isClient = checkrole?.role === 'client'

	const [submitStatus, setSubmitStatus] = useState(initSubmitStatus)
	const [createParent, { isLoading: createParentLoading }] =
		useCreateParentMutation()
	const [updateParent] = useUpdateParentMutation()
	const [updateClientParent] = useUpdateClientParentMutation()
	const { data: fetchReligion } = useGetMasterDataQuery({
		endpoint: 'religion',
	})
	const { data: fetchEducation } = useGetMasterDataQuery({
		endpoint: 'educations',
	})

	const { data: dataReligion } = fetchReligion ?? []
	const { data: dataEducation } = fetchEducation ?? []

	const schema = yup.object({
		full_name: yup.string().required('Nama Lengkap wajib di isi!'),
		nickname: yup.string().required('Nama Panggilan wajib di isi!'),
		date_of_birth: yup.string().required('Tanggal Lahir wajib di isi!'),
		address_of_birth: yup.string().required('Tempat Lahir wajib di isi!'),
		clan: yup.string().required('Suku bangsa wajib di isi!'),
		religion_id: yup
			.number()
			.required('Agama wajib di isi!')
			.notOneOf([0], 'Agama wajib di isi!'),
		address: yup.string().required('Alamat wajib di isi!'),
		sort_wedding: yup
			.number()
			.typeError('Keterangan Pernikahan tidak valid')
			.required('Keterangan Pernikahan wajib di isi!'),
		sort_child: yup
			.number()
			.typeError('Keterangan Anak tidak valid')
			.required('Keterangan Anak wajib di isi!'),
		education_id: yup
			.number()
			.required('Pendidikan terakhir wajib di isi!')
			.notOneOf([0], 'Pendidikan terakhir wajib di isi!'),
		current_job: yup.string().required('Pekerjaan wajib di isi!'),
		age_at_marriage: yup
			.number()
			.typeError('Umur saat menikah tidak valid')
			.required('Umur saat menikah wajib di isi!'),
		phone_number: yup
			.string()

			.matches(/^\+62\d{10,}$/, 'Format tidak valid & minimal 10 digit sesudah +62!'),
		email: yup.string().email('Alamat email invalid'),

		is_death: yup.string().notRequired(),
		date_of_death: yup.string().notRequired(),
	})

	const {
		control,
		handleSubmit,
		reset,
		formState: { errors },
		watch,
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			type: type,
			full_name: '',
			nickname: '',
			date_of_birth: '',
			address_of_birth: '',
			clan: '',
			religion_id: '0',
			address: '',
			sort_wedding: '',
			sort_child: '',
			education_id: '0',
			current_job: '',
			age_at_marriage: '',
			phone_number: '',
			email: '',
			is_death: '0',
			date_of_death: '',
		},
	})

	useEffect(() => {
		let subscription

		if (isSurvey) {
			subscription = watch((value) => {
				const payload = {
					...value,
					user_id,
					date_of_death: value.is_death === 0 ? '' : value.date_of_death,
				}
				dispatch(setForm3Data({ for: type, data: payload }))
			})
		}

		return () => {
			if (subscription) {
				subscription.unsubscribe()
			}
		}
	}, [watch, user_id])

	useEffect(() => {
		if (dataParent) {
			reset({ ...dataParent, date_of_death: '' })
		}
	}, [reset, dataParent])

	const handleCheckboxChange = (event) => {
		return event.target.checked ? '1' : '0'
	}

	const handleParentSubmit = async (param) => {
		setSubmitStatus(initSubmitStatus)

		try {
			if (user_id === 0) {
				throw new Error('no user_id detected')
			}

			const performSubmit = async (payload) => {
				const result = await payload.unwrap()
				const { status, message } = result

				if (status === 'ok') {
					setSubmitStatus({
						status: true,
						type: 'success',
						message,
						headerMessage: 'Berhasil',
					})

					setTimeout(() => {
						if (activeTab !== 3) {
							setActiveTab(activeTab + 1)
							toggleTab(activeTab + 1)
						} else {
							localStorage.removeItem('latest_userId')
							dispatch(setUserId(0))
							navigate('/data-customer')
						}
					}, 1000)
				}
			}

			if (editForm) {
				if (!dataParent?.id) {
					await performSubmit(createParent({ user_id, ...param }))
				} else {
					await performSubmit(updateParent({ id: dataParent.id, ...param }))
				}
			} else if (isClient) {
				// TODO: create api for client
				if (!dataParent?.id) {
					await performSubmit(createParent({ user_id, ...param }))
				} else {
					await performSubmit(
						updateClientParent({ id: dataParent.id, ...param })
					)
				}
			} else {
				await performSubmit(createParent({ user_id, ...param }))
			}
		} catch (error) {
			// setSubmitStatus({
			// 	status: true,
			// 	type: 'danger',
			// 	message: error?.data?.message ?? 'Something went wrong.',
			// })

			setPostDataStatus({
				status: true,
				type: 'danger',
				message: <>{msgErr}</>,
				headerMessage: 'Terjadi Kesalahan'
			})
		}
	}

	return (
		<>
			{submitStatus.status && (
				<Alert color={submitStatus?.type ?? 'danger'} className="mb-4">
					<h3>
						{submitStatus?.headerMessage}
					</h3>
					<p className="mb-0">
						{submitStatus?.message ?? 'error message here'}
					</p>
				</Alert>
			)}
			<Form onSubmit={handleSubmit(handleParentSubmit)}>
				<Row>
					<Col lg="6">
						<h5 className="fw-bold mb-3">
							{type === 'father' ? 'DATA AYAH' : 'DATA IBU'}
						</h5>

						<Row className="p-1 mb-3">
							<Controller
								name="type"
								control={control}
								render={({ field: { onChange, onBlur, value } }) => (
									<Input
										type="hidden"
										className="form-control bg-light"
										placeholder="Masukkan Nama Lengkap"
										onChange={onChange}
										onBlur={onBlur}
										value={value}
									/>
								)}
							/>
							<Label className="col-form-label col-md-3 text-truncate">
								Nama Lengkap
							</Label>
							<div className="col-md-9">
								<Controller
									name="full_name"
									control={control}
									render={({ field: { onChange, onBlur, value } }) => (
										<>
											<Input
												type="text"
												className="form-control bg-light"
												placeholder="Masukkan Nama Lengkap"
												onChange={onChange}
												onBlur={onBlur}
												value={value}
												invalid={errors.full_name ? true : false}
											/>
											{errors && (
												<small className="text-danger">
													{errors?.full_name?.message}
												</small>
											)}
										</>
									)}
								/>
							</div>
						</Row>
						<Row className="p-1 mb-3">
							<Label className="col-form-label col-md-3 text-truncate">
								Nama Panggilan
							</Label>
							<div className="col-md-9">
								<Controller
									name="nickname"
									control={control}
									render={({ field: { onChange, onBlur, value } }) => (
										<>
											<Input
												type="text"
												className="form-control bg-light"
												placeholder="Masukkan Nama Panggilan"
												onChange={onChange}
												onBlur={onBlur}
												value={value}
												invalid={errors.nickname ? true : false}
											/>
											{errors && (
												<small className="text-danger">
													{errors?.nickname?.message}
												</small>
											)}
										</>
									)}
								/>
							</div>
						</Row>
						<Row className="p-1 mb-3">
							<Label className="col-form-label col-md-3">No Telp</Label>
							<div className="col-md-9">
								<Controller
									name="phone_number"
									control={control}
									render={({ field: { onChange, onBlur, value } }) => (
										<>
											{/* <Input
												type="text"
												className="form-control bg-light"
												placeholder="Masukkan No Telp"
												onChange={onChange}
												onBlur={onBlur}
												value={value}
												invalid={errors.phone_number ? true : false}
											/> */}
											<PhoneNumberInput
												initialValue={value}
												prefixOnZero="+62"
												className="form-control bg-light"
												placeholder="Masukkan No Telp"
												onChange={onChange}
												onBlur={onBlur}
												value={value}
											/>
											{errors && (
												<small className="text-danger">
													{errors?.phone_number?.message}
												</small>
											)}
										</>
									)}
								/>
							</div>
						</Row>
						<Row className="p-1 mb-3">
							<Label className="col-form-label col-md-3">Alamat Email</Label>
							<div className="col-md-9">
								<Controller
									name="email"
									control={control}
									render={({ field: { onChange, onBlur, value } }) => (
										<>
											<Input
												type="text"
												className="form-control bg-light"
												placeholder="Masukkan Email"
												onChange={onChange}
												onBlur={onBlur}
												value={value}
												invalid={errors.email ? true : false}
											/>
											{errors && (
												<small className="text-danger">
													{errors?.email?.message}
												</small>
											)}
										</>
									)}
								/>
							</div>
						</Row>
						<Row className="p-1 mb-3">
							<Label className="col-form-label col-md-3">Tempat Lahir</Label>
							<div className="col-md-9">
								<Controller
									name="address_of_birth"
									control={control}
									render={({ field: { onChange, onBlur, value } }) => (
										<>
											<Input
												type="text"
												className="form-control bg-light"
												placeholder="Masukkan Tempat Lahir"
												onChange={onChange}
												onBlur={onBlur}
												value={value}
												invalid={errors.address_of_birth ? true : false}
											/>
											{errors && (
												<small className="text-danger">
													{errors?.address_of_birth?.message}
												</small>
											)}
										</>
									)}
								/>
							</div>
						</Row>
						<Row className="p-1 mb-3">
							<Label className="col-form-label col-md-3">Tanggal Lahir</Label>
							<div className="col-md-9">
								<Controller
									name="date_of_birth"
									control={control}
									render={({ field: { onChange, onBlur, value } }) => (
										<>
											<Input
												type="date"
												className="form-control bg-light"
												placeholder="Masukkan Tempat Lahir"
												onChange={onChange}
												onBlur={onBlur}
												value={value}
												invalid={errors.date_of_birth ? true : false}
											/>
											{errors && (
												<small className="text-danger">
													{errors?.date_of_birth?.message}
												</small>
											)}
										</>
									)}
								/>
							</div>
						</Row>
						<Row className="p-1 mb-3">
							<Label className="col-form-label col-md-3">Alamat Rumah</Label>
							<div className="col-md-9">
								<Controller
									name="address"
									control={control}
									render={({ field: { onChange, onBlur, value } }) => (
										<>
											<Input
												type="text"
												className="form-control bg-light"
												placeholder="Masukkan Alamat Rumah"
												onChange={onChange}
												onBlur={onBlur}
												value={value}
												invalid={errors.address ? true : false}
											/>
											{errors && (
												<small className="text-danger">
													{errors?.address?.message}
												</small>
											)}
										</>
									)}
								/>
							</div>
						</Row>
					</Col>
					<Col lg="6">
						<h5 className="fw-bold mb-3">DATA TAMBAHAN</h5>
						<Row className="p-1 mb-3">
							<Label className="col-form-label col-md-3">Suku Bangsa</Label>
							<div className="col-md-9">
								<Controller
									name="clan"
									control={control}
									render={({ field: { onChange, onBlur, value } }) => (
										<>
											<Input
												type="text"
												className="form-control bg-light"
												placeholder="Masukkan Suku Bangsa"
												onChange={onChange}
												onBlur={onBlur}
												value={value}
												invalid={errors.clan ? true : false}
											/>
											{errors && (
												<small className="text-danger">
													{errors?.clan?.message}
												</small>
											)}
										</>
									)}
								/>
							</div>
						</Row>
						<Row className="p-1 mb-3">
							<Label className="col-form-label col-md-3">Agama</Label>
							<div className="col-md-9">
								<Controller
									name="religion_id"
									control={control}
									render={({ field: { onChange, onBlur, value } }) => (
										<>
											<Input
												type="select"
												className="form-control bg-light"
												placeholder="Masukkan Agama"
												onChange={onChange}
												onBlur={onBlur}
												value={value}
												invalid={errors.religion_id ? true : false}
											>
												<option value="0">Pilih</option>
												{dataReligion?.map((item) => (
													<option value={item.id} key={item.id}>
														{item.name}
													</option>
												))}
											</Input>
											{errors && (
												<small className="text-danger">
													{errors?.religion_id?.message}
												</small>
											)}
										</>
									)}
								/>
							</div>
						</Row>
						<Row className="p-1 mb-3">
							<Label className="col-form-label col-md-3">Anak ke</Label>
							<div className="col-md-9">
								<Controller
									name="sort_child"
									control={control}
									render={({ field: { onChange, onBlur, value } }) => (
										<>
											<Input
												type="text"
												className="form-control bg-light"
												placeholder="Masukkan Keterangan Anak"
												onChange={onChange}
												onBlur={onBlur}
												value={value}
												invalid={errors.sort_child ? true : false}
											/>
											{errors && (
												<small className="text-danger">
													{errors?.sort_child?.message}
												</small>
											)}
										</>
									)}
								/>
							</div>
						</Row>
						<Row className="p-1 mb-3">
							<Label className="col-form-label col-md-3">Pernikahan ke</Label>
							<div className="col-md-9">
								<Controller
									name="sort_wedding"
									control={control}
									render={({ field: { onChange, onBlur, value } }) => (
										<>
											<Input
												type="text"
												className="form-control bg-light"
												placeholder="Masukkan Keterangan Pernikahan"
												onChange={onChange}
												onBlur={onBlur}
												value={value}
												invalid={errors.sort_wedding ? true : false}
											/>
											{errors && (
												<small className="text-danger">
													{errors?.sort_wedding?.message}
												</small>
											)}
										</>
									)}
								/>
							</div>
						</Row>
						<Row className="p-1 mb-3">
							<Label className="col-form-label col-md-3">
								Usia saat menikah
							</Label>
							<div className="col-md-9">
								<Controller
									name="age_at_marriage"
									control={control}
									render={({ field: { onChange, onBlur, value } }) => (
										<>
											<Input
												type="text"
												className="form-control bg-light"
												placeholder="Masukkan Usia saat menikah"
												onChange={onChange}
												onBlur={onBlur}
												value={value}
												invalid={errors.age_at_marriage ? true : false}
											/>
											{errors && (
												<small className="text-danger">
													{errors?.age_at_marriage?.message}
												</small>
											)}
										</>
									)}
								/>
							</div>
						</Row>
						<Row className="p-1 mb-3">
							<Label className="col-form-label col-md-3">
								Pendidikan Terakhir
							</Label>
							<div className="col-md-9">
								<Controller
									name="education_id"
									control={control}
									render={({ field: { onChange, onBlur, value } }) => (
										<>
											<Input
												type="select"
												className="form-control bg-light"
												placeholder="Masukkan Usia saat menikah"
												onChange={onChange}
												onBlur={onBlur}
												value={value}
												invalid={errors.education_id ? true : false}
											>
												<option value="0">Pilih</option>
												{dataEducation?.map((item) => (
													<option value={item.id} key={item.id}>
														{item.name}
													</option>
												))}
											</Input>
											{errors && (
												<small className="text-danger">
													{errors?.education_id?.message}
												</small>
											)}
										</>
									)}
								/>
							</div>
						</Row>
						<Row className="p-1 mb-3">
							<Label className="col-form-label col-md-3">
								Pekerjaan Saat ini
							</Label>
							<div className="col-md-9">
								<Controller
									name="current_job"
									control={control}
									render={({ field: { onChange, onBlur, value } }) => (
										<>
											<Input
												type="text"
												className="form-control bg-light"
												placeholder="Masukkan Keterangan Pekerjaan"
												onChange={onChange}
												onBlur={onBlur}
												value={value}
												invalid={errors.current_job ? true : false}
											/>
											{errors && (
												<small className="text-danger">
													{errors?.current_job?.message}
												</small>
											)}
										</>
									)}
								/>
							</div>
						</Row>
						<Row className="p-1 mb-3 align-items-center">
							<Label className="col-form-label col-md-3">Meninggal</Label>
							<div className="col-md-1 flex align-items-center">
								<Controller
									name="is_death"
									control={control}
									render={({ field: { onChange, onBlur, value } }) => (
										<>
											<Input
												type="checkbox"
												placeholder="Masukkan Keterangan Pekerjaan"
												onChange={(e) => onChange(handleCheckboxChange(e))}
												onBlur={onBlur}
												value={value}
											/>
										</>
									)}
								/>
							</div>
							<div className="col-md-8">
								<Controller
									name="date_of_death"
									control={control}
									render={({ field: { onChange, onBlur, value } }) => (
										<>
											<Input
												type="date"
												className="form-control bg-light"
												placeholder="Masukkan Keterangan Pekerjaan"
												onChange={onChange}
												onBlur={onBlur}
												value={value}
											/>
										</>
									)}
								/>
							</div>
						</Row>
					</Col>
					{!isSurvey && (
						<Col>
							<div className="d-flex justify-content-end gap-2">
								{!isClient && (
									<Button
										color="primary"
										onClick={() => navigate('/data-customer')}
									>
										Batal
									</Button>
								)}
								<Button
									color="success"
									className="px-4"
									type="submit"
									disabled={user_id === 0 || createParentLoading}
								>
									Simpan
								</Button>
							</div>
						</Col>
					)}
				</Row>
			</Form>
		</>
	)
}

export default FormParents
