import React, { useState } from 'react'
import { BiEdit, BiSolidTrashAlt } from 'react-icons/bi'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { layoutSetupSelector } from '../../../app/layoutSlice'
import { useGetListServiceQuery } from '../api/listServiceApi'
import { useDeleteBranchMutation } from './api/branchApi'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Toast, ToastBody, ToastHeader } from 'reactstrap'
import { FaCheck } from 'react-icons/fa6'

const initSuccessDelete = {
	status: false,
	message: '',
}

const ActionButtonBranch = ({ id, serviceName, dataSource }) => {
	const navigate = useNavigate()
	const [showModal, setShowModal] = useState(false)
	const [successDelete, setSuccessDelete] = useState(initSuccessDelete)
	const { tablePage, tableKeyword } = useSelector(layoutSetupSelector)

	const { refetch } = useGetListServiceQuery(
		{
			endpoint: dataSource,
			page: tablePage,
			keyword: tableKeyword
		},
		{ refetchOnMountOrArgChange: true }
	)

	const [deleteBranch, { isLoading }] = useDeleteBranchMutation()

	const toggleModal = () => {
		setShowModal(!showModal)
	}

	const deleteHandler = async (id) => {
		try {
			const result = await deleteBranch(id)
			if (result.data.status === 'ok') {
				setShowModal(!showModal)
				setSuccessDelete({
					status: true,
					message: result.data.message,
				})

				setTimeout(() => {
					refetch()
				}, 800)
			}
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<>
			<div className="d-flex gap-2">
				<button
					className="btn text-primary p-0 btn-light p-1"
					onClick={() => navigate(`/branch-location/edit-branch/${id}`)}
				>
					<BiEdit size={16} />
				</button>
				<button
					className="btn text-primary p-0 btn-light p-1"
					onClick={toggleModal}
				>
					<BiSolidTrashAlt size={16} />
				</button>
			</div>
			{successDelete.status && (
				<Toast className="position-absolute" style={{ left: '35%', top: '1%' }}>
					<ToastHeader
						icon={<FaCheck className="text-success" />}
						className="text-success"
					>
						Success Delete
					</ToastHeader>
					<ToastBody>{successDelete.message}</ToastBody>
				</Toast>
			)}
			<div>
				<Modal isOpen={showModal} toggle={toggleModal} centered>
					<ModalHeader toggle={toggleModal}>
						Hapus <span className="fw-bold">{serviceName}</span> ?
					</ModalHeader>
					<ModalBody>
						<p className="mb-0">
							Yakin ingin menghapus
							<span className="fw-bold"> {serviceName}?</span> Anda tidak bisa
							mengembalikan data yang sudah dihapus
						</p>
					</ModalBody>
					<ModalFooter>
						<Button
							color="danger"
							onClick={() => deleteHandler(id)}
							disabled={isLoading}
						>
							Hapus
						</Button>{' '}
						<Button color="light" onClick={toggleModal} disabled={isLoading}>
							Cancel
						</Button>
					</ModalFooter>
				</Modal>
			</div>
		</>
	)
}

export default ActionButtonBranch
