import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormGroup, Input, Label } from 'reactstrap'
import { setForm4Data, surveyStateSelector } from '../../../../app/surveySlice'
import { useGetSurveyFormValueQuery } from '../../api/surveyApi'
import { useParams } from 'react-router-dom'

// ! {type} is equal active tab or step

const FormStep4 = () => {
	const { childId } = useParams()
	const dispatch = useDispatch()
	const { child_id } = useSelector(surveyStateSelector)
	const [forms4Data, setForms4Data] = useState({
		child_id: null,
		type: 4,
		keluhan_orang_tua_saat_ini: '',
		apa_saja_yang_sudah_dilakukan_orang_tua: '',
		apa_saja_kendala_yang_dihadapi: '',
	})

	const { data: fetchForm4Value } = useGetSurveyFormValueQuery(
		{
			type: 4,
			child_id: childId,
		},
		{ skip: !childId }
	)
	const { data: form4Value } = fetchForm4Value?.data ?? {}

	useEffect(() => {
		const timer = setTimeout(() => {
			dispatch(setForm4Data(forms4Data))
		}, 1000)

		return () => clearTimeout(timer)
	}, [forms4Data])

	useEffect(() => {
		if (form4Value) {
			setForms4Data({ ...forms4Data, ...form4Value, child_id: childId })
		}
	}, [form4Value])

	const handleTextarea = (value, fieldName) => {
		setForms4Data({ ...forms4Data, [fieldName]: value, child_id })
	}

	return (
		<>
			<FormGroup>
				<Label className="fw-bold">Keluhan orang tua saat ini (min. 3)</Label>
				<Input
					type="textarea"
					rows={10}
					placeholder="Masukkan keterangan"
					value={forms4Data.keluhan_orang_tua_saat_ini}
					onChange={(e) =>
						handleTextarea(e.target.value, 'keluhan_orang_tua_saat_ini')
					}
				/>
			</FormGroup>
			<FormGroup>
				<Label className="fw-bold">
					Apa saja yang sudah dilakukan orang tua (min. 3)
				</Label>
				<Input
					type="textarea"
					rows={10}
					placeholder="Masukkan keterangan"
					value={forms4Data.apa_saja_yang_sudah_dilakukan_orang_tua}
					onChange={(e) =>
						handleTextarea(
							e.target.value,
							'apa_saja_yang_sudah_dilakukan_orang_tua'
						)
					}
				/>
			</FormGroup>
			<FormGroup>
				<Label className="fw-bold">
					Apa saja kendala yang dihadapi (min. 3)
				</Label>
				<Input
					type="textarea"
					rows={10}
					placeholder="Masukkan keterangan"
					value={forms4Data.apa_saja_kendala_yang_dihadapi}
					onChange={(e) =>
						handleTextarea(e.target.value, 'apa_saja_kendala_yang_dihadapi')
					}
				/>
			</FormGroup>
		</>
	)
}

export default FormStep4
