import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	showSidebar: true,
	isMobile: false,
	isLoader: true,
	tablePage: 1,
	tableKeyword: '',
	page_title: 'Dashboard'
}

const layoutSlice = createSlice({
	name: 'layoutSetup',
	initialState,
	reducers: {
		sidebarSetup: (state, action) => {
			const { value } = action.payload
			state.showSidebar = value
		},
		setTablePage: (state, action) => {
			state.tablePage = action.payload
		},
		setTableKeyword: (state, action) => {
			state.tableKeyword = action.payload;
		},
		setPageTitle: (state, action) => {
			state.page_title = action.payload
		}
	},
})

export const layoutSetupSelector = (state) => state.layoutSetup
export const {
	sidebarSetup,
	setTablePage,
	setPageTitle,
	setTableKeyword
} = layoutSlice.actions
export default layoutSlice.reducer
