import React, { useState } from 'react'
import { useEffect } from 'react'
import { FaRegTrashCan } from 'react-icons/fa6'
import { useDispatch } from 'react-redux'
import { Button, Col, Input, Label, Row } from 'reactstrap'
import { setForm3Data } from '../../../app/surveySlice'

const formInit = [
	{
		keyId: 1,
		name: null,
		gender: null,
		date_of_birth: null,
		education_or_profession: null,
	},
]

const SortSiblings = ({ dataSiblings }) => {
	const dispatch = useDispatch()

	const [forms, setForms] = useState([])
	const [idCounter, setIdCounter] = useState(2)

	useEffect(() => {
		// Declare a variable to store the timeout ID
		let timeoutId

		const isAllFormsFilled = () => {
			for (const form of forms) {
				if (
					form.name === null ||
					form.gender === null ||
					form.date_of_birth === null ||
					form.education_or_profession === null
				) {
					return false
				}
			}
			return true
		}

		if (isAllFormsFilled()) {
			// ? Set the timeout and store the timeout ID
			timeoutId = setTimeout(() => {
				// ? Create a new array of objects with the updated properties
				const updatedForms = forms.map((form) => ({
					...form,
					name: form.name?.trim() || null,
					gender: form.gender?.trim() || null,
					birth_of_date: form.date_of_birth?.trim() || null,
					education_or_profession: form.education_or_profession?.trim() || null,
				}))

				// ? Dispatch the action with the updatedForms
				dispatch(setForm3Data({ for: 'siblings', data: updatedForms }))
			}, 1000) // ? 1000 milliseconds delay
		}

		// ? Cleanup function to clear the timeout if the component is unmounted or if the forms state changes
		return () => {
			clearTimeout(timeoutId)
		}
	}, [forms])

	useEffect(() => {
		if (dataSiblings) {
			setForms(dataSiblings)
		}
	}, [dataSiblings])

	const handleInputChange = (index, inputName, value) => {
		const updatedForms = [...forms] // Shallow copy of the forms array
		const updatedForm = { ...updatedForms[index] } // Shallow copy of the specific form object
		updatedForm[inputName] = value // Update the property value
		updatedForms[index] = updatedForm // Put the updated form back into the array
		setForms(updatedForms) // Update the state
	}

	const addForm = () => {
		setForms([
			...forms,
			{
				keyId: idCounter,
				name: '',
				gender: '',
				birth_of_date: '',
				education_or_profession: '',
			},
		])
		setIdCounter(idCounter + 1)
	}

	const deleteForm = (keyId) => {
		if (forms.length > 1) {
			const updatedForms = forms.filter((form) => form.keyId !== keyId)
			setForms(updatedForms)
		}
	}

	return (
		<>
			<h5 className="mb-4">
				Tuliskan Daftar Urutan Saudara (termasuk subyek)
				<span className="text-danger">*</span>
			</h5>
			{forms?.map((form, index) => (
				<div key={index} className="mb-3">
					<Row className="row-cols-lg-auto g-3 align-items-center">
						<Col lg={3}>
							<Label for={`name_${form.keyId}`}>Nama</Label>
							<Input
								id={`name_${form.keyId}`}
								className="bg-light"
								name={`name_${form.keyId}`}
								placeholder="Masukkan nama"
								type="text"
								defaultValue={form?.name ?? ''}
								onChange={(e) =>
									handleInputChange(index, 'name', e.target.value)
								}
							/>
						</Col>
						<Col lg={3}>
							<Label for={`gender_${form.keyId}`}>Jenis Kelamin</Label>
							<Input
								id={`gender_${form.keyId}`}
								className="bg-light"
								name={`gender_${form.keyId}`}
								type="select"
								defaultValue={form?.gender ?? ''}
								onChange={(e) =>
									handleInputChange(index, 'gender', e.target.value)
								}
							>
								<option value="none">Pilih jenis kelamin</option>
								<option value="male">Laki-laki</option>
								<option value="female">Perempuan</option>
							</Input>
						</Col>
						<Col lg={3}>
							<Label for={`birth_of_date_${form.keyId}`}>Tanggal Lahir</Label>
							<Input
								id={`birth_of_date_${form.keyId}`}
								className="bg-light"
								name={`birth_of_date_${form.keyId}`}
								placeholder="Masukkan nama"
								type="date"
								defaultValue={form?.date_of_birth ?? ''}
								onChange={(e) =>
									handleInputChange(index, 'date_of_birth', e.target.value)
								}
							/>
						</Col>
						<Col lg={2}>
							<Label for={`education_${form.keyId}`}>
								Pendidikan/Pekerjaan
							</Label>
							<Input
								id={`education_${form.keyId}`}
								className="bg-light"
								name={`education_${form.keyId}`}
								placeholder="Masukkan keterangan"
								type="text"
								defaultValue={form?.education_or_profession ?? ''}
								onChange={(e) =>
									handleInputChange(
										index,
										'education_or_profession',
										e.target.value
									)
								}
							/>
						</Col>
						{index !== 0 && (
							<Col className="align-self-end">
								<button className="btn" onClick={() => deleteForm(form.keyId)}>
									<FaRegTrashCan size={18} />
								</button>
							</Col>
						)}
					</Row>
				</div>
			))}
			<hr className="border border-light border-2 opacity-50" />
			<Row>
				<Col lg="12">
					<Button color="primary" block size="sm" onClick={addForm}>
						Tambah Anak
					</Button>
				</Col>
			</Row>
		</>
	)
}

export default SortSiblings
