import React, { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { FaRegTrashCan } from 'react-icons/fa6'
import { Button, Col, Input, Label, Row } from 'reactstrap'
import {
	createDateTimeFromDateAndTime,
	formatTime,
} from '../../../helpers/GlobalHelper'
import { useGetListServiceQuery } from '../../services/api/listServiceApi'
import { useGetTherapistByServiceIdQuery } from '../../therapist/api/therapistApi'

const TherapistDropDown = ({
	id,
	index,
	serviceId,
	handleInputChange,
	therapistId,
}) => {
	const { data: fetchTherapistByServiceId } = useGetTherapistByServiceIdQuery(
		serviceId,
		{ skip: serviceId === 0 }
	)
	const { data: dataTherapist } = fetchTherapistByServiceId ?? []

	return (
		<>
			<Label for={`therapist_id_${id}`}>Pilih Terapis</Label>
			<Input
				id={`therapist_id_${id}`}
				className="bg-light"
				name={`therapist_id_${id}`}
				type="select"
				value={therapistId}
				onChange={(e) =>
					handleInputChange(index, 'therapist_id', +e.target.value)
				}
			>
				<option value="0">Pilih</option>
				{dataTherapist?.map((item, idx) => (
					<option value={item?.id} key={idx}>
						{item?.full_name}
					</option>
				))}
			</Input>
		</>
	)
}

const TherapyOptions = ({ setScheduleData, dataScheduleByChild }) => {
	const [forms, setForms] = useState([
		{
			keyId: 1,
			service_id: null,
			therapist_id: '',
			time: {
				string: formatTime(new Date()),
				raw: new Date(),
			},
			date: '',
		},
	])
	const [idCounter, setIdCounter] = useState(2)

	const { data: fetchServices } = useGetListServiceQuery({
		endpoint: 'service',
	})
	const { data: dataServices } = fetchServices ?? []

	useEffect(() => {
		setScheduleData(forms)
	}, [forms])

	useEffect(() => {
		if (dataScheduleByChild.length !== 0) {
			const existingValueForm = dataScheduleByChild.map((data, idx) => ({
				keyId: idx + 1,
				service_id: data.service.service_id,
				therapist_id: data.therapist.therapist_id,
				time: {
					string: formatTime(
						createDateTimeFromDateAndTime(
							data.schedule_date[0].date,
							data.schedule_date[0].time_from
						)
					),
					raw: createDateTimeFromDateAndTime(
						data.schedule_date[0].date,
						data.schedule_date[0].time_from
					),
				},
				date: data.schedule_date[0].date,
			}))
			setIdCounter(existingValueForm.length + 1)
			setForms(existingValueForm)
		}
	}, [dataScheduleByChild])

	const handleInputChange = (index, inputName, value) => {
		if (inputName === 'time') {
			value = {
				string: formatTime(value),
				raw: value,
			}
		}
		const updatedForms = [...forms]
		updatedForms[index][inputName] = value
		setForms(updatedForms)
	}

	const addForm = () => {
		setForms([
			...forms,
			{
				keyId: idCounter,
				service_id: '',
				therapist_id: '',
				time: '',
				date: '',
			},
		])
		setIdCounter(idCounter + 1)
	}

	const deleteForm = (keyId) => {
		if (forms.length > 1) {
			const updatedForms = forms.filter((form) => form.keyId !== keyId)
			setForms(updatedForms)
		}
	}

	return (
		<>
			<div className="mb-4">
				<h5>Tanggal dan jam Terapi</h5>
				<p>Masukkan jam mulai dan tanggal mulai terapi</p>
			</div>
			{forms?.map((form, index) => (
				<div key={index} className="mb-3">
					<Row className="row-cols-lg-auto g-3 align-items-center">
						<Col lg={3}>
							<Label for={`service_id_${form.keyId}`}>Pilih Layanan</Label>
							<Input
								id={`service_id_${form.keyId}`}
								className="bg-light"
								name={`service_id_${form.keyId}`}
								type="select"
								value={form?.service_id ?? 0}
								onChange={(e) =>
									handleInputChange(index, 'service_id', +e.target.value)
								}
							>
								<option value="0">Pilih</option>
								{dataServices?.map((item, idx) => (
									<option value={item?.id} key={idx}>
										{item.name}
									</option>
								))}
							</Input>
						</Col>
						<Col lg={3}>
							<TherapistDropDown
								id={form.keyId}
								index={index}
								serviceId={forms[index]?.service_id}
								handleInputChange={handleInputChange}
								therapistId={form?.therapist_id}
							/>
						</Col>
						<Col lg={3}>
							<Label for={`time_${form.keyId}`}>Jam</Label>
							<ReactDatePicker
								selected={forms[index]?.time?.raw}
								onChange={(date) => handleInputChange(index, 'time', date)}
								showTimeSelect
								className="bg-light form-control"
								showTimeSelectOnly
								timeIntervals={30}
								timeCaption="Jam"
								dateFormat="h:mm aa"
							/>
						</Col>
						<Col lg={2}>
							<Label for={`date_time_${form.keyId}`}>Tanggal</Label>
							<Input
								id={`date_time_${form.keyId}`}
								className="bg-light"
								name={`date_time_${form.keyId}`}
								placeholder="Masukkan nama"
								type="date"
								defaultValue={form?.date ?? ''}
								onChange={(e) =>
									handleInputChange(index, 'date', e.target.value)
								}
							/>
						</Col>
						{index !== 0 && (
							<Col className="align-self-end">
								<button className="btn" onClick={() => deleteForm(form.keyId)}>
									<FaRegTrashCan className="text-danger" size={18} />
								</button>
							</Col>
						)}
					</Row>
				</div>
			))}
			<hr className="border border-light border-2 opacity-50" />
			<Row>
				<Col lg="12">
					<Button color="primary" block size="sm" onClick={addForm}>
						Tambah Layanan
					</Button>
				</Col>
			</Row>
		</>
	)
}

export default TherapyOptions
