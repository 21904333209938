import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
const { VITE_BASE_URL } = import.meta.env

const getToken = (state) => state.authSetup.token

export const userLoginApi = createApi({
	reducerPath: 'userLoginApi',
	tagTypes: ['userLogin'],
	baseQuery: fetchBaseQuery({
		baseUrl: `${VITE_BASE_URL}/v1/user/auth/`,
		prepareHeaders: (headers, { getState }) => {
			const token = getToken(getState())
			if (token) {
				headers.set('Authorization', `Bearer ${token}`)
			}
			return headers
		},
	}),
	endpoints: (builder) => ({
		postUserLogin: builder.mutation({
			query: (body) => ({
				url: `login`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['UserAuth'],
		}),
		checkLoginClient: builder.query({
			query: () => 'check-login',
		}),
	}),
})

export const {
	usePostUserLoginMutation,
	useCheckLoginClientQuery
} = userLoginApi
