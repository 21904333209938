import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	isPoppedOut: false,
	surveyPage: false,
	child_id: null,
	form1Data: {},
	form2Data: {},
	form3Data: {
		child: {},
		father: {},
		mother: {},
		siblings: {},
	},
	form4Data: {},
	form5Data: {},
	form6Data: {},
	form7Data: {},
	form8Data: {},
	form9Data: {},
	form10Data: {},
	form10FileData: {}
}

const surveySlice = createSlice({
	name: 'surveyState',
	initialState,
	reducers: {
		setPoppedOut: (state, action) => {
			state.isPoppedOut = action.payload
			state.surveyPage = action.payload
		},
		setChildId: (state, action) => {
			state.child_id = action.payload
		},
		setForm1Data: (state, action) => {
			state.form1Data = action.payload
		},
		setForm2Data: (state, action) => {
			state.form2Data = action.payload
		},
		setForm3Data: (state, action) => {
			if (action.payload.for === 'child') {
				state.form3Data.child = action.payload.data
			}
			if (action.payload.for === 'siblings') {
				state.form3Data.siblings = action.payload.data
			}
			if (action.payload.for === 'father') {
				state.form3Data.father = action.payload.data
			}
			if (action.payload.for === 'mother') {
				state.form3Data.mother = action.payload.data
			}
		},
		setForm4Data: (state, action) => {
			state.form4Data = action.payload
		},
		setForm5Data: (state, action) => {
			state.form5Data = action.payload
		},
		setForm6Data: (state, action) => {
			state.form6Data = action.payload
		},
		setForm7Data: (state, action) => {
			state.form7Data = action.payload
		},
		setForm8Data: (state, action) => {
			state.form8Data = action.payload
		},
		setForm9Data: (state, action) => {
			state.form9Data = action.payload
		},
		setForm10Data: (state, action) => {
			state.form10Data = action.payload
		},
		setForm10Therapy: (state, action) => {
			state.form10Data.terapi = action.payload 
		},
		setForm10FileData: (state, action) => {
			state.form10FileData = action.payload
		}	
	},
})

export const surveyStateSelector = (state) => state.surveyState
export const {
	setPoppedOut,
	setChildId,
	setForm1Data,
	setForm2Data,
	setForm3Data,
	setForm4Data,
	setForm5Data,
	setForm6Data,
	setForm7Data,
	setForm8Data,
	setForm9Data,
	setForm10Data,
	setForm10Therapy,
	setForm10FileData
} = surveySlice.actions
export default surveySlice.reducer
