import { useEffect, useState } from 'react'
import { CiSearch } from 'react-icons/ci'
import Popout from 'react-popout'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Card, CardBody, Col, Container, Input, Row } from 'reactstrap'
import { layoutSetupSelector, setPageTitle } from '../../app/layoutSlice'
import { setPoppedOut, surveyStateSelector } from '../../app/surveySlice'
import HelmetTitle from '../../components/templates/HelmetTitle'
import TableClient from '../../components/templates/TableClient'
import { useGetListChildQuery } from '../patient/api/patientApi'
import ActionButtonSurvey from './components/ActionButtonSurvey'

const urlPopout =
	import.meta.env.MODE === 'development'
		? 'http://localhost:4999'
		: 'https://dashboard.klinikyamet.com'

const dataSurveyHeader = [
	{
		id: 1,
		title: 'No',
	},
	{
		id: 2,
		title: 'Nama Anak',
	},
	{
		id: 3,
		title: 'Nama Customer',
	},
	{
		id: 4,
		title: 'No Telp',
	},
	{
		id: 5,
		title: 'Usia',
	},
	{
		id: 6,
		title: 'Cabang',
	},
	{
		id: 7,
		title: 'Status',
	},
	{
		id: 8,
		title: 'Aksi',
	},
]

const ListSurveyPage = () => {
	const dispatch = useDispatch()
	const { isPoppedOut } = useSelector(surveyStateSelector)
	const [searchValue, setSearchValue] = useState('')
	const [searchQuery, setSearchQuery] = useState('')
	const { data: fetchDataChild } = useGetListChildQuery(
		{ name: searchQuery },
		{ refetchOnFocus: true }
	)
	const { data: dataChild } = fetchDataChild ?? []

	const popout = () => {
		dispatch(setPoppedOut(!isPoppedOut))
	}

	const closePopoutHandler = () => {
		dispatch(setPoppedOut(false))
		window.location.reload()
	}

	useEffect(() => {
		dispatch(setPageTitle('Survey'))
	}, [])

	useEffect(() => {
		const timer = setTimeout(() => {
			setSearchQuery(searchValue)
		}, 1000)

		return () => clearTimeout(timer)
	}, [searchValue])

	if (!isPoppedOut) {
		return (
			<>
				<HelmetTitle title={`List Survey`} />
				<Container fluid>
					<Row>
						<Col>
							<Card>
								<CardBody>
									<div className="d-flex justify-content-between align-items-center">
										<h3 className="mb-0">Data Survey</h3>
										<div className="d-flex gap-2">
											<div className="position-relative">
												<Input
													placeholder="Pencarian..."
													className="pe-5"
													onChange={(e) => setSearchValue(e.target.value)}
												/>
												<CiSearch
													size={24}
													className="position-absolute"
													style={{ top: '20%', right: '5%' }}
												/>
											</div>
											<Button
												color="success"
												className="px-4"
												// onClick={() => navigate('/form-survey/add')}
												onClick={popout}
											>
												Tambah
											</Button>
										</div>
									</div>
									<hr className="border border-light border-2 opacity-50"></hr>
									<TableClient
										dataHeader={dataSurveyHeader}
										dataRow={dataChild}
										dataSource={'child-survey'}
										actionButton={ActionButtonSurvey}
									/>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</>
		)
	} else {
		return (
			<Popout
				url={`${urlPopout}/form-survey/add`}
				title="Survey Calon Pasien"
				options={{
					left: '100px',
					top: '100px',
					innerHeight: window.innerHeight,
					innerWidth: window.innerWidth,
				}}
				onClosing={closePopoutHandler}
			/>
		)
	}
}

export default ListSurveyPage
