import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { Table } from 'reactstrap'
import { layoutSetupSelector } from '../../../app/layoutSlice'
import { formatCurrency } from '../../../helpers/GlobalHelper'
import { useGetMasterDataQuery } from '../../general/api/masterApi'
import ActiveBadge from '../../../components/templates/ActiveBadge'

const TableService = ({
	dataHeader,
	dataRow,
	dataSource,
	actionButton: ActionButton,
}) => {
	const { tablePage } = useSelector(layoutSetupSelector)
	const { data: fetchProvince } = useGetMasterDataQuery({
		endpoint: 'province',
	})
	const { data: provinceData } = fetchProvince ?? []

	const getProvinceName = (province_id) => {
		const resultProvinces = provinceData?.find(
			(province) => province.id === province_id
		)
		return resultProvinces?.name ?? 'Provinsi tidak ditemukan'
	}

	const generateRowNumber = (idx) => {
		const itemsPerPage = 10 // Replace this with your desired items per page value
		const startingRowNumber = (tablePage - 1) * itemsPerPage + 1

		if (tablePage !== 1) {
			const combinedRowNum = `${startingRowNumber + idx}`
			return combinedRowNum
		}
		return (startingRowNumber + idx).toString()
	}

	return (
		<div className="table-responsive">
			<Table className="table mb-0">
				<thead className="table-light">
					<tr>
						{dataHeader ? (
							<Fragment>
								{dataHeader?.map((header) => (
									<th
										key={header.id}
										width={header.width}
										className={header.className ? header.className : ''}
									>
										{header.title}
									</th>
								))}
							</Fragment>
						) : (
							<Fragment>
								<th>No</th>
								<th>Nama Layanan</th>
								<th>Deskripsi Layanan</th>
								<th>Aksi</th>
							</Fragment>
						)}
					</tr>
				</thead>
				<tbody className="bg-white align-middle">
					{dataSource === 'service' && (
						<>
							{dataRow?.map((data, idx) => (
								<tr key={data.id}>
									<th scope="row">{generateRowNumber(idx)}</th>
									<td>{data.name}</td>
									<td style={{ maxWidth: '60%' }}>
										{data.description}
									</td>
									<td>
										{ActionButton ? (
											<ActionButton
												id={data.id}
												serviceName={data.name}
												dataSource={dataSource}
											/>
										) : (
											''
										)}
									</td>
								</tr>
							))}
						</>
					)}
					{dataSource === 'master-price' && (
						<>
							{dataRow?.map((data, idx) => (
								<tr key={data.id}>
									<th scope="row">{generateRowNumber(idx)}</th>
									<td>{data.title}</td>
									<td>{data.description}</td>
									<td>
										{formatCurrency(data.min_price)} -{' '}
										{formatCurrency(data.max_price)}
									</td>
									<td>
										<ActionButton
											id={data.id}
											serviceName={data.title}
											dataSource={dataSource}
										/>
									</td>
								</tr>
							))}
						</>
					)}
					{dataSource === 'branchs' && (
						<>
							{dataRow?.map((data, idx) => (
								<tr key={data.id}>
									<th scope="row">{generateRowNumber(idx)}</th>
									<td>{data.branch_name}</td>
									<td>{data.address}</td>
									<td>{getProvinceName(data.city.province_id)}</td>
									<td>{data.city.name}</td>
									<td>{data.phone_number}</td>
									<td>{data.wa_number}</td>
									<td className="text-center">
										<ActiveBadge status={data.status} />
									</td>
									<td>
										<ActionButton
											id={data.id}
											dataSource={dataSource}
											serviceName={data.branch_name}
										/>
									</td>
								</tr>
							))}
						</>
					)}
					{dataSource === 'admin' && (
						<>
							{dataRow?.map((data, idx) => (
								<tr key={data.id}>
									<th scope="row">{generateRowNumber(idx)}</th>
									<td>{data.name}</td>
									<td>{data.email}</td>
									<td>{data?.branch?.branch_name ?? '-'}</td>
									<td>{data.phone_number}</td>
									<td className="text-center">
										<ActiveBadge status={data.status} />
									</td>
									<td>
										<ActionButton
											id={data.id}
											dataSource={dataSource}
											serviceName={data.name}
										/>
									</td>
								</tr>
							))}
						</>
					)}
					{(!dataRow || dataRow.length === 0) && (
						<tr>
							<td colSpan={dataHeader.length} className="text-center">
								Tidak Ada Data
							</td>
						</tr>
					)}
				</tbody>
			</Table>
		</div>
	)
}

export default TableService
