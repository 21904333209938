import BootstrapTheme from '@fullcalendar/bootstrap'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import FullCalendar from '@fullcalendar/react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
	Alert,
	Button,
	ButtonGroup,
	Col,
	Container,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
} from 'reactstrap'
import {
	createDateTimeFromDateAndTime,
	parseDate,
} from '../../../helpers/GlobalHelper'
import {
	useDeleteScheduleMutation,
	useGetAllSchedulesQuery,
	useGetScheduleByDayAndTimeQuery,
	useUpdateScheduleStatusMutation,
} from '../../general/api/scheduleApi'
import { FaX } from 'react-icons/fa6'
import styled from 'styled-components'

const StyledButton = styled(Button)`
	outline: none;
	border: none;
	border-radius: 0;
	font-size: 11px;
`

const initAlert = {
	status: false,
	type: '',
	message: '',
}

const Calendar = () => {
	const navigate = useNavigate()
	const [scheduleData, setScheduleData] = useState([])
	const [showModalEvent, setShowModalEvent] = useState(false)
	const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
	const [selectedEvent, setSelectedEvent] = useState({})
	const [activeStatus, setActiveStatus] = useState(0)
	const [alertStatus, setAlertStatus] = useState(initAlert)
	const [filteredTime, setFilteredTime] = useState('08:00:00')
	const [deleteSchedule, { isLoading: deleteScheduleLoading }] =
		useDeleteScheduleMutation()
	const [updateScheduleStatus, { isLoading: updateScheduleLoading }] =
		useUpdateScheduleStatusMutation()
	const { data: fetchSchedules, refetch } = useGetAllSchedulesQuery()
	const { data: fetchSchedulesByTime, refetch: refetchList } =
		useGetScheduleByDayAndTimeQuery(filteredTime)
	const { data: dataSchedules } = fetchSchedules ?? []
	const { data: dataSchedulesByTime } = fetchSchedulesByTime ?? []

	useEffect(() => {
		if (dataSchedules) {
			const covertedDataSchedule = dataSchedules.flatMap((data) =>
				data.schedule_date.map((schedule) => ({
					title: data.child.child_name,
					start: createDateTimeFromDateAndTime(
						schedule.date,
						schedule.time_from
					),
					extendedProps: {
						schedule_id: data.schedule_id,
						schedule_date_id: schedule.id,
						is_active: schedule.is_active,
						service_name: data.service.service_name,
						therapist_name: data.therapist.therapist_name,
					},
					className:
						schedule.is_active === 1
							? 'bg-primary text-white'
							: 'bg-secondary text-white',
				}))
			)
			setScheduleData(covertedDataSchedule)
			refetch()
			refetchList()
		}
	}, [dataSchedules])

	useEffect(() => {
		if (!showModalEvent) {
			setSelectedEvent({})
		}
	}, [showModalEvent])

	const modalToggle = () => {
		setShowModalEvent(!showModalEvent)
	}

	const handleEventClick = (arg) => {
		const event = arg.event
		const {
			start,
			title,
			extendedProps: {
				schedule_date_id,
				schedule_id,
				is_active,
				service_name,
				therapist_name,
			},
		} = event
		setActiveStatus(is_active)
		setSelectedEvent({
			title,
			start,
			schedule_id,
			schedule_date_id,
			is_active,
			service_name,
			therapist_name,
		})
		modalToggle()
	}

	const handleDeleteEvent = async () => {
		try {
			const result = await deleteSchedule(selectedEvent.schedule_id)
			if (result?.error?.status === 400) {
				setAlertStatus({
					status: true,
					type: 'danger',
					message: result?.error?.data?.message,
				})
			}

			if (result?.data?.status === 'ok') {
				setAlertStatus({
					status: true,
					type: 'success',
					message: 'berhasil hapus jadwal',
				})
			}
			setTimeout(() => {
				setAlertStatus(initAlert)
			}, 3500)
			setConfirmDeleteModal(false)
			setShowModalEvent(false)
			refetch()
		} catch (error) {
			console.error(error)

		}
	}

	const handleUpdateEvent = async () => {
		try {
			const { schedule_id, schedule_date_id } = selectedEvent
			const payload = {
				schedule_id,
				schedule_date_id,
				is_active: +activeStatus,
			}

			await updateScheduleStatus(payload).unwrap()

			setTimeout(() => {
				setShowModalEvent(false)
			}, 800)
			refetch()
		} catch (error) {
			setAlertStatus({
				status: true,
				type: 'danger',
				message: error?.data?.message,
			})
			setShowModalEvent(false)
			setTimeout(() => {
				setAlertStatus(initAlert)
			}, 3500)
		}
	}

	return (
		<>
			<Container fluid>
				{alertStatus.status && (
					<Alert color={alertStatus?.type}>
						<h3>{alertStatus?.type === 'success' ? 'Berhasil' : 'Gagal'}</h3>
						<p className="mb-0">{alertStatus?.message ?? ''}</p>
					</Alert>
				)}
				<Row>
					<Col lg="3">
						<div className="py-2">
							<h5 className="text-center mb-3">JADWAL TERAPI HARI INI</h5>
							<Button
								block
								size="lg"
								color="primary"
								onClick={() => navigate('/add-schedule')}
							>
								Buat Jadwal baru
							</Button>
							<Row className="gap-1 py-3">
								<Col lg={12}>
									<ButtonGroup>
										<StyledButton
											color="primary"
											outline
											onClick={() => setFilteredTime('08:00:00')}
											active={filteredTime === '08:00:00'}
										>
											08.00 - 09.00
										</StyledButton>
										<StyledButton
											color="primary"
											outline
											onClick={() => setFilteredTime('09:00:00')}
											active={filteredTime === '09:00:00'}
										>
											09.00 - 10.00
										</StyledButton>
										<StyledButton
											color="primary"
											outline
											onClick={() => setFilteredTime('10:00:00')}
											active={filteredTime === '10:00:00'}
										>
											10.00 - 11.00
										</StyledButton>
										<StyledButton
											color="primary"
											outline
											onClick={() => setFilteredTime('11:00:00')}
											active={filteredTime === '11:00:00'}
										>
											11.00 - 12.00
										</StyledButton>
									</ButtonGroup>
								</Col>
								<Col lg={12}>
									<ButtonGroup>
										<StyledButton
											color="primary"
											outline
											onClick={() => setFilteredTime('12:00:00')}
											active={filteredTime === '12:00:00'}
										>
											12.00 - 13.00
										</StyledButton>
										<StyledButton
											color="primary"
											outline
											onClick={() => setFilteredTime('13:00:00')}
											active={filteredTime === '13:00:00'}
										>
											13.00 - 14.00
										</StyledButton>
										<StyledButton
											color="primary"
											outline
											onClick={() => setFilteredTime('14:00:00')}
											active={filteredTime === '14:00:00'}
										>
											14.00 - 15.00
										</StyledButton>
										<StyledButton
											color="primary"
											outline
											onClick={() => setFilteredTime('15:00:00')}
											active={filteredTime === '15:00:00'}
										>
											15.00 - 16.00
										</StyledButton>
									</ButtonGroup>
								</Col>
								<Col lg={12}>
									<ButtonGroup>
										<StyledButton
											color="primary"
											outline
											onClick={() => setFilteredTime('16:00:00')}
											active={filteredTime === '16:00:00'}
										>
											16.00 - 17.00
										</StyledButton>
									</ButtonGroup>
								</Col>
							</Row>
							{dataSchedulesByTime?.length === 0 ? (
								<p className="text-center text-secondary py-5">
									Belum ada jadwal
								</p>
							) : (
								<ul className="list-unstyled mt-1">
									{dataSchedulesByTime?.map((schedule, idx) => (
										<li key={idx}>
											<div className="d-flex gap-3 h3 mb-0 align-items-center border-bottom border-2 p-3">
												<span>{idx + 1}</span>
												<div>
													{schedule?.child?.child_name}
													<div>
														<h6 className="mb-0 text-secondary mt-1">
															{schedule?.service?.service_name} (
															{schedule?.therapist.therapist_name})
														</h6>
													</div>
												</div>
											</div>
										</li>
									))}
								</ul>
							)}
						</div>
					</Col>
					<Col>
						<FullCalendar
							plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin]}
							themeSystem="bootstrap"
							handleWindowResize={true}
							headerToolbar={{
								right: 'prev,next today',
								left: 'title',
							}}
							initialView="dayGridMonth"
							weekends={true}
							editable={false}
							events={scheduleData}
							eventClick={handleEventClick}
							eventTimeFormat={{
								// like '14:30'
								hour: '2-digit',
								minute: '2-digit',
								meridiem: false,
								hour12: false,
							}}
						/>
					</Col>
				</Row>
			</Container>
			<Modal isOpen={showModalEvent} toggle={modalToggle} centered>
				<div className="p-3 d-flex justify-content-between align-items-center">
					<h5 className="fw-bold mb-0">Detail</h5>
					<button className="btn" onClick={modalToggle}>
						<FaX />
					</button>
				</div>
				<ModalBody>
					<Row className="my-3">
						<Col xs={5}>Tanggal</Col>
						<Col xs={2} className="text-center">
							:
						</Col>
						<Col xs={5} className="text-center">
							{parseDate(selectedEvent?.start ?? new Date(), '/')}
						</Col>
					</Row>
					<Row className="my-3">
						<Col xs={5}>Nama Anak</Col>
						<Col xs={2} className="text-center">
							:
						</Col>
						<Col xs={5} className="text-center">
							{selectedEvent?.title ?? '-'}
						</Col>
					</Row>
					<Row className="my-3">
						<Col xs={5}>Layanan</Col>
						<Col xs={2} className="text-center">
							:
						</Col>
						<Col xs={5} className="text-center">
							{selectedEvent?.service_name ?? '-'}
						</Col>
					</Row>
					<Row className="my-3">
						<Col xs={5}>Nama Terapis</Col>
						<Col xs={2} className="text-center">
							:
						</Col>
						<Col xs={5} className="text-center">
							{selectedEvent?.therapist_name ?? '-'}
						</Col>
					</Row>
					<FormGroup>
						<Label>Status</Label>
						<Input
							type="select"
							className="form-control"
							bsSize="lg"
							value={activeStatus}
							onChange={(e) => setActiveStatus(e.target.value)}
						>
							<option value="1">Aktif</option>
							<option value="0">Tidak Aktif</option>
						</Input>
					</FormGroup>
					<ModalFooter>
						<Button
							color="danger"
							onClick={() => setConfirmDeleteModal(!confirmDeleteModal)}
						>
							Hapus
						</Button>
						<Button
							color="info"
							onClick={handleUpdateEvent}
							disabled={updateScheduleLoading}
						>
							Simpan
						</Button>
					</ModalFooter>
					<Modal
						isOpen={confirmDeleteModal}
						toggle={() => setConfirmDeleteModal(!confirmDeleteModal)}
						centered
					>
						<ModalHeader>Hapus Jadwal?</ModalHeader>
						<ModalBody className="text-center">
							<p className="mb-3 fw-bold">
								Apakah Anda yakin akan menghapus jadwal bulan ini?
							</p>
							<div className="fw-bold text-danger">
								Menghapus salah satu jadwal akan menghapus seluruh jadwal yang
								sudah ditentukan pada bulan ini.
							</div>
						</ModalBody>
						<ModalFooter>
							<Button
								color="light"
								onClick={() => setConfirmDeleteModal(!confirmDeleteModal)}
							>
								Batalkan
							</Button>
							<Button
								color="danger"
								onClick={handleDeleteEvent}
								disabled={deleteScheduleLoading}
							>
								Iya lanjutkan hapus Jadwal
							</Button>
						</ModalFooter>
					</Modal>
				</ModalBody>
			</Modal>
		</>
	)
}

export default Calendar
