import React, { useCallback, useEffect, useState } from 'react'
import { CiSearch } from 'react-icons/ci'
import { useNavigate } from 'react-router-dom'
import { Button, Card, CardBody, Col, Container, Input, Row } from 'reactstrap'
import HelmetTitle from '../../../components/templates/HelmetTitle'
import { useGetListServiceQuery } from '../api/listServiceApi'
import PaginationButton from '../../../components/templates/PaginationButton/PaginationButton'
import TableService from '../components/TableService'
import ActionButtonAdmin from './ActionButtonAdmin'
import { useDispatch, useSelector } from 'react-redux'
import { layoutSetupSelector, setPageTitle, setTableKeyword } from '../../../app/layoutSlice'
import { debounce } from 'lodash'

const headerAdminData = [
	{
		id: 1,
		title: 'No',
	},
	{
		id: 2,
		title: 'Nama Admin',
	},
	{
		id: 3,
		title: 'Email',
	},
	{
		id: 4,
		title: 'Cabang',
	},
	{
		id: 5,
		title: 'No. Telepon',
	},
	{
		id: 6,
		title: 'Status',
		className: 'text-center',
	},
	{
		id: 7,
		title: 'Aksi',
	},
]

const AdminData = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [page, setPage] = useState(1)
	const { tableKeyword } = useSelector(layoutSetupSelector)


	const { data: fetchAdmin, error } = useGetListServiceQuery(
		{ endpoint: 'admin', page, keyword: tableKeyword },
		{
			refetchOnMountOrArgChange: true,
		}
	)
	const { data: dataAdmin } = fetchAdmin ?? []
	const getBranchAdminOnly = dataAdmin?.data?.filter(
		(admin) => admin.role.role_name === 'Admin Cabang'
	)

	const handlerSearch = (event) => {
		dispatch(setTableKeyword(event.target.value))
	}

	const debouncedHandleSearch = useCallback(debounce(handlerSearch, 500), []);

	useEffect(() => {
		dispatch(setPageTitle('Admin'))
	}, [])

	if (error) {
		return (
			<div className="text-center">
				Something went wrong, {error?.data?.message ?? 'unknown'}
			</div>
		)
	}

	return (
		<>
			<HelmetTitle title="Data Admin" />
			<Container fluid>
				<Row className="justify-content-center">
					<Col xs={12}>
						<Card>
							<CardBody>
								<div className="d-flex justify-content-between align-items-center">
									<h3 className="mb-0">Data Admin</h3>
									<div className="d-flex gap-2">
										<div className="position-relative">
											<Input
												onChange={debouncedHandleSearch}
												placeholder="Pencarian..."
												className="pe-5"
											/>
											<CiSearch
												size={24}
												className="position-absolute"
												style={{ top: '20%', right: '5%' }}
											/>
										</div>
										<Button
											color="success"
											className="px-4"
											onClick={() => navigate(`/data-admin/add-admin`)}
										>
											Tambah
										</Button>
									</div>
								</div>
								<hr className="border border-light border-2 opacity-50"></hr>
								<TableService
									dataHeader={headerAdminData}
									dataRow={getBranchAdminOnly}
									dataSource={'admin'}
									actionButton={ActionButtonAdmin}
								/>
								<PaginationButton
									metaPages={dataAdmin}
									setPage={setPage}
									currentPage={page}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	)
}

export default AdminData
