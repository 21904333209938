import { configureStore } from '@reduxjs/toolkit'
import { adminLoginApi } from '../features/auth/api/LoginAdminAPI'
import { masterApi } from '../features/general/api/masterApi'
import { resetPasswordApi } from '../features/reset_password/api/ResetPasswordAPI'
import { listServiceApi } from '../features/services/api/listServiceApi'
import { branchApi } from '../features/services/branchlocation/api/branchApi'
import { serviceApi } from '../features/services/listservice/api/serviceApi'
import { priceApi } from '../features/services/pricesService/api/priceApi'
import authReducer from './authSlice'
import layoutReducer from './layoutSlice'
import customerReducer from './customerSlice'
import surveyReducer from './surveySlice'
import { adminApi } from '../features/services/adminadata/api/adminApi'
import { userLoginApi } from '../features/auth/api/LoginUserAPI'
import { parentApi } from '../features/customer/api/parentApi'
import { userApi } from '../features/general/api/userApi'
import { patientApi } from '../features/patient/api/patientApi'
import { therapistApi } from '../features/therapist/api/therapistApi'
import { scheduleApi } from '../features/general/api/scheduleApi'
import { invoiceApi } from '../features/invoice/api/invoiceApi'
import { surveyApi } from '../features/survey/api/surveyApi'
import { clientApi } from '../features/general/api/clientApi'
const { VITE_APP_DEV } = import.meta.env;


export const store = configureStore({
	reducer: {
		authSetup: authReducer,
		layoutSetup: layoutReducer,
		customerState: customerReducer,
		surveyState: surveyReducer,

		[resetPasswordApi.reducerPath]: resetPasswordApi.reducer,
		[adminLoginApi.reducerPath]: adminLoginApi.reducer,
		[listServiceApi.reducerPath]: listServiceApi.reducer,
		[serviceApi.reducerPath]: serviceApi.reducer,
		[priceApi.reducerPath]: priceApi.reducer,
		[branchApi.reducerPath]: branchApi.reducer,
		[masterApi.reducerPath]: masterApi.reducer,
		[adminApi.reducerPath]: adminApi.reducer,
		[userLoginApi.reducerPath]: userLoginApi.reducer,
		[parentApi.reducerPath]: parentApi.reducer,
		[patientApi.reducerPath]: patientApi.reducer,
		[therapistApi.reducerPath]: therapistApi.reducer,
		[scheduleApi.reducerPath]: scheduleApi.reducer,
		[invoiceApi.reducerPath]: invoiceApi.reducer,
		[userApi.reducerPath]: userApi.reducer,
		[surveyApi.reducerPath]: surveyApi.reducer,
		[clientApi.reducerPath]: clientApi.reducer
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat([
			resetPasswordApi.middleware,
			adminLoginApi.middleware,
			listServiceApi.middleware,
			serviceApi.middleware,
			priceApi.middleware,
			branchApi.middleware,
			masterApi.middleware,
			masterApi.middleware,
			adminApi.middleware,
			userLoginApi.middleware,
			parentApi.middleware,
			patientApi.middleware,
			therapistApi.middleware,
			userApi.middleware,
			scheduleApi.middleware,
			invoiceApi.middleware,
			surveyApi.middleware,
			clientApi.middleware
		]),
	devTools: VITE_APP_DEV === "false" || !VITE_APP_DEV ? false : true,
})
