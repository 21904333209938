import classnames from 'classnames'
import React, { useState } from 'react'
import {
	Card,
	CardBody,
	Col,
	NavItem,
	NavLink,
	Row,
	TabContent,
	TabPane,
} from 'reactstrap'
import HelmetTitle from '../../../components/templates/HelmetTitle'
import FormPatient from './FormPatient'
import TableSchedule from './TableSchedule'

const TabFormPatient = ({ editForm }) => {
	const [activeTab, setActiveTab] = useState(1)
	const [passedSteps, setPassedSteps] = useState([1])

	function toggleTab(tab) {
		if (activeTab !== tab) {
			var modifiedSteps = [...passedSteps, tab]
			if (tab >= 1 && tab <= 4) {
				setActiveTab(tab)
				setPassedSteps(modifiedSteps)
			}
		}
	}

	return (
		<>
			<HelmetTitle title={`Tambah Pasien`} />
			<Row>
				<Col xs={12}>
					<Card>
						<CardBody>
							<h4 className="mb-0">Tambah Pasien</h4>
						</CardBody>
					</Card>
				</Col>
				<Col xs={12} className="mb-4">
					<div className="wizard clearfix">
						<div className="steps clearfix">
							<ul className="gap-4">
								<NavItem className={classnames({ current: activeTab === 1 })}>
									<NavLink
										className={classnames({ current: activeTab === 1 })}
										onClick={() => {
											setActiveTab(1)
										}}
									>
										<span className="number">1.</span> Informasi Pasien
									</NavLink>
								</NavItem>
								<NavItem className={classnames({ current: activeTab === 2 })}>
									<NavLink
										className={classnames({ active: activeTab === 2 })}
										onClick={() => {
											setActiveTab(2)
										}}
									>
										<span className="number">2.</span> Informasi Jadwal Terapi
									</NavLink>
								</NavItem>
							</ul>
						</div>
					</div>
				</Col>
				<Col lg="12">
					<Card>
						<CardBody>
							<div className="wizard clearfix">
								<div className="content clearfix">
									<TabContent activeTab={activeTab} className="body pt-0">
										<TabPane tabId={1}>
											<FormPatient editForm={editForm} />
										</TabPane>
										<TabPane tabId={2}>
											<TableSchedule />
										</TabPane>
									</TabContent>
								</div>
							</div>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</>
	)
}

export default TabFormPatient
