import React from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Row,
	Form,
	Input,
	Alert,
} from 'reactstrap'
import {
	usePostServiceMutation,
	useUpdateServiceMutation,
} from './api/serviceApi'
import { useState } from 'react'
import { useGetListServiceQuery } from '../api/listServiceApi'
import { useSelector } from 'react-redux'
import { layoutSetupSelector } from '../../../app/layoutSlice'
import { useEffect } from 'react'
import HelmetTitle from '../../../components/templates/HelmetTitle'

const FormService = ({ editForm }) => {
	const { id } = useParams()
	const navigate = useNavigate()
	const [postDataStatus, setPostDataStatus] = useState({
		status: false,
		type: '',
		headerMessage: '',
		message: '',
	})
	const { tablePage } = useSelector(layoutSetupSelector)
	const { data: listService } = useGetListServiceQuery({
		endpoint: 'service',
		page: tablePage,
	})

	const { data } = listService?.data ?? []
	const getDataById = data?.find((service) => service.id === +id)

	const [postService, { isLoading }] = usePostServiceMutation()
	const [updateService, { isLoading: updateLoading }] =
		useUpdateServiceMutation()

	const schema = yup.object({
		name: yup.string().required('Nama Layanan wajib di isi!'),
		description: yup.string().required('Deskripsi Layanan wajib di isi!'),
	})

	const {
		control,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			name: '',
			description: '',
		},
	})

	useEffect(() => {
		reset({
			name: getDataById?.name ?? '',
			description: getDataById?.description ?? '',
		})
	}, [reset])

	const handleOnSubmit = async (param) => {
		setPostDataStatus({
			status: false,
			message: '',
			type: '',
			headerMessage: '',
		})
		try {
			if (editForm) {
				const result = await updateService({ ...param, id }).unwrap()
				const { status, message } = result
				if (status !== 'ok') {
					throw new Error(result?.message ?? 'Something went wrong')
				}

				setPostDataStatus({
					status: true,
					message,
					type: 'success',
					headerMessage: 'Berhasil',
				})

				setTimeout(() => {
					navigate('/service-type')
				}, 1000)
				return
			}
			const result = await postService(param).unwrap()
			const { status, message } = result
			if (status !== 'ok') {
				throw new Error(message)
			}

			setPostDataStatus({
				status: true,
				message,
				type: 'success',
				headerMessage: 'Berhasil',
			})

			setTimeout(() => {
				navigate('/service-type')
			}, 1000)
			// reset()
		} catch (error) {
			let msgErr = '';
			if (error.status === 422) {
				for (const property in error.data.data) {
					msgErr += error.data.data[property][0] + "\n";
				}
			}

			if (error.status !== 422) {
				msgErr = error.data.message
			}

			setPostDataStatus({
				status: true,
				type: 'danger',
				message: <>{msgErr}</>,
				headerMessage: 'Terjadi Kesalahan'
			})
		}
	}

	return (
		<>
			<HelmetTitle title={editForm ? 'Edit Layanan' : 'Tambah Layanan'} />

			<Container fluid={true}>
				<Row>
					<Col>
						<Card>
							<CardBody>
								<div className="py-2">
									<h3 className="mb-0">
										{editForm ? 'Edit Layanan' : 'Tambah Layanan'}
									</h3>
									<hr className="border border-light border-2 opacity-50"></hr>
								</div>
								{postDataStatus.status && (
									<Alert color={postDataStatus?.type ?? 'danger'}>
										<h4 className="alert-heading">
											{postDataStatus?.headerMessage}
										</h4>
										<p
											className="mb-0"
											style={{ whiteSpace: 'pre-line' }}
										>
											{postDataStatus.message}
										</p>
									</Alert>
								)}
								<Form onSubmit={handleSubmit(handleOnSubmit)}>
									<Row className="mb-3">
										<label
											htmlFor="example-text-input"
											className="col-md-2 col-form-label"
										>
											Nama Layanan
										</label>
										<div className="col-md-10">
											<Controller
												name="name"
												control={control}
												render={({ field: { onChange, onBlur, value } }) => {
													return (
														<>
															<Input
																placeholder="Masukkan Nama Layanan"
																className="form-control bg-light"
																type="text"
																onChange={onChange}
																onBlur={onBlur}
																value={value}
																aria-label="name"
																invalid={errors.name ?? false}
															/>
															{errors && (
																<small className="text-danger">
																	{errors?.name?.message}
																</small>
															)}
														</>
													)
												}}
											/>
										</div>
									</Row>
									<Row className="mb-3">
										<label
											htmlFor="example-search-input"
											className="col-md-2 col-form-label"
										>
											Deskripsi Layanan
										</label>
										<div className="col-md-10">
											<Controller
												name="description"
												control={control}
												render={({ field: { onChange, onBlur, value } }) => {
													return (
														<>
															<Input
																placeholder="Masukkan Deskripsi Layanan"
																className="form-control bg-light"
																type="text"
																onChange={onChange}
																onBlur={onBlur}
																value={value}
																aria-label="description"
																invalid={errors.description ?? false}
															/>
															{errors && (
																<small className="text-danger">
																	{errors?.description?.message}
																</small>
															)}
														</>
													)
												}}
											/>
										</div>
									</Row>
									<Row>
										<Col>
											<div className="d-flex justify-content-end gap-2">
												<Button
													color="primary"
													onClick={() => navigate('/service-type')}
												>
													Batal
												</Button>
												<Button
													color="success"
													className="px-4"
													disabled={isLoading || updateLoading}
												>
													Simpan
												</Button>
											</div>
										</Col>
									</Row>
								</Form>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>

		</>
	)
}

export default FormService
