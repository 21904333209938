import { createSlice } from '@reduxjs/toolkit'

const lastUserId = localStorage.getItem('latest_userId')

const initialState = {
	user_id: lastUserId ?? 0,
}

const customerSlice = createSlice({
	name: 'customerState',
	initialState,
	reducers: {
		setUserId: (state, action) => {
			state.user_id = +action.payload
		},
	},
})

export const customerStateSelector = (state) => state.customerState
export const { setUserId } = customerSlice.actions
export default customerSlice.reducer
