import { BrowserRouter, Route, Routes } from 'react-router-dom'
import AuthLayout from '../components/templates/AuthLayout'
import NonAuthLayout from '../components/templates/NonAuthLayout'
import NewLoginAdmin from '../features/auth/NewLoginAdmin'
import NewLoginClient from '../features/auth/NewLoginClient'
import ListCustomer from '../features/customer/ListCustomer'
import TabFormCustomer from '../features/customer/components/TabFormCustomer'
import Dashboard from '../features/dashboard/Dashboard'
import Error404 from '../features/errors/error404'
import ListPatient from '../features/patient/ListPatient'
import TabFormPatient from '../features/patient/components/TabFormPatient'
import ResetPasswordAdmin from '../features/reset_password/ResetPasswordAdmin'
import ResetPasswordClient from '../features/reset_password/ResetPasswordClient'
import AdminData from '../features/services/adminadata/AdminData'
import FormAdmin from '../features/services/adminadata/FormAdmin'
import BranchLocation from '../features/services/branchlocation/BranchLocation'
import FormBranch from '../features/services/branchlocation/FormBranch'
import FormService from '../features/services/listservice/FormService'
import ListService from '../features/services/listservice/ListService'
import FormPrice from '../features/services/pricesService/FormPrice'
import PricesService from '../features/services/pricesService/PricesService'
import ListTherapist from '../features/therapist/ListTherapist'
import FormTherapist from '../features/therapist/components/FormTherapist'
import ListSurveyPage from '../features/survey/ListSurveyPage'
import TabFormSurvey from '../features/survey/components/TabFormSurvey'
import FormAddSchedule from '../features/dashboard/components/FormAddSchedule'
import ListInvoice from '../features/invoice/ListInvoice'
import DetailInvoice from '../features/invoice/components/DetailInvoice'
import ProfilePage from '../features/profile/ProfilePage'
import ScheduleUser from '../features/user/ScheduleUser'
import InvoiceUser from '../features/user/InvoiceUser'
import ViewSummary from '../features/survey/ViewSummary'

const Routing = () => {
	const authProtectedRoutes = [
		{
			path: '/',
			component: <Dashboard type="admin_pusat" />,
			type: 'admin_pusat',
		},
		{
			path: '/dashboard-cabang',
			component: <Dashboard type="admin_cabang" />,
			type: 'admin_cabang',
		},
		{
			path: '/dashboard-client',
			component: <Dashboard type="client" />,
			type: 'client',
		},
		{
			path: '/add-schedule',
			component: <FormAddSchedule />,
			type: 'admin_cabang',
		},
		{
			path: '/form-survey',
			component: <ListSurveyPage />,
			type: 'admin_cabang',
		},
		{
			path: '/form-survey/add',
			component: <TabFormSurvey />,
			type: 'admin_cabang',
		},
		{
			path: '/form-survey/draft/:childId',
			component: <TabFormSurvey draft />,
			type: 'admin_cabang',
		},
		{
			path: '/form-survey/summary/:childId',
			component: <ViewSummary />,
			type: 'admin_cabang',
		},
		{
			path: '/data-customer',
			component: <ListCustomer />,
			type: 'admin_cabang',
		},
		{
			path: '/data-customer/add-customer',
			component: <TabFormCustomer />,
			type: 'admin_cabang',
		},
		{
			path: '/data-customer/edit-customer/:id',
			component: <TabFormCustomer editForm />,
			type: 'admin_cabang',
		},
		{
			path: '/data-patient',
			component: <ListPatient />,
			type: 'admin_cabang',
		},
		{
			path: '/data-patient/add-patient',
			component: <TabFormPatient />,
			type: 'admin_cabang',
		},
		{
			path: '/data-patient/edit-patient/:id',
			component: <TabFormPatient editForm />,
			type: 'admin_cabang',
		},
		{
			path: '/data-therapist',
			component: <ListTherapist />,
			type: 'admin_cabang',
		},
		{
			path: '/data-therapist/add-therapist',
			component: <FormTherapist />,
			type: 'admin_cabang',
		},
		{
			path: '/data-therapist/edit-therapist/:id',
			component: <FormTherapist editForm />,
			type: 'admin_cabang',
		},
		{
			path: '/invoice-customer',
			component: <ListInvoice />,
			type: 'admin_cabang',
		},
		{
			path: '/invoice-customer/:invoiceId',
			component: <DetailInvoice />,
			type: 'admin_cabang',
		},
		{
			path: '/profile',
			component: <ProfilePage />,
			type: 'admin_cabang',
		},
		{
			path: '/schedule',
			component: <ScheduleUser />,
			type: 'client',
		},
		{
			path: '/invoice',
			component: <InvoiceUser />,
			type: 'client',
		},
		{
			path: '/service-type',
			component: <ListService />,
			type: 'admin_pusat',
		},
		{
			path: '/service-type/add-service',
			component: <FormService />,
			type: 'admin_pusat',
		},
		{
			path: '/service-type/edit-service/:id',
			component: <FormService editForm />,
			type: 'admin_pusat',
		},
		{
			path: '/service-price',
			component: <PricesService />,
			type: 'admin_pusat',
		},
		{
			path: '/service-price/add-service-price',
			component: <FormPrice />,
			type: 'admin_pusat',
		},
		{
			path: '/service-price/edit-service-price/:id',
			component: <FormPrice editForm />,
			type: 'admin_pusat',
		},
		{
			path: '/branch-location',
			component: <BranchLocation />,
			type: 'admin_pusat',
		},
		{
			path: '/branch-location/add-branch',
			component: <FormBranch />,
			type: 'admin_pusat',
		},
		{
			path: '/branch-location/edit-branch/:id',
			component: <FormBranch editForm />,
			type: 'admin_pusat',
		},
		{
			path: '/data-admin',
			component: <AdminData />,
			type: 'admin_pusat',
		},
		{
			path: '/data-admin/add-admin',
			component: <FormAdmin />,
			type: 'admin_pusat',
		},
		{
			path: '/data-admin/edit-admin/:id',
			component: <FormAdmin editForm />,
			type: 'admin_pusat',
		},
	]

	const nonAuthRoutes = [
		{
			path: '/login-client',
			component: <NewLoginClient />,
			isMainContent: true,
		},
		{
			path: '/login-admin',
			component: <NewLoginAdmin />,
			isMainContent: true,
		},
		{
			path: '/reset-password-admin',
			component: <ResetPasswordAdmin />,
			isMainContent: false,
		},
		{
			path: '/reset-password-client',
			component: <ResetPasswordClient />,
			isMainContent: false,
		},
	]

	return (
		<>
			<BrowserRouter>
				<Routes>
					{authProtectedRoutes.map((route, idx) => {
						const checkchild = route.children?.length
						if (route.path === `/form-survey/add` || route.path.includes('draft') || route.path.includes('/form-survey/summary')) {
							return (
								<Route path={route.path} element={route.component} key={idx} />
							)
						}
						return (
							<Route
								path={checkchild ? `${route.path}/*` : route.path}
								element={
									<AuthLayout type={route.type}>{route.component}</AuthLayout>
								}
								key={idx}
								exact={true}
							/>
						)
					})}
					{nonAuthRoutes.map((route, idx) => (
						<Route
							path={route.path}
							element={
								<NonAuthLayout isMainContent={route.isMainContent}>
									{route.component}
								</NonAuthLayout>
							}
							key={idx}
							exact={true}
						/>
					))}
					<Route path="*" element={<Error404 />} exact={true} />
					<Route path="/error-404" element={<Error404 />} exact={true} />
				</Routes>
			</BrowserRouter>
		</>
	)
}
export default Routing
