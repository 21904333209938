import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
const { VITE_BASE_URL } = import.meta.env

const getToken = (state) => state.authSetup.token

export const branchApi = createApi({
	reducerPath: 'branchApi',
	tagTypes: ['branchApi'],
	baseQuery: fetchBaseQuery({
		baseUrl: `${VITE_BASE_URL}/v1/admin/`,
		prepareHeaders: (headers, { getState }) => {
			const token = getToken(getState())
			if (token) {
				headers.set('Authorization', `Bearer ${token}`)
			}
			return headers
		},
	}),
	endpoints: (builder) => ({
		postBranch: builder.mutation({
			query: (body) => ({
				url: `branchs`,
				method: 'POST',
				body,
			}),

			invalidatesTags: ['AddBranch'],
		}),
		deleteBranch: builder.mutation({
			query: (id) => ({
				url: `branchs/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['GetBranch'],
		}),
		updateBranch: builder.mutation({
			query: ({id, ...rest}) => 
			({
				url: `branchs/${id}`,
				method: 'PUT',
				body: rest,
			}),
			invalidatesTags: ['UpdateBranch'],
		}),
		getBranchDetailById: builder.query({
			query: (id) => ({
				url: `branchs/${id}`,
			}),
			invalidatesTags: ['GetBranchDetailById'],
		}),
	}),
})

export const {
	usePostBranchMutation,
	useDeleteBranchMutation,
	useUpdateBranchMutation,
	useGetBranchDetailByIdQuery,
} = branchApi
