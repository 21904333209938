import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import {
	Alert,
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Form,
	Input,
	Row,
} from 'reactstrap'
import * as yup from 'yup'
import HelmetTitle from '../../../components/templates/HelmetTitle'
import { useGetListServiceQuery } from '../api/listServiceApi'
import { useGetBranchDetailByIdQuery } from '../branchlocation/api/branchApi'
import {
	useGetAdminDetailByIdQuery,
	usePostAdminMutation,
	useUpdateAdminMutation,
} from './api/adminApi'
import PhoneNumberInput from '../../../components/templates/PhoneNumberInput'

const FormAdmin = ({ editForm }) => {
	const { id } = useParams()
	const navigate = useNavigate()
	const [selectedBranch, setSelectedBranch] = useState(null)
	const [postDataStatus, setPostDataStatus] = useState({
		status: false,
		type: '',
		message: '',
		headerMessage: '',
	})
	const [postAdmin, { isLoading: addAdminLoading }] = usePostAdminMutation()
	const [updateAdmin, { isLoading: updateAdminLoading }] =
		useUpdateAdminMutation()
	const { data: fetchBranch } = useGetListServiceQuery({
		endpoint: 'branchs',
		page: 1,
	})
	const { data: fetchBranchById } = useGetBranchDetailByIdQuery(
		selectedBranch,
		{ skip: !selectedBranch }
	)
	const {
		data: fetchAdminDataById,
		isLoading: loadingAdmin,
		error: errorAdmin,
		refetch,
	} = useGetAdminDetailByIdQuery(id, { skip: !id })

	const { data: dataBranchs } = fetchBranch?.data ?? []
	const { data: dataBranchById } = fetchBranchById ?? {}
	const { data: dataAdminById } = fetchAdminDataById ?? {}

	const schema = yup.object({
		name: yup.string().required('Nama wajib di isi!'),
		branch_id: yup
			.number()
			.required('Cabang wajib di isi!')
			.notOneOf([0], 'Cabang wajib di isi!'),
		email: yup
			.string()
			.email('Alamat email invalid')
			.required('Alamat email wajib di isi!'),
		phone_number: yup
			.string()
			.required('Nomor Telepon wajib di isi!')
			.matches(/^\+62\d{10,}$/, 'Format tidak valid & minimal 10 digit sesudah +62!'),
	})

	const {
		control,
		handleSubmit,
		reset,
		formState: { errors },
		watch,
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			email: '',
			name: '',
			phone_number: '',
			branch_id: '0',
			status: '0',
		},
	})
	const getBranchId = watch('branch_id')

	useEffect(() => {
		if (+getBranchId !== 0) {
			setSelectedBranch(getBranchId)
		}
	}, [getBranchId])

	useEffect(() => {
		if (postDataStatus.type === 'success') {
			setTimeout(() => {
				navigate('/data-admin')
			}, 1500);
		}
	}, [postDataStatus]);

	useEffect(() => {
		if (dataAdminById) {
			const {
				email = '',
				name = '',
				phone_number = '',
				branch_id = '0',
				status = '0',
			} = dataAdminById ?? {}
			reset({
				email,
				name,
				phone_number,
				branch_id,
				status,
			})
			refetch()
		}
	}, [reset, dataAdminById])

	const handleOnSubmit = async (param) => {
		setPostDataStatus({
			status: false,
			type: '',
			message: '',
			headerMessage: ''
		})

		const payload = {
			...param,
			province_id: dataBranchById?.city?.province_id ?? '',
			city_id: dataBranchById?.city?.id ?? '',
			role_id: 5,
		}

		try {
			if (editForm) {
				const result = await updateAdmin({ id, payload }).unwrap()
				if (result.status === 'ok') {
					setPostDataStatus({
						status: true,
						type: 'success',
						message: 'success update admin',
						headerMessage: 'Berhasil'
					})
				}
				return
			}
			const result = await postAdmin(payload).unwrap()
			const { message } = result
			setPostDataStatus({
				status: true,
				type: 'success',
				message,
				headerMessage: 'Berhasil'
			})
		} catch (error) {
			let msgErr = '';
			if (error.status === 422) {
				for (const property in error.data.data) {
					msgErr += error.data.data[property][0] + "\n";
				}
			}

			if (error.status !== 422) {
				msgErr = error.data.message
			}

			setPostDataStatus({
				status: true,
				type: 'danger',
				message: <>{msgErr}</>,
				headerMessage: 'Terjadi Kesalahan'
			})
		}
	}

	if (loadingAdmin) {
		return <div className="d-flex justify-content-center">Loading...</div>
	}

	if (errorAdmin) {
		return (
			<div className="d-flex justify-content-center">
				Error, Something went wrong
			</div>
		)
	}

	return (
		<>
			<HelmetTitle title={editForm ? 'Edit Admin' : 'Tambah Admin'} />
			<Container fluid>
				<Row className="justify-content-center">
					<Col xs={12}>
						<Card>
							<CardBody>
								<div className="py-2">
									<h3 className="mb-0">
										{editForm ? 'Edit Admin' : 'Tambah Admin'}
									</h3>
									<hr className="border border-light border-2 opacity-50"></hr>
								</div>
								{postDataStatus.status && (
									<Alert color={postDataStatus?.type ?? 'danger'}>
										<h4 className="alert-heading">
											{postDataStatus?.headerMessage}
										</h4>
										<p
											className="mb-0"
											style={{ whiteSpace: 'pre-line' }}
										>
											{postDataStatus.message}
										</p>
									</Alert>
								)}
								<Form onSubmit={handleSubmit(handleOnSubmit)}>
									<div className="fw-bold mb-3">DATA ADMIN</div>
									<Row className="mb-3">
										<label
											htmlFor="example-text-input"
											className="col-md-2 col-form-label"
										>
											Nama Admin
										</label>
										<div className="col-md-10">
											<Controller
												name="name"
												control={control}
												render={({ field: { onChange, onBlur, value } }) => (
													<>
														<Input
															placeholder="Masukkan Nama Admin"
															className="form-control bg-light"
															type="text"
															aria-label="name"
															onChange={onChange}
															onBlur={onBlur}
															value={value}
															invalid={errors.name ? true : false}
														/>
														{errors && (
															<small className="text-danger">
																{errors?.name?.message}
															</small>
														)}
													</>
												)}
											/>
										</div>
									</Row>
									<Row className="mb-3">
										<label
											htmlFor="example-text-input"
											className="col-md-2 col-form-label"
										>
											Cabang
										</label>
										<div className="col-md-10">
											<Controller
												name="branch_id"
												control={control}
												render={({ field: { onChange, onBlur, value } }) => (
													<>
														<Input
															placeholder="Masukkan Nama Cabang"
															className="form-control bg-light"
															type="select"
															aria-label="branch_id"
															onChange={onChange}
															onBlur={onBlur}
															value={value}
														>
															<option value="0">Pilih Cabang</option>
															{dataBranchs?.map((branch, idx) => (
																<option value={branch.id} key={idx}>
																	{branch.branch_name}
																</option>
															))}
														</Input>
													</>
												)}
											/>
										</div>
									</Row>
									<Row className="mb-3">
										<label
											htmlFor="example-text-input"
											className="col-md-2 col-form-label"
										>
											No Telepon
										</label>
										<div className="col-md-10">
											<Controller
												name="phone_number"
												control={control}
												render={({ field: { onChange, onBlur, value } }) => (
													<>
														<PhoneNumberInput
															initialValue={value || '+62'}
															prefixOnZero="+62"
															className="form-control bg-light"
															placeholder="Masukkan No Telp"
															onChange={onChange}
															onBlur={onBlur}
															value={value}
														/>
														{errors && (
															<small className="text-danger">
																{errors?.phone_number?.message}
															</small>
														)}
													</>
												)}
											/>
										</div>
									</Row>
									<Row className="mb-3">
										<label
											htmlFor="example-text-input"
											className="col-md-2 col-form-label"
										>
											Status
										</label>
										<div className="col-md-10">
											<Controller
												name="status"
												control={control}
												render={({ field: { onChange, onBlur, value } }) => (
													<>
														<Input
															placeholder="Masukkan Tanggal Masuk Kerja"
															className="form-control bg-light"
															type="select"
															aria-label="status"
															onChange={onChange}
															onBlur={onBlur}
															value={value}
														>
															<option value="0">Tidak Aktif</option>
															<option value="1">Aktif</option>
														</Input>
													</>
												)}
											/>
										</div>
									</Row>
									<Row className="mb-3">
										<label
											htmlFor="example-text-input"
											className="col-md-2 col-form-label"
										>
											Email
										</label>
										<div className="col-md-10">
											<Controller
												name="email"
												control={control}
												render={({ field: { onChange, onBlur, value } }) => (
													<>
														<Input
															placeholder="Masukkan Email"
															className="form-control bg-light"
															type="text"
															aria-label="email"
															onChange={onChange}
															onBlur={onBlur}
															value={value}
															invalid={errors.email ? true : false}
														/>
														<small className="fw-bold">
															Password untuk log in akun adalah: 12345678
														</small>
														{errors && (
															<div>
																<small className="text-danger">
																	{errors?.email?.message}
																</small>
															</div>
														)}
													</>
												)}
											/>
										</div>
									</Row>
									<Row>
										<Col>
											<div className="d-flex justify-content-end gap-2">
												<Button
													color="primary"
													onClick={() => navigate('/data-admin')}
												>
													Batal
												</Button>
												<Button
													color="success"
													className="px-4"
													disabled={addAdminLoading || updateAdminLoading}
												>
													Simpan
												</Button>
											</div>
										</Col>
									</Row>
								</Form>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	)
}

export default FormAdmin
