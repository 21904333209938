import { createSlice } from '@reduxjs/toolkit'

const token = localStorage.getItem('token')
const getData = localStorage.getItem('data')

const { branch_id } = JSON.parse(getData ?? '{}')


const initialState = {
	token: token || null,
	condition_reset_password: 'form_reset',
	//form_reset|form_change|success
	name_reset_password: '',
	email_reset_password: '',
	branch_id: branch_id ?? null,
}

const authSlice = createSlice({
	name: 'authSetup',
	initialState,
	reducers: {
		setToken: (state, action) => {
			state.token = action.payload
		},
		setBranchId: (state, action) => {
			state.branch_id = action.payload
		},
		setConditionResetPassword: (state, action) => {
			state.condition_reset_password = action.payload.condition_reset_password
		},
		setProfileResetPassword: (state, action) => {
			state.name_reset_password = action.payload.name_reset_password
			state.email_reset_password = action.payload.email_reset_password
		},
	},
})

export const authSetupSelector = (state) => state.authSetup
export const {
	setToken,
	setBranchId,
	setConditionResetPassword,
	setProfileResetPassword,
} = authSlice.actions
export default authSlice.reducer
