import React, { Fragment } from 'react'
import FormParents from '../../../customer/components/Form/FormParents'
import FormPatient from '../../../patient/components/FormPatient'
import SortSiblings from '../SortSiblings'

const FormStep3 = ({ activeTab, setActiveTab, toggleTab, dataChildById }) => {
	return (
		<Fragment>
			<FormPatient childData={dataChildById} />
			<hr className="border border-light border-2 opacity-50" />
			<SortSiblings dataSiblings={dataChildById?.siblings}/>
			<div className="my-5">
				<FormParents
					type={`father`}
					activeTab={activeTab}
					setActiveTab={setActiveTab}
					toggleTab={toggleTab}
					dataParent={dataChildById?.parent?.father}
				/>
				<hr className="border border-light border-2 opacity-50" />
				<FormParents
					type={`mother`}
					activeTab={activeTab}
					setActiveTab={setActiveTab}
					toggleTab={toggleTab}
					dataParent={dataChildById?.parent?.mother}
				/>
			</div>
		</Fragment>
	)
}

export default FormStep3
