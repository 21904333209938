import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "react-router-dom";

const CarouselContentResetPassword = () => {
    return (
        <div className="auth-full-bg pt-lg-5 p-4 text-white">
            <div className="w-100">
                <div className="bg-overlay"></div>
                <div className="d-flex h-100 flex-column">
                    <div className="p-4 mt-auto">
                        <div className="row justify-content-center">
                            <div className="col-lg-7">
                                <div className="text-center">
                                    <h3>Klinik Yamet</h3>
                                    <h4 className="mb-3">
                                        <i className="bx bxs-quote-alt-left text-warning h1 align-middle me-3"></i>
                                        <span className="text-warning">40+</span>
                                        {' '}Cabang di seluruh Indonesia
                                    </h4>
                                    <div dir="ltr">
                                        <Carousel className="owl-carousel owl-theme auth-review-carousel slider_css" id="auth-review-carousel"
                                            showThumbs={false}>
                                            <div>
                                                <div className="item">
                                                    <div className="pb-5 pt-3">
                                                        <p className="font-size-16 fw-bold mb-1 text-warning">
                                                            Visi
                                                        </p>
                                                        <p className="font-size-16 fw-bold mb-3 text-white">
                                                            Menjadi Pusat Terapi Tumbuh Kembang terbaik di Indonesia
                                                        </p>

                                                        <div>
                                                            <p className="font-size-14 mb-0">
                                                                Created By   - <Link to="https://www.linkedin.com/company/reka-cipta-digital/" className="text-warning">Reka Cipta Digital</Link>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Carousel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CarouselContentResetPassword;
