import { useEffect, useState } from "react";
import { Helmet, HelmetData } from "react-helmet-async";
const { VITE_APP_TITLE } = import.meta.env;

const HelmetTitle = (props) => {
    const [titleContent, setTitleContent] = useState('');
    const { title } = props;
    const helmetData = new HelmetData({});
    useEffect(() => {
        if (title) {
            setTitleContent(`${title} | ${VITE_APP_TITLE}`)
        }
    }, [title]);

    return (
        <>
            <Helmet helmetData={helmetData}>
                <title>
                    {!titleContent ? VITE_APP_TITLE : titleContent}
                </title>
                <meta name="author" content="Klinik Yamet" />
                <meta name="description" content="Klinik Yamet Dashboard" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
        </>
    );
}

export default HelmetTitle;
