import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { authSetupSelector, setConditionResetPassword, setProfileResetPassword } from "../../app/authSlice";
import HelmetTitle from "../../components/templates/HelmetTitle";
import CarouselContentResetPassword from "./components/CarouselContentResetPassword";
import FormChangePasswordClient from "./components/FormChangePasswordClient";
import FormResetPasswordClient from "./components/FormResetPasswordClient";
import SuccessChangePasswordClient from "./components/SuccessChangePasswordClient";


const ResetPasswordClient = () => {
    const dispatch = useDispatch()
    const { condition_reset_password } = useSelector(authSetupSelector)

    useEffect(() => {
        dispatch(
            setConditionResetPassword(
                { condition_reset_password: 'form_reset' }
            )
        );

        dispatch(
            setProfileResetPassword(
                { name_reset_password: '', email_reset_password: '' }
            )
        );
    }, []);

    const componentContent = (condition_reset_password) => {
        if (condition_reset_password === 'form_reset') {
            return <FormResetPasswordClient />
        }

        if (condition_reset_password === 'form_change') {
            return <FormChangePasswordClient />
        }

        if (condition_reset_password === 'success') {
            return <SuccessChangePasswordClient />
        }
    }

    return (
        <>
            <HelmetTitle title="Lupa Kata Sandi | Admin" />
            <div>
                <Container fluid className="p-0">
                    <Row className="g-0">
                        <Col xl={9}>
                            <CarouselContentResetPassword />
                        </Col>
                        <Col xl={3}>
                            <div className="auth-full-page-content p-md-5 p-4">
                                <div className="w-100">
                                    <div className="d-flex flex-column h-100">
                                        <div className="mb-4">

                                            <Link
                                                to="https://klinikyamet.com/"
                                                className="d-block auth-logo"
                                            >
                                                <img
                                                    src='logo-yamet-2.png'
                                                    alt=""
                                                    height="25"
                                                    className="logo-dark-element"
                                                />
                                            </Link>
                                        </div>
                                        {
                                            componentContent(
                                                condition_reset_password
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default ResetPasswordClient;
