import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { FaRegTrashCan } from 'react-icons/fa6'
import { useDispatch } from 'react-redux'
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap'
import { setForm10Therapy } from '../../../app/surveySlice'

const formInit = [
	{
		keyId: 1,
		jenis: '',
		frekuensi: '',
		lama: '',
		tempat: '',
	},
]

const TherapyCount = () => {
	const dispatch = useDispatch()

	const [forms, setForms] = useState(formInit)
	const [idCounter, setIdCounter] = useState(2)

	useEffect(() => {
		let timeoutId

		const isAllFormsFilled = () => {
			for (const form of forms) {
				if (
					form.jenis === null ||
					form.frekuensi === null ||
					form.lama === null ||
					form.tempat === null
				) {
					return false
				}
			}
			return true
		}

		if (isAllFormsFilled()) {
			timeoutId = setTimeout(() => {
				const updatedForms = forms.map((form) => ({
					...form,
					jenis: form.jenis?.trim() || null,
					frekuensi: form.frekuensi?.trim() || null,
					lama: form.lama?.trim() || null,
					tempat: form.tempat?.trim() || null,
				}))
				dispatch(setForm10Therapy(updatedForms))
			}, 1000)
		}

		return () => clearTimeout(timeoutId)
	}, [forms])

	const handleInputChange = (index, inputName, value) => {
		const updatedForms = [...forms]
		updatedForms[index][inputName] = value
		setForms(updatedForms)
	}

	const addForm = () => {
		setForms([
			...forms,
			{
				keyId: idCounter,
				jenis: '',
				frekuensi: '',
				lama: '',
				tempat: '',
			},
		])
		setIdCounter(idCounter + 1)
	}

	const deleteForm = (keyId) => {
		if (forms.length > 1) {
			const updatedForms = forms.filter((form) => form.keyId !== keyId)
			setForms(updatedForms)
		}
	}

	return (
		<div className="mb-3">
			{forms?.map((form, index) => (
				<Row
					key={index}
					className="row-cols-lg-auto g-3 align-items-center mb-3"
				>
					<Col lg={3}>
						<Label>Jenis Terapi</Label>
						<Input
							className="form-control bg-light"
							placeholder="Masukkan jenis terapi"
							onChange={(e) =>
								handleInputChange(index, 'jenis', e.target.value)
							}
						/>
					</Col>
					<Col lg={3}>
						<Label>Frekuensi</Label>
						<Input
							className="form-control bg-light"
							placeholder="Dalam Seminggu"
							onChange={(e) =>
								handleInputChange(index, 'frekuensi', e.target.value)
							}
						/>
					</Col>
					<Col lg={3}>
						<Label>Lama</Label>
						<Input
							className="form-control bg-light"
							placeholder="Lama sesi terapi"
							onChange={(e) => handleInputChange(index, 'lama', e.target.value)}
						/>
					</Col>
					<Col lg={2}>
						<Label>Tempat Terapi</Label>
						<Input
							className="form-control bg-light"
							placeholder="Tempat terapi"
							onChange={(e) =>
								handleInputChange(index, 'tempat', e.target.value)
							}
						/>
					</Col>
					{index !== 0 && (
						<Col className="align-self-end">
							<button className="btn" onClick={() => deleteForm(form.keyId)}>
								<FaRegTrashCan size={18} />
							</button>
						</Col>
					)}
				</Row>
			))}
			<hr className="border border-light border-2 opacity-50" />
			<Row>
				<Col lg="12">
					<Button color="primary" block size="sm" onClick={addForm}>
						Tambah Terapi
					</Button>
				</Col>
			</Row>
		</div>
	)
}

export default TherapyCount
