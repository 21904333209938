import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
const { VITE_BASE_URL } = import.meta.env

const getToken = (state) => state.authSetup.token

export const parentApi = createApi({
	reducerPath: 'parentApi',
	tagTypes: ['parentApi'],
	baseQuery: fetchBaseQuery({
		baseUrl: `${VITE_BASE_URL}/v1/admin`,
		prepareHeaders: (headers, { getState }) => {
			const token = getToken(getState())
			if (token) {
				headers.set('Authorization', `Bearer ${token}`)
			}
			return headers
		},
	}),
	endpoints: (builder) => ({
		getListParent: builder.query({
			query: (url) => {
				const { page = 1 } = url
				return `/parent?page=${page}`
			},
			providesTags: ['GetListCustomer'],
		}),
		createParent: builder.mutation({
			query: (payload) => ({
				url: '/parent',
				method: 'POST',
				body: payload,
			}),
			providesTags: ['CreateParent'],
		}),
		updateParent: builder.mutation({
			query: ({ id, ...rest }) => ({
				url: `/parent/${id}`,
				method: 'PUT',
				body: rest,
			}),
			providesTags: ['UpdateParent'],
		}),
		deleteParent: builder.mutation({
			query: (id) => ({
				url: `/parent/${id}`,
				method: 'DELETE',
			}),
			providesTags: ['DeleteCustomer'],
		}),
	}),
	refetchOnMountOrArgChange: true,
	refetchOnFocus: true,
})

export const {
	useGetListParentQuery,
	useCreateParentMutation,
	useDeleteParentMutation,
	useUpdateParentMutation,
} = parentApi
