import React, { Fragment, useState } from 'react'
import { useSelector } from 'react-redux'
import { layoutSetupSelector } from '../../app/layoutSlice'
import { Table } from 'reactstrap'
import ActiveBadge from './ActiveBadge'
import { useGetUserByIdQuery } from '../../features/general/api/userApi'
import PaymentStatus from './PaymentStatus'
import { calculateAge, formatCurrency } from '../../helpers/GlobalHelper'
import SurveyStatusBadge from '../../features/survey/components/SurveyStatusBadge'
import PaymentEvidence from './PaymentEvidence'
import ShowPaymentEvindence from '../../features/invoice/components/ShowPaymentEvindence'

const CustomerInfo = ({ userId, type = 'name' }) => {
	const { data: fetchUserById } = useGetUserByIdQuery(userId, { skip: !userId })
	const { data: dataUserById } = fetchUserById ?? {}
	if (type === 'name') {
		return <div>{dataUserById?.name}</div>
	}

	if (type === 'phone_number') {
		return <div>{dataUserById?.phone_number}</div>
	}
}

const TableClient = ({
	dataHeader,
	dataRow,
	dataSource,
	actionButton: ActionButton,
}) => {
	const { tablePage } = useSelector(layoutSetupSelector)

	const generateRowNumber = (idx) => {
		const itemsPerPage = 10 // Replace this with your desired items per page value
		const startingRowNumber = (tablePage - 1) * itemsPerPage + 1

		if (tablePage !== 1) {
			const combinedRowNum = `${startingRowNumber + idx}`
			return combinedRowNum
		}
		return (startingRowNumber + idx).toString()
	}

	return (
		<div className="table-responsive">
			<Table className="table mb-0">
				<thead className="table-light">
					<tr>
						{dataHeader ? (
							<Fragment>
								{dataHeader?.map((header) => (
									<th
										key={header.id}
										width={header.width}
										className={header.className ? header.className : ''}
									>
										{header.title}
									</th>
								))}
							</Fragment>
						) : (
							<Fragment>
								<th>No</th>
								<th>Nama Layanan</th>
								<th>Deskripsi Layanan</th>
								<th>Aksi</th>
							</Fragment>
						)}
					</tr>
				</thead>
				<tbody className="bg-white align-middle">
					{dataSource === 'customer' && (
						<>
							{dataRow?.map((data, idx) => (
								<tr key={idx}>
									<th scope="row">{generateRowNumber(idx)}</th>
									<td>{data.user_number}</td>
									<td>{data.name}</td>
									<td>{data.email}</td>
									<td>{data.phone_number}</td>
									<td>
										{ActionButton ? (
											<ActionButton
												id={data.id}
												serviceName={data.full_name}
												dataSource={dataSource}
											/>
										) : (
											''
										)}
									</td>
								</tr>
							))}
						</>
					)}
					{dataSource === 'child' && (
						<>
							{dataRow?.map((data, idx) => (
								<tr key={idx}>
									<th scope="row">{generateRowNumber(idx)}</th>
									<td>{data.child_number}</td>
									<td>{data.full_name}</td>
									<td>
										<CustomerInfo userId={data.user_id} />
									</td>
									<td>{data.branch.branch_name}</td>
									<td>
										<ActiveBadge status={data.status} />
									</td>
									<td>
										{ActionButton ? (
											<ActionButton
												id={data.id}
												serviceName={data.full_name}
												dataSource={dataSource}
												parentData={data.parent}
											/>
										) : (
											''
										)}
									</td>
								</tr>
							))}
						</>
					)}
					{dataSource === 'therapist' && (
						<>
							{dataRow?.map((data, idx) => (
								<tr key={idx}>
									<th scope="row">{generateRowNumber(idx)}</th>
									<td>{data.therapist_number}</td>
									<td>{data.full_name}</td>
									<td>{data.email}</td>
									<td>{data.phone_number}</td>
									<td>{<ActiveBadge status={data.status} />}</td>
									<td>
										{ActionButton ? (
											<ActionButton
												id={data.id}
												serviceName={data.full_name}
												dataSource={dataSource}
											/>
										) : (
											''
										)}
									</td>
								</tr>
							))}
						</>
					)}
					{dataSource === 'invoices' && (
						<>
							{dataRow?.map((data, idx) => (
								<tr key={idx}>
									<th scope="row">{idx + 1}</th>
									<td>{data.invoice_number}</td>
									<td>{data.child.name}</td>
									<td>{data.user.name}</td>
									<td>{data.user.phone_number}</td>
									<td>{formatCurrency(data.total_price)}</td>
									<td><ShowPaymentEvindence link={data.file_upload} invoiceId={data.id} invoiceNumber={data.invoice_number}/></td>
									<td>
										<PaymentStatus status={data.status} />
									</td>
									<td>
										<ActionButton invoiceId={data.id} />
									</td>
								</tr>
							))}
						</>
					)}
					{dataSource === 'user-invoices' && (
						<>
							{dataRow?.map((data, idx) => (
								<tr key={idx}>
									<th scope="row">{idx + 1}</th>
									<td>{data.invoice_number}</td>
									<td>{data.child.name}</td>
									<td>{formatCurrency(data.total_price)}</td>
									<td>
										<PaymentEvidence
											link={data.file_upload}
											invoiceId={data.id}
										/>
									</td>
									<td>
										<PaymentStatus status={data.status} />
									</td>
									<td>
										<ActionButton invoiceId={data.id} />
									</td>
								</tr>
							))}
						</>
					)}
					{dataSource === 'child-survey' && (
						<>
							{dataRow?.map((data, idx) => (
								<tr key={idx}>
									<th scope="row">{idx + 1}</th>
									<td>{data.full_name}</td>
									<td>
										<CustomerInfo userId={data.user_id} type={'name'} />
									</td>
									<td>
										<CustomerInfo userId={data.user_id} type={'phone_number'} />
									</td>
									<td>{calculateAge(data.date_of_birth)} Tahun</td>
									<td>{data.branch.branch_name}</td>
									<td>
										<SurveyStatusBadge status={data.isCompleteFillForm} />
									</td>
									<td>
										<ActionButton childId={data.id} />
									</td>
								</tr>
							))}
						</>
					)}
					{(!dataRow || dataRow.length === 0) && (
						<tr>
							<td colSpan={dataHeader?.length ?? 4} className="text-center">
								Tidak Ada Data
							</td>
						</tr>
					)}
				</tbody>
			</Table>
		</div>
	)
}

export default TableClient
