import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
const { VITE_BASE_URL } = import.meta.env

const getToken = (state) => state.authSetup.token

export const masterApi = createApi({
	reducerPath: 'masterApi',
	tagTypes: ['masterApi'],
	baseQuery: fetchBaseQuery({
		baseUrl: `${VITE_BASE_URL}/v1/public/master`,
		prepareHeaders: (headers, { getState }) => {
			const token = getToken(getState())
			if (token) {
				headers.set('Authorization', `Bearer ${token}`)
			}
			return headers
		},
	}),
	endpoints: (builder) => ({
		getMasterData: builder.query({
			query: (url) => {
				const { endpoint } = url
				return `/${endpoint}`
			},
			providesTags: ['GetService'],
		}),
		getCityByProvinceId: builder.query({
			query: (url) => {
				return `/city?province_id=${url.provinceId}`
			}
		})
	}),
	refetchOnMountOrArgChange:  true,
	refetchOnFocus: true,
})

export const { useGetMasterDataQuery, useGetCityByProvinceIdQuery } = masterApi
