import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
const { VITE_BASE_URL } = import.meta.env

const getToken = (state) => state.authSetup.token

export const adminApi = createApi({
	reducerPath: 'adminApi',
	tagTypes: ['adminApi'],
	baseQuery: fetchBaseQuery({
		baseUrl: `${VITE_BASE_URL}/v1/`,
		prepareHeaders: (headers, { getState }) => {
			const token = getToken(getState())
			if (token) {
				headers.set('Authorization', `Bearer ${token}`)
			}
			return headers
		},
	}),
	endpoints: (builder) => ({
		postAdmin: builder.mutation({
			query: (body) => ({
				url: `admin`,
				method: 'POST',
				body,
			}),

			invalidatesTags: ['AddAdmin'],
		}),
		deleteAdmin: builder.mutation({
			query: (id) => ({
				url: `admin/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['GetAdmin'],
		}),
		updateAdmin: builder.mutation({
			query: ({ id, ...rest }) =>  ({
				url: `admin/${id}`,
				method: 'PUT',
				body: rest.payload
			}),
			invalidatesTags: ['UpdateAdmin'],
		}),
		getAdminDetailById: builder.query({
			query: (id) => ({
				url: `admin/${id}`,
			}),
			invalidatesTags: ['GetAdminDetailById'],
		}),
	}),
})

export const {
	usePostAdminMutation,
	useDeleteAdminMutation,
	useUpdateAdminMutation,
	useGetAdminDetailByIdQuery,
} = adminApi
