import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap'

const ShowPaymentEvindence = ({ link, invoiceId, invoiceNumber }) => {
	const [showModal, setShowModal] = useState(false)
	const [imgLink, setImageLink] = useState(null)

	useEffect(() => {
		if (link) {
			setImageLink(link)
		}
	}, [link])

	const toggleModal = () => {
		setShowModal(!showModal)
	}

	if (!link) {
		return (
			<div
				className="d-inline-flex text-white fw-bold py-1 px-2 rounded"
				style={{ backgroundColor: '#FFA800' }}
			>
				Belum
			</div>
		)
	}

	return (
		<>
			<button
				className="btn btn-sm bg-success fw-bold d-inline-flex text-white py-1 px-3 rounded"
				onClick={toggleModal}
			>
				Lihat
			</button>
			{imgLink && (
				<Modal isOpen={showModal} toggle={toggleModal} centered backdrop={'static'}>
					<ModalBody>
						<h3 className='mb-3'>Bukti Bayar #{invoiceNumber ?? 0}</h3>
						<div
							className="position-relative d-flex justify-content-center align-items-center mx-auto"
							style={{ width: '400px', height: '400px' }}
						>
							<img
								src={imgLink}
								alt={`img_${invoiceId}`}
								style={{
									maxWidth: '100%',
									maxHeight: '100%',
									width: 'auto',
									height: 'auto',
									objectFit: 'cover'
								}}
							/>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button color="light" onClick={toggleModal}>
							Tutup
						</Button>
					</ModalFooter>
				</Modal>
			)}
		</>
	)
}

export default ShowPaymentEvindence
