import React, { useCallback, useEffect } from 'react'
import HelmetTitle from '../../../components/templates/HelmetTitle'
import { Button, Card, CardBody, Col, Container, Input, Row } from 'reactstrap'
import { CiSearch } from 'react-icons/ci'
import TableService from '../components/TableService'
import PaginationButton from '../../../components/templates/PaginationButton/PaginationButton'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useGetListServiceQuery } from '../api/listServiceApi'
import ActionButtonPrices from './ActionButtonPrices'
import { layoutSetupSelector, setPageTitle, setTableKeyword } from '../../../app/layoutSlice'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'

const headerListPriceService = [
	{
		id: 1,
		title: 'No',
	},
	{
		id: 2,
		title: 'Nama Harga',
	},
	{
		id: 3,
		title: 'Deskripsi Harga',
	},
	{
		id: 4,
		title: 'Range Harga',
	},
	{
		id: 5,
		title: 'Aksi',
	},
]

const PricesService = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [page, setPage] = useState(1)

	const { tableKeyword } = useSelector(layoutSetupSelector)

	const handlerSearch = (event) => {
		dispatch(setTableKeyword(event.target.value))
	}

	const debouncedHandleSearch = useCallback(debounce(handlerSearch, 500), []);

	const { data: fetchServices, error } = useGetListServiceQuery(
		{ endpoint: 'master-price', page, keyword: tableKeyword },
		{ refetchOnMountOrArgChange: true }
	)
	const { data: dataPrices } = fetchServices ?? []

	useEffect(() => {
		dispatch(setPageTitle('Harga Layanan'))
	}, [])

	if (error) {
		return (
			<div className="text-center">
				Something went wrong, {error.data.message ?? 'unknown'}
			</div>
		)
	}

	return (
		<>
			<HelmetTitle title="Harga Layanan" />

			<Container fluid>
				<Row className="justify-content-center">
					<Col xs={12}>
						<Card>
							<CardBody>
								<div className="d-flex justify-content-between align-items-center">
									<h3 className="mb-0">Harga Layanan</h3>
									<div className="d-flex gap-2">
										<div className="position-relative">
											<Input
												onChange={debouncedHandleSearch}
												placeholder="Pencarian..."
												className="pe-5"
											/>
											<CiSearch
												size={24}
												className="position-absolute"
												style={{ top: '20%', right: '5%' }}
											/>
										</div>
										<Button
											color="success"
											className="px-4"
											onClick={() =>
												navigate('/service-price/add-service-price')
											}
										>
											Tambah
										</Button>
									</div>
								</div>
								<hr className="border border-light border-2 opacity-50"></hr>
								<TableService
									dataHeader={headerListPriceService}
									dataRow={dataPrices?.data}
									dataSource={'master-price'}
									actionButton={ActionButtonPrices}
								/>
								<PaginationButton
									metaPages={dataPrices}
									setPage={setPage}
									currentPage={page}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>

		</>
	)
}

export default PricesService
