import React from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { store } from "./app/store.jsx";
import './assets/scss/theme.scss';
import "./index.css";
import Routing from "./router/router.jsx";
import 'react-datepicker/dist/react-datepicker.css'

const helmetContext = {};
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <HelmetProvider context={helmetContext}>
        <Routing />
      </HelmetProvider>
    </Provider>
  </React.StrictMode>
);
