import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import HeaderContent from './HeaderContent'
import Sidebar from './Sidebar'
import { useCheckLoginAdminQuery } from '../../features/auth/api/LoginAdminAPI'
import { useCheckLoginClientQuery } from '../../features/auth/api/LoginUserAPI'
import { useDispatch } from 'react-redux'
import { setTableKeyword } from '../../app/layoutSlice'

const AuthLayout = (props) => {
	const { type, children } = props
	const location = useLocation()

	const dispatch = useDispatch();

	const checkrole = JSON.parse(localStorage.getItem('data'))

	const isClient = checkrole?.role === 'client'

	const { pathname } = location
	const disabledBreadcrumbs = ['/', '/dashboard-client']
	const {
		error: errorCheckToken,
		refetch: refetchCheckToken
	} = !isClient ? useCheckLoginAdminQuery() : useCheckLoginClientQuery()

	useEffect(() => {
		if (errorCheckToken) {
			redirectToLogin(type)
		}
	}, [errorCheckToken])

	useEffect(() => {
		if (children) {
			// Define a function to refetch the query
			const handleRefetch = () => {
				refetchCheckToken
			}


			dispatch(setTableKeyword(''))
			// Add event listeners for focus and blur events on the window
			window.addEventListener('focus', handleRefetch)

			// Clean up the event listener when the component unmounts
			return () => {
				window.removeEventListener('focus', handleRefetch)
			}
		}
	}, [children])

	const redirectToLogin = (type) => {
		const url =
			type === 'admin_pusat' || type === 'admin_cabang'
				? '/login-admin'
				: '/login-client'

		localStorage.clear()
		window.location.replace(url)
	}

	return (
		<>
			<div id="layout-wrapper">
				<HeaderContent />
				<Sidebar />
				<div className="main-content">
					<div
						className={classnames({
							'page-content': pathname !== '/',
							'page-content-no-breadcrumb':
								disabledBreadcrumbs.includes(pathname),
						})}
					>
						{children}
					</div>
				</div>
			</div>
		</>
	)
}

AuthLayout.propTypes = {
	children: PropTypes.any,
}

export default AuthLayout
