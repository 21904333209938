import React, { useEffect, useState } from 'react'
import { FiUpload } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import { Col, FormGroup, Input, Label, Row } from 'reactstrap'
import {
	setForm10Data,
	setForm10FileData,
	surveyStateSelector,
} from '../../../../app/surveySlice'
import TherapyCount from '../TherapyCount'
import { useParams } from 'react-router-dom'
import { useGetSurveyFormValueQuery } from '../../api/surveyApi'

const FormStep10 = () => {
	const { childId } = useParams()
	const dispatch = useDispatch()
	const [forms10Data, setForms10Data] = useState({})
	const [filesData, setFilesData] = useState({})
	const [filename, setFilename] = useState([])
	const { child_id } = useSelector(surveyStateSelector)
	const { data: fetchForm10Value } = useGetSurveyFormValueQuery(
		{
			type: 10,
			child_id: childId,
		},
		{ skip: !childId }
	)
	const { data: form10Value } = fetchForm10Value?.data ?? {}

	useEffect(() => {
		const initForms10Data = {
			type: 10,
			child_id: childId ? childId : null,
			mulai_kesekolah_formal_usia: '',
			sekolah_informal_usia: '',
			nama_sekolah_formal_yang_pernah_diikuti: '',
			nama_sekolah_informal_yang_pernah_diikuti: '',
			anak_belajar_dengan_membaca_sendiri: '',
			anak_belajar_dengan_cara_dibacakan_oleh_orang_tua: '',
			nilai_rata2_di_sekolah: '',
			nilai_tertinggi: '',
			mata_pelajaran_nilai_tertinggi: '',
			nilai_terendah: '',
			mata_pelajaran_nilai_terendah: ' ',
			keluhan_guru: '',
			pernah_melakukan_terapi_sebelumnya: '',
			pernah_melakukan_terapi_sebelumnya_usia: '',
			pernah_melakukan_terapi_sebelumnya_tempat: '',
			pernah_melakukan_terapi_sebelumnya_diagnosa: '',
			terapi: [],
			keterangan_tambahan_lainnya: '',
		}
		const initFilesData = {
			type: 10,
			file_hasil_pemeriksaan_bera: '',
			file_hasil_pemeriksaan_eeg: '',
			file_hasil_pemeriksaan_ct_scan: '',
			file_program_terapi_tiga_bulan_terakhir: '',
			file_hasil_pemeriksaan_psikologis: '',
		}
		setForms10Data(initForms10Data)
		setFilesData(initFilesData)
	}, [])

	useEffect(() => {
		const timer = setTimeout(() => {
			dispatch(setForm10FileData({ ...filesData, child_id }))
		}, 1000)

		return () => clearTimeout(timer)
	}, [filesData, child_id])

	useEffect(() => {
		const timer = setTimeout(() => {
			dispatch(setForm10Data({ ...forms10Data, child_id }))
		}, 1000)

		return () => clearTimeout(timer)
	}, [forms10Data, child_id])

	useEffect(() => {
		if (form10Value) {
			setForms10Data({ ...forms10Data, ...form10Value })
		}
	}, [form10Value])

	const inputHandle = (value, inputName) => {
		setForms10Data({ ...forms10Data, [inputName]: value, child_id })
	}

	const handleFileChange = (e, index, inputName) => {
		const file = e.target.files[0]
		if (file) {
			const updatedFilename = [...filename]
			updatedFilename[index] = file.name
			setFilename(updatedFilename)
			convertToBase64(file, inputName)
		}
	}

	const convertToBase64 = (file, inputName) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => {
			setFilesData({ ...filesData, [inputName]: reader.result, child_id })
		}
		reader.onerror = (error) => {
			console.error('Error converting file to Base64:', error)
		}
	}

	return (
		<>
			<Row>
				<Col lg="12">
					<h5 className="fw-bold">Riwayat Pendidikan Formal & Informal</h5>
					<p>Isi sesuai kondisi Anak</p>
				</Col>
			</Row>
			<Row className="mt-3 mb-2">
				<Col lg="6">
					<FormGroup>
						<Label>Mulai ke sekolah formal usia</Label>
						<Input
							type="text"
							className="form-control bg-light"
							placeholder="Masukkan usia"
							name="mulai_sekolah_formal"
							defaultValue={forms10Data?.mulai_kesekolah_formal_usia ?? ''}
							onChange={(e) =>
								inputHandle(+e.target.value, 'mulai_kesekolah_formal_usia')
							}
						/>
					</FormGroup>
				</Col>
				<Col lg="6">
					<FormGroup>
						<Label>Nama sekolah formal yg pernah diikuti</Label>
						<Input
							type="text"
							className="form-control bg-light"
							placeholder="Masukkan nama sekolah"
							name="nama_sekolah_formal"
							defaultValue={
								forms10Data?.nama_sekolah_formal_yang_pernah_diikuti ?? ''
							}
							onChange={(e) =>
								inputHandle(
									e.target.value,
									'nama_sekolah_formal_yang_pernah_diikuti'
								)
							}
						/>
					</FormGroup>
				</Col>
				<Col lg="6">
					<FormGroup>
						<Label>Sekolah informal usia</Label>
						<Input
							type="text"
							className="form-control bg-light"
							placeholder="Masukkan usia"
							name="usia_sekolah_informal"
							defaultValue={forms10Data?.sekolah_informal_usia ?? ''}
							onChange={(e) =>
								inputHandle(e.target.value, 'sekolah_informal_usia')
							}
						/>
					</FormGroup>
				</Col>
				<Col lg="6">
					<FormGroup>
						<Label>Nama sekolah informal yg pernah diikuti</Label>
						<Input
							type="text"
							className="form-control bg-light"
							placeholder="Masukkan nama sekolah"
							name="nama_sekolah_informal"
							defaultValue={
								forms10Data?.nama_sekolah_informal_yang_pernah_diikuti ?? ''
							}
							onChange={(e) =>
								inputHandle(
									e.target.value,
									'nama_sekolah_informal_yang_pernah_diikuti'
								)
							}
						/>
					</FormGroup>
				</Col>
				<Col lg="12">
					<FormGroup>
						<Label>Cara belajar</Label>
						<Row>
							<Col lg="6">
								<FormGroup check>
									<Input
										type="checkbox"
										name="baca_buku"
										id="baca_buku"
										value={1}
										checked={
											forms10Data?.anak_belajar_dengan_membaca_sendiri === 1
										}
										onChange={(e) =>
											inputHandle(
												e.target.checked ? 1 : 0,
												'anak_belajar_dengan_membaca_sendiri'
											)
										}
									/>
									<Label for="baca_buku">
										Anak belajar dengan cara membaca buku sendiri
									</Label>
								</FormGroup>
							</Col>
							<Col lg="6">
								<FormGroup check>
									<Input
										type="checkbox"
										name="dibacakan_orang_tua"
										id="dibacakan_orang_tua"
										value={1}
										checked={
											forms10Data?.anak_belajar_dengan_cara_dibacakan_oleh_orang_tua ===
											1
										}
										onChange={(e) =>
											inputHandle(
												e.target.checked ? 1 : 0,
												'anak_belajar_dengan_cara_dibacakan_oleh_orang_tua'
											)
										}
									/>
									<Label for="dibacakan_orang_tua">
										Anak belajar dengan cara dibacakan oleh orangtua
									</Label>
								</FormGroup>
							</Col>
						</Row>
					</FormGroup>
				</Col>
				<Col lg="12">
					<Row>
						<Col lg="6">
							<FormGroup>
								<Label>Nilai rata-rata di sekolah</Label>
								<Input
									// type="text"
									type="number"
									step="0.1"
									min="0"
									className="form-control bg-light"
									placeholder="Nilai rata-rata dalam angka"
									name="nilai_average_sekolah"
									defaultValue={forms10Data?.nilai_rata2_di_sekolah ?? ''}
									onChange={
										(e) => {
											const setRata2DS = parseInt(e.target.value) < 0 ? 0 : parseInt(e.target.value)
											inputHandle(+setRata2DS, 'nilai_rata2_di_sekolah')
										}
									}
								/>
								<div className='mt-1 text-secondary'>
									Mohon gunakan simbol titik(.) jika terdapat koma atau bukan bilangan bulat, contoh: 20.5
								</div>
							</FormGroup>
						</Col>
					</Row>
				</Col>
				<Col lg="6">
					<FormGroup>
						<Label>Nilai tertinggi</Label>
						<Input
							type="text"
							className="form-control bg-light"
							placeholder="Nilai rata-rata dalam angka"
							name="nilai_tertinggi_sekolah"
							defaultValue={forms10Data?.nilai_tertinggi ?? ''}
							onChange={(e) => inputHandle(+e.target.value, 'nilai_tertinggi')}
						/>
					</FormGroup>
				</Col>
				<Col lg="6">
					<FormGroup>
						<Label>Mata pelajaran</Label>
						<Input
							type="text"
							className="form-control bg-light"
							placeholder="Masukkan nama mata pelajaran"
							name="mata_pelajaran_tertinggi"
							defaultValue={forms10Data?.mata_pelajaran_nilai_tertinggi ?? ''}
							onChange={(e) =>
								inputHandle(e.target.value, 'mata_pelajaran_nilai_tertinggi')
							}
						/>
					</FormGroup>
				</Col>
				<Col lg="6">
					<FormGroup>
						<Label>Nilai terendah</Label>
						<Input
							type="text"
							className="form-control bg-light"
							placeholder="Nilai rata-rata dalam angka"
							name="nilai_terendah_sekolah"
							defaultValue={forms10Data?.nilai_terendah ?? ''}
							onChange={(e) => inputHandle(+e.target.value, 'nilai_terendah')}
						/>
					</FormGroup>
				</Col>
				<Col lg="6">
					<FormGroup>
						<Label>Mata pelajaran</Label>
						<Input
							type="text"
							className="form-control bg-light"
							placeholder="Masukkan nama mata pelajaran"
							name="mata_pelajaran_terendah"
							defaultValue={forms10Data?.mata_pelajaran_nilai_terendah ?? ''}
							onChange={(e) =>
								inputHandle(e.target.value, 'mata_pelajaran_nilai_terendah')
							}
						/>
					</FormGroup>
				</Col>
				<Col lg="12">
					<FormGroup>
						<Label className="fw-bold">Keluhan Guru</Label>
						<Input
							type="textarea"
							name="keluhan_guru"
							placeholder="Masukkan keterangan"
							rows={10}
							defaultValue={forms10Data?.keluhan_guru ?? ''}
							onChange={(e) => inputHandle(e.target.value, 'keluhan_guru')}
						/>
					</FormGroup>
				</Col>
			</Row>
			<hr className="border border-light border-2 opacity-50" />
			<Row>
				<Col lg="12">
					<h5 className="fw-bold">Riwayat Terapi</h5>
				</Col>
				<Col lg="12">
					<FormGroup check>
						<Input
							type="checkbox"
							name="pernah_melakukan_terapi_sebelumnya"
							id="pernah_melakukan_terapi_sebelumnya"
							checked={forms10Data?.pernah_melakukan_terapi_sebelumnya === 1}
							onChange={(e) =>
								inputHandle(
									e.target.checked ? 1 : 0,
									'pernah_melakukan_terapi_sebelumnya'
								)
							}
						/>
						<Label for="pernah_melakukan_terapi_sebelumnya">
							Pernah melalukan terapi sebelumnya
						</Label>
					</FormGroup>
				</Col>
				<Col lg="6" xs="12">
					<FormGroup>
						<Label>Tempat</Label>
						<Input
							type="text"
							name="pernah_melakukan_terapi_sebelumnya_tempat"
							className="form-control bg-light"
							placeholder="Masukkan nama tempat"
							defaultValue={forms10Data?.pernah_melakukan_terapi_sebelumnya_tempat ?? ''}
							onChange={(e) =>
								inputHandle(
									e.target.value,
									'pernah_melakukan_terapi_sebelumnya_tempat'
								)
							}
						/>
					</FormGroup>
				</Col>
				<Col lg="6" xs="12">
					<FormGroup>
						<Label>Usia</Label>
						<Input
							type="text"
							name="pernah_melakukan_terapi_sebelumnya_usia"
							className="form-control bg-light"
							placeholder="Masukkan usia mulai terapi"
							defaultValue={forms10Data?.pernah_melakukan_terapi_sebelumnya_usia ?? ''}
							onChange={(e) =>
								inputHandle(
									+e.target.value,
									'pernah_melakukan_terapi_sebelumnya_usia'
								)
							}
						/>
					</FormGroup>
				</Col>
				<Col lg="12 mb-3">
					<Label className="fw-bold">Diagnosa</Label>
					<Input
						type="textarea"
						className="form-control"
						name="pernah_melakukan_terapi_sebelumnya_diagnosa"
						rows={10}
						placeholder="Masukkan Keterangan"
						defaultValue={forms10Data?.pernah_melakukan_terapi_sebelumnya_diagnosa ?? ''}
						onChange={(e) =>
							inputHandle(
								e.target.value,
								'pernah_melakukan_terapi_sebelumnya_diagnosa'
							)
						}
					/>
				</Col>
				<Col lg="12">
					<h5 className="fw-bold">Riwayat Terapi</h5>
					<p>Masukkan jam mulai dan tanggal mulai terapi</p>
					<TherapyCount therapyData={forms10Data?.terapi} />
				</Col>
			</Row>
			<hr className="border border-light border-2 opacity-50" />
			<Row>
				<Col lg="12">
					<h5 className="fw-bold mb-3">Lampirkan file jika ada</h5>
				</Col>
				<Col lg="6">
					<FormGroup>
						<Label>Hasil pemeriksaan EEG</Label>
						<div>
							<input
								type="file"
								placeholder="Klik untuk upload"
								id="eeg"
								name="eeg_file"
								hidden
								onChange={(e) =>
									handleFileChange(e, 0, 'file_hasil_pemeriksaan_eeg')
								}
							/>
							<label
								htmlFor="eeg"
								className="bg-light w-100 p-2 rounded border border-dark-subtle"
							>
								<div className="d-flex justify-content-between align-items-center ">
									<p className="mb-0 text-secondary">
										{filename[0] ?? 'Klik untuk upload'}
									</p>
									<FiUpload />
								</div>
							</label>
						</div>
					</FormGroup>
				</Col>
				<Col lg="6">
					<FormGroup>
						<Label>Hasil pemeriksaan BERA</Label>
						<div>
							<input
								type="file"
								placeholder="Klik untuk upload"
								id="bera"
								name="bera_file"
								hidden
								onChange={(e) =>
									handleFileChange(e, 1, 'file_hasil_pemeriksaan_bera')
								}
							/>
							<label
								htmlFor="bera"
								className="bg-light w-100 p-2 rounded border border-dark-subtle"
							>
								<div className="d-flex justify-content-between align-items-center ">
									<p className="mb-0 text-secondary">
										{filename[1] ?? 'Klik untuk upload'}
									</p>
									<FiUpload />
								</div>
							</label>
						</div>
					</FormGroup>
				</Col>
				<Col lg="6">
					<FormGroup>
						<Label>CT-Scan</Label>
						<div>
							<input
								type="file"
								placeholder="Klik untuk upload"
								id="ct_scan"
								name="ct_scan_file"
								hidden
								onChange={(e) =>
									handleFileChange(e, 2, 'file_hasil_pemeriksaan_ct_scan')
								}
							/>
							<label
								htmlFor="ct_scan"
								className="bg-light w-100 p-2 rounded border border-dark-subtle"
							>
								<div className="d-flex justify-content-between align-items-center ">
									<p className="mb-0 text-secondary">
										{' '}
										{filename[2] ?? 'Klik untuk upload'}
									</p>
									<FiUpload />
								</div>
							</label>
						</div>
					</FormGroup>
				</Col>
				<Col lg="6">
					<FormGroup>
						<Label>Program terapi 3 bulan terakhir</Label>
						<div>
							<input
								type="file"
								placeholder="Klik untuk upload"
								id="last_3_m_therapy"
								name="last_3_m_therapy_file"
								hidden
								onChange={(e) =>
									handleFileChange(
										e,
										3,
										'file_program_terapi_tiga_bulan_terakhir'
									)
								}
							/>
							<label
								htmlFor="last_3_m_therapy"
								className="bg-light w-100 p-2 rounded border border-dark-subtle"
							>
								<div className="d-flex justify-content-between align-items-center ">
									<p className="mb-0 text-secondary">
										{' '}
										{filename[3] ?? 'Klik untuk upload'}
									</p>
									<FiUpload />
								</div>
							</label>
						</div>
					</FormGroup>
				</Col>
				<Col lg="6">
					<FormGroup>
						<Label>Hasil pemeriksaan psikologis/psikiatris</Label>
						<div>
							<input
								type="file"
								placeholder="Klik untuk upload"
								id="psikologi_psikiatris"
								name="psikologi_psikiatris_file"
								hidden
								onChange={(e) =>
									handleFileChange(e, 4, 'file_hasil_pemeriksaan_psikologis')
								}
							/>
							<label
								htmlFor="psikologi_psikiatris"
								className="bg-light w-100 p-2 rounded border border-dark-subtle"
							>
								<div className="d-flex justify-content-between align-items-center ">
									<p className="mb-0 text-secondary">
										{' '}
										{filename[4] ?? 'Klik untuk upload'}
									</p>
									<FiUpload />
								</div>
							</label>
						</div>
					</FormGroup>
				</Col>
				<Col lg="12">
					<FormGroup>
						<Label className="fw-bold">Keterangan tambahan Lainnya</Label>
						<Input
							type="textarea"
							name="keterangan_lain_pola_makan"
							placeholder="Masukkan keterangan"
							rows={10}
							defaultValue={forms10Data?.keterangan_tambahan_lainnya ?? ""}
							onChange={(e) =>
								inputHandle(e.target.value, 'keterangan_tambahan_lainnya')
							}
						/>
					</FormGroup>
				</Col>
			</Row>
		</>
	)
}

export default FormStep10
