import React from 'react'
import { BiSolidTrashAlt } from 'react-icons/bi'
import { FaEye } from 'react-icons/fa6'
import { useNavigate, useParams } from 'react-router-dom'

const ActionButtonInvoice = ({ invoiceId }) => {
	const navigate = useNavigate()
	const checkrole = JSON.parse(localStorage.getItem('data'))
	const isClient = checkrole?.role === 'client'
	return (
		<div className="d-flex gap-2">
			<button
				className="btn text-primary p-0 btn-light p-1"
				onClick={() => navigate(`/invoice-customer/${invoiceId}`)}
			>
				<FaEye size={16} />
			</button>
			{!isClient && (
				<button
					className="btn text-primary p-0 btn-light p-1"
					// onClick={toggleModal}
				>
					<BiSolidTrashAlt size={16} />
				</button>
			)}
		</div>
	)
}

export default ActionButtonInvoice
