import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { CiLock } from 'react-icons/ci'
import { HiOutlineEye, HiOutlineEyeOff, HiOutlineMail } from 'react-icons/hi'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import {
	Alert,
	Button,
	Card,
	CardBody,
	Col,
	Form,
	Label,
	Navbar,
	Row,
} from 'reactstrap'
import * as yup from 'yup'
import { setToken } from '../../app/authSlice'
import dailyActivity from '../../assets/images/daily-activity.png'
import HelmetTitle from '../../components/templates/HelmetTitle'
import { usePostUserLoginMutation } from './api/LoginUserAPI'

const NewLoginClient = () => {
	const [isShowPassword, setIsShowPassword] = useState(false)
	const [postUserLogin, { isLoading, error }] = usePostUserLoginMutation()

	const navigate = useNavigate()
	const dispatch = useDispatch()

	const handlerShowHidePassword = () => {
		setIsShowPassword(isShowPassword ? false : true)
	}

	const schema = yup.object({
		email: yup
			.string()
			.email('Email harus berformat email!')
			.required('Email wajib di isi!'),
		password: yup.string().required('Kata sandi wajib di isi!'),
	})

	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			email: '',
			password: '',
		},
	})

	const handleOnSubmit = async (param) => {
		try {
			let url = ''
			const result = await postUserLogin(param).unwrap()

			const {
				token = 'lksizvxcvk2923992',
				name,
				email,
			} = result?.data ?? ''

			if (result.status !== 'ok') {
				throw new Error(result?.message ?? 'Something went wrong')
			}

			localStorage.setItem('token', token)
			dispatch(setToken(token))


			url = '/dashboard-client'
			localStorage.setItem(
				'data',
				JSON.stringify({ name: name, email: email, role: 'client' })
			)


			navigate(url)
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<>
			<HelmetTitle title="Masuk | Orang Tua" />
			{/* header */}
			<Navbar
				fixed="top"
				style={{ zIndex: 999 }}
				className="bg-white border-bottom border-warning px-2 px-md-3 px-lg-5"
			>
				<Link to="https://klinikyamet.com/" className="navbar-brand">
					<img
						alt="logo"
						src="/logo-yamet.png"
						style={{
							height: 35,
							width: 35,
						}}
					/>
				</Link>
				<Button
					color="success"
					size="md"
					className="px-4"
					onClick={() => navigate('/login-admin')}
				>
					Login Admin
				</Button>
			</Navbar>
			{/* end header */}

			{/* content form */}
			<Row className="justify-content-center align-self-center mt-12 px-2 px-lg-12 min-vh-100 bg-white">
				<Col className="d-none d-lg-inline-block" md={6} lg={6} xl={6}>
					<img
						src={dailyActivity}
						className="img-fluid rounded-3 shadow"
						alt="activity"
					/>
				</Col>
				<Col md={6} lg={6} xl={6}>
					<Card className="overflow-hidden shadow-none border-0 rounded-3 align-self-center mt-2 mt-lg-5">
						<div>
							<Row>
								<Col className="col-12">
									<div className="text-center p-4">
										<h5 className="text-success fw-bold fs-2 mb-1">
											Selamat datang,
										</h5>
										<span className="fs-5 fw-medium">
											silakan masuk ke akun Anda
										</span>
									</div>
								</Col>
							</Row>
						</div>
						<CardBody className="pt-0">
							{error && (
								<Row>
									<Col>
										<Alert color="danger">
											<small className="text-danger">
												{error.data.message}
											</small>
										</Alert>
									</Col>
								</Row>
							)}
							<div className="p-0 p-lg-2">
								<Form
									onSubmit={handleSubmit(handleOnSubmit)}
									className="form-horizontal"
								>
									<div className="mb-3">
										<Label>Email</Label>
										<div className="input-group mb-2">
											<span
												className={`input-group-text bg-transparent border-start border-top border-bottom border-end-0 rounded-start-3 ${errors.email && 'border-danger'
													}`}
											>
												<HiOutlineMail size="1.2rem" />
											</span>
											<Controller
												name="email"
												control={control}
												render={({ field: { onChange, onBlur, value } }) => {
													return (
														<input
															type="email"
															className={`form-control border-end border-top border-bottom border-start-0 rounded-end-3 ${errors.email && 'border-danger'
																}`}
															onChange={onChange}
															onBlur={onBlur}
															value={value}
															placeholder="Masukkan Email Anda"
															aria-label="email"
														/>
													)
												}}
											/>
										</div>
										<div className="mb-3 mt-0">
											<span className="mt-0 text-danger text-start">
												{errors.email?.message}
											</span>
										</div>
									</div>

									<div className="mb-3">
										<Label>Kata Sandi</Label>
										<div className="input-group mb-3">
											<span
												className={`input-group-text bg-transparent border-start border-top border-bottom rounded-start-3 ${errors.password && 'border-danger'
													}`}
											>
												<CiLock size="1.2rem" />
											</span>
											<Controller
												name="password"
												control={control}
												render={({ field: { onChange, onBlur, value } }) => {
													return (
														<input
															type={isShowPassword ? 'text' : 'password'}
															className={`form-control border-end-0 border-top border-bottom border-start-0 rounded-0 ${errors.password && 'border-danger'
																}`}
															onChange={onChange}
															onBlur={onBlur}
															value={value}
															placeholder="Masukkan Password"
															aria-label="password"
														/>
													)
												}}
											/>

											<button
												className={`btn btn-outline-transparent border-top border-end border-bottom border-start-0 rounded-end-3 ${errors.password && 'border-danger'
													}`}
												type="button"
												onClick={handlerShowHidePassword}
											>
												{isShowPassword ? (
													<HiOutlineEyeOff size="1.2rem" />
												) : (
													<HiOutlineEye size="1.2rem" />
												)}
											</button>
										</div>
									</div>
									<Row>
										<Col className="col-12">
											<div className="text-end">
												<Link
													to="/reset-password-client"
													className="text-secondary mb-1"
												>
													Lupa Kata Sandi
												</Link>
											</div>
										</Col>
										<Col className="col-12">
											<Button
												block
												color="success"
												className="mt-3 rounded-3"
												disabled={isLoading}
											>
												Masuk
											</Button>
										</Col>
									</Row>
								</Form>
							</div>
						</CardBody>
					</Card>
				</Col>
			</Row>
			{/* end content form */}
		</>
	)
}

export default NewLoginClient
