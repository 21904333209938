import React from 'react'

const ActiveBadge = ({ status }) => {
	const statusDisplayName = +status === 1 ? 'Aktif' : 'Tidak Aktif'

	if (+status === 1) {
		return (
			<div
				className="bg-success d-inline-flex text-white py-1 px-3 rounded"
			>
				{statusDisplayName}
			</div>
		)
	} else {
		return (
			<div
				className="d-inline-flex text-white py-1 px-3 rounded"
				style={{backgroundColor: '#FFA800'}}
			>
				{statusDisplayName}
			</div>
		)
	}
}

export default ActiveBadge
