import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
	Alert,
	Button,
	Card,
	CardBody,
	Col,
	Form,
	Input,
	Label,
	Row,
} from 'reactstrap'
import { useGetMasterDataQuery } from '../../general/api/masterApi'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useGetListServiceQuery } from '../../services/api/listServiceApi'
import { useDispatch, useSelector } from 'react-redux'
import { authSetupSelector } from '../../../app/authSlice'
import {
	useCreatePatientMutation,
	useGetChildByIdQuery,
	useUpdateChildMutation,
} from '../api/patientApi'
import { useSearchUsersQuery } from '../../general/api/userApi'
import styled from 'styled-components'
import { setForm3Data } from '../../../app/surveySlice'
import { customerStateSelector } from '../../../app/customerSlice'

const initSubmitStatus = {
	status: false,
	type: '',
	message: '',
	headerMessage: '',
}

const CustomerDropDown = styled.li`
	cursor: pointer;
	padding: 5px;

	&:hover {
		background-color: #1b7bc7;
		color: #ffffff;
	}
`

const FormPatient = ({ editForm, childData }) => {
	const { id } = useParams()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { pathname } = useLocation()
	const [submitStatus, setSubmitStatus] = useState(initSubmitStatus)
	const [searchTerm, setSearchTerm] = useState('')
	const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('')
	const [selectedCustomer, setSelectedCustomer] = useState({
		user_id: 0,
		name: '',
	})
	const isSurvey =
		pathname === '/form-survey/add' ||
		pathname.includes('draft') ||
		pathname.includes('/form-survey/summary')
	const { branch_id } = useSelector(authSetupSelector)
	const { user_id } = useSelector(customerStateSelector)

	const [createPatient, { isLoading: createPatientLoading }] =
		useCreatePatientMutation()
	const [updateChild, { isLoading: updatePatientLoading }] =
		useUpdateChildMutation()
	const { data: fetchUserSearch, error: searchError } =
		useSearchUsersQuery(debouncedSearchTerm)
	const { data: fetchChildById, refetch } = useGetChildByIdQuery(id, {
		skip: !id,
	})
	const { data: fetchReligion } = useGetMasterDataQuery({
		endpoint: 'religion',
	})
	const { data: dataReligion } = fetchReligion ?? []
	const { data: fetchBranchs } = useGetListServiceQuery({
		endpoint: 'branchs',
		page: 1,
	})
	const { data: dataBranchs } = fetchBranchs?.data ?? []
	const { data: dataChildById } = fetchChildById ?? {}
	const { data: filteredUser = [] } = fetchUserSearch ?? []

	const {
		control,
		handleSubmit,
		reset,
		watch,
		setValue,
		formState: { errors },
	} = useForm({
		defaultValues: {},
	})

	useEffect(() => {
		reset({ branch_id: branch_id ?? 0, status: 1 })
	}, [branch_id])

	// ! Set field value in admin
	useEffect(() => {
		if (dataChildById) {
			reset({ ...dataChildById })
			setSelectedCustomer({
				user_id: dataChildById?.user_id,
				name: '',
			})
			refetch()
		}
	}, [dataChildById])

	// ! Set field value in user/client
	useEffect(() => {
		if (childData) {
			reset({ ...childData })
			setSelectedCustomer({
				user_id: childData?.user_id,
				name: '',
			})
		}
	}, [childData])

	// Debounce delay in milliseconds
	const debounceDelay = 500

	useEffect(() => {
		// Update the debounced search term after the specified delay
		const debounceTimer = setTimeout(() => {
			setDebouncedSearchTerm(searchTerm)
		}, debounceDelay)

		// Cleanup the timer on component unmount or when the searchTerm changes
		return () => {
			clearTimeout(debounceTimer)
		}
	}, [searchTerm])

	useEffect(() => {
		if (selectedCustomer.name) {
			setValue('customer_name', selectedCustomer.name)
		}
	}, [selectedCustomer])

	useEffect(() => {
		if (isSurvey) {
			const subscription = watch((value) => {
				dispatch(setForm3Data({ for: 'child', data: { user_id, ...value } }))
			})
			return () => {
				subscription.unsubscribe()
			}
		}
	}, [watch, user_id])

	const handlePatientSubmit = async (param) => {
		setSubmitStatus(initSubmitStatus)
		try {
			if (!selectedCustomer.user_id) {
				throw new Error('Select customer first')
			}
			const payload = {
				...param,
				user_id: selectedCustomer?.user_id,
			}

			if (editForm) {
				const result = await updateChild(payload).unwrap()
				const { message } = result

				setSubmitStatus({
					status: true,
					type: 'success',
					message,
					headerMessage: 'Berhasil'
				})
				setTimeout(() => {
					navigate('/data-patient')
				}, 1000)
				return
			}

			const result = await createPatient(payload).unwrap()
			const { message } = result

			setSubmitStatus({
				status: true,
				type: 'success',
				message,
				headerMessage: 'Berhasil',
			})
			setTimeout(() => {
				navigate('/data-patient')
			}, 1000)
		} catch (error) {
			let msgErr = '';
			if (error.status === 422) {
				for (const property in error.data.data) {
					msgErr += error.data.data[property][0] + "\n";
				}
			}

			if (error.status !== 422) {
				msgErr = error.data.message
			}

			setSubmitStatus({
				status: true,
				type: 'danger',
				message: msgErr,
				headerMessage: 'Terjadi Kesalahan',
			})
		}
	}

	const handleCustomerChange = (e) => {
		setSelectedCustomer({
			id: 0,
			name: '',
		})
		setSearchTerm(e.target.value)
	}

	const handleSelectCustomer = (value) => {
		setSearchTerm('')
		setSelectedCustomer(value)
	}

	return (
		<>
			{submitStatus.status && (
				<Alert color={submitStatus?.type ?? 'danger'} className="mb-4">
					<h3 className="alert-heading">
						{submitStatus?.headerMessage}
					</h3>
					<p
						className="mb-0"
						style={{ whiteSpace: 'pre-line' }}
					>
						{submitStatus?.message ?? 'error message here'}
					</p>
				</Alert>
			)}
			<Form onSubmit={handleSubmit(handlePatientSubmit)}>
				<Row>
					<Col lg="6">
						<h5 className="fw-bold mb-3">DATA ANAK</h5>
						{!editForm && !pathname.includes('draft') && (
							<Row className="p-1 mb-3">
								<Label className="col-form-label col-md-3 text-truncate">
									Customer
								</Label>
								<div className="col-md-9">
									<Controller
										name="customer_name"
										control={control}
										render={({ field: { onChange, onBlur, value } }) => (
											<>
												<Input
													type="text"
													className="form-control bg-light"
													placeholder="Cari customer"
													onChange={(e) => onChange(handleCustomerChange(e))}
													onBlur={onBlur}
													value={value}
													invalid={errors.user_id ? true : false}
													disabled={editForm || Boolean(childData)}
												/>
												{searchTerm && (
													<Card>
														<CardBody className="border border-light p-1">
															{filteredUser.length === 0 ? (
																<small className="mb-0">
																	Customer tidak ditemukan
																</small>
															) : (
																<ul className="list-unstyled mb-0">
																	{filteredUser?.map((item, idx) => (
																		<CustomerDropDown
																			key={idx}
																			className="list-customer-dropdown"
																			onClick={() =>
																				handleSelectCustomer({
																					user_id: item.id,
																					name: item.name,
																				})
																			}
																		>
																			{item.name} -{' '}
																			<span className="fw-bold">
																				({item.email})
																			</span>
																		</CustomerDropDown>
																	))}
																</ul>
															)}
														</CardBody>
													</Card>
												)}
												{errors && (
													<small className="text-danger">
														{errors?.user_id?.message}
													</small>
												)}
											</>
										)}
									/>
								</div>
							</Row>
						)}
						<Row className="p-1 mb-3">
							<Label className="col-form-label col-md-3 text-truncate">
								Nama Lengkap
							</Label>
							<div className="col-md-9">
								<Controller
									name="full_name"
									control={control}
									render={({ field: { onChange, onBlur, value } }) => (
										<>
											<Input
												type="text"
												className="form-control bg-light"
												placeholder="Masukkan Nama Lengkap"
												onChange={onChange}
												onBlur={onBlur}
												value={value}
												invalid={errors.full_name ? true : false}
											/>
											{errors && (
												<small className="text-danger">
													{errors?.full_name?.message}
												</small>
											)}
										</>
									)}
								/>
							</div>
						</Row>
						<Row className="p-1 mb-3">
							<Label className="col-form-label col-md-3 text-truncate">
								Nama Panggilan
							</Label>
							<div className="col-md-9">
								<Controller
									name="nickname"
									control={control}
									render={({ field: { onChange, onBlur, value } }) => (
										<>
											<Input
												type="text"
												className="form-control bg-light"
												placeholder="Masukkan Nama Panggilan"
												onChange={onChange}
												onBlur={onBlur}
												value={value}
												invalid={errors.nickname ? true : false}
											/>
											{errors && (
												<small className="text-danger">
													{errors?.nickname?.message}
												</small>
											)}
										</>
									)}
								/>
							</div>
						</Row>
						<Row className="p-1 mb-3">
							<Label className="col-form-label col-md-3 text-truncate">
								Jenis Kelamin
							</Label>
							<div className="col-md-9">
								<Controller
									name="gender"
									control={control}
									render={({ field: { onChange, onBlur, value } }) => (
										<>
											<Input
												type="select"
												className="form-control bg-light"
												placeholder="Masukkan Nama Panggilan"
												onChange={onChange}
												onBlur={onBlur}
												value={value}
												invalid={errors.gender ? true : false}
											>
												<option value="0">Pilih Jenis Kelamin</option>
												<option value="male">Laki-laki</option>
												<option value="female">Perempuan</option>
											</Input>
											{errors && (
												<small className="text-danger">
													{errors?.gender?.message}
												</small>
											)}
										</>
									)}
								/>
							</div>
						</Row>
						<Row className="p-1 mb-3">
							<Label className="col-form-label col-md-3">Tempat Lahir</Label>
							<div className="col-md-9">
								<Controller
									name="address_of_birth"
									control={control}
									render={({ field: { onChange, onBlur, value } }) => (
										<>
											<Input
												type="text"
												className="form-control bg-light"
												placeholder="Masukkan Tempat Lahir"
												onChange={onChange}
												onBlur={onBlur}
												value={value}
												invalid={errors.address_of_birth ? true : false}
											/>
											{errors && (
												<small className="text-danger">
													{errors?.address_of_birth?.message}
												</small>
											)}
										</>
									)}
								/>
							</div>
						</Row>
						<Row className="p-1 mb-3">
							<Label className="col-form-label col-md-3">Tanggal Lahir</Label>
							<div className="col-md-9">
								<Controller
									name="date_of_birth"
									control={control}
									render={({ field: { onChange, onBlur, value } }) => (
										<>
											<Input
												type="date"
												className="form-control bg-light"
												placeholder="Masukkan Tempat Lahir"
												onChange={onChange}
												onBlur={onBlur}
												value={value}
												invalid={errors.date_of_birth ? true : false}
											/>
											{errors && (
												<small className="text-danger">
													{errors?.date_of_birth?.message}
												</small>
											)}
										</>
									)}
								/>
							</div>
						</Row>
						{!childData && (
							<Row className="p-1 mb-3">
								<Label className="col-form-label col-md-3 text-truncate">
									Cabang
								</Label>
								<div className="col-md-9">
									<Controller
										name="branch_id"
										control={control}
										render={({ field: { onChange, onBlur, value } }) => (
											<>
												<Input
													type="select"
													className="form-control bg-light"
													placeholder="Masukkan Nama Panggilan"
													onChange={onChange}
													onBlur={onBlur}
													value={value}
													invalid={errors.branch_id ? true : false}
													disabled={Boolean(branch_id)}
												>
													<option value="0">Pilih Cabang</option>
													{dataBranchs?.map((branch, idx) => (
														<option value={branch.id} key={idx}>
															{branch.branch_name}
														</option>
													))}
												</Input>
												{errors && (
													<small className="text-danger">
														{errors?.branch_id?.message}
													</small>
												)}
											</>
										)}
									/>
								</div>
							</Row>
						)}
					</Col>
					<Col lg={6}>
						<h5 className="fw-bold mb-3">DATA TAMBAHAN</h5>
						<Row className="p-1 mb-3">
							<Label className="col-form-label col-md-3">Agama</Label>
							<div className="col-md-9">
								<Controller
									name="religion_id"
									control={control}
									render={({ field: { onChange, onBlur, value } }) => (
										<>
											<Input
												type="select"
												className="form-control bg-light"
												placeholder="Masukkan Agama"
												onChange={onChange}
												onBlur={onBlur}
												value={value}
												invalid={errors.religion_id ? true : false}
											>
												<option value="0">Pilih</option>
												{dataReligion?.map((item) => (
													<option value={item.id} key={item.id}>
														{item.name}
													</option>
												))}
											</Input>
											{errors && (
												<small className="text-danger">
													{errors?.religion_id?.message}
												</small>
											)}
										</>
									)}
								/>
							</div>
						</Row>
						<Row className="p-1 mb-3">
							<Label className="col-form-label col-md-3">Suku Bangsa</Label>
							<div className="col-md-9">
								<Controller
									name="clan"
									control={control}
									render={({ field: { onChange, onBlur, value } }) => (
										<>
											<Input
												type="text"
												className="form-control bg-light"
												placeholder="Masukkan Suku Bangsa"
												onChange={onChange}
												onBlur={onBlur}
												value={value}
												invalid={errors.clan ? true : false}
											/>
											{errors && (
												<small className="text-danger">
													{errors?.clan?.message}
												</small>
											)}
										</>
									)}
								/>
							</div>
						</Row>
						<Row className="p-1 mb-3">
							<Label className="col-form-label col-md-3">Jumlah Saudara</Label>
							<div className="col-md-9">
								<Controller
									name="count_sibling"
									control={control}
									render={({ field: { onChange, onBlur, value } }) => (
										<>
											<Input
												type="number"
												className="form-control bg-light"
												placeholder="Masukkan Jumlah Saudara"
												onChange={onChange}
												onBlur={onBlur}
												value={value}
												invalid={errors.count_sibling ? true : false}
												min={0}
											/>
											{errors && (
												<small className="text-danger">
													{errors?.count_sibling?.message}
												</small>
											)}
										</>
									)}
								/>
							</div>
						</Row>
						<Row className="p-1 mb-3">
							<Label className="col-form-label col-md-3">
								Anak ke - Berapa
							</Label>
							<div className="col-md-9">
								<Controller
									name="sort_child"
									control={control}
									render={({ field: { onChange, onBlur, value } }) => (
										<>
											<Input
												type="number"
												className="form-control bg-light"
												placeholder="Masukkan Keterangan Anak"
												onChange={onChange}
												onBlur={onBlur}
												value={value}
												invalid={errors.sort_child ? true : false}
												min={0}
											/>
											{errors && (
												<small className="text-danger">
													{errors?.sort_child?.message}
												</small>
											)}
										</>
									)}
								/>
							</div>
						</Row>
						<Row className="p-1 mb-3">
							<Label className="col-form-label col-md-3">Sekolah / Kelas</Label>
							<div className="col-md-9">
								<Controller
									name="school_or_class"
									control={control}
									render={({ field: { onChange, onBlur, value } }) => (
										<>
											<Input
												type="text"
												className="form-control bg-light"
												placeholder="Masukkan Info Sekolah"
												onChange={onChange}
												onBlur={onBlur}
												value={value}
												invalid={errors.school_or_class ? true : false}
											/>
											{errors && (
												<small className="text-danger">
													{errors?.school_or_class?.message}
												</small>
											)}
										</>
									)}
								/>
							</div>
						</Row>
						<Row className="p-1 mb-3">
							<Label className="col-form-label col-md-3 text-truncate">
								Status
							</Label>
							<div className="col-md-9">
								<Controller
									name="status"
									control={control}
									render={({ field: { onChange, onBlur, value } }) => (
										<>
											<Input
												type="select"
												className="form-control bg-light"
												onChange={onChange}
												onBlur={onBlur}
												value={value}
												invalid={errors.nickname ? true : false}
												disabled={!editForm}
											>
												<option value="1">Aktif</option>
												<option value="0">Tidak Aktif</option>
											</Input>
											{errors && (
												<small className="text-danger">
													{errors?.status?.message}
												</small>
											)}
										</>
									)}
								/>
							</div>
						</Row>
					</Col>
					{!isSurvey && (
						<Col>
							<div className="d-flex justify-content-end gap-2">
								<Button
									color="primary"
									onClick={() => navigate('/data-patient')}
								>
									Batal
								</Button>
								<Button
									color="success"
									className="px-4"
									type="submit"
									disabled={createPatientLoading}
								>
									Simpan
								</Button>
							</div>
						</Col>
					)}
				</Row>
			</Form>
		</>
	)
}

export default FormPatient
