import React from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, FormGroup, Input, Label, Row } from 'reactstrap'
import { setForm7Data, surveyStateSelector } from '../../../../app/surveySlice'
import { useEffect } from 'react'
import { useGetSurveyFormValueQuery } from '../../api/surveyApi'
import { useParams } from 'react-router-dom'

const FormStep7 = () => {
	const { childId } = useParams()
	const dispatch = useDispatch()
	const [forms7Data, setForms7Data] = useState({})
	const { child_id } = useSelector(surveyStateSelector)
	const { data: fetchForm7Value } = useGetSurveyFormValueQuery(
		{
			type: 7,
			child_id: childId,
		},
		{ skip: !childId }
	)
	const { data: form7Value } = fetchForm7Value?.data ?? {}

	useEffect(() => {
		const initForms7Data = {
			child_id: childId ? childId : null,
			type: 7,
			reflek_vokalisasi: 0,
			reflek_vokalisasi_usia: '',
			bubbling: 0,
			bubbling_usia: '',
			laling: 0,
			laling_usia: '',
			echolalia: 0,
			echolalia_usia: '',
			mengucap_satu_kata: 0,
			mengucap_satu_kata_usia: '',
			true_speech: 0,
			true_speech_usia: '',
			mengungkapkan_keinginan: 0,
			mengungkapkan_keinginan_usia: '',
			bercerita: 0,
			bercerita_usia: '',
			tertarik_dengan_lingkungan_luar: 0,
			tertarik_dengan_lingkungan_luar_age: '',
			mau_digendong_oleh_siapa_saja: 0,
			mau_digendong_oleh_siapa_saja_age: '',
			mampu_interaksi_timbal_balik: 0,
			mampu_interaksi_timbal_balik_age: '',
			mampu_komunikasi_terhadap_ekspresi_ibu: 0,
			mampu_komunikasi_terhadap_ekspresi_ibu_age: '',
			mampu_berekspresi_senyum_senang_marah: 0,
			mampu_berekspresi_senyum_senang_marah_age: '',
			mengeces: 0,
			makan_makanan_keras: 0,
			makan_makanan_berkuah: 0,
			pilih_pilih_makanan: 0,
			makan_diemut: 0,
			mengunyah_makanan_saat_makan: 0,
			makan_langsung_ditelan: 0,
			teratur: 0,
			pantangan_makan: 0,
			keterangan_pantangan_makan: '',
			keterangan_lainnya: '',
		}
		setForms7Data(initForms7Data)
	}, [child_id])

	useEffect(() => {
		const timer = setTimeout(() => {
			dispatch(setForm7Data(forms7Data))
		}, 1000)

		return () => clearTimeout(timer)
	}, [forms7Data])

	useEffect(() => {
		if (form7Value) {
			setForms7Data({ ...forms7Data, ...form7Value })
		}
	}, [form7Value])

	const inputHandle = (value, inputName) => {
		setForms7Data({ ...forms7Data, [inputName]: value, child_id })
	}

	return (
		<>
			<Row>
				<Col lg="12">
					<h5 className="fw-bold">Perkembangan Bahasa Anak</h5>
					<p>Centang yang dialami Anak</p>
				</Col>
			</Row>
			<Row className="mt-3 mb-2">
				<Col lg="6">
					<Row className="align-items-center">
						<Col lg="4">
							<FormGroup check>
								<Input
									type="checkbox"
									name="reflek_vokalisasi"
									id="reflect_vokal"
									value="1"
									checked={forms7Data?.reflek_vokalisasi === 1}
									onChange={(e) =>
										inputHandle(e.target.checked ? 1 : 0, 'reflek_vokalisasi')
									}
								/>
								<Label for="reflect_vokal">
									Reflek vokalisasi (spt lenguhan)
								</Label>
							</FormGroup>
						</Col>
						<Col lg="8">
							<FormGroup>
								<Input
									type="text"
									className="form-control bg-light"
									name="reflek_vokalisasi_usia"
									placeholder="Usia (dalam bulan)"
									defaultValue={forms7Data?.reflek_vokalisasi_usia ?? ''}
									onChange={(e) =>
										inputHandle(+e.target.value, 'reflek_vokalisasi_usia')
									}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row className="align-items-center">
						<Col lg="4">
							<FormGroup check>
								<Input
									type="checkbox"
									name="bubbling"
									id="bubbling"
									value="1"
									checked={forms7Data?.bubbling === 1}
									onChange={(e) =>
										inputHandle(e.target.checked ? 1 : 0, 'bubbling')
									}
								/>
								<Label for="bubbling">Bubbling (bababa …. Mamama )</Label>
							</FormGroup>
						</Col>
						<Col lg="8">
							<FormGroup>
								<Input
									type="text"
									className="form-control bg-light"
									name="bubbling_age"
									placeholder="Usia (dalam bulan)"
									defaultValue={forms7Data?.bubbling_usia ?? ''}
									onChange={(e) =>
										inputHandle(+e.target.value, 'bubbling_usia')
									}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row className="align-items-center">
						<Col lg="4">
							<FormGroup check>
								<Input
									type="checkbox"
									name="laling"
									id="laling"
									value="1"
									checked={forms7Data?.laling === 1}
									onChange={(e) =>
										inputHandle(e.target.checked ? 1 : 0, 'laling')
									}
								/>
								<Label for="laling">
									Laling (lebih jelas mengucapkan satu suku kata)
								</Label>
							</FormGroup>
						</Col>
						<Col lg="8">
							<FormGroup>
								<Input
									type="text"
									className="form-control bg-light"
									name="laling_age"
									placeholder="Usia (dalam bulan)"
									defaultValue={forms7Data?.laling_usia ?? ''}
									onChange={(e) => inputHandle(+e.target.value, 'laling_usia')}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row className="align-items-center">
						<Col lg="4">
							<FormGroup check>
								<Input
									type="checkbox"
									name="echolalia"
									id="echolalia"
									value="1"
									checked={forms7Data?.echolalia === 1}
									onChange={(e) =>
										inputHandle(e.target.checked ? 1 : 0, 'echolalia')
									}
								/>
								<Label for="echolalia">Echolalia (meniru ucapan)</Label>
							</FormGroup>
						</Col>
						<Col lg="8">
							<FormGroup>
								<Input
									type="text"
									className="form-control bg-light"
									name="echolalia_age"
									placeholder="Usia (dalam bulan)"
									defaultValue={forms7Data?.echolalia_usia ?? ''}
									onChange={(e) =>
										inputHandle(+e.target.value, 'echolalia_usia')
									}
								/>
							</FormGroup>
						</Col>
					</Row>
				</Col>
				<Col lg="6">
					<Row className="align-items-center">
						<Col lg="4">
							<FormGroup check>
								<Input
									type="checkbox"
									name="ucap_1_kata"
									id="ucap_1_kata"
									value="1"
									checked={forms7Data?.mengucap_satu_kata === 1}
									onChange={(e) =>
										inputHandle(e.target.checked ? 1 : 0, 'mengucap_satu_kata')
									}
								/>
								<Label for="ucap_1_kata">Mengucap 1 kata</Label>
							</FormGroup>
						</Col>
						<Col lg="8">
							<FormGroup>
								<Input
									type="text"
									className="form-control bg-light"
									name="ucap_1_kata_age"
									placeholder="Usia (dalam bulan)"
									defaultValue={forms7Data?.mengucap_satu_kata_usia ?? ''}
									onChange={(e) =>
										inputHandle(+e.target.value, 'mengucap_satu_kata_usia')
									}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row className="align-items-center">
						<Col lg="4">
							<FormGroup check>
								<Input
									type="checkbox"
									name="true_speech"
									id="true_speech"
									value="1"
									checked={forms7Data?.true_speech === 1}
									onChange={(e) =>
										inputHandle(e.target.checked ? 1 : 0, 'true_speech')
									}
								/>
								<Label for="true_speech">
									True speech (mengucap kata dan menger)
								</Label>
							</FormGroup>
						</Col>
						<Col lg="8">
							<FormGroup>
								<Input
									type="text"
									className="form-control bg-light"
									name="true_speech_age"
									placeholder="Usia (dalam bulan)"
									defaultValue={forms7Data?.true_speech_usia ?? ''}
									onChange={(e) =>
										inputHandle(+e.target.value, 'true_speech_usia')
									}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row className="align-items-center">
						<Col lg="4">
							<FormGroup check>
								<Input
									type="checkbox"
									name="keinginan"
									id="keinginan"
									value="1"
									checked={forms7Data?.mengungkapkan_keinginan === 1}
									onChange={(e) =>
										inputHandle(
											e.target.checked ? 1 : 0,
											'mengungkapkan_keinginan'
										)
									}
								/>
								<Label for="keinginan">
									Mengungkapkan keinginan minimal 2 kata
								</Label>
							</FormGroup>
						</Col>
						<Col lg="8">
							<FormGroup>
								<Input
									type="text"
									className="form-control bg-light"
									name="keinginan_age"
									placeholder="Usia (dalam bulan)"
									defaultValue={forms7Data?.mengungkapkan_keinginan_usia ?? ''}
									onChange={(e) =>
										inputHandle(+e.target.value, 'mengungkapkan_keinginan_usia')
									}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row className="align-items-center">
						<Col lg="4">
							<FormGroup check>
								<Input
									type="checkbox"
									name="bercerita"
									id="bercerita"
									value="1"
									checked={forms7Data?.bercerita === 1}
									onChange={(e) =>
										inputHandle(e.target.checked ? 1 : 0, 'bercerita')
									}
								/>
								<Label for="bercerita">Bercerita</Label>
							</FormGroup>
						</Col>
						<Col lg="8">
							<FormGroup>
								<Input
									type="text"
									className="form-control bg-light"
									name="bercerita_age"
									placeholder="Usia (dalam bulan)"
									defaultValue={forms7Data?.bercerita_usia}
									onChange={(e) =>
										inputHandle(+e.target.value, 'bercerita_usia')
									}
								/>
							</FormGroup>
						</Col>
					</Row>
				</Col>
			</Row>
			<hr className="border border-light border-2 opacity-50" />
			<Row>
				<Col lg="12">
					<h5 className="fw-bold">Perkembangan Emosi</h5>
					<p>Centang yang dialami Anak</p>
				</Col>
			</Row>
			<Row className="mt-3 mb-2">
				<Col lg="6">
					<Row className="align-items-center">
						<Col lg="4">
							<FormGroup check>
								<Input
									type="checkbox"
									name="tertarik_lingkungan_luar"
									id="tertarik_lingkungan_luar"
									value="1"
									checked={forms7Data?.tertarik_dengan_lingkungan_luar === 1}
									onChange={(e) =>
										inputHandle(
											e.target.checked ? 1 : 0,
											'tertarik_dengan_lingkungan_luar'
										)
									}
								/>
								<Label for="tertarik_lingkungan_luar">
									Tertarik dengan lingkungan luar (mainan dan wajah orang)
								</Label>
							</FormGroup>
						</Col>
						<Col lg="8">
							<FormGroup>
								<Input
									type="text"
									className="form-control bg-light"
									name="tertarik_lingkungan_luar_age"
									placeholder="Usia (dalam bulan)"
									defaultValue={
										forms7Data?.tertarik_dengan_lingkungan_luar_age ?? ''
									}
									onChange={(e) =>
										inputHandle(
											+e.target.value,
											'tertarik_dengan_lingkungan_luar_age'
										)
									}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row className="align-items-center">
						<Col lg="4">
							<FormGroup check>
								<Input
									type="checkbox"
									name="mau_digendong_oleh_siapa_saja"
									id="mau_digendong"
									value="1"
									checked={forms7Data?.mau_digendong_oleh_siapa_saja === 1}
									onChange={(e) =>
										inputHandle(
											e.target.checked ? 1 : 0,
											'mau_digendong_oleh_siapa_saja'
										)
									}
								/>
								<Label for="mau_digendong">Mau digendong oleh siapa saja</Label>
							</FormGroup>
						</Col>
						<Col lg="8">
							<FormGroup>
								<Input
									type="text"
									className="form-control bg-light"
									name="mau_digendong_age"
									placeholder="Usia (dalam bulan)"
									defaultValue={
										forms7Data?.mau_digendong_oleh_siapa_saja_age ?? ''
									}
									onChange={(e) =>
										inputHandle(
											+e.target.value,
											'mau_digendong_oleh_siapa_saja_age'
										)
									}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row className="align-items-center">
						<Col lg="4">
							<FormGroup check>
								<Input
									type="checkbox"
									name="interaksi_timbal_balik"
									id="interaksi_timbal_balik"
									value="1"
									checked={forms7Data?.mampu_interaksi_timbal_balik === 1}
									onChange={(e) =>
										inputHandle(
											e.target.checked ? 1 : 0,
											'mampu_interaksi_timbal_balik'
										)
									}
								/>
								<Label for="interaksi_timbal_balik">
									Mampu interaksi timbal balik
								</Label>
							</FormGroup>
						</Col>
						<Col lg="8">
							<FormGroup>
								<Input
									type="text"
									className="form-control bg-light"
									name="interaksi_timbal_balik_age"
									placeholder="Usia (dalam bulan)"
									defaultValue={
										forms7Data?.mampu_interaksi_timbal_balik_age ?? ''
									}
									onChange={(e) =>
										inputHandle(
											+e.target.value,
											'mampu_interaksi_timbal_balik_age'
										)
									}
								/>
							</FormGroup>
						</Col>
					</Row>
				</Col>
				<Col lg="6">
					<Row className="align-items-center">
						<Col lg="4">
							<FormGroup check>
								<Input
									type="checkbox"
									name="komunikasi_dgn_ibu"
									id="komunikasi_dgn_ibu"
									value="1"
									checked={
										forms7Data?.mampu_komunikasi_terhadap_ekspresi_ibu === 1
									}
									onChange={(e) =>
										inputHandle(
											e.target.checked ? 1 : 0,
											'mampu_komunikasi_terhadap_ekspresi_ibu'
										)
									}
								/>
								<Label for="komunikasi_dgn_ibu">
									Mampu komunikasi terhadap ekspresi ibu
								</Label>
							</FormGroup>
						</Col>
						<Col lg="8">
							<FormGroup>
								<Input
									type="text"
									className="form-control bg-light"
									name="komunikasi_dgn_ibu_age"
									placeholder="Usia (dalam bulan)"
									defaultValue={
										forms7Data?.mampu_komunikasi_terhadap_ekspresi_ibu_age ?? ''
									}
									onChange={(e) =>
										inputHandle(
											+e.target.value,
											'mampu_komunikasi_terhadap_ekspresi_ibu_age'
										)
									}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row className="align-items-center">
						<Col lg="4">
							<FormGroup check>
								<Input
									type="checkbox"
									name="ekspresi_emosi"
									id="ekspresi_emosi"
									value="1"
									checked={
										forms7Data?.mampu_berekspresi_senyum_senang_marah === 1
									}
									onChange={(e) =>
										inputHandle(
											e.target.checked ? 1 : 0,
											'mampu_berekspresi_senyum_senang_marah'
										)
									}
								/>
								<Label for="ekspresi_emosi">
									Mampu mengekspresikan tersenyum, senang, marah
								</Label>
							</FormGroup>
						</Col>
						<Col lg="8">
							<FormGroup>
								<Input
									type="text"
									className="form-control bg-light"
									name="ekspresi_emosi_age"
									placeholder="Usia (dalam bulan)"
									defaultValue={
										forms7Data?.mampu_berekspresi_senyum_senang_marah_age
									}
									onChange={(e) =>
										inputHandle(
											+e.target.value,
											'mampu_berekspresi_senyum_senang_marah_age'
										)
									}
								/>
							</FormGroup>
						</Col>
					</Row>
				</Col>
			</Row>
			<hr className="border border-light border-2 opacity-50" />
			<Row>
				<Col lg="12">
					<h5 className="fw-bold">Perkembangan Bahasa Anak</h5>
					<p>Centang yang dialami Anak</p>
				</Col>
			</Row>
			<Row className="mt-3 mb-2">
				<Col lg="6">
					<FormGroup check>
						<Input
							type="checkbox"
							name="mengeces"
							id="mengeces"
							value="1"
							checked={forms7Data?.mengeces === 1}
							onChange={(e) =>
								inputHandle(e.target.checked ? 1 : 0, 'mengeces')
							}
						/>
						<Label for="mengeces">Mengeces</Label>
					</FormGroup>
					<FormGroup check>
						<Input
							type="checkbox"
							name="makanan_keras"
							id="makanan_keras"
							value="1"
							checked={forms7Data?.makan_makanan_keras === 1}
							onChange={(e) =>
								inputHandle(e.target.checked ? 1 : 0, 'makan_makanan_keras')
							}
						/>
						<Label for="makanan_keras">Makan makanan keras</Label>
					</FormGroup>
					<FormGroup check>
						<Input
							type="checkbox"
							name="makanan_berkuah"
							id="makanan_berkuah"
							value="1"
							checked={forms7Data?.makan_makanan_berkuah === 1}
							onChange={(e) =>
								inputHandle(e.target.checked ? 1 : 0, 'makan_makanan_berkuah')
							}
						/>
						<Label for="makanan_berkuah">Makan makanan berkuah</Label>
					</FormGroup>
					<FormGroup check>
						<Input
							type="checkbox"
							name="pilih_makanan"
							id="pilih_makanan"
							value="1"
							checked={forms7Data?.pilih_pilih_makanan === 1}
							onChange={(e) =>
								inputHandle(e.target.checked ? 1 : 0, 'pilih_pilih_makanan')
							}
						/>
						<Label for="pilih_makanan">Pilih-pilih makanan</Label>
					</FormGroup>
				</Col>
				<Col lg="6">
					<FormGroup check>
						<Input
							type="checkbox"
							name="makanan_diemut"
							id="makanan_diemut"
							value="1"
							checked={forms7Data?.makan_diemut === 1}
							onChange={(e) =>
								inputHandle(e.target.checked ? 1 : 0, 'makan_diemut')
							}
						/>
						<Label for="makanan_diemut">Makan di-emut</Label>
					</FormGroup>
					<FormGroup check>
						<Input
							type="checkbox"
							name="mengunyah_makanan"
							id="mengunyah_makanan"
							value="1"
							checked={forms7Data?.mengunyah_makanan_saat_makan === 1}
							onChange={(e) =>
								inputHandle(
									e.target.checked ? 1 : 0,
									'mengunyah_makanan_saat_makan'
								)
							}
						/>
						<Label for="mengunyah_makanan">Mengunyah makanan saat makan</Label>
					</FormGroup>
					<FormGroup check>
						<Input
							type="checkbox"
							name="makanan_langsung_telan"
							id="makanan_langsung_telan"
							value="1"
							checked={forms7Data?.makan_langsung_ditelan === 1}
							onChange={(e) =>
								inputHandle(e.target.checked ? 1 : 0, 'makan_langsung_ditelan')
							}
						/>
						<Label for="makanan_langsung_telan">Makan langsung ditelan</Label>
					</FormGroup>
				</Col>
			</Row>
			<hr className="border border-light border-2 opacity-50" />
			<Row>
				<Col lg="12">
					<h5 className="fw-bold mb-3">Pola Makan</h5>
				</Col>
				<Col lg="12">
					<FormGroup check>
						<Input
							type="checkbox"
							name="teratur"
							id="teratur"
							value="1"
							checked={forms7Data?.teratur === 1}
							onChange={(e) => inputHandle(e.target.checked ? 1 : 0, 'teratur')}
						/>
						<Label for="teratur">Teratur</Label>
					</FormGroup>
				</Col>
				<Col lg="6">
					<FormGroup check>
						<Input
							type="checkbox"
							name="pantangan_makan"
							id="pantangan_makan"
							value="1"
							checked={forms7Data?.pantangan_makan === 1}
							onChange={(e) =>
								inputHandle(e.target.checked ? 1 : 0, 'pantangan_makan')
							}
						/>
						<Label for="pantangan_makan">Pantangan makan</Label>
					</FormGroup>
				</Col>
				<Col lg="6">
					<FormGroup>
						<Input
							type="text"
							className="form-control bg-light"
							name="pantangan_makan_reason"
							placeholder="Jika iya, sebutkan"
							defaultValue={forms7Data?.keterangan_pantangan_makan ?? ''}
							onChange={(e) =>
								inputHandle(e.target.value, 'keterangan_pantangan_makan')
							}
						/>
					</FormGroup>
				</Col>
				<Col lg="12">
					<FormGroup>
						<Label>Keterangan Lainnya</Label>
						<Input
							type="textarea"
							name="keterangan_lain_pola_makan"
							placeholder="Masukkan keterangan"
							rows={10}
							defaultValue={forms7Data?.keterangan_lainnya ?? ''}
							onChange={(e) =>
								inputHandle(e.target.value, 'keterangan_lainnya')
							}
						/>
					</FormGroup>
				</Col>
			</Row>
		</>
	)
}

export default FormStep7
