import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
const { VITE_BASE_URL } = import.meta.env

const getToken = (state) => state.authSetup.token

export const userApi = createApi({
	reducerPath: 'userApi',
	tagTypes: ['userApi'],
	baseQuery: fetchBaseQuery({
		baseUrl: `${VITE_BASE_URL}/v1/admin/`,
		prepareHeaders: (headers, { getState }) => {
			const token = getToken(getState())
			if (token) {
				headers.set('Authorization', `Bearer ${token}`)
			}
			return headers
		},
	}),
	endpoints: (builder) => ({
		getAllUsers: builder.query({
			query: ({ page, keyword }) => ({
				url: `users?page=${page}&keyword=${keyword}`,
			}),
			invalidatesTags: ['GetAllUsers'],
		}),
		getUserById: builder.query({
			query: (id) => ({
				url: `users/${id}`,
			}),
			invalidatesTags: ['GetUserById'],
		}),
		postUser: builder.mutation({
			query: (body) => ({
				url: `users`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['PostUser'],
		}),
		updateUser: builder.mutation({
			query: ({ user_id, ...rest }) => ({
				url: `users/${user_id}`,
				method: 'PUT',
				body: rest,
			}),
			invalidatesTags: ['UpdateUser'],
		}),
		deleteUser: builder.mutation({
			query: (id) => ({
				url: `users/${id}`,
				method: 'DELETE',
			}),
			providesTags: ['DeleteCustomer'],
		}),
		searchUsers: builder.query({
			query: (name) => `/users?name=${name}`,
		}),
	}),
	refetchOnMountOrArgChange: true,
	refetchOnFocus: true,
})

export const {
	usePostUserMutation,
	useGetAllUsersQuery,
	useDeleteUserMutation,
	useUpdateUserMutation,
	useGetUserByIdQuery,
	useSearchUsersQuery
} = userApi
