import classnames from 'classnames'
import React, { useEffect, useState } from 'react'
import {
	Card,
	CardBody,
	Col,
	NavItem,
	NavLink,
	Row,
	TabContent,
	TabPane,
} from 'reactstrap'
import HelmetTitle from '../../../components/templates/HelmetTitle'
import FormCustomer from './Form/FormCustomer'
import FormParents from './Form/FormParents'
import { useGetUserByIdQuery } from '../../general/api/userApi'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setUserId } from '../../../app/customerSlice'

const TabFormCustomer = ({ editForm }) => {
	const { id } = useParams()
	const dispatch = useDispatch()
	const [activeTab, setActiveTab] = useState(1)
	const [passedSteps, setPassedSteps] = useState([1])
	const { data: fetchDataUser, refetch } = useGetUserByIdQuery(id, {
		skip: !id,
	})

	const { data: dataUserById } = fetchDataUser ?? {}
	const { parent } = dataUserById ?? {}

	function toggleTab(tab) {
		if (activeTab !== tab) {
			var modifiedSteps = [...passedSteps, tab]
			if (tab >= 1 && tab <= 4) {
				setActiveTab(tab)
				setPassedSteps(modifiedSteps)
			}
		}
	}

	useEffect(() => {
		if (editForm) {
			setPassedSteps([1, 2, 3])
		}
	}, [editForm])

	useEffect(() => {
		if (editForm) {
			refetch()
		}
	}, [refetch])

	useEffect(() => {
		if (id) {
			dispatch(setUserId(id))
		}
	}, [id])

	return (
		<>
			<HelmetTitle title={!editForm ? `Tambah Orang Tua` : `Edit Orang Tua`} />
			<Row>
				<Col xs={12}>
					<Card>
						<CardBody>
							<h4 className="mb-0">
								{!editForm ? `Tambah Orang Tua` : `Edit Orang Tua`}
							</h4>
						</CardBody>
					</Card>
				</Col>
				<Col xs={12} className="mb-4">
					<div className="wizard clearfix">
						<div className="steps clearfix">
							<ul className="gap-4">
								<NavItem className={classnames({ current: activeTab === 1 })}>
									<NavLink
										className={classnames({ current: activeTab === 1 })}
										onClick={() => {
											setActiveTab(1)
										}}
										disabled={!(passedSteps || []).includes(1)}
									>
										<span className="number">1.</span> Data Customer
									</NavLink>
								</NavItem>
								<NavItem className={classnames({ current: activeTab === 2 })}>
									<NavLink
										className={classnames({ active: activeTab === 2 })}
										onClick={() => {
											setActiveTab(2)
										}}
										disabled={!(passedSteps || []).includes(2)}
									>
										<span className="number">2.</span> Data Ayah
									</NavLink>
								</NavItem>
								<NavItem className={classnames({ current: activeTab === 3 })}>
									<NavLink
										className={classnames({ active: activeTab === 3 })}
										onClick={() => {
											setActiveTab(3)
										}}
										disabled={!(passedSteps || []).includes(3)}
									>
										<span className="number">3.</span> Data Ibu
									</NavLink>
								</NavItem>
							</ul>
						</div>
					</div>
				</Col>
				<Col lg="12">
					<Card>
						<CardBody>
							<div className="wizard clearfix">
								<div className="content clearfix">
									<TabContent activeTab={activeTab} className="body pt-0">
										<TabPane tabId={1}>
											<FormCustomer
												editForm={editForm}
												activeTab={activeTab}
												setActiveTab={setActiveTab}
												toggleTab={toggleTab}
												refetch={refetch}
												dataUserById={dataUserById}
											/>
										</TabPane>
										<TabPane tabId={2}>
											<FormParents
												editForm={editForm}
												type={`father`}
												activeTab={activeTab}
												setActiveTab={setActiveTab}
												toggleTab={toggleTab}
												dataParent={parent?.father ?? {}}
											/>
										</TabPane>
										<TabPane tabId={3}>
											<FormParents
												editForm={editForm}
												type={`mother`}
												activeTab={activeTab}
												setActiveTab={setActiveTab}
												toggleTab={toggleTab}
												dataParent={parent?.mother ?? {}}
											/>
										</TabPane>
									</TabContent>
								</div>
							</div>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</>
	)
}

export default TabFormCustomer
