import { useState } from 'react'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6'
import { useParams } from 'react-router-dom'
import { Button, ButtonGroup, Table } from 'reactstrap'
import {
	formatDateToIndonesian,
	getMonthNameIndonesian,
	reformatDate,
} from '../../../helpers/GlobalHelper'
import { useGetScheduleDataQuery } from '../../general/api/clientApi'
import { useGetScheduleByChildIdQuery } from '../api/patientApi'

const ScheduleStatus = ({ status }) => {
	if (status === 'Sudah') {
		return (
			<div className="bg-success text-white d-inline-flex py-1 px-3 rounded fw-bold">
				Sudah
			</div>
		)
	} else if (status === 'Belum') {
		return (
			<div className="bg-info text-white d-inline-flex py-1 px-3 rounded fw-bold">
				Belum
			</div>
		)
	} else if (status !== 1) {
		return (
			<div className="bg-danger text-white d-inline-flex py-1 px-3 rounded fw-bold">
				Batal
			</div>
		)
	}

	return <div>Status</div>
}

const TableSchedule = () => {
	const { id } = useParams()
	const date = new Date()
	const year = new Date().getFullYear()
	const checkrole = JSON.parse(localStorage.getItem('data'))
	const [month, setMonth] = useState(date.getMonth() + 1)
	const { data: fetchSchedulesData } = useGetScheduleDataQuery(month, {
		skip: checkrole?.role !== 'client',
	})

	const {
		data: fetchScheduleByChild,
		error,
		isLoading,
	} = useGetScheduleByChildIdQuery(
		{
			child_id: id,
			month,
		},
		{ skip: !id || checkrole?.role === 'client' }
	)

	const { data: dataSchedules } =
		fetchScheduleByChild ?? fetchSchedulesData ?? []

	const toggleMonth = (nav) => {
		if (nav === 'prev') {
			setMonth(month - 1)
		}

		if (nav === 'next') {
			setMonth(month + 1)
		}
	}

	if (isLoading) {
		return <div className="text-center">Loading...</div>
	}

	if (error) {
		return <div className="text-center">Error Fetching Data</div>
	}

	return (
		<>
			<div className="d-flex justify-content-between align-items-center mb-3">
				<h5 className="fw-bold mb-0">
					{getMonthNameIndonesian(month)} {year}
				</h5>
				<div className="d-flex gap-2">
					<Button
						size="sm"
						color="primary"
						onClick={() => setMonth(date.getMonth() + 1)}
					>
						Bulan Ini
					</Button>
					<ButtonGroup>
						<Button
							size="sm"
							color="primary"
							onClick={() => toggleMonth('prev')}
							disabled={month === 1}
						>
							<FaChevronLeft />
						</Button>
						<Button
							size="sm"
							color="primary"
							onClick={() => toggleMonth('next')}
							disabled={month === 12}
						>
							<FaChevronRight />
						</Button>
					</ButtonGroup>
				</div>
			</div>
			<Table>
				<thead>
					<tr>
						<th>No</th>
						<th>Tanggal</th>
						<th>Jam</th>
						<th>Layanan Terapi</th>
						<th>Nama Terapis</th>
						<th className="text-center">Status Kelas</th>
					</tr>
				</thead>
				<tbody className="align-middle">
					{dataSchedules?.map((valueSchedule, idx) => (
						<tr key={idx}>
							<th scope={'row'}>{idx + 1}</th>
							<td>{formatDateToIndonesian(valueSchedule.date)}</td>
							<td>
								{reformatDate(`${valueSchedule?.schedule_date[0]?.date} ${valueSchedule?.schedule_date[0]?.time_from}`, 'LT')} {' - '}
								{reformatDate(`${valueSchedule?.schedule_date[0]?.date} ${valueSchedule?.schedule_date[0]?.time_to}`, 'LT')}
							</td>
							<td>{valueSchedule?.service?.service_name}</td>
							<td>{valueSchedule?.therapist?.therapist_name}</td>
							<td className="text-center">
								<ScheduleStatus status={valueSchedule.status} />
							</td>
						</tr>
					))}
					{(dataSchedules?.length === 0 || !dataSchedules) && (
						<tr>
							<td colSpan={6} className="text-center">
								Tidak Ada Jadwal
							</td>
						</tr>
					)}
				</tbody>
			</Table>
		</>
	)
}

export default TableSchedule
