import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import { setPageTitle } from '../../app/layoutSlice'
import TableSchedule from '../patient/components/TableSchedule'

const ScheduleUser = () => {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(setPageTitle('Jadwal Terapi'))
	}, [])

	return (
		<Container fluid>
			<Row>
				<Col>
					<Card>
						<CardBody>
							<TableSchedule />
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	)
}

export default ScheduleUser
