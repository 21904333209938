import classnames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import {
	Alert,
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Modal,
	ModalBody,
	NavItem,
	NavLink,
	Navbar,
	Row,
	TabContent,
	TabPane,
} from 'reactstrap'
import { customerStateSelector, setUserId } from '../../../app/customerSlice'
import {
	setChildId,
	setForm3Data,
	surveyStateSelector,
} from '../../../app/surveySlice'
import HelmetTitle from '../../../components/templates/HelmetTitle'
import { checkObject } from '../../../helpers/GlobalHelper'
import {
	useCreateParentMutation,
	useUpdateParentMutation,
} from '../../customer/api/parentApi'
import FormCustomer from '../../customer/components/Form/FormCustomer'
import {
	useGetUserByIdQuery,
	usePostUserMutation,
} from '../../general/api/userApi'
import {
	useCreatePatientMutation,
	useCreateSiblingMutation,
	useGetChildByIdQuery,
	useUpdateChildMutation,
} from '../../patient/api/patientApi'
import {
	useFormUploadMutation,
	usePostFormMutation,
	usePostFormTwoMutation,
} from '../api/surveyApi'
import FormStep10 from './FormStep/FormStep10'
import FormStep2 from './FormStep/FormStep2'
import FormStep3 from './FormStep/FormStep3'
import FormStep4 from './FormStep/FormStep4'
import FormStep5 from './FormStep/FormStep5'
import FormStep6 from './FormStep/FormStep6'
import FormStep7 from './FormStep/FormStep7'
import FormStep8 from './FormStep/FormStep8'
import FormStep9 from './FormStep/FormStep9'

const TabFormSurvey = ({ editForm, draft }) => {
	const { childId } = useParams()
	const dispatch = useDispatch()
	const [activeTab, setActiveTab] = useState(1)
	const [passedSteps, setPassedSteps] = useState([1])
	const [showModal, setShowModal] = useState(false)
	const [errorSubmit, setErrorSubmit] = useState(null)
	const [userIdByChild, setUserIdByChild] = useState(null)
	const {
		child_id,
		form1Data,
		form2Data,
		form3Data,
		form4Data,
		form5Data,
		form6Data,
		form7Data,
		form8Data,
		form9Data,
		form10Data,
		form10FileData,
	} = useSelector(surveyStateSelector)
	const { user_id } = useSelector(customerStateSelector)
	const { data: fetchChildById, isLoading } = useGetChildByIdQuery(childId, {
		skip: !childId,
	})
	const { data: fetchDataUser } = useGetUserByIdQuery(userIdByChild, {
		skip: !userIdByChild,
	})

	const { data: dataByChildId } = fetchChildById ?? {}
	const { data: dataUserById } = fetchDataUser ?? {}

	const [postUser, { error: errorPostUser }] = usePostUserMutation()
	const [postFormTwo, { error: errorFormTwo }] = usePostFormTwoMutation()
	const [postForm, { error: errorPostForm }] = usePostFormMutation()
	const [formUpload, { error: errorFormUpload }] = useFormUploadMutation()
	const [createPatient, { error: errorCreatePatient }] =
		useCreatePatientMutation()
	const [createParent, { error: errorCreateParent }] = useCreateParentMutation()
	const [createSibling, { error: errorCreateSiblings }] =
		useCreateSiblingMutation()
	const [updateChild, { error: errorUpdateChild }] = useUpdateChildMutation()
	const [updateParent, { error: errorUpdateParent }] = useUpdateParentMutation()

	useEffect(() => {
		dispatch(setChildId(+childId))
	}, [])

	useEffect(() => {
		if (editForm) {
			setPassedSteps([1, 2, 3])
		}
	}, [editForm])

	useEffect(() => {
		if (draft) {
			setActiveTab(1)
			setPassedSteps([1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
		}
	}, [draft])

	useEffect(() => {
		if (dataByChildId) {
			dispatch(setUserId(dataByChildId?.user_id))
			dispatch(setForm3Data({ for: 'child', data: { ...dataByChildId } }))
			setUserIdByChild(dataByChildId?.user_id)
		}
	}, [dataByChildId])

	useEffect(() => {
		if (errorPostUser) {
			handleMutationError(errorPostUser)
		}
		if (errorFormTwo) {
			handleMutationError(errorFormTwo)
		}
		if (errorPostForm) {
			handleMutationError(errorPostForm)
		}
		if (errorCreatePatient) {
			handleMutationError(errorCreatePatient)
		}
		if (errorCreateParent) {
			handleMutationError(errorCreateParent)
		}
		if (errorFormUpload) {
			handleMutationError(errorFormUpload)
		}
		if (errorCreateSiblings) {
			handleMutationError(errorCreateSiblings)
		}
		if (errorUpdateChild) {
			handleMutationError(errorUpdateChild)
		}
		if (errorUpdateParent) {
			handleMutationError(errorUpdateParent)
		}
	}, [
		errorUpdateParent,
		errorPostUser,
		errorFormTwo,
		errorPostForm,
		errorCreatePatient,
		errorCreateParent,
		errorFormUpload,
		errorCreateSiblings,
		errorUpdateChild,
	])

	function toggle() {
		setShowModal(!showModal)
	}

	function handleMutationError(error) {
		if (error && error.data) {
			// You can access specific error fields here
			const { data } = error
			let errorMessages
			if (typeof data.data === 'string') {
				errorMessages = [data.data]
				setErrorSubmit({
					status: true,
					message: errorMessages,
				})
				return
			}
			if (data.data) {
				const dynamicKeys = Object.keys(data.data)
				const dynamicValues = Object.values(data.data)

				errorMessages = dynamicKeys.map((key, index) => {
					return { [index]: dynamicValues[index][0] }
				})

				setErrorSubmit({
					status: true,
					message: errorMessages,
				})
			}

			if (!data.data) {
				setErrorSubmit({
					status: true,
					message: [{ 0: data.message }],
				})
			}

			window.scrollTo({ top: 0, behavior: 'smooth' })
		} else {
			// Handle generic or unexpected errors here
			console.error('Unexpected error:', error)
		}
	}

	async function toggleTab(tab) {
		setErrorSubmit(false)
		switch (activeTab) {
			case 1:
				if (!user_id) {
					const resultCreateUser = await postUser(form1Data).unwrap()
					const { data, status, message } = resultCreateUser

					if (status === 'ok') {
						dispatch(setUserId(data.id))
						setActiveTab(tab)
					} else {
						throw new Error(message)
					}
				} else {
					setActiveTab(tab)
				}
				break
			case 2:
				const resultForm2 = await postFormTwo(form2Data).unwrap()
				const { status, message } = resultForm2

				if (status === 'ok') {
					setActiveTab(tab)
				} else {
					throw new Error(message)
				}
				break
			case 3:
				if (!draft) {
					if (checkObject(form3Data.child)) {
						if (!child_id) {
							const resultChild = await createPatient(form3Data.child).unwrap()
							const { status: postStatusChild, data: dataChild } = resultChild

							if (postStatusChild === 'ok') {
								dispatch(setChildId(dataChild.id))

								if (checkObject(form3Data.siblings) && dataChild.id) {
									const payload = {
										childId: dataChild.id,
										siblings: form3Data.siblings,
									}

									await createSibling(payload).unwrap()
								}
							} else {
								throw new Error('Error create child')
							}
						}

						if (checkObject(form3Data.father)) {
							await createParent(form3Data.father).unwrap()
						}

						if (checkObject(form3Data.mother)) {
							await createParent(form3Data.mother).unwrap()
						}

						setActiveTab(tab)
					} else {
						setErrorSubmit({
							status: true,
							message: [{ 0: `Child Data is Empty` }],
						})
						window.scrollTo({ top: 0, behavior: 'smooth' })
						throw new Error('Child Data is Empty')
					}
				} else {
					if (child_id) {
						const resultChild = await updateChild(form3Data.child).unwrap()
						const { status: updateStatusChild, data: dataChild } = resultChild

						if (updateStatusChild === 'ok') {
							dispatch(setChildId(dataChild.id))

							if (checkObject(form3Data.siblings) && dataChild.id) {
								const payload = {
									childId: dataChild.id,
									siblings: form3Data.siblings,
								}

								await createSibling(payload).unwrap()
							}
						} else {
							throw new Error('Error update child')
						}

						if (dataByChildId?.parent) {
							const { father, mother } = dataByChildId?.parent

							if (checkObject(form3Data.father)) {
								if (father) {
									await updateParent({
										id: father.id,
										...form3Data.father,
									}).unwrap()
								} else {
									await createParent(form3Data.father).unwrap()
								}
							}

							if (checkObject(form3Data.mother)) {
								if (mother) {
									await updateParent({
										id: mother.id,
										...form3Data.mother,
									}).unwrap()
								} else {
									await createParent(form3Data.mother).unwrap()
								}
							}
						}
					}
					setActiveTab(tab)
				}

				break
			case 4:
				const resultFormFour = await postForm(form4Data).unwrap()
				const { status: statusFormFour } = resultFormFour

				if (statusFormFour === 'ok') {
					setActiveTab(tab)
				}
				break
			case 5:
				const resultFormFive = await postForm(form5Data).unwrap()
				const { status: statusFormFive } = resultFormFive

				if (statusFormFive === 'ok') {
					setActiveTab(tab)
				}
				break
			case 6:
				const resultFormSix = await postForm(form6Data).unwrap()
				const { status: statusFormSix } = resultFormSix

				if (statusFormSix === 'ok') {
					setActiveTab(tab)
				}
			case 7:
				const resultFormSeven = await postForm(form7Data).unwrap()
				const { status: statusFormSeven } = resultFormSeven

				if (statusFormSeven === 'ok') {
					setActiveTab(tab)
				}
				break
			case 8:
				const resultFormEight = await postForm(form8Data).unwrap()
				const { status: statusFormEight } = resultFormEight

				if (statusFormEight === 'ok') {
					setActiveTab(tab)
				}
			case 9:
				const resultFormNine = await postForm(form9Data).unwrap()
				const { status: statusFormNine } = resultFormNine

				if (statusFormNine) {
					setActiveTab(tab)
				}
			default:
				console.error('default switch value')
				break
		}

		if (activeTab !== tab) {
			let modifiedSteps = [...passedSteps, tab]
			if (tab >= 1 && tab <= 10) {
				setPassedSteps(modifiedSteps)
			}
		}
	}

	async function finishSurvey() {
		const resultFormTen = await postForm(form10Data).unwrap()

		const resultUploadFormTen = await formUpload(form10FileData).unwrap()

		if (resultFormTen.status === 'ok' && resultUploadFormTen.status === 'ok') {
			setTimeout(() => {
				self.close()
			}, 1200)
		}
	}

	if (isLoading) {
		return <div className="text-center">Loading...</div>
	}

	return (
		<div className="main-content mx-auto">
			<HelmetTitle title="Form Survey" />
			<Navbar
				fixed="top"
				style={{ zIndex: 999 }}
				className="bg-white border-bottom border-warning px-2 px-md-3 px-lg-5"
			>
				<img src="/logo-yamet-2.png" alt="logo" height="50" />
			</Navbar>
			<Container fluid>
				<Row>
					<Col xs={12}>
						<Card>
							<CardBody>
								<h4 className="mb-0">Form Survey</h4>
							</CardBody>
						</Card>
					</Col>
					<div className="wizard clearfix">
						<div className="steps-survey clearfix">
							<ul className="bg-white">
								<NavItem
									className={classnames({
										'current-survey': activeTab === 1,
									})}
								>
									<NavLink
										className={classnames({
											'current-survey': activeTab === 1,
										})}
										onClick={() => {
											setActiveTab(1)
										}}
										disabled={!(passedSteps || []).includes(1)}
									>
										<span className="number">1.</span>
									</NavLink>
								</NavItem>
								<NavItem
									className={classnames({
										'current-survey': activeTab === 2,
									})}
								>
									<NavLink
										className={classnames({ active: activeTab === 2 })}
										onClick={() => {
											setActiveTab(2)
										}}
										disabled={!(passedSteps || []).includes(2)}
									>
										<span className="number">2.</span>
									</NavLink>
								</NavItem>
								<NavItem
									className={classnames({
										'current-survey': activeTab === 3,
									})}
								>
									<NavLink
										className={classnames({ active: activeTab === 3 })}
										onClick={() => {
											setActiveTab(3)
										}}
										disabled={!(passedSteps || []).includes(3)}
									>
										<span className="number">3.</span>
									</NavLink>
								</NavItem>
								<NavItem
									className={classnames({
										'current-survey': activeTab === 4,
									})}
								>
									<NavLink
										className={classnames({ active: activeTab === 4 })}
										onClick={() => {
											setActiveTab(4)
										}}
										disabled={!(passedSteps || []).includes(4)}
									>
										<span className="number">4.</span>
									</NavLink>
								</NavItem>
								<NavItem
									className={classnames({
										'current-survey': activeTab === 5,
									})}
								>
									<NavLink
										className={classnames({ active: activeTab === 5 })}
										onClick={() => {
											setActiveTab(5)
										}}
										disabled={!(passedSteps || []).includes(5)}
									>
										<span className="number">5.</span>
									</NavLink>
								</NavItem>
								<NavItem
									className={classnames({
										'current-survey': activeTab === 6,
									})}
								>
									<NavLink
										className={classnames({ active: activeTab === 6 })}
										onClick={() => {
											setActiveTab(6)
										}}
										disabled={!(passedSteps || []).includes(6)}
									>
										<span className="number">6.</span>
									</NavLink>
								</NavItem>
								<NavItem
									className={classnames({
										'current-survey': activeTab === 7,
									})}
								>
									<NavLink
										className={classnames({ active: activeTab === 7 })}
										onClick={() => {
											setActiveTab(7)
										}}
										disabled={!(passedSteps || []).includes(7)}
									>
										<span className="number">7.</span>
									</NavLink>
								</NavItem>
								<NavItem
									className={classnames({
										'current-survey': activeTab === 8,
									})}
								>
									<NavLink
										className={classnames({ active: activeTab === 8 })}
										onClick={() => {
											setActiveTab(8)
										}}
										disabled={!(passedSteps || []).includes(8)}
									>
										<span className="number">8.</span>
									</NavLink>
								</NavItem>
								<NavItem
									className={classnames({
										'current-survey': activeTab === 9,
									})}
								>
									<NavLink
										className={classnames({ active: activeTab === 9 })}
										onClick={() => {
											setActiveTab(9)
										}}
										disabled={!(passedSteps || []).includes(9)}
									>
										<span className="number">9.</span>
									</NavLink>
								</NavItem>
								<NavItem
									className={classnames({
										'current-survey': activeTab === 10,
									})}
								>
									<NavLink
										className={classnames({ active: activeTab === 10 })}
										onClick={() => {
											setActiveTab(10)
										}}
										disabled={!(passedSteps || []).includes(10)}
									>
										<span className="number">10.</span>
									</NavLink>
								</NavItem>
							</ul>
						</div>
					</div>

					<Col lg="12">
						<Card>
							<CardBody>
								{errorSubmit?.status && (
									<Alert color="warning">
										<h3>Warning</h3>
										<ul className="mb-0">
											{errorSubmit?.message?.map((item, idx) => (
												<li key={idx}>{item[idx]}</li>
											))}
										</ul>
									</Alert>
								)}
								<div className="wizard clearfix">
									<div className="content clearfix">
										<TabContent activeTab={activeTab} className="body pt-0">
											<TabPane tabId={1}>
												<FormCustomer
													editForm={editForm}
													activeTab={activeTab}
													setActiveTab={setActiveTab}
													toggleTab={toggleTab}
													dataUserById={dataUserById}
												/>
											</TabPane>
											<TabPane tabId={2}>
												<FormStep2 userId={dataByChildId?.user_id} />
											</TabPane>
											<TabPane tabId={3}>
												<FormStep3
													activeTab={activeTab}
													setActiveTab={setActiveTab}
													toggleTab={toggleTab}
													dataChildById={dataByChildId}
												/>
											</TabPane>
											<TabPane tabId={4}>
												<FormStep4 />
											</TabPane>
											<TabPane tabId={5}>
												<FormStep5 />
											</TabPane>
											<TabPane tabId={6}>
												<FormStep6 />
											</TabPane>
											<TabPane tabId={7}>
												<FormStep7 />
											</TabPane>
											<TabPane tabId={8}>
												<FormStep8 />
											</TabPane>
											<TabPane tabId={9}>
												<FormStep9 />
											</TabPane>
											<TabPane tabId={10}>
												<FormStep10 />
											</TabPane>
										</TabContent>
									</div>
									<div className="actions clearfix">
										<ul>
											<li
												className={
													activeTab === 1 ? 'previous disabled' : 'previous'
												}
											>
												<Link
													to="#"
													onClick={() => {
														toggleTab(activeTab - 1)
													}}
												>
													Kembali
												</Link>
											</li>
											{activeTab !== 10 ? (
												<li
													className={
														activeTab === 10 ? 'next disabled' : 'next'
													}
												>
													<Link
														to="#"
														onClick={() => {
															toggleTab(activeTab + 1)
														}}
													>
														Selanjutnya
													</Link>
												</li>
											) : (
												<li>
													<Button color="primary" onClick={toggle}>
														Selesai
													</Button>
												</li>
											)}
										</ul>
									</div>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
				<Modal isOpen={showModal} toggle={toggle} centered backdrop={`static`}>
					<ModalBody className="rounded-5">
						<div className="d-flex flex-column align-items-center justify-content-center">
							<img src="/logo-yamet-2.png" alt="logo" height="50" />
							<p className="my-3">Apakah Anda yakin akan menyimpan data?</p>
							<div className="mb-3 d-flex gap-5">
								<button className="btn text-primary fw-bold" onClick={toggle}>
									Kembali
								</button>
								<Button
									color="primary"
									className="rounded-3"
									onClick={finishSurvey}
								>
									Iya, lanjut simpan
								</Button>
							</div>
						</div>
					</ModalBody>
				</Modal>
			</Container>
		</div>
	)
}

export default TabFormSurvey
