import { Link } from 'react-router-dom';
import { FaCheck } from "react-icons/fa6";

const SuccessChangePasswordClient = () => {
    return (
        <div className='my-auto'>
            <div className="text-center">
                <div className="avatar-md mx-auto">
                    <div className="avatar-title rounded-circle bg-light">
                        <i className='mb-0 text-success h1'>
                            <FaCheck />
                        </i>
                    </div>
                </div>
                <div className="p-2 mt-4">
                    <h4>Berhasil !</h4>
                    <p className="text-muted">
                        anda telah berhasil merubah kata sandi, silahkan Masuk
                    </p>
                    <div className="mt-4">
                        <Link to="/login-client" className="btn btn-success">
                            Masuk
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SuccessChangePasswordClient;
