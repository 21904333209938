import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
	Alert,
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Form,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
} from 'reactstrap'
import styled from 'styled-components'
import { setPageTitle } from '../../../app/layoutSlice'
import HelmetTitle from '../../../components/templates/HelmetTitle'
import {
	useGetChildByNameQuery,
	useGetScheduleByChildIdQuery,
} from '../../patient/api/patientApi'
import TherapyOptions from './TherapyOptions'
import { useCreateScheduleMutation, useGetAllSchedulesByChildQuery } from '../../general/api/scheduleApi'

const CustomerDropDown = styled.li`
	cursor: pointer;
	padding: 5px;

	&:hover {
		background-color: #1b7bc7;
		color: #ffffff;
	}
`

const initSubmitStatus = {
	status: false,
	type: 'danger',
	message: 'error message',
}

const FormAddSchedule = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const month = new Date().getMonth()

	const [showModal, setShowModal] = useState(false)
	const [submitStatus, setSubmitStatus] = useState(initSubmitStatus)
	const [searchTerm, setSearchTerm] = useState('')
	const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('')
	const [showResult, setShowResult] = useState(false)
	const [selectedChild, setSelectedChild] = useState({
		user_id: 0,
		name: '',
	})

	const [scheduleData, setScheduleData] = useState([])

	const [createSchedule] = useCreateScheduleMutation()
	const { data: fetchChildByName } = useGetChildByNameQuery(debouncedSearchTerm)

	const {
		data: fetchScheduleByChild,
	} = useGetAllSchedulesByChildQuery(
		{
			child_id: selectedChild?.user_id,
			month,
		},
		{ skip: selectedChild?.user_id === 0 }
	)

	const { data: filteredUser = [] } = fetchChildByName ?? []
	const { data: dataScheduleByChild = [] } = fetchScheduleByChild ?? []

	function toggle() {
		setShowModal(!showModal)
	}

	useEffect(() => {
		dispatch(setPageTitle('Jadwal'))
	}, [])

	// Debounce delay in milliseconds
	const debounceDelay = 500
	useEffect(() => {
		// Update the debounced search term after the specified delay
		const debounceTimer = setTimeout(() => {
			setDebouncedSearchTerm(searchTerm)
		}, debounceDelay)

		// Cleanup the timer on component unmount or when the searchTerm changes
		return () => {
			clearTimeout(debounceTimer)
		}
	}, [searchTerm])

	const handleSubmitSchedule = async () => {
		setSubmitStatus(initSubmitStatus)
		try {
			scheduleData.forEach((schedule) => {
				if (schedule.date === '') {
					throw new Error(`Silahkan lengkapi jadwal terlebih dahulu`)
				}
			})

			const modifiedSchedule = scheduleData.map((item) => ({
				therapist_id: item.therapist_id,
				service_id: item.service_id,
				date_time: `${item.date} ${item.time.string}`,
			}))

			const payload = {
				child_id: selectedChild?.user_id,
				services: modifiedSchedule,
			}

			const result = await createSchedule(payload).unwrap()

			const { message } = result

			setSubmitStatus({
				status: true,
				type: 'success',
				message,
			})

			toggle()
			// return
			setTimeout(() => {
				navigate('/')
			}, 3000)
		} catch (error) {
			console.error(error)

			const errorMsg =
				error?.data?.message === 'schedule is full'
					? 'Jadwal terapis full, ubah hari atau jam terapi'
					: error?.data?.message
			setSubmitStatus({
				status: true,
				type: 'danger',
				message:
					errorMsg ?? error.message ?? 'Gagal menambahkan jadwal, coba lagi.',
			})
			toggle()
		}
	}

	const handlerSearchName = (e) => {
		if (Boolean(e.target.value)) {
			setShowResult(true)
		}
		setSearchTerm(e.target.value)
	}

	const handleSelectCustomer = (value) => {
		setShowResult(false)
		setSearchTerm(value.name)
		setSelectedChild(value)
	}

	return (
		<div>
			{submitStatus.status && (
				<Alert color={submitStatus?.type} className="mb-4">
					<h3>{submitStatus.type === 'success' ? 'Berhasil' : 'Gagal'}</h3>
					<p className="mb-0">
						{submitStatus?.message ?? 'error message here'}
					</p>
				</Alert>
			)}
			<HelmetTitle title="Tambah Jadwal" />
			<Container fluid>
				<Row>
					<Col lg="12">
						<Card>
							<CardBody>
								<h5 className="mb-0">Tambah Jadwal</h5>
								<hr className="border border-light border-2 opacity-50" />
								<Form>
									<FormGroup>
										<Label>Nama Anak</Label>
										<Input
											type="text"
											className="form-control bg-light"
											placeholder="Masukkan Nama Anak"
											value={searchTerm}
											onChange={handlerSearchName}
										/>
										{showResult && (
											<Card>
												<CardBody className="border border-light p-1">
													{filteredUser.length === 0 ? (
														<small className="mb-0">
															Customer tidak ditemukan
														</small>
													) : (
														<ul className="list-unstyled mb-0">
															{filteredUser?.map((item, idx) => (
																<CustomerDropDown
																	key={idx}
																	className="list-customer-dropdown"
																	onClick={() =>
																		handleSelectCustomer({
																			user_id: item.id,
																			name: item.full_name,
																		})
																	}
																>
																	{item.full_name}
																</CustomerDropDown>
															))}
														</ul>
													)}
												</CardBody>
											</Card>
										)}
									</FormGroup>
									<hr className="border border-light border-2 opacity-50" />
									<TherapyOptions setScheduleData={setScheduleData} dataScheduleByChild={dataScheduleByChild} />
									<Row className="justify-content-end my-3">
										<Col>
											<div className="d-flex justify-content-end gap-2">
												<Button color="primary" onClick={() => navigate('/')}>
													Batal
												</Button>
												<Button
													color="success"
													className="px-4"
													type="button"
													onClick={toggle}
												>
													Simpan
												</Button>
											</div>
										</Col>
									</Row>
								</Form>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
			<Modal isOpen={showModal} toggle={toggle} centered>
				<ModalHeader className="d-flex justify-content-between">
					Simpan Jadwal
				</ModalHeader>
				<ModalBody>
					<p>
						Anda akan menyimpan jadwal peserta didik selama sebulan ke depan.
						Jadwal akan dipublikasikan kepada pihak siswa.
					</p>
				</ModalBody>
				<ModalFooter>
					<Button
						color="light"
						className="text-dark"
						size="lg"
						onClick={toggle}
					>
						Kembali
					</Button>
					<Button color="success" size="lg" onClick={handleSubmitSchedule}>
						Simpan
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	)
}

export default FormAddSchedule
