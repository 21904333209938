import { Card, Col, Row } from "reactstrap";
import welcomeFamily from '../../../assets/images/welcome/family.svg'
import welcomeAdmin from '../../../assets/images/welcome/admin.svg'
import { useEffect, useState } from "react";
import { UpperCaseName } from "../../../helpers/GlobalHelper";

const CardWelcome = (props) => {
    const { type } = props

    const [nameUser, setNameUser] = useState('');

    useEffect(() => {
        const sessions = localStorage.getItem('data');
        if (sessions) {
            const parseSession = JSON.parse(sessions);
            if (parseSession.name) {
                setNameUser(UpperCaseName(parseSession.name));
            } else {
                setNameUser('-')
            }
        }
    }, []);

    return (
        <Card className="rounded-3">
            <Row>
                <Col>
                    <div className="p-4">
                        <h3 className="text-success">
                            <span className="wave">
                                👋
                            </span>
                            {' '}
                            <span>😀</span>
                            {' '}
                            <span>Hai</span>
                        </h3>
                        <p>{nameUser}</p>
                    </div>
                </Col>
                <Col lg="3" sm="4" className="align-self-center">
                    <div>
                        <img
                            src={
                                type === 'admin_pusat' || type === 'admin_cabang' ? welcomeAdmin : welcomeFamily
                            }
                            alt="welcome"
                            className="img-fluid d-block"
                        />
                    </div>
                </Col>
            </Row>
        </Card>
    );
}

export default CardWelcome;
