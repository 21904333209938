import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	FormGroup,
	Input,
	Label,
	Row,
	Table,
	Toast,
	ToastBody,
	ToastHeader,
} from 'reactstrap'
import { setPageTitle } from '../../../app/layoutSlice'
import HelmetTitle from '../../../components/templates/HelmetTitle'
import {
	useExportInvoiceMutation,
	useGetInvoiceByIdQuery,
	useUpdateInvoiceStatusMutation,
} from '../api/invoiceApi'
import { formatCurrency } from '../../../helpers/GlobalHelper'
import { FaCheck, FaPrint, FaXmark } from 'react-icons/fa6'
import styled from 'styled-components'
import {
	useExportInvoiceUserMutation,
	useGetInvoiceDetailQuery,
} from '../../general/api/clientApi'
import PaymentStatus from '../../../components/templates/PaymentStatus'

const CustomToast = styled(Toast)`
	position: absolute;
	z-index: 10;
	top: 20%;
	left: 50%;
	transform: translateX(-20%);
	padding: 10px;
`

const DetailInvoice = () => {
	const { invoiceId } = useParams()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const checkrole = JSON.parse(localStorage.getItem('data'))
	const [statusInvoice, setStatusInvoice] = useState(1)
	const [updateStatus, setUpdateStatus] = useState({
		show: false,
		type: 'danger',
		message: '',
	})

	const [exportInvoice] = useExportInvoiceMutation()
	const [exportInvoiceUser] = useExportInvoiceUserMutation()
	const [updateInvoiceStatus] = useUpdateInvoiceStatusMutation()
	// ! Fetch for admin
	const { data: fetchInvoiceById, refetch: refetchForAdmin } =
		useGetInvoiceByIdQuery(invoiceId, {
			skip: !invoiceId || checkrole?.role === 'client',
		})
	// ! Fetch for user
	const { data: fetchInvoiceDetailUser, refetch: refetchForUser } =
		useGetInvoiceDetailQuery(invoiceId, {
			skip: !invoiceId || checkrole?.role !== 'client',
		})
	const { data: detailInvoice } =
		fetchInvoiceById ?? fetchInvoiceDetailUser ?? {}

	const refetchFn =
		checkrole?.role === 'client' ? refetchForUser : refetchForAdmin

	useEffect(() => {
		dispatch(setPageTitle('Daftar Riwayat Transaksi'))
	}, [])

	useEffect(() => {
		if (detailInvoice) {
			refetchFn()
			setStatusInvoice(detailInvoice.status)
		}
	}, [detailInvoice])

	useEffect(() => {
		if (updateStatus.show) {
			const timer = setTimeout(() => {
				setUpdateStatus({
					show: false,
					type: 'danger',
					message: '',
				})
			}, 2000)

			return () => {
				clearTimeout(timer)
			}
		}
	}, [updateStatus])

	const handleUpdateInvoiceStatus = async (e) => {
		setStatusInvoice(e.target.value)
		try {
			const result = await updateInvoiceStatus({
				invoiceId,
				status: +e.target.value,
			}).unwrap()

			const { message } = result
			setUpdateStatus({
				show: true,
				type: 'success',
				message: message,
			})
		} catch (error) {
			console.error(error)

			setUpdateStatus({
				show: true,
				type: 'danger',
				message: error?.message ?? 'Update Invoice Failed',
			})
			setStatusInvoice(statusInvoice)
		}
	}

	const handleExportInvoice = async () => {
		try {
			if (checkrole?.role === 'client') {
				const result = await exportInvoiceUser(invoiceId).unwrap()
				const { data } = result

				window.open(data)

				return
			}
			const result = await exportInvoice(invoiceId).unwrap()
			const { data } = result

			window.open(data)
		} catch (error) {
			console.error(error)

		}
	}

	return (
		<>
			{updateStatus.show && (
				<CustomToast>
					<ToastHeader className={`text-${updateStatus.type}`}>
						{updateStatus.type === 'success' ? (
							<>
								<FaCheck className={`text-success me-2`} />
								Update Berhasil
							</>
						) : (
							<>
								<FaXmark className={`text-danger me-2`} />
								Update Gagal
							</>
						)}
					</ToastHeader>
					<ToastBody>{updateStatus?.message ?? '-'}</ToastBody>
				</CustomToast>
			)}
			<HelmetTitle title={detailInvoice?.invoice_number} />
			<Container fluid>
				<Row>
					<Col xs="12">
						<Card>
							<CardBody>
								<div className="d-flex align-items-center justify-content-between">
									<h3 className="mb-0">
										Nomor Invoice {detailInvoice?.invoice_number ?? '-'}
									</h3>
									{checkrole?.role !== 'client' ? (
										<FormGroup>
											<div className="d-flex gap-2 align-items-center">
												<Label className="mb-0">Status</Label>
												<Input
													type="select"
													className="form-control bg-light"
													onChange={handleUpdateInvoiceStatus}
													value={statusInvoice}
												>
													<option value="1">Lunas</option>
													<option value="2">Belum Lunas</option>
												</Input>
											</div>
										</FormGroup>
									) : (
										<PaymentStatus status={statusInvoice} />
									)}
								</div>
								<hr className="border border-light border-2 opacity-50"></hr>
								<Row className="mb-5 mt-3">
									<Col lg="3" xs={6}>
										<h6 className="fw-bold">Nama Orang Tua/Wali (Customer):</h6>
										<div>{detailInvoice?.user.name}</div>
										<div>{detailInvoice?.user.email}</div>
									</Col>
									<Col lg="3" xs={6}>
										<h6 className="fw-bold">Metode Pembayaran:</h6>
										<div>BCA a/n Klinik Yamet</div>
										<div>Nomor Rekening: 167389689541</div>
									</Col>
								</Row>
								<Row>
									<Col xs="12">
										<h5 className="fw-bold">Detail Transaksi</h5>
										<Table responsive>
											<thead>
												<tr>
													<th width="5%">No</th>
													<th width="10%">Layanan</th>
													<th width="10%">Jumlah</th>
													<th width="45%">Harga</th>
													<th width="10%">Total Harga</th>
												</tr>
											</thead>
											<tbody className="bg-white">
												{detailInvoice?.services.map((item, idx) => (
													<tr key={idx}>
														<th scope="row">{idx + 1}</th>
														<td>{item.name}</td>
														<td>{item.count}</td>
														<td>{formatCurrency(item.price)}</td>
														<td>
															{formatCurrency(item.price_total_by_service)}
														</td>
													</tr>
												))}
												<tr>
													<td
														colSpan={4}
														className="text-end fw-bold align-middle"
													>
														Total
													</td>
													<td className="text-start fw-bold align-middle">
														<h3 className="mb-0">
															{formatCurrency(detailInvoice?.total_price ?? 0)}
														</h3>
													</td>
												</tr>
											</tbody>
										</Table>
									</Col>
								</Row>
								<Row className="mt-5 justify-content-end">
									<Col xs="6" className="gap-2 d-flex justify-content-end">
										<Button color="success" onClick={handleExportInvoice}>
											<FaPrint />
										</Button>
										<Button
											color="primary"
											className="px-3"
											onClick={() =>
												navigate(
													checkrole?.role === 'client'
														? '/invoice'
														: '/invoice-customer'
												)
											}
										>
											Kembali
										</Button>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	)
}

export default DetailInvoice
