import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
const { VITE_BASE_URL } = import.meta.env

const getToken = (state) => state.authSetup.token

export const invoiceApi = createApi({
	reducerPath: 'invoiceApi',
	tagTypes: ['invoiceApi'],
	baseQuery: fetchBaseQuery({
		baseUrl: `${VITE_BASE_URL}/v1/admin/`,
		prepareHeaders: (headers, { getState }) => {
			const token = getToken(getState())
			if (token) {
				headers.set('Authorization', `Bearer ${token}`)
			}
			return headers
		},
	}),
	endpoints: (builder) => ({
		getAllInvoices: builder.query({
			query: ({ keyword }) => ({
				url: `invoices?keyword=${keyword}`,
			}),
			providesTags: ['GetAllInvoices'],
		}),
		getInvoiceById: builder.query({
			query: (id) => ({
				url: `invoices/${id}`,
			}),
			providesTags: ['GetInvoiceById'],
		}),
		updateInvoiceStatus: builder.mutation({
			query: ({ invoiceId, ...payload }) => ({
				url: `invoices/${invoiceId}`,
				method: 'PUT',
				body: payload,
			}),
			invalidatesTags: ['GetAllInvoices'],
		}),
		exportInvoice: builder.mutation({
			query: (id) => ({
				url: `invoices/${id}/export`,
				method: 'GET'
			}),
		}),
	}),
	refetchOnMountOrArgChange: true,
	refetchOnFocus: true,
})

export const {
	useGetAllInvoicesQuery,
	useGetInvoiceByIdQuery,
	useUpdateInvoiceStatusMutation,
	useExportInvoiceMutation,
} = invoiceApi
