import React, { useEffect, useState } from 'react'
import HelmetTitle from '../../../components/templates/HelmetTitle'
import {
	Alert,
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Form,
	Input,
	Row,
} from 'reactstrap'
import { useGetListServiceQuery } from '../api/listServiceApi'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {
	useGetCityByProvinceIdQuery,
	useGetMasterDataQuery,
} from '../../general/api/masterApi'
import { useNavigate, useParams } from 'react-router-dom'
import {
	useGetBranchDetailByIdQuery,
	usePostBranchMutation,
	useUpdateBranchMutation,
} from './api/branchApi'
import PhoneNumberInput from '../../../components/templates/PhoneNumberInput'

const FormBranch = ({ editForm }) => {
	const { id } = useParams()
	const navigate = useNavigate()
	const [provinceId, setProvinceId] = useState(11)
	const [postDataStatus, setPostDataStatus] = useState({
		status: false,
		message: '',
		type: '',
		headerMessage: '',
	})
	const [postBranch, { isLoading: addBranchLoading }] = usePostBranchMutation()
	const [updateBranch, { isLoading: updateBranchLoading }] =
		useUpdateBranchMutation()

	const { data: fetchServices } = useGetListServiceQuery(
		{ endpoint: 'master-price', page: 1 },
		{ refetchOnMountOrArgChange: true }
	)
	const { data: fetchCity } = useGetCityByProvinceIdQuery(
		{ provinceId },
		{ skip: +provinceId === 0 || !provinceId }
	)
	const { data: fetchProvince } = useGetMasterDataQuery({
		endpoint: 'province',
	})
	const { data: fetchBranchById, refetch } = useGetBranchDetailByIdQuery(id, {
		skip: !id,
	})

	const { data: dataPrices } = fetchServices ?? []
	const { data: dataProvince } = fetchProvince ?? []
	const { data: dataCity } = fetchCity ?? []
	const { data: dataCityById } = fetchBranchById ?? []

	const schema = yup.object({
		branch_name: yup.string().required('Nama Cabang wajib di isi!'),
		address: yup.string().required('Alamat Cabang wajib di isi!'),
		province_id: yup
			.number()
			.required('Provinsi wajib di isi!')
			.notOneOf([0], 'Provinsi wajib di isi!'),
		city_id: yup
			.number()
			.required('Kota wajib di isi!')
			.notOneOf([0], 'Kota wajib di isi!'),
		postal_code: yup
			.string()
			.required('Kode pos wajib di isi!')
			.matches(/^\d+$/, 'Kode pos tidak valid!'),
		phone_number: yup
			.string()
			.required('Nomor telepon wajib di isi!')
			.matches(/^\d+$/, 'Nomor telepon tidak valid!'),
		wa_number: yup
			.string()
			.required('Nomor WhatsApp wajib di isi!')
			.matches(/^\+62\d{10,}$/, 'Format tidak valid & minimal 10 digit sesudah +62!'),
		price_category_id: yup
			.number()
			.required('Kategori harga wajib di isi!')
			.notOneOf([0], 'Kategori harga wajib di isi!'),
	})

	const {
		control,
		handleSubmit,
		reset,
		formState: { errors },
		watch,
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			branch_name: '',
			address: '',
			province_id: '0',
			city_id: '0',
			postal_code: '',
			phone_number: '',
			wa_number: '',
			price_category_id: '0',
			status: '0',
		},
	})

	const getProvinceId = watch('province_id')

	useEffect(() => {
		setProvinceId(getProvinceId)
	}, [getProvinceId])

	useEffect(() => {
		if (dataCityById) {
			const {
				branch_name = '',
				address = '',
				city: { id: city_id = '0', province_id = '0' },
				postal_code = '',
				phone_number = '',
				wa_number = '',
				price_category_id = '0',
				status = '0',
			} = dataCityById ?? {}

			reset({
				branch_name,
				address,
				province_id,
				city_id,
				postal_code,
				phone_number,
				wa_number,
				price_category_id,
				status,
			})
			refetch()
		}
	}, [reset, dataCityById])

	useEffect(() => {
		if (postDataStatus.type === 'success') {
			setTimeout(() => {
				navigate('/branch-location')
			}, 1500);
		}
	}, [postDataStatus]);

	const handleOnSubmit = async (param) => {
		setPostDataStatus({
			status: false,
			type: '',
			headerMessage: '',
			message: '',
		})
		try {
			if (editForm) {
				const result = await updateBranch({ id, ...param }).unwrap()
				const { status, message } = result
				if (status !== 'ok') {
					throw new Error(result?.message ?? 'Something went wrong')
				}

				setPostDataStatus({
					status: true,
					type: 'success',
					headerMessage: 'Berhasil',
					message,
				})
				// setTimeout(() => {
				// 	navigate(-1)
				// }, 1000)
				return
			}
			const result = await postBranch(param).unwrap()
			const { message } = result
			setPostDataStatus({
				status: true,
				type: 'success',
				headerMessage: 'Berhasil',
				message,
			})
		} catch (error) {
			let msgErr = '';
			if (error.status === 422) {
				for (const property in error.data.data) {
					msgErr += error.data.data[property][0] + "\n";
				}
			}

			if (error.status !== 422) {
				msgErr = error.data.message
			}

			setPostDataStatus({
				status: true,
				type: 'danger',
				message: <>{msgErr}</>,
				headerMessage: 'Terjadi Kesalahan'
			})
		}
	}

	return (
		<>
			<HelmetTitle title={editForm ? 'Edit Cabang' : 'Tambah Cabang'} />

			<Container fluid>
				<Row className="justify-content-center">
					<Col xs={12}>
						<Card>
							<CardBody>
								<div className="py-2">
									<h3 className="mb-0">
										{editForm ? 'Edit Cabang' : 'Tambah Cabang'}
									</h3>
									<hr className="border border-light border-2 opacity-50"></hr>
								</div>
								{postDataStatus.status && (
									<Alert color={postDataStatus?.type ?? 'danger'}>
										<h4 className="alert-heading">
											{postDataStatus?.headerMessage}
										</h4>
										<p
											className="mb-0"
											style={{ whiteSpace: 'pre-line' }}
										>
											{postDataStatus.message}
										</p>
									</Alert>
								)}
								<Form onSubmit={handleSubmit(handleOnSubmit)}>
									<Row className="mb-3">
										<label
											htmlFor="example-text-input"
											className="col-md-2 col-form-label"
										>
											Nama Cabang
										</label>
										<div className="col-md-10">
											<Controller
												name="branch_name"
												control={control}
												render={({ field: { onChange, onBlur, value } }) => (
													<>
														<Input
															placeholder="Masukkan Nama Cabang"
															className="form-control bg-light"
															type="text"
															aria-label="name"
															onChange={onChange}
															onBlur={onBlur}
															value={value}
															invalid={
																errors?.branch_name?.type === 'required' ??
																false
															}
														/>
														{errors && (
															<small className="text-danger">
																{errors?.branch_name?.message}
															</small>
														)}
													</>
												)}
											/>
										</div>
									</Row>
									<Row className="mb-3">
										<label
											htmlFor="example-text-input"
											className="col-md-2 col-form-label"
										>
											Alamat
										</label>
										<div className="col-md-10">
											<Controller
												name="address"
												control={control}
												render={({ field: { onChange, onBlur, value } }) => (
													<>
														<Input
															placeholder="Masukkan Alamat"
															className="form-control bg-light"
															type="text"
															aria-label="address"
															onChange={onChange}
															onBlur={onBlur}
															value={value}
															invalid={
																errors?.address?.type === 'required' ?? false
															}
														/>
														{errors && (
															<small className="text-danger">
																{errors?.address?.message}
															</small>
														)}
													</>
												)}
											/>
										</div>
									</Row>
									<Row className="mb-3">
										<label
											htmlFor="example-text-input"
											className="col-md-2 col-form-label"
										>
											Provinsi
										</label>
										<div className="col-md-10">
											<Controller
												name="province_id"
												control={control}
												render={({ field: { onChange, onBlur, value } }) => (
													<>
														<Input
															placeholder="Masukkan Provinsi"
															className="form-control bg-light"
															type="select"
															aria-label="province"
															onChange={onChange}
															onBlur={onBlur}
															value={value}
															invalid={errors.province_id ? true : false}
														>
															<option value="0">Pilih Provinsi</option>
															{dataProvince?.map((province, idx) => (
																<option key={idx} value={province.id}>
																	{province.name}
																</option>
															))}
														</Input>
														{errors && (
															<small className="text-danger">
																{errors?.province_id?.message}
															</small>
														)}
													</>
												)}
											/>
										</div>
									</Row>
									<Row className="mb-3">
										<label
											htmlFor="example-text-input"
											className="col-md-2 col-form-label"
										>
											Kabupaten / Kota
										</label>
										<div className="col-md-10">
											<Controller
												name="city_id"
												control={control}
												render={({ field: { onChange, onBlur, value } }) => (
													<>
														<Input
															placeholder="Masukkan Kabupaten / Kota"
															className="form-control bg-light"
															type="select"
															aria-label="city"
															onChange={onChange}
															onBlur={onBlur}
															value={value}
															invalid={errors?.city_id ? true : false}
														>
															<option value="0">Pilih Kota</option>
															{dataCity?.map((city, idx) => (
																<option key={idx} value={city.id}>
																	{city.name}
																</option>
															))}
														</Input>
														{errors && (
															<small className="text-danger">
																{errors?.city_id?.message}
															</small>
														)}
													</>
												)}
											/>
										</div>
									</Row>
									<Row className="mb-3">
										<label
											htmlFor="example-text-input"
											className="col-md-2 col-form-label"
										>
											Kode Pos
										</label>
										<div className="col-md-10">
											<Controller
												name="postal_code"
												control={control}
												render={({ field: { onChange, onBlur, value } }) => (
													<>
														<Input
															placeholder="Masukkan Kode Pos"
															className="form-control bg-light"
															type="text"
															aria-label="postal code"
															onChange={onChange}
															onBlur={onBlur}
															value={value}
															invalid={errors.postal_code ? true : false}
														/>
														{errors && (
															<small className="text-danger">
																{errors?.postal_code?.message}
															</small>
														)}
													</>
												)}
											/>
										</div>
									</Row>
									<Row className="mb-3">
										<label
											htmlFor="example-text-input"
											className="col-md-2 col-form-label"
										>
											No. Telepon
										</label>
										<div className="col-md-10">
											<Controller
												name="phone_number"
												control={control}
												render={({ field: { onChange, onBlur, value } }) => (
													<>
														<Input
															placeholder="Masukkan No Telp (08123456789)"
															className="form-control bg-light"
															type="text"
															aria-label="phone number"
															onChange={onChange}
															onBlur={onBlur}
															value={value}
															invalid={errors.phone_number ? true : false}
														/>
														{errors && (
															<small className="text-danger">
																{errors?.phone_number?.message}
															</small>
														)}
													</>
												)}
											/>
										</div>
									</Row>
									<Row className="mb-3">
										<label
											htmlFor="example-text-input"
											className="col-md-2 col-form-label"
										>
											No. WhatsApp
										</label>
										<div className="col-md-10">
											<Controller
												name="wa_number"
												control={control}
												render={({ field: { onChange, onBlur, value } }) => (
													<>
														<PhoneNumberInput
															initialValue={value || '+62'}
															prefixOnZero="+62"
															className="form-control bg-light"
															placeholder="Masukkan No Telp"
															onChange={onChange}
															onBlur={onBlur}
															value={value}
														/>
														{errors && (
															<small className="text-danger">
																{errors?.wa_number?.message}
															</small>
														)}
													</>
												)}
											/>
										</div>
									</Row>
									<Row className="mb-3">
										<label
											htmlFor="example-text-input"
											className="col-md-2 col-form-label"
										>
											Kategori Harga
										</label>
										<div className="col-md-10">
											<Controller
												name="price_category_id"
												control={control}
												render={({ field: { onChange, onBlur, value } }) => (
													<>
														<Input
															className="form-control bg-light"
															type="select"
															aria-label="price category"
															onChange={onChange}
															onBlur={onBlur}
															value={value}
															invalid={errors.price_category_id ? true : false}
														>
															<option value="0">Pilih Harga</option>
															{dataPrices?.data.map((item, idx) => (
																<option key={idx} value={item.id}>
																	{item.title}
																</option>
															))}
														</Input>
														{errors && (
															<small className="text-danger">
																{errors?.price_category_id?.message}
															</small>
														)}
													</>
												)}
											/>
										</div>
									</Row>
									<Row className="mb-3">
										<label
											htmlFor="example-text-input"
											className="col-md-2 col-form-label"
										>
											Status Cabang
										</label>
										<div className="col-md-10">
											<Controller
												name="status"
												control={control}
												render={({ field: { onChange, onBlur, value } }) => (
													<Input
														className="form-control bg-light"
														type="select"
														aria-label="whatsapp"
														onChange={onChange}
														onBlur={onBlur}
														value={value}
													>
														<option value="0">Tidak Aktif</option>
														<option value="1">Aktif</option>
													</Input>
												)}
											/>
										</div>
									</Row>
									<Row>
										<Col>
											<div className="d-flex justify-content-end gap-2">
												<Button
													color="primary"
													onClick={() => navigate('/branch-location')}
												>
													Batal
												</Button>
												<Button
													color="success"
													className="px-4"
													disabled={addBranchLoading || updateBranchLoading}
												>
													Simpan
												</Button>
											</div>
										</Col>
									</Row>
								</Form>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	)
}

export default FormBranch
