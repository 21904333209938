import React from 'react'
import {
	Alert,
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Form,
	Input,
	Row,
} from 'reactstrap'
import HelmetTitle from '../../../components/templates/HelmetTitle'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetListServiceQuery } from '../api/listServiceApi'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {
	useGetPriceDetailByIdQuery,
	usePostPriceMutation,
	useUpdatePriceMutation,
} from './api/priceApi'
import { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { layoutSetupSelector } from '../../../app/layoutSlice'
import { adjustNumberFormat } from '../../../helpers/GlobalHelper'

const FormPrice = ({ editForm }) => {
	const { id } = useParams()
	const navigate = useNavigate()
	const [postDataStatus, setPostDataStatus] = useState({
		status: false,
		type: '',
		headerMessage: '',
		message: '',
	})
	const [serviceIdPriceData, setServiceIdPriceData] = useState([])
	const { tablePage } = useSelector(layoutSetupSelector)
	const [postPrice, { isLoading }] = usePostPriceMutation()
	const [updatePrice, { isLoading: updatePriceLoading }] =
		useUpdatePriceMutation()
	const { data: listServices } = useGetListServiceQuery({
		endpoint: 'service',
		page: tablePage,
	})
	const { data: getPriceData, refetch } = useGetPriceDetailByIdQuery(id, {
		skip: !id,
	})
	const priceDetailData = getPriceData?.data ?? {}
	const { data: dataService } = listServices?.data ?? []

	const schema = yup.object({
		title: yup.string().required('Nama Harga wajib di isi!'),
		description: yup.string().required('Deskripsi Harga wajib di isi!'),
	})

	const {
		control,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			title: '',
			description: '',
		},
	})

	useEffect(() => {
		reset({
			title: priceDetailData?.title ?? '',
			description: priceDetailData?.description ?? '',
		})

		// Set Price from API
		if (Object.keys(priceDetailData).length !== 0) {
			const getPriceDatas = priceDetailData?.service_prices?.map(
				({ service_id, price }) => ({
					service_id,
					price,
				})
			)

			if (getPriceDatas) {
				setServiceIdPriceData(getPriceDatas)
			}
			refetch()
		}
	}, [reset, getPriceData])

	const handleOnSubmit = async (param) => {
		setPostDataStatus({
			status: false,
			type: '',
			headerMessage: '',
			message: '',
		})
		try {
			if (editForm) {
				const result = await updatePrice({
					...param,
					id,
					service_price: serviceIdPriceData,
				}).unwrap()

				const { status, message } = result
				if (status !== 'ok') {
					throw new Error(result?.message ?? 'Something went wrong')
				}

				setPostDataStatus({
					status: true,
					message,
					type: 'success',
					headerMessage: 'Berhasil',
				})

				setTimeout(() => {
					navigate(-1)
				}, 1000)
				return
			}
			const result = await postPrice({
				...param,
				service_price: serviceIdPriceData,
			}).unwrap()
			const { message } = result

			setPostDataStatus({
				status: true,
				message,
				type: 'success',
				headerMessage: 'Berhasil',
			})
			reset()
			setTimeout(() => {
				navigate(-1)
			}, 1000)
		} catch (error) {
			let msgErr = '';
			if (error.status === 422) {
				for (const property in error.data.data) {
					msgErr += error.data.data[property][0] + "\n";
				}
			}

			if (error.status !== 422) {
				msgErr = error.data.message
			}

			setPostDataStatus({
				status: true,
				type: 'danger',
				message: <>{msgErr}</>,
				headerMessage: 'Terjadi Kesalahan'
			})
		}
	}

	const getPriceByServiceId = (service_id) => {
		const priceData = serviceIdPriceData.find(
			(data) => data.service_id === service_id
		)
		return priceData?.price ?? 0
	}

	const handleServicePrice = (id, value) => {
		if (value) {
			const existingIndex = serviceIdPriceData.findIndex(
				(item) => item.service_id === id
			)

			//checking & replace value
			value = adjustNumberFormat(value)

			if (existingIndex !== -1) {
				// Update existing entry
				const updatedServiceData = [...serviceIdPriceData]
				updatedServiceData[existingIndex] = {
					service_id: id,
					price: Number(value),
				}
				setServiceIdPriceData(updatedServiceData)
			} else {
				// Add a new entry
				setServiceIdPriceData([
					...serviceIdPriceData,
					{ service_id: id, price: Number(value) },
				])
			}
		}
	}

	return (
		<>
			<HelmetTitle
				title={editForm ? 'Edit Harga Layanan' : 'Tambah Harga Layanan'}
			/>

			<Container fluid={true}>
				<Row>
					<Col>
						<Card>
							<CardBody>
								<div className="py-2">
									<h3 className="mb-0">
										{editForm ? 'Edit Harga Layanan' : 'Tambah Harga Layanan'}
									</h3>
									<hr className="border border-light border-2 opacity-50"></hr>
								</div>
								{postDataStatus.status && (
									<Alert color={postDataStatus?.type ?? 'danger'}>
										<h4 className="alert-heading">
											{postDataStatus?.headerMessage}
										</h4>
										<p
											className="mb-0"
											style={{ whiteSpace: 'pre-line' }}
										>
											{postDataStatus.message}
										</p>
									</Alert>
								)}
								<Form onSubmit={handleSubmit(handleOnSubmit)}>
									<Row className="mb-3">
										<label
											htmlFor="example-text-input"
											className="col-md-2 col-form-label"
										>
											Nama Harga
										</label>
										<div className="col-md-10">
											<Controller
												name="title"
												control={control}
												render={({ field: { onChange, onBlur, value } }) => {
													return (
														<>
															<Input
																placeholder="Masukkan Nama Harga Layanan"
																className="form-control bg-light"
																type="text"
																onChange={onChange}
																onBlur={onBlur}
																value={value}
																aria-label="title"
																invalid={
																	errors?.title?.type === 'required' ?? false
																}
															/>
															{errors && (
																<small className="text-danger">
																	{errors?.title?.message}
																</small>
															)}
														</>
													)
												}}
											/>
										</div>
									</Row>
									<Row className="mb-3">
										<label
											htmlFor="example-text-input"
											className="col-md-2 col-form-label"
										>
											Deskripsi Harga
										</label>
										<div className="col-md-10">
											<Controller
												name="description"
												control={control}
												render={({ field: { onChange, onBlur, value } }) => {
													return (
														<>
															<Input
																placeholder="Masukkan Deskripsi Harga Layanan"
																className="form-control bg-light"
																type="text"
																onChange={onChange}
																onBlur={onBlur}
																value={value}
																aria-label="description"
																invalid={
																	errors?.description?.type === 'required' ??
																	false
																}
															/>
															{errors && (
																<small className="text-danger">
																	{errors?.description?.message}
																</small>
															)}
														</>
													)
												}}
											/>
										</div>
									</Row>
									<Row className="mb-3">
										<label
											htmlFor="example-text-input"
											className="col-md-2 col-form-label"
										>
											Layanan
										</label>

										<Col>
											{dataService?.map((service) => (
												<Row className="mb-3" key={service.id}>
													<Col xs={12} md={2}>
														<label className="col-form-label">
															{service.name}
														</label>
													</Col>
													<Col xs={12} md={2}>
														<label className="col-form-label">
															Harga Layanan/Sesi
														</label>
													</Col>
													<Col>
														<Input
															className="form-control bg-light"
															type="text"
															placeholder="Harga dalam rupiah"
															onChange={(e) =>
																handleServicePrice(service.id, e.target.value)
															}
															value={getPriceByServiceId(service.id)}
														/>
													</Col>
												</Row>
											))}
										</Col>
									</Row>
									<Row>
										<Col>
											<div className="d-flex justify-content-end gap-2">
												<Button
													color="primary"
													onClick={() => navigate('/service-price')}
												>
													Batal
												</Button>
												<Button
													color="success"
													className="px-4"
													disabled={isLoading || updatePriceLoading}
												>
													Simpan
												</Button>
											</div>
										</Col>
									</Row>
								</Form>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>

		</>
	)
}

export default FormPrice
