import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
const { VITE_BASE_URL } = import.meta.env

const getToken = (state) => state.authSetup.token

export const patientApi = createApi({
	reducerPath: 'patientApi',
	tagTypes: ['Patient'],
	baseQuery: fetchBaseQuery({
		baseUrl: `${VITE_BASE_URL}/v1/admin`,
		prepareHeaders: (headers, { getState }) => {
			const token = getToken(getState())
			if (token) {
				headers.set('Authorization', `Bearer ${token}`)
			}
			return headers
		},
	}),
	endpoints: (builder) => ({
		getListChild: builder.query({
			query: (url) => {
				const { page, name = '', keyword = '' } = url
				if (!page) {
					return `/child?&name=${name}`
				}

				if (page) {
					return `/child?page=${page}&keyword=${keyword}`
				}
			},
			providesTags: ['GetListChild'],
		}),
		getChildByName: builder.query({
			query: (name) => `/child?name=${name}`,
			providesTags: ['GetChildByName'],
		}),
		getChildById: builder.query({
			query: (id) => `/child/${id}`,
			providesTags: ['GetChildById'],
		}),
		getScheduleByChildId: builder.query({
			query: ({ child_id, month }) =>
				`/child/${child_id}/schedule-date?month=${month}`,
			invalidatesTags: ['GetScheduleDateByChildId'],
		}),
		createPatient: builder.mutation({
			query: (payload) => ({
				url: '/child',
				method: 'POST',
				body: payload,
			}),
			providesTags: ['CreateParent'],
		}),
		createSibling: builder.mutation({
			query: ({ childId, ...payload }) => ({
				url: `/child/${childId}/siblings`,
				method: 'POST',
				body: payload
			})
		}),
		updateChild: builder.mutation({
			query: ({ id, ...payload }) => ({
				url: `/child/${id}`,
				method: 'PUT',
				body: payload,
			}),
		}),
		deleteChild: builder.mutation({
			query: (id) => ({
				url: `/child/${id}`,
				method: 'DELETE',
			}),
			providesTags: ['DeleteChild'],
		}),
		postGetChildById: builder.mutation({
			query: (id) => ({
				url: `/child/${id}`,
				method: 'GET',
			}),
		}),
	}),
	refetchOnMountOrArgChange: true,
	refetchOnFocus: true,
})

export const {
	useGetListChildQuery,
	useCreatePatientMutation,
	useDeleteChildMutation,
	useUpdateChildMutation,
	useGetChildByNameQuery,
	useGetChildByIdQuery,
	useGetScheduleByChildIdQuery,
	usePostGetChildByIdMutation,
	useCreateSiblingMutation
} = patientApi
