import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import {
	Alert,
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Form,
	FormGroup,
	Input,
	Label,
	Row,
} from 'reactstrap'
import HelmetTitle from '../../../components/templates/HelmetTitle'
import { useGetListServiceQuery } from '../../services/api/listServiceApi'
import {
	useCreateTherapistMutation,
	useGetListTherapistByIdQuery,
	useUpdateTherapistMutation,
} from '../api/therapistApi'
import { useSelector } from 'react-redux'
import { authSetupSelector } from '../../../app/authSlice'
import PhoneNumberInput from '../../../components/templates/PhoneNumberInput'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

const initSubmitStatus = {
	status: false,
	type: '',
	message: '',
	headerMessage: '',
}

const FormTherapist = ({ editForm }) => {
	const { id } = useParams()
	const navigate = useNavigate()
	const [checkedService, setCheckedService] = useState([])
	const [postDataStatus, setPostDataStatus] = useState(initSubmitStatus)
	const { branch_id } = useSelector(authSetupSelector)
	const [createTherapist, { isLoading: createLoading }] =
		useCreateTherapistMutation()
	const [updateTherapist, { isLoading: updateLoading }] =
		useUpdateTherapistMutation()
	const { data: fetchTherapsitById, refetch } = useGetListTherapistByIdQuery(
		id,
		{
			skip: !id,
		}
	)

	const schema = yup.object({
		branch_id: yup
			.number()
			.required('ID cabang wajib diisi')
			.notOneOf([0], 'Pilih Cabang!'),
		status: yup.number().required('Status wajib diisi'),
		full_name: yup.string().required('Nama lengkap wajib diisi'),
		nickname: yup.string().required('Nama panggilan wajib diisi'),
		address_of_birth: yup.string().required('Alamat tempat lahir wajib diisi'),
		date_of_birth: yup.string().required('Tanggal lahir wajib diisi'),
		email: yup
			.string()
			.email('Email tidak valid')
			.required('Email wajib diisi'),
		date_join: yup.string().required('Tanggal bergabung wajib diisi'),
		str_number: yup.string().required('Nomor STR wajib diisi'),
		expired_date: yup.string().required('Tanggal berlaku STR wajib diisi'),
		phone_number: yup
			.string()
			.required('Nomor Telepon wajib di isi!')
			.matches(/^\+62\d{10,}$/, 'Format tidak valid & minimal 10 digit sesudah +62!'),
	})

	const { data: fetchService } = useGetListServiceQuery({ endpoint: 'service' })
	const { data: fetchBranchs } = useGetListServiceQuery({ endpoint: 'branchs' })
	const { data: dataServices } = fetchService ?? []
	const { data: dataBranchs } = fetchBranchs?.data ?? []
	const { data: therapistById } = fetchTherapsitById ?? []

	const {
		control,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			full_name: '',
			nickname: '',
			address_of_birth: '',
			date_of_birth: '',
			phone_number: '',
			email: '',
			branch_id: 0,
			status: 1,
			date_join: '',
			str_number: '',
			expired_date: '',
		},
	})

	useEffect(() => {
		if (therapistById) {
			refetch()
			const { services, ...fieldsExceptServices } = therapistById
			const checkService = services.map((item) => item.id)
			setCheckedService(checkService)
			reset(fieldsExceptServices)
		}
	}, [reset, therapistById])

	const handleCheckboxChange = (event) => {
		const value = +event.target.value
		const isChecked = checkedService.includes(value)
		setCheckedService(
			isChecked
				? checkedService.filter((item) => item !== value)
				: [...checkedService, value]
		)
	}

	const handleTherapist = async (param) => {
		setPostDataStatus(initSubmitStatus)
		const payload = {
			...param,
			branch_id: branch_id ? branch_id : param.branch_id,
			status: +param?.status,
			services: checkedService,
		}

		try {
			if (editForm) {
				const result = await updateTherapist({ id, ...payload }).unwrap()
				const { message } = result
				setPostDataStatus({
					status: true,
					message,
					type: 'success',
					headerMessage: 'Berhasil',
				})
				setTimeout(() => {
					navigate('/data-therapist')
				}, 1000)
				return
			}
			const result = await createTherapist(payload).unwrap()
			const { message, status } = result
			if (status === 'ok') {
				setPostDataStatus({
					status: true,
					message,
					type: 'success',
					headerMessage: 'Berhasil',
				})
			}
			setTimeout(() => {
				navigate('/data-therapist')
			}, 1000)
		} catch (error) {
			let msgErr = '';
			if (error.status === 422) {
				for (const property in error.data.data) {
					msgErr += error.data.data[property][0] + "\n";
				}
			}

			if (error.status !== 422) {
				msgErr = error.data.message
			}

			setPostDataStatus({
				status: true,
				type: 'danger',
				message: <>{msgErr}</>,
				headerMessage: 'Terjadi Kesalahan'
			})
		}
	}

	return (
		<>
			<HelmetTitle title={editForm ? 'Edit Terapis' : 'Tambah Terapis'} />
			<Container fluid>
				<Card>
					<CardBody>
						<div className="py-2">
							<h4 className="mb-0">
								{editForm ? 'Edit Terapis' : 'Tambah Terapis'}
							</h4>
							<hr className="border border-light border-2 opacity-50"></hr>
						</div>
						{postDataStatus.status && (
							<Alert color={postDataStatus?.type ?? 'danger'}>
								<h4 className="alert-heading">
									{postDataStatus?.headerMessage}
								</h4>
								<p
									className="mb-0"
									style={{ whiteSpace: 'pre-line' }}
								>
									{postDataStatus.message}
								</p>
							</Alert>
						)}
						<Form onSubmit={handleSubmit(handleTherapist)}>
							<Row>
								<Col lg="12">
									<h5 className="fw-bold mb-3">
										DATA TERAPIS
									</h5>
									<Row className="p-1 mb-3">
										{/* <Controller
												name="type"
												control={control}
												render={({ field: { onChange, onBlur, value } }) => (
													<Input
														type="hidden"
														className="form-control bg-light"
														placeholder="Masukkan Nama Lengkap"
														onChange={onChange}
														onBlur={onBlur}
														value={value}
													/>
												)}
											/> */}
										<Label className="col-form-label col-md-2 text-truncate">
											Nama Lengkap
										</Label>
										<div className="col-md-10">
											<Controller
												name="full_name"
												control={control}
												render={({ field: { onChange, onBlur, value } }) => (
													<>
														<Input
															type="text"
															className="form-control bg-light"
															placeholder="Masukkan Nama Lengkap"
															onChange={onChange}
															onBlur={onBlur}
															value={value}
															invalid={errors.full_name ? true : false}
														/>
														{errors && (
															<small className="text-danger">
																{errors?.full_name?.message}
															</small>
														)}
													</>
												)}
											/>
										</div>
									</Row>
									<Row className="p-1 mb-3">
										<Label className="col-form-label col-md-2 text-truncate">
											Nama Panggilan
										</Label>
										<div className="col-md-10">
											<Controller
												name="nickname"
												control={control}
												render={({ field: { onChange, onBlur, value } }) => (
													<>
														<Input
															type="text"
															className="form-control bg-light"
															placeholder="Masukkan Nama Panggilan"
															onChange={onChange}
															onBlur={onBlur}
															value={value}
															invalid={errors.nickname ? true : false}
														/>
														{errors && (
															<small className="text-danger">
																{errors?.nickname?.message}
															</small>
														)}
													</>
												)}
											/>
										</div>
									</Row>
									<Row className="p-1 mb-3">
										<Label className="col-form-label col-md-2 text-truncate">
											Email
										</Label>
										<div className="col-md-10">
											<Controller
												name="email"
												control={control}
												render={({ field: { onChange, onBlur, value } }) => (
													<>
														<Input
															type="text"
															className="form-control bg-light"
															placeholder="Masukkan Email"
															onChange={onChange}
															onBlur={onBlur}
															value={value}
															invalid={errors.email ? true : false}
														/>
														{errors && (
															<small className="text-danger">
																{errors?.email?.message}
															</small>
														)}
													</>
												)}
											/>
										</div>
									</Row>
									<Row className="p-1 mb-3">
										<Label className="col-form-label col-md-2">
											Tempat Lahir
										</Label>
										<div className="col-md-10">
											<Controller
												name="address_of_birth"
												control={control}
												render={({ field: { onChange, onBlur, value } }) => (
													<>
														<Input
															type="text"
															className="form-control bg-light"
															placeholder="Masukkan Tempat Lahir"
															onChange={onChange}
															onBlur={onBlur}
															value={value}
															invalid={errors.address_of_birth ? true : false}
														/>
														{errors && (
															<small className="text-danger">
																{errors?.address_of_birth?.message}
															</small>
														)}
													</>
												)}
											/>
										</div>
									</Row>
									<Row className="p-1 mb-3">
										<Label className="col-form-label col-md-2">
											Tanggal Lahir
										</Label>
										<div className="col-md-10">
											<Controller
												name="date_of_birth"
												control={control}
												render={({ field: { onChange, onBlur, value } }) => (
													<>
														<Input
															type="date"
															className="form-control bg-light"
															placeholder="Masukkan Tempat Lahir"
															onChange={onChange}
															onBlur={onBlur}
															value={value}
															invalid={errors.date_of_birth ? true : false}
														/>
														{errors && (
															<small className="text-danger">
																{errors?.date_of_birth?.message}
															</small>
														)}
													</>
												)}
											/>
										</div>
									</Row>
									<Row className="p-1 mb-3">
										<Label className="col-form-label col-md-2">No Telp</Label>
										<div className="col-md-10">
											<Controller
												name="phone_number"
												control={control}
												render={({ field: { onChange, onBlur, value } }) => (
													<>
														<PhoneNumberInput
															initialValue={value || '+62'}
															prefixOnZero="+62"
															className="form-control bg-light"
															placeholder="Masukkan No Telp"
															onChange={onChange}
															onBlur={onBlur}
															value={value}
														/>
														{errors && (
															<small className="text-danger">
																{errors?.phone_number?.message}
															</small>
														)}
													</>
												)}
											/>
										</div>
									</Row>
									<Row className="p-1 mb-3">
										<Label className="col-form-label col-md-2">
											Tanggal Masuk Kerja
										</Label>
										<div className="col-md-10">
											<Controller
												name="date_join"
												control={control}
												render={({ field: { onChange, onBlur, value } }) => (
													<>
														<Input
															type="date"
															className="form-control bg-light"
															placeholder="Masukkan Tempat Lahir"
															onChange={onChange}
															onBlur={onBlur}
															value={value}
															invalid={errors.date_join ? true : false}
														/>
														{errors && (
															<small className="text-danger">
																{errors?.date_join?.message}
															</small>
														)}
													</>
												)}
											/>
										</div>
									</Row>
									<Row className="p-1 mb-3">
										<Label className="col-form-label col-md-2">No STR</Label>
										<div className="col-md-7">
											<Controller
												name="str_number"
												control={control}
												render={({ field: { onChange, onBlur, value } }) => (
													<>
														<Input
															type="text"
															className="form-control bg-light"
															placeholder="Masukkan No STR"
															onChange={onChange}
															onBlur={onBlur}
															value={value}
															invalid={errors.str_number ? true : false}
														/>
														{errors && (
															<small className="text-danger">
																{errors?.str_number?.message}
															</small>
														)}
													</>
												)}
											/>
										</div>
										<div className="col-md-3">
											<Controller
												name="expired_date"
												control={control}
												render={({ field: { onChange, onBlur, value } }) => (
													<>
														<Input
															type="date"
															className="form-control bg-light"
															placeholder="Masukkan Tempat Lahir"
															onChange={onChange}
															onBlur={onBlur}
															value={value}
															invalid={errors.expired_date ? true : false}
														/>
														{errors && (
															<small className="text-danger">
																{errors?.expired_date?.message}
															</small>
														)}
													</>
												)}
											/>
										</div>
									</Row>
									<Row className="p-1 mb-3">
										<Label className="col-form-label col-md-2 text-truncate">
											Cabang
										</Label>
										<div className="col-md-10">
											<Controller
												name="branch_id"
												control={control}
												render={({ field: { onChange, onBlur, value } }) => (
													<>
														<Input
															type="select"
															className="form-control bg-light"
															onChange={onChange}
															onBlur={onBlur}
															value={value}
															invalid={errors.branch_id ? true : false}
														>
															<option value="1">Pilih Cabang</option>
															{dataBranchs?.map((item, idx) => (
																<option key={idx} value={item.id}>
																	{item.branch_name}
																</option>
															))}
														</Input>
														{errors && (
															<small className="text-danger">
																{errors?.branch_id?.message}
															</small>
														)}
													</>
												)}
											/>
										</div>
									</Row>
									<Row className="p-1 mb-3">
										<Label className="col-form-label col-md-2 text-truncate">
											Status
										</Label>
										<div className="col-md-10">
											<Controller
												name="status"
												control={control}
												render={({ field: { onChange, onBlur, value } }) => (
													<>
														<Input
															type="select"
															className="form-control bg-light"
															onChange={onChange}
															onBlur={onBlur}
															value={value}
															invalid={errors.status ? true : false}
														>
															<option value="1">Aktif</option>
															{<option value="0">Tidak Aktif</option>}
														</Input>
														{errors && (
															<small className="text-danger">
																{errors?.status?.message}
															</small>
														)}
													</>
												)}
											/>
										</div>
									</Row>
									<Row>
										<Col lg="12">
											<h5 className="fw-bold mb-0">LAYANAN TERAPI</h5>
										</Col>
										<Col lg={6}>
											<Row className="my-4">
												{dataServices?.map((service, idx) => (
													<Col lg="6" key={idx}>
														<FormGroup check className="mb-3">
															<Input
																name="services"
																type="checkbox"
																onChange={(e) => handleCheckboxChange(e)}
																value={service.id}
																invalid={errors.status ? true : false}
																id={`service${service.id}`}
																checked={checkedService.includes(service?.id)}
															/>
															<Label check for={`service${service.id}`}>
																{service?.name}
															</Label>
															{errors && (
																<small className="text-danger">
																	{errors?.service?.message}
																</small>
															)}
														</FormGroup>
													</Col>
												))}
											</Row>
										</Col>
									</Row>
								</Col>
								<Col>
									<div className="d-flex justify-content-end gap-2">
										<Button
											color="primary"
											onClick={() => navigate('/data-therapist')}
										>
											Batal
										</Button>
										<Button
											color="success"
											className="px-4"
											type="submit"
											disabled={createLoading}
										>
											Simpan
										</Button>
									</div>
								</Col>
							</Row>
						</Form>
					</CardBody>
				</Card>
			</Container>
		</>
	)
}

export default FormTherapist
