import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import imgError404 from '../../assets/images/error-img.png'
import { useEffect, useState } from "react";

const Error404 = () => {
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        setInterval(() => {
            setIsLoading(false)
        }, 2500);
    }, []);
    return (
        <>
            {
                isLoading
                &&
                <div id="preloader">
                    <div id="status">
                        <div className="spinner-chase">
                            <div className="chase-dot" />
                            <div className="chase-dot" />
                            <div className="chase-dot" />
                            <div className="chase-dot" />
                            <div className="chase-dot" />
                            <div className="chase-dot" />
                        </div>
                    </div>
                </div>
            }
            <div className="account-pages my-5 pt-5">
                <Container>
                    <Row>
                        <Col lg="12">
                            <div className="text-center mb-5">
                                <h1 className="display-2 fw-medium">
                                    4
                                    <i className="bx bx-buoy bx-spin text-warning display-3" />
                                    4
                                </h1>
                                <h4 className="text-uppercase">Sorry, page not found</h4>
                                <div className="mt-5 text-center">
                                    <Link
                                        className="btn btn-warning "
                                        to="/"
                                    >
                                        Back to Dashboard
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col md="8" xl="6">
                            <div>
                                <img src={imgError404} alt="" className="img-fluid" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Error404;
