import React, { useEffect, useState } from 'react'
import { BiEdit, BiSolidTrashAlt } from 'react-icons/bi'
import { FaCheck, FaEye } from 'react-icons/fa6'
import { useSelector } from 'react-redux'
import {
	Button,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Toast,
	ToastBody,
	ToastHeader,
} from 'reactstrap'
import { layoutSetupSelector } from '../../../app/layoutSlice'
import { useGetListServiceQuery } from '../api/listServiceApi'
import { useDeleteServiceMutation } from './api/serviceApi'
import { useNavigate } from 'react-router-dom'

const initSuccessDelete = {
	status: false,
	message: '',
}

const initViewModal = {
	status: false,
	title: '',
	description: '',
}

const ActionButtonService = ({ id, serviceName, dataSource }) => {
	const navigate = useNavigate()
	const [showModal, setShowModal] = useState(false)
	const [showViewModal, setShowViewModal] = useState(initViewModal)
	const [successDelete, setSuccessDelete] = useState(initSuccessDelete)
	const { tablePage, tableKeyword } = useSelector(layoutSetupSelector)

	const { data: listService, refetch } = useGetListServiceQuery(
		{ endpoint: dataSource, page: tablePage, keyword: tableKeyword },
		{ refetchOnMountOrArgChange: true }
	)
	const [
		deleteService,
		{
			isLoading,
			isError: isErrorDelete,
			error: errorDelete,
			isSuccess: isSuccessDelete
		}
	] = useDeleteServiceMutation()

	const toggleModal = () => {
		setShowModal(!showModal)
	}

	const showData = () => {
		const { data } = listService?.data
		const getDataById = data?.find((service) => service.id === id)
		setShowViewModal({
			status: true,
			title: getDataById.name,
			description: getDataById.description,
		})
	}

	const deleteHandler = async (id) => {
		try {
			await deleteService(id)
			// if (result.data.status === 'ok') {
			// 	setShowModal(!showModal)
			// 	setSuccessDelete({
			// 		status: true,
			// 		message: result.message,
			// 	})

			// 	setTimeout(() => {
			// 		refetch
			// 	}, 800)
			// }
		} catch (error) {
			console.error(error)
		}
	}

	useEffect(() => {
		if (isSuccessDelete) {
			refetch()
			setShowModal(!showModal)
			setSuccessDelete({
				status: true,
				message: 'Berhasil menghapus layanan',
			})
		}
	}, [isSuccessDelete]);

	useEffect(() => {
		if (isErrorDelete) {
			console.error('error delete service:', errorDelete)
		}
	}, [isErrorDelete]);

	return (
		<>
			<div className="d-flex gap-2">
				<button
					className="btn text-primary p-0 btn-light p-1"
					onClick={showData}
				>
					<FaEye size={16} />
				</button>
				<button className="btn text-primary p-0 btn-light p-1" onClick={() => navigate(`/service-type/edit-service/${id}`)}>
					<BiEdit size={16} />
				</button>
				<button
					className="btn text-primary p-0 btn-light p-1"
					onClick={toggleModal}
				>
					<BiSolidTrashAlt size={16} />
				</button>
			</div>
			{successDelete.status && (
				<Toast className="position-absolute" style={{ left: '35%', top: '1%' }}>
					<ToastHeader
						icon={<FaCheck className="text-success" />}
						className="text-success"
					>
						Success Delete
					</ToastHeader>
					<ToastBody>{successDelete.message}</ToastBody>
				</Toast>
			)}
			<div>
				<Modal isOpen={showModal} toggle={toggleModal} centered>
					<ModalHeader toggle={toggleModal}>
						Hapus Layanan <span className="fw-bold">{serviceName}</span> ?
					</ModalHeader>
					<ModalBody>
						<p className="mb-0">
							Yakin ingin menghapus layanan{' '}
							<span className="fw-bold">{serviceName}?</span> Anda tidak bisa
							mengembalikan data layanan yang sudah dihapus
						</p>
					</ModalBody>
					<ModalFooter>
						<Button
							color="danger"
							onClick={() => deleteHandler(id)}
							disabled={isLoading}
						>
							Hapus
						</Button>{' '}
						<Button color="light" onClick={toggleModal} disabled={isLoading}>
							Cancel
						</Button>
					</ModalFooter>
				</Modal>
			</div>
			<div>
				<Modal
					isOpen={showViewModal.status}
					toggle={() => setShowViewModal(initViewModal)}
					centered
				>
					<ModalHeader toggle={() => setShowViewModal(initViewModal)}>
						Detail Layanan
					</ModalHeader>
					<ModalBody>
						<div>
							<label className="fw-bold">Nama Layanan</label>
							<p>{showViewModal?.title}</p>
						</div>
						<div>
							<label className="fw-bold">Deskripsi Layanan</label>
							<p>{showViewModal?.description}</p>
						</div>
					</ModalBody>
				</Modal>
			</div>
		</>
	)
}

export default ActionButtonService
