import React, { useState } from 'react'
import { useEffect } from 'react'

const PhoneNumberInput = ({
	initialValue = '',
	prefixOnZero = '+62',
	...props
}) => {
	const [phoneNumber, setPhoneNumber] = useState(initialValue)

	useEffect(() => {
		props.onChange(phoneNumber)
	}, [phoneNumber])

	useEffect(() => {
		if (initialValue) {
			setPhoneNumber(initialValue)
		}
	}, [initialValue])

	const handleChange = (e) => {
		const { value } = e.target

		if (value === '0') {
			setPhoneNumber(prefixOnZero)
		} else {
			setPhoneNumber(value)
		}
	}

	return (
		<div>
			<input
				className={props.className}
				placeholder={props.placeholder}
				type="text"
				value={phoneNumber}
				onChange={handleChange}
				onBlur={props.onBlur}
			/>
		</div>
	)
}

export default PhoneNumberInput
