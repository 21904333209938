import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
const { VITE_BASE_URL } = import.meta.env

const getToken = (state) => state.authSetup.token

export const therapistApi = createApi({
	reducerPath: 'therapistApi',
	tagTypes: ['Therapist'],
	baseQuery: fetchBaseQuery({
		baseUrl: `${VITE_BASE_URL}/v1/admin`,
		prepareHeaders: (headers, { getState }) => {
			const token = getToken(getState())
			if (token) {
				headers.set('Authorization', `Bearer ${token}`)
			}
			return headers
		},
	}),
	endpoints: (builder) => ({
		getListTherapist: builder.query({
			query: (url) => {
				const { page = 1, keyword = '' } = url
				return `/therapist?page=${page}&keyword=${keyword}`
			},
			providesTags: ['GetListTherapist'],
		}),
		createTherapist: builder.mutation({
			query: (payload) => ({
				url: '/therapist',
				method: 'POST',
				body: payload,
			}),
			providesTags: ['CreateTherapist'],
		}),
		updateTherapist: builder.mutation({
			query: ({ id, ...payload }) => ({
				url: `/therapist/${id}`,
				method: 'PUT',
				body: payload
			})
		}),
		deleteTherapist: builder.mutation({
			query: (id) => ({
				url: `/therapist/${id}`,
				method: 'DELETE',
			}),
			providesTags: ['DeleteTherapist'],
		}),
		getListTherapistById: builder.query({
			query: (id) => ({
				url: `/therapist/${id}`
			}),
			providesTags: ['GetTherapistById']
		}),
		getTherapistByServiceId: builder.query({
			query: (service_id) => ({
				url: `/therapist?service_id=${service_id}`
			}),
			providesTags: ['GetTherapistByServiceid']
		})
	}),
	refetchOnMountOrArgChange: true,
	refetchOnFocus: true,
})

export const {
	useGetListTherapistQuery,
	useCreateTherapistMutation,
	useUpdateTherapistMutation,
	useDeleteTherapistMutation,
	useGetListTherapistByIdQuery,
	useGetTherapistByServiceIdQuery
} = therapistApi
